
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import ArtikelRow from '../custom/ArtikelRow.vue'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import Bestellung from '@/entities/common/bestellung'
import BestellungSofortLogic from '@/logic/member/bestellung_sofort_logic'

@Component({ components: { ArtikelRow } })
export default class BestellungSofort extends mixins(Calculator) {
  @Prop() bestellung?: Bestellung = null
  logic?: BestellungSofortLogic = null

  @Watch('bestellung')
  watchBestellung(newValue: Bestellung) {
    this.logic.init(newValue, this.bestellungAbgeschlossen)
  }

  mounted() {
    this.logic = new BestellungSofortLogic()
    this.logic.init(this.bestellung, this.bestellungAbgeschlossen)
  }

  @Emit('bestellung-abgeschlossen')
  bestellungAbgeschlossen() {
  }

  abschliessen() {
    this.logic.abschliessen()
  }

  stornieren() {
    this.logic.stornieren()
  }
}
