import vuexStore from "@/store";

export default class MemberBaseLogic {
  get user() {
    return vuexStore.state.user
  }

  get isAdmin() {
    return vuexStore.state.user.admin || vuexStore.state.user.role === 'system'

  }
}