
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import ArtikelRow from '../custom/ArtikelRow.vue'
import Kuechenaufgabe from '@/entities/member/kuechenaufgabe'
import { Prop } from 'vue-property-decorator'
import { AbholzeitType, LieferungStatus } from '@/entities/common/enums'
import LieferungStatusUpdateRequest from '@/entities/member/request/lieferung_status_update_request'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import RestPaths from '@/util/rest_paths'

@Component({ components: { ArtikelRow } })
export default class KuecheDetailLieferung extends mixins(Calculator) {
  @Prop() aufgabe: Kuechenaufgabe

  formatiereGewuenschteLieferzeit(): string {
    if (!this.aufgabe) return null
    if (this.aufgabe.lieferung.type == AbholzeitType.BALDEST) {
      return 'schnellstmöglich'
    } else if (this.aufgabe.lieferung.type == AbholzeitType.UHRZEIT) {
      return this.formatiereDatumUhrzeit(this.aufgabe.lieferung.gewLieferzeit)
    }
  }

  formatiereStatus(): string {
    if (!this.aufgabe) return null
    switch (this.aufgabe.lieferung.status) {
      case LieferungStatus.ABGESENDET:
        return 'noch nicht angenommen'
      case LieferungStatus.ABGELEHNT:
        return 'abgelehnt'
      case LieferungStatus.IN_ZUBEREITUNG:
        return 'in Zubereitung'
      case LieferungStatus.ZUBEREITET:
        return 'zubereitet'
      case LieferungStatus.IN_LIEFERUNG:
        return 'in Lieferung'
      case LieferungStatus.GELIEFERT:
        return 'geliefert'
    }
    return '---'
  }

  berechneGesamtpreis(): number {
    if (!this.aufgabe) return 0
    var lieferkosten = this.aufgabe.lieferung.lieferkosten
    var gesamtpreis = this.berechnePreisListe(this.aufgabe.artikelliste)
    if (lieferkosten) {
      gesamtpreis += lieferkosten
    }
    return gesamtpreis
  }

  get lieferungAnnehmenVisible() {
    return this.aufgabe.lieferung.status === LieferungStatus.ABGESENDET
  }

  get lieferungAblehnenVisible() {
    return this.aufgabe.lieferung.status === LieferungStatus.ABGESENDET
  }

  get lieferungInZubereitungVisible() {
    return this.aufgabe.lieferung.status === LieferungStatus.ANGENOMMEN
  }

  get lieferungZubereitetVisible() {
    return this.aufgabe.lieferung.status === LieferungStatus.IN_ZUBEREITUNG
  }

  get lieferungInLieferungVisible() {
    return this.aufgabe.lieferung.status === LieferungStatus.ZUBEREITET
  }

  get lieferungGeliefertVisible() {
    return this.aufgabe.lieferung.status === LieferungStatus.IN_LIEFERUNG
  }

  lieferungAnnehmen() {
    var request = new LieferungStatusUpdateRequest()
    request.id = this.aufgabe.id
    request.lieferungStatus = LieferungStatus.ANGENOMMEN

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterStatusUpdateLieferung,
      request
    ).then((response) => {
      if (response.data.success) {
        this.aufgabe.lieferung.status = LieferungStatus.ANGENOMMEN
      }
    })
  }

  lieferungAblehnen() {
    var request = new LieferungStatusUpdateRequest()
    request.id = this.aufgabe.id
    request.lieferungStatus = LieferungStatus.ABGELEHNT

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterStatusUpdateLieferung,
      request
    ).then((response) => {
      if (response.data.success) {
        this.aufgabe.lieferung.status = LieferungStatus.ABGELEHNT
      }
    })
  }

  lieferungInZubereitung() {
    var request = new LieferungStatusUpdateRequest()
    request.id = this.aufgabe.id
    request.lieferungStatus = LieferungStatus.IN_ZUBEREITUNG

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterStatusUpdateLieferung,
      request
    ).then((response) => {
      if (response.data.success) {
        this.aufgabe.lieferung.status = LieferungStatus.IN_ZUBEREITUNG
      }
    })
  }

  lieferungZubereitet() {
    var request = new LieferungStatusUpdateRequest()
    request.id = this.aufgabe.id
    request.lieferungStatus = LieferungStatus.ZUBEREITET

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterStatusUpdateLieferung,
      request
    ).then((response) => {
      if (response.data.success) {
        this.aufgabe.lieferung.status = LieferungStatus.GELIEFERT
      }
    })
  }

  lieferungInLieferung() {
    var request = new LieferungStatusUpdateRequest()
    request.id = this.aufgabe.id
    request.lieferungStatus = LieferungStatus.IN_LIEFERUNG

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterStatusUpdateLieferung,
      request
    ).then((response) => {
      if (response.data.success) {
        this.aufgabe.lieferung.status = LieferungStatus.IN_LIEFERUNG
      }
    })
  }

  lieferungGeliefert() {
    var request = new LieferungStatusUpdateRequest()
    request.id = this.aufgabe.id
    request.lieferungStatus = LieferungStatus.GELIEFERT

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterStatusUpdateLieferung,
      request
    ).then((response) => {
      if (response.data.success) {
        this.aufgabe.lieferung.status = LieferungStatus.GELIEFERT
      }
    })
  }
}
