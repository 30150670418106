import bestellung_filiale from '@/entities/common/bestellung_filiale'
import bstl_artikel from '@/entities/common/bstl_artikel'
import pliste_produkt_zeit, {
  PreislisteProdukt,
} from '@/entities/common/pliste_produkt_zeit'
import router from '@/router'
import RouteNames from '@/util/route_names'
import ProdukteLogic from '@/logic/produkte_logic'
import store from '@/store'
import PreislisteZeitraumRequest from '@/entities/common/request/preisliste_zeitpunkt_request'
import { Terminart } from '@/entities/common/enums'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import PlisteProduktZeitListe from '@/entities/common/lists/pliste_produkt_zeit_liste'
import RestPaths from '@/util/rest_paths'
import PlisteProduktZeit from '@/entities/common/pliste_produkt_zeit'
import BstlArtikel from '@/entities/common/bstl_artikel'
import BestellungData from '@/util/bestellung_data'
import Bestellung from '@/entities/common/bestellung'
import NachbestellenInsertRequest from '@/entities/common/request/nachbestellen_insert_request'
import { v4 as uuidv4 } from 'uuid'
import ConverterUtil from '@/util/converter_util'
import StoreOps from '@/util/store_ops'
import Nachbestellung from '@/entities/common/nachbestellung'

export default class NachbestellungLogic implements ProdukteLogic {
  
  filiale?: bestellung_filiale = null
  preisliste: PlisteProduktZeit = null
  warenkorb?: bstl_artikel[] = []
  created = false
  artikel: PreislisteProdukt
  bestellung: Bestellung = null
  bestellungCompleted: boolean = false
  createdListener: () => void

  artikelWaehlen(produkt: PreislisteProdukt): void {
    this.artikel = produkt
    router.push({
      name: RouteNames.BESTELLUNG.NACHBESTELLEN.ARTIKEL,
      params: { id: this.artikel.id },
    })
  }

  get warenkorbGefuellt(): boolean {
    return this.warenkorb.length > 0
  }

  bestellungEinfuegen(): void {
    store.commit(StoreOps.UPDATE_BESTELLUNG_ARTIKEL_BESTELLUNG, this.warenkorb)
    router.push({
      name: RouteNames.BESTELLUNG.NACHBESTELLEN.BESTAETIGEN,
    })
  }

  get hasMindestbestellwert() {
    return false
  }

  get mindestbestellwert() {
    return 0
  }

  get kostenlosAb() {
    return 0
  }
  
  init() {
    var nachbestellungData = store.state.bestellung as BestellungData
    this.bestellung = nachbestellungData.bestellung
    this.warenkorb = nachbestellungData.artikelliste

    this.filiale = store.state.bestellung.bestellung.filiale

    var request = new PreislisteZeitraumRequest()
    var terminart = this.bestellung.terminart

    if (terminart == Terminart.RESERVIERUNG) {
      request.zeitpunkt = this.bestellung.reservierung.zeitVon
    } else if (terminart == Terminart.SOFORTBESTELLUNG) {
      request.zeitpunkt = this.bestellung.bestellZp
    }

    request.filialeId = this.bestellung.filiale.filialeId

    Poster.postUser<RemoteResult<PlisteProduktZeit>>(
      RestPaths.artikelPdukteNachZeitSingle,
      request
    ).then((response) => {
      this.preisliste = response.data.payload
      //this.created = true
      //if (this.createdListener) {
      //  this.createdListener()
      //}
    })
  }

  reloadArtikel(id: string) {
    this.preisliste.kategorien.forEach((kategorie) => {
      kategorie.produkte.forEach((p) => {
        if (p.id == id) {
          this.artikel = p
          return
        }
      })
    })
  }

  addArtikel(artikel: BstlArtikel) {
    this.warenkorb.push(artikel)
    router.go(-1)
  }

  bestellungAufgeben() {
    var request = new NachbestellenInsertRequest()
    request.id = uuidv4()
    request.bestellungId = this.bestellung.id

    this.warenkorb.forEach((element) => {
      request.artikel.push(ConverterUtil.convertBstlArtikel(element))
    })

    Poster.postUser<RemoteResult<Nachbestellung>>(
      RestPaths.bestellungKundeNachbestellungEinfuegen,
      request
    ).then((response) => {
      if (response.data.success) {
        this.bestellungCompleted = true
      }
    })
  }

  backToOverview() {
    router.push({
      name: RouteNames.BESTELLUNG.DETAILS,
      params: { id: this.bestellung.id },
    })
  }
}
