import BestellungFiliale from '@/entities/common/bestellung_filiale'
import router from '@/router'
import store from '@/store'
import RouteNames from '@/util/route_names'

export default class ReservierungSelectVorbestellungLogic {
  filiale?: BestellungFiliale = null

  init() {
    this.filiale = store.state.neueBestellungData.filiale
  }

  produkteVorbestellen() {
    router.push({
      name: RouteNames.NEU.RESERVIETUNG_VORBESTELLUNG.PRODUKTE,
    })
  }

  reservierungAbschliessen() {
    router.push({
      name: RouteNames.BESTELLUNG.DETAILS,
      params: { id: store.state.neueBestellungData.bestellungId },
    })
  }
}
