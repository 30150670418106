
import OeffnungszeitFilialeLogic from '@/logic/member/oeffnungszeit_filiale_logic'
import Vue from 'vue'
import Component from 'vue-class-component'
import OeffnungszeitFilialeMemCommon from '../memcommon/OeffnungszeitFilialeMemCommon.vue'

@Component({ components: { OeffnungszeitFilialeMemCommon } })
export default class OeffnungszeitFiliale extends Vue {
  logic?: OeffnungszeitFilialeLogic = null

  mounted() {
    this.logic = new OeffnungszeitFilialeLogic()
    this.logic.init(this.$route.params.filialeId)
  }
}
