
import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import Tisch from '@/entities/common/tisch'
import ReservierungenLogic from '@/logic/member/reservierungen_logic'

@Component
export default class Reservierungen extends mixins(Calculator) {
  $refs!: {
    datumMenu: any
  }

  logic?: ReservierungenLogic = null

  bestellungAnzeigen(bestellung) {
    this.logic.bestellungAnzeigen(bestellung)
  }

  neueReservierungEinfuegen() {
    this.logic.neueReservierungEinfuegen()
  }

  createNewReserv(tisch: string) {
    this.logic.createNewReserv(tisch)
  }

  showEvent({ event }) {
    this.logic.showEvent(event)
  }

  fetchEvents({ start }) {
    this.logic.fetchEvents(start)
  }

  formatTime(time) {
    return this.logic.formatTime(time)
  }

  createNewReservierung(tisch: Tisch, uhrzeit: string) {
    this.logic.createNewReservierung(tisch, uhrzeit)
  }

  reservierungStornieren() {
    this.logic.reservierungStornieren()
  }

  mounted() {
    this.logic = new ReservierungenLogic()
    this.logic.updateListener = () => {
      var kalender = document.getElementById('kalender')
      var rect = kalender.getBoundingClientRect()
      var newHeight = window.innerHeight - rect.top
      kalender.style.height = newHeight + 'px'
    }
    this.logic.init(
      this.$route.query.filiale as string,
      this.$route.query.datum as string
    )
  }

  updateReservierungen() {
    this.logic.updateReservierungen()
  }
}
