import router from '@/router'
import vuexStore from '@/store'
import RouteNames from '@/util/route_names'
import FilialeDetailsMemcommonLogic from '../memcommon/filiale_details_memcommon_logic'

export default class FilialeDetailsLogic extends FilialeDetailsMemcommonLogic {
  oeffnungszeitenBearbeiten() {
    router.push({
      name: RouteNames.MEMBER.OEFFNUNGSZEIT_DETAILS,
      params: { filialeId: this.filiale.id },
    })
  }

  oeffnungszeitenFilialeBearbeiten() {
    router.push({
      name: RouteNames.MEMBER.OEFFNUNGSZEIT_FILIALE,
      params: { filialeId: this.filiale.id },
    })
  }

  preislistenBearbeiten() {
    router.push({
      name: RouteNames.MEMBER.PREISLISTEN_FILIALE,
      params: { filialeId: this.filiale.id },
    })
  }

  tischeBearbeiten() {
    router.push({
      name: RouteNames.MEMBER.TISCHE_FILIALE,
      params: { filialeId: this.filiale.id },
    })
  }

  liefergebietZuordnen(): void {
    router.push({
      name: RouteNames.MEMBER.FILIALE_LIEFERGEBIET_ZUORDNEN,
      params: { id: this.filiale.id },
    })
  }

  get isAdmin(): boolean {
    return vuexStore.state.user.admin
  }

  navBearbeiten(): void {
    router.push({
      name: RouteNames.MEMBER.FILIALE_EDIT,
      params: { id: this.filiale.id },
    })
  }
}
