
import OeffnungszeitAusnahmenFilialeLogic from '@/logic/system/oeffnungszeit_ausnahmen_filiale_logic'
import Vue from 'vue'
import Component from 'vue-class-component'
import OeffnungszeitAusnahmenFilialeMemCommon from '../memcommon/OeffnungszeitAusnahmenFilialeMemCommon.vue'

@Component({ components: { OeffnungszeitAusnahmenFilialeMemCommon } })
export default class OeffnungszeitAusnahmenFiliale extends Vue {
  logic?: OeffnungszeitAusnahmenFilialeLogic = null

  mounted() {
    this.logic = new OeffnungszeitAusnahmenFilialeLogic()
    this.logic.init(this.$route.params.filialeId)
  }
}
