import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Betrieb from '@/entities/member/betrieb'
import router from '@/router'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import BetriebObserver from '@/logic/system/betrieb_observer'
import FilialeSimple from '@/entities/member/filiale_simple'
import FilialeSimpleList from '@/entities/member/lists/filiale_simple_list'

export default class BetriebDetailsLogic {
  betrieb?: Betrieb
  drawerOpen: boolean = true
  filialen: FilialeSimple[] = []

  private observers: BetriebObserver[] = []

  public attachObserver(observer: BetriebObserver): void {
    const isExist = this.observers.includes(observer)
    if (isExist) {
      return
    }

    this.observers.push(observer)
  }

  public detachObserver(observer: BetriebObserver): void {
    const observerIndex = this.observers.indexOf(observer)
    if (observerIndex === -1) {
      return
    }

    this.observers.splice(observerIndex, 1)
  }

  init(id: string) {
    var request = new IdRequest()
    request.id = id

    Poster.postMember<RemoteResult<Betrieb>>(
      RestPaths.betriebDetails,
      request
    ).then((response) => {
      this.betrieb = response.data.payload
    })

    var fRequest = new IdRequest()
    fRequest.id = id

    Poster.postMember<RemoteResult<FilialeSimpleList>>(
      RestPaths.filialeListeSimple,
      request
    ).then((response) => {
      this.filialen = response.data.payload.list
    })
  }

  navBestellverwaltung() {
    router.push({ name: RouteNames.SYSTEM.BESTELLVERWALTUNG })
  }

  navPage() {
    router.push({ name: RouteNames.SYSTEM.BETRIEB })
  }

  navMitarbeiter() {
    router.push({ name: RouteNames.SYSTEM.MITARBEITER })
  }

  navFilialen() {
    router.push({ name: RouteNames.SYSTEM.FILIALEN })
  }

  navProdukte() {
    router.push({ name: RouteNames.SYSTEM.PRODUKTE })
  }

  navPreislisten() {
    router.push({ name: RouteNames.SYSTEM.PREISLISTEN })
  }

  navOeffnungszeiten() {
    router.push({ name: RouteNames.SYSTEM.OEFFNUNGSZEITEN })
  }

  navTische() {
    router.push({ name: RouteNames.SYSTEM.TISCHE })
  }

  sendRechnung() {
    var request = new IdRequest()
    request.id = this.betrieb.id

    Poster.postMember<RemoteResult<Boolean>>(
      RestPaths.auswertungRechnungVersenden,
      request
    ).then((response) => {})
  }
}
