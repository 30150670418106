
import PasswortAendernLogic from '@/logic/member/passwort_aendern_logic'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class PasswortAendern extends Vue {
  logic?: PasswortAendernLogic = new PasswortAendernLogic()

  showPassword: boolean = false

  passwortAendern() {
    this.logic.passwortAendern()
  }
}
