
import Component, { mixins } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'
import Calculator from '../mixins/Calculator.vue'
import OeffnungszeitAusnahmenLogic from '@/logic/member/oeffnungszeit_ausnahmen_logic'

@Component
export default class OeffnungszeitAusnahmenMemCommon extends mixins(
  Calculator
) {
  @Prop() logic: OeffnungszeitAusnahmenLogic

  getEventColor(date) {
    return this.logic.getEventColor(date)
  }

  functionEvents(date) {
    this.logic.functionEvents(date)
  }

  reloadDates(val) {
    this.logic.reloadDates(val)
  }

  ausnahmeEinfuegen() {
    this.logic.ausnahmeEinfuegen()
  }

  oeffnungHinzufuegen() {
    this.logic.oeffnungHinzufuegen()
  }

  ausnahmeLoeschen() {
    this.logic.ausnahmeLoeschen()
  }

  saveTimeVon(index, time) {
    this.$refs['von-' + index][0].save(time)
  }

  saveTimeBis(index, time) {
    this.$refs['bis-' + index][0].save(time)
  }
}
