
import NewPreislisteFilialeNeuLogic from '@/logic/system/new_preisliste_filiale_neu_logic'
import Vue from 'vue'
import Component from 'vue-class-component'
import NewPreislisteFilialeNeuMemCommon from '../memcommon/NewPreislisteFilialeNeuMemCommon.vue'
import BetriebDetails from './BetriebDetails.vue'

@Component({ components: { NewPreislisteFilialeNeuMemCommon } })
export default class NewPreislisteFilialeNeu extends Vue {
  logic?: NewPreislisteFilialeNeuLogic = null

  mounted() {
    var betriebId = (this.$parent as BetriebDetails).logic.betrieb.id
    var filialeId = this.$route.params.filialeId

    this.logic = new NewPreislisteFilialeNeuLogic()
    this.logic.init(filialeId, betriebId)
  }
}
