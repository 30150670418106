
import Produkte from '@/components/custom/Produkte.vue'
import Component from 'vue-class-component'
import { Vue, Watch } from 'vue-property-decorator'
import ReservierungBestellungLogic from '@/logic/bestellung/reservierung_bestellung_logic'
import ReservierungBestellung from './ReservierungBestellung.vue'

@Component({ components: {Produkte } })
export default class ReservierungBestellungProdukte extends Vue {
  logic?: ReservierungBestellungLogic = null

  mounted() {
    this.logic = (this.$parent as ReservierungBestellung).logic
  }

  // @Watch('$parent.logic')
  // watchLogic(value) {
  //   this.logic = value
  // }
}
