import Bestellung from '@/entities/common/bestellung'
import DateTimeCapsule from '@/entities/common/data_time_capsule'
import {
  Bezahlart,
  BuchungBestellart,
  Terminart,
} from '@/entities/common/enums'
import RemoteResult from '@/entities/common/remote_result'
import BestellungInsertRequest from '@/entities/common/request/bestellung_insert_request'
import BuchungsartInsertRequest from '@/entities/common/request/buchungsart_insert_request'
import IdRequest from '@/entities/common/request/id_request'
import TischMitFiliale from '@/entities/common/tisch_mit_filiale'
import router from '@/router'
import store from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import StoreOps from '@/util/store_ops'
import { v4 as uuidv4 } from 'uuid'

export default class TerminQrcodeLogic {
  filialeTisch?: TischMitFiliale = null
  wirdGebucht: boolean = false
  buchungsart: string = BuchungBestellart.SELBST
  showBuchungsart: boolean = false
  serviceWirdAngefordert: boolean = false

  init(id: string) {
    var request = new IdRequest()
    request.id = id

    Poster.postUser<RemoteResult<TischMitFiliale>>(
      RestPaths.tischMitFiliale,
      request
    ).then((response) => {
      if (response.data.success) {
        this.filialeTisch = response.data.payload
      }
    })
  }

  berechneBildId() {
    var filiale = this.filialeTisch.filiale
    if (filiale.bildId) {
      return RestPaths.urlBase + RestPaths.imageImage + '/' + filiale.bildId
    }
    return null
  }

  buchungsartWaehlen() {
    var request = new BuchungsartInsertRequest()
    request.bestellart = this.buchungsart
    request.id = store.state.neueBestellungData.bestellungId

    Poster.postUser<RemoteResult<DateTimeCapsule>>(
      RestPaths.bestellungKundeTischBuchungsartWaehlen,
      request
    ).then((response) => {
      if (response.data.success) {
        if (this.buchungsart == BuchungBestellart.SELBST) {
          router.push({ name: RouteNames.NEU.SHOPPING.PRODUKTE })
        } else if (this.buchungsart == BuchungBestellart.SERVICE) {
          this.serviceWirdAngefordert = true
        }
      }
    })
  }

  tischBuchen() {
    this.wirdGebucht = true
    var request = new BestellungInsertRequest()
    var id = uuidv4()

    var payload = {
      id: id,
      filialeTisch: this.filialeTisch.filiale,
    }
    store.commit(StoreOps.UPDATE_QR_CODE_TISCH, payload)

    request.id = id
    request.terminart = Terminart.SOFORTBESTELLUNG
    request.identifier = store.state.user.key
    request.filialeId = this.filialeTisch.filiale.filialeId
    request.bezahlart = Bezahlart.BARZAHLUNG

    request.createSofortbestellung()
    request.sofort.id = uuidv4()
    request.sofort.bestellart = BuchungBestellart.SELBST
    request.sofort.personen = 2
    request.sofort.tischId = this.filialeTisch.tisch.id

    Poster.postUser<RemoteResult<Bestellung>>(
      RestPaths.bestellungKundeEinfuegen,
      request
    ).then((response) => {
      if (response.data.success) {
        this.wirdGebucht = false
        this.showBuchungsart = true
      }
    })
  }
}
