import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import OeffnungszeitZeitraum from '@/entities/member/oeffnungszeit_zeitraum'
import router from '@/router'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import OeffnungszeitDetailsMemcommonLogic from '../memcommon/oeffnungszeit_details_memcommon_logic'

export default class OeffnungszeitDetailsLogic extends OeffnungszeitDetailsMemcommonLogic {
  ausnahmenHinzufuegen(): void {
    throw new Error('Method not implemented.')
  }
  
  listeBearbeiten(): void {
    router.push({
      name: RouteNames.SYSTEM.OEFFNUNGSZEIT_EDIT,
      params: { id: this.oeffnungszeit.filialeId },
    })
  }
}
