import Produktkategorie from '@/entities/common/produktkategorie'
import ProduktkategorieInsertRequest from '@/entities/member/request/produktkategorie_insert_request'
import { v4 as uuidv4 } from 'uuid'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import RestPaths from '@/util/rest_paths'
import IdRequest from '@/entities/common/request/id_request'
import ProduktkategorieList from '@/entities/member/lists/produktkategorie_list'
import ProduktkategorieUpdateRequest from '@/entities/member/request/produktkategorie_update_request'
import MemberBaseLogic from '../member_base_logic'

export default class KategorienMemcommonLogic extends MemberBaseLogic {
  liste: Produktkategorie[] = []
  kategorieNeuVisible: boolean = false
  editDialogVisible: boolean = false

  kategorie: Kategorie = new Kategorie()
  aktuelleKategorie: Produktkategorie = null

  listWorking: boolean = false
  listError: boolean = false

  insertWorking: boolean = false
  insertError: boolean = false
  insertFormDisable: boolean = false

  editWorking: boolean = false
  editError: boolean = false
  editFormDisabled: boolean = false

  public betriebId: string

  requiredRule = [
    (v: string) => {
      if (!v) return 'Bitte Bezeichnung angeben'
      return true
    },
  ]

  init() {
    this.loadKategorien()
  }

  neueKategorieEinfuegen() {
    this.insertFormDisable = true
    this.insertWorking = true
    this.insertError = false

    var request = new ProduktkategorieInsertRequest()
    request.id = uuidv4()
    request.betriebId = this.betriebId
    request.bezeichnung = this.kategorie.bezeichnung

    Poster.postMember<RemoteResult<Produktkategorie>>(
      RestPaths.produktKategorieEinfuegen,
      request
    )
      .then((response) => {
        this.insertWorking = false
        if (response.data.success) {
          this.liste.push(response.data.payload)
          this.kategorie = new Kategorie()
          this.kategorieNeuVisible = false
        } else {
          this.insertError = true
          this.insertFormDisable = false
        }
      })
      .catch(() => {
        this.insertWorking = false
        this.insertError = true
        this.insertFormDisable = false
      })
  }

  neueKategorie() {
    this.kategorieNeuVisible = true
  }

  private loadKategorien() {
    var request = new IdRequest()
    request.id = this.betriebId

    this.listWorking = true
    this.listError = false

    Poster.postMember<RemoteResult<ProduktkategorieList>>(
      RestPaths.produktKategorieListe,
      request
    )
      .then((response) => {
        this.listWorking = false
        if (response.data.success) {
          this.liste = response.data.payload.list
        } else {
          this.listError = true
        }
      })
      .catch(() => {
        this.listWorking = false
        this.listError = true
      })
  }

  bearbeiten(kategorie: Produktkategorie) {
    this.aktuelleKategorie = kategorie
    this.kategorie = new Kategorie()
    this.kategorie.bezeichnung = kategorie.bezeichnung
    this.editDialogVisible = true
    this.editFormDisabled = false
    //this.aktuelleKategorie.bezeichnung = kategorie.bezeichnung
  }

  speichern() {
    this.editWorking = true
    this.editError = false
    this.editFormDisabled = true

    var request = new ProduktkategorieUpdateRequest()
    request.id = this.aktuelleKategorie.id
    request.bezeichnung = this.kategorie.bezeichnung

    Poster.postMember<RemoteResult<Produktkategorie>>(
      RestPaths.produktKategorieUpdate,
      request
    )
      .then((response) => {
        this.editWorking = false
        if (response.data.success) {
          this.editDialogVisible = false
          this.aktuelleKategorie.bezeichnung = response.data.payload.bezeichnung
          //this.aktuelleKategorie = null
          //this.kategorie = null
        } else {
          this.editFormDisabled = false
          this.editError = true
        }
      })
      .catch(() => {
        this.editWorking = false
        this.editError = true
        this.editFormDisabled = false
      })
  }
}

class Kategorie {
  bezeichnung = ''
}
