import BestellungFiliale from '@/entities/common/bestellung_filiale'
import BstlArtikel from '@/entities/common/bstl_artikel'
import { Bezahlart } from '@/entities/common/enums'
import PlisteProduktZeit from '@/entities/common/pliste_produkt_zeit'
import Tisch from '@/entities/common/tisch'

export default class Nbd {
  bestellungId?: string
  terminart?: string
  artikelliste: BstlArtikel[] = []
  filiale?: BestellungFiliale
  bezahlart?: string = Bezahlart.BARZAHLUNG
  preislisten: PlisteProduktZeit[] = []

  reservierungData: ReservierungData = new ReservierungData()
  abholungData: AbholungData = new AbholungData()
  sofortbestellungData: SofortbestellungData = new SofortbestellungData()
  lieferungData: LieferungData = new LieferungData()
}



class ReservierungData {
  datum?: string
  kundeVorname?: string
  kundeNachname?: string
  kundeEmail?: string
  kundeTelefon?: string
  tisch?: Tisch
  zeitVon?: string
  zeitBis?: string
  anzahlPersonen?: number
}

class AbholungData {
  datum = ''
  abholzeitType? : string
  gewaehlteUhrzeit?: string
  kundeNachname?: string
  kundeVorname?: string
  kundeTelefon?: string
  kundeEmail?: string
  direktabholung: boolean = false
  tischId: string
  tischBezeichnung: string
  verzehrart: string
}

class SofortbestellungData {
  tisch?: Tisch
  bestellart?: null
}

class LieferungData {
  datum: string = ''
  gewaehlteUhrzeit?: string
  abholzeitType?: string
  kundeNachname?: string
  kundeVorname?: string
  kundeTelefon?: string
  kundeEmail?: string
  kundeStrasse?: string
  kundePlz?: string
  kundeOrt?: string
  kundeAdresszusatz?: string
  kommentare?: string
}

export {ReservierungData, AbholungData, SofortbestellungData, LieferungData}
