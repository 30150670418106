
import PasswortAendernLogic from '@/logic/bestellung/passwort_aendern_logic'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class PasswortAendern extends Vue {
  logic?: PasswortAendernLogic = null

  showPassword: boolean = false

  mounted() {
    this.logic = new PasswortAendernLogic()
  }

  passwortAendern() {
    this.logic.passwortAendern()
  }
}
