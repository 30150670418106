
import OeffnungszeitEditLogic from '@/logic/member/oeffnungszeit_edit_logic'
import Component, { mixins } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'
import Calculator from '../mixins/Calculator.vue'
import OeffnungstagVorlage from '@/entities/member/oeffnungstag_vorlage'

@Component
export default class OeffnungszeitEditMemCommon extends mixins(Calculator) {
  $refs: {
    form: HTMLFormElement
  }

  @Prop() logic: OeffnungszeitEditLogic

  gebeOeffnungszeitMin(vorlage: OeffnungstagVorlage, index: number): string {
    return this.logic.gebeOeffnungszeitMin(vorlage, index)
  }

  checkExtraButtonDisabled(
    vorlage: OeffnungstagVorlage,
    index: number
  ): boolean {
    return this.logic.checkExtraButtonDisabled(vorlage, index)
  }

  zeitEntfernen(vorlage: OeffnungstagVorlage, index: number) {
    this.logic.zeitEntfernen(vorlage, index)
  }

  extraZeitHinzufuegen(vorlage: OeffnungstagVorlage) {
    this.logic.extraZeitHinzufuegen(vorlage)
  }

  zeitVonSpeichern(value, indexI, indexJ) {
    this.$refs['von-' + indexI + '-' + indexJ][0].save(value)
  }

  zeitBisSpeichern(value, indexI, indexJ) {
    this.$refs['bis-' + indexI + '-' + indexJ][0].save(value)
  }

  speichern() {
    var valid = this.$refs.form.validate()
    if (!valid) return

    this.logic.speichern()
  }
}
