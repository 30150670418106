
import Vue from 'vue'
import Component from 'vue-class-component'
import BasiskategorienLogic from '@/logic/system/basiskategorien_logic'
import BetriebDetails from './BetriebDetails.vue'
import BasiskategorienMemCommon from '@/components/memcommon/BasiskategorienMemCommon.vue'

@Component({ components: { BasiskategorienMemCommon} })
export default class Basiskategorien extends Vue {
  logic?: BasiskategorienLogic = null

  mounted() {
    this.logic = new BasiskategorienLogic()
    this.logic.betriebId = ((this.$parent as BetriebDetails).logic.betrieb.id)
    this.logic.init()
  }
}
