import { ErrorCode } from '@/entities/common/enums'
import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Pliste from '@/entities/member/pliste'
import router from '@/router'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import MemberBaseLogic from '../member_base_logic'

export default abstract class PreislisteDetailsMemcommonLogic extends MemberBaseLogic {
  preisliste?: Pliste = null
  editError: boolean = false
  editWorking: boolean = false
  loadError: boolean = false

  abstract bearbeiten()

  aktivieren() {
    this.editWorking = true
    this.editError = false

    var request = new IdRequest()
    request.id = this.preisliste.id

    Poster.postMember<RemoteResult<any>>(
      RestPaths.preislisteAktivieren,
      request
    )
      .then((response) => {
        this.editWorking = false

        if (response.data.success) {
          this.preisliste.aktiv = true
        } else {
          this.editError = true
        }
      })
      .catch(() => {
        this.editWorking = false
        this.editError = true
      })
  }

  deaktivieren() {
    this.editWorking = true
    this.editError = false

    var request = new IdRequest()
    request.id = this.preisliste.id

    Poster.postMember<RemoteResult<any>>(
      RestPaths.preislisteDeaktivieren,
      request
    )
      .then((response) => {
        this.editWorking = false

        if (response.data.success) {
          this.preisliste.aktiv = false
        } else {
          this.editError = true
        }
      })
      .catch(() => {
        this.editWorking = false
        this.editError = true
      })
  }

  init(id: string) {
    this.loadError = false

    var request = new IdRequest()
    request.id = id

    Poster.postMember<RemoteResult<Pliste>>(
      RestPaths.preislisteDetails,
      request
    )
      .then((response) => {
        if (response.data.success) {
          this.preisliste = response.data.payload
        } else {
          this.loadError = true
          if (response.data.errorCode === ErrorCode.WRONG_CREDENTIALS) {
            // Route to login page
          }
        }
      })
      .catch(() => {
        this.loadError = true
      })
  }
}
