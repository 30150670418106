import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import FilialeSimple from '@/entities/member/filiale_simple'
import OeffnungszeitZeitraumList from '@/entities/member/lists/oeffnungszeit_zeitraum_list'
import OeffnungszeitZeitraum from '@/entities/member/oeffnungszeit_zeitraum'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import MemberBaseLogic from '../member_base_logic'

export default abstract class OeffnungszeitenMemcommonLogic extends MemberBaseLogic {
  _selectedFiliale?: string = null
  oeffnungListe: OeffnungszeitZeitraum[] = []
  loadError: boolean = false

  abstract get filialen(): FilialeSimple[]

  get selectedFiliale() {
    return this._selectedFiliale
  }

  abstract replaceFiliale(id: string): void

  set selectedFiliale(value: string) {
    this._selectedFiliale = value

    this.replaceFiliale(value)
    var request = new IdRequest()
    request.id = value

    Poster.postMember<RemoteResult<OeffnungszeitZeitraumList>>(
      RestPaths.oeffnungszeitZeitraumListe,
      request
    )
      .then((response) => {
        if (response.data.success) {
          this.oeffnungListe = response.data.payload.list
        } else {
          this.loadError = true
        }
      })
      .catch(() => {
        this.loadError = true
      })
  }

  abstract ausnahmenHinzufuegen(): void

  abstract zeigeDetails(oeffnungszeit): void

  abstract neueOeffnungszeit(): void
}
