import Bestellung from '@/entities/common/bestellung'
import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'

export default class BestellungLogic {
  bestellung?: Bestellung = null

  init(id: string) {
    var request = new IdRequest()
    request.id = id

    Poster.postMember<RemoteResult<Bestellung>>(
      RestPaths.bestellungMitarbeiterDetails,
      request
    ).then((response) => {
      if (response.data.success) {
        this.bestellung = response.data.payload
      }
    })
  }
}
