
import MitarbeiterLogic from '@/logic/member/mitarbeiter_logic'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import MitarbeiterMemCommon from '@/components/memcommon/MitarbeiterMemCommon.vue'
import store from '@/store'

@Component({ components: { MitarbeiterMemCommon } })
export default class Mitarbeiter extends Vue {
  logic?: MitarbeiterLogic = null

  mounted() {
    this.logic = new MitarbeiterLogic()
    this.logic.betriebId = store.state.user.betriebId
    this.logic.init()
  }
}
