
import AbholungStartenLogic from '@/logic/bestellung_neu/abholung_starten_logic'
import Vue from 'vue'
import Component from 'vue-class-component'
import FilialeHeader from '../custom/FilialeHeader.vue'
import Bestellung from '@/entities/common/bestellung'
import router from '@/router'
import RouteNames from '@/util/route_names'

@Component({ components: { FilialeHeader } })
export default class AbholungStarten extends Vue {
  logic?: AbholungStartenLogic = null

  bestellungStarten() {
    this.logic.bestellungStarten()
  }

  mounted() {
    this.logic = new AbholungStartenLogic()
    this.logic.init(this.$route.params.id)
  }

  bestellungAnzeigen(bestellung: Bestellung) {
    router.push({
      name: RouteNames.BESTELLUNG.DETAILS,
      params: { id: bestellung.id },
    })
  }
}
