
import RemoteResult from '@/entities/common/remote_result'
import StringRequest from '@/entities/common/request/string_request'
import SystemUser from '@/entities/member/system_user'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import Component, { mixins } from 'vue-class-component'
import ToolbarButton from '../custom/ToolbarButton.vue'
import Calculator from '../mixins/Calculator.vue'

@Component({ components: { ToolbarButton } })
export default class SystemAccountDetails extends mixins(Calculator) {
  user: SystemUser = null

  aktivierenDialogVisible: boolean = false
  deaktivierenDialogVisible: boolean = false

  mounted() {
    var request = new StringRequest()
    request.param = this.$route.params.username

    Poster.postMember<RemoteResult<SystemUser>>(
      RestPaths.mitarbeiterSystemDetails,
      request
    ).then((response) => {
      if (response.data.success) {
        this.user = response.data.payload
      }
    })
  }

  deaktivierenBestaetigen() {
    var request = new StringRequest()
    request.param = this.user.username

    Poster.postMember<RemoteResult<any>>(
      RestPaths.mitarbeiterSystemDeaktivieren,
      request
    ).then((response) => {
      if (response.data.success) {
        this.user.deaktiviert = true
        this.deaktivierenDialogVisible = false
      }
    })
  }

  aktivierenBestaetigen() {
    var request = new StringRequest()
    request.param = this.user.username

    Poster.postMember<RemoteResult<any>>(
      RestPaths.mitarbeiterSystemAktivieren,
      request
    ).then((response) => {
      if (response.data.success) {
        this.user.deaktiviert = false
        this.aktivierenDialogVisible = false
      }
    })
  }

  deaktivieren() {
    this.deaktivierenDialogVisible = true
  }

  aktivieren() {
    this.aktivierenDialogVisible = true
  }

  bearbeiten() {}
}
