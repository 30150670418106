
import Component from 'vue-class-component'
import Produkte from '@/components/custom/Produkte.vue'
import FilialeHeader from '../custom/FilialeHeader.vue'
import NachbestellungLogic from '@/logic/bestellung/nachbestellung_logic'
import Nachbestellung from './Nachbestellung.vue'
import Vue from 'vue'

@Component({
  components: { Produkte, FilialeHeader },
})
export default class NachbestellungProdukte extends Vue {
  logic: NachbestellungLogic = null

  mounted() {
    this.logic = (this.$parent as Nachbestellung).logic
  }

  // @Watch('$parent.logic')
  // watchLogic(value) {
  //   this.logic = value
  // }
}
