import Bestellung from '@/entities/common/bestellung'
import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import AbholungStatusUpdateRequest from '@/entities/member/request/abholung_status_update_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'

export default class BestellungAbholungLogic {
  private _bestellung?: Bestellung

  get bestellung(): Bestellung {
    return this._bestellung
  }

  set bestellung(newValue: Bestellung) {
    this._bestellung = newValue
  }

  init(bestellung: Bestellung) {
    this.bestellung = bestellung
  }

  updateStatus(status: string, finish?: () => void) {
    var request = new AbholungStatusUpdateRequest()
    request.id = this.bestellung.id
    request.status = status

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterStatusUpdateAbholung,
      request
    )
      .then((response) => {
        if (!response.data.success) {
        }
        this.bestellung.abholung.status = status
        if (finish) {
          finish()
        }
      })
      .catch(() => {})
  }

  stornieren(finish: () => void) {
    var request = new IdRequest()
    request.id = this.bestellung.id

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterStornieren,
      request
    ).then((response) => {
      if (response.data.success) {
        this.bestellung.storniert = true
        this.bestellung.abgeschlossen = true
        finish()
      }
    })
  }
}
