import { render, staticRenderFns } from "./PreislisteKategorieContainer.vue?vue&type=template&id=635b59e6"
import script from "./PreislisteKategorieContainer.vue?vue&type=script&lang=ts"
export * from "./PreislisteKategorieContainer.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports