import Betrieb from "@/entities/member/betrieb";
import router from "@/router";
import RouteNames from "@/util/route_names";
import Formatter from "../formatter";
import BetriebDetailsLogic from "./betrieb_details_logic";

export default class BetriebPageLogic {
  parent: BetriebDetailsLogic

  get betrieb() : Betrieb {
    return this.parent.betrieb
  }

  get erstellungsZp() {
    return Formatter.formatiereDatumUhrzeit(this.betrieb.erstellungsZp)
  }

  betriebBearbeiten() {
    router.push({
      name: RouteNames.SYSTEM.BETRIEB_EDIT,
      params: { id: this.betrieb.id },
    })
  }
}