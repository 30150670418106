
import EigeneDatenLogic from '@/logic/member/eigene_daten_logic'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class EigeneDaten extends Vue {
  logic?: EigeneDatenLogic = null

  passwortAendern() {
    this.logic.passwortAendern()
  }

  mounted() {
    this.logic = new EigeneDatenLogic()
    this.logic.init()
  }
}
