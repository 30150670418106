export default class OeffnungstagVorlage {
  wochentag?: number
  bezeichnung?: string
  _geoeffnet?: boolean = false
  oeffnungszeiten: ZeitraumData[] = []

  public set geoeffnet(newValue: boolean) {
    if(!newValue) {
      this.oeffnungszeiten = []
    }
    this._geoeffnet = newValue
    
  }

  public get geoeffnet() {
    return this._geoeffnet
  }

  constructor(empty?: boolean|undefined) {
    //if (empty === undefined) this.oeffnungszeiten.push(new ZeitraumData())
  }

  public addOeffnungszeit(): void {
    this.oeffnungszeiten.push(new ZeitraumData())
  }
}

class ZeitraumData {
  zeitVon?: string
  zeitVonVisible: boolean = false
  zeitVonError?: string
  zeitBis?: string
  zeitBisVisible: boolean = false
  zeitBisError?: string
}

export {ZeitraumData}