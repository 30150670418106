import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import { v4 as uuidv4 } from 'uuid'
import PlisteInsertRequest, {
  Kategorie as ReqKategorie,
  KategorieZeitraum as ReqKategorieZeitraum,
  Produkt as ReqProdukt,
  Auswahl as ReqAuswahl,
  Extra as ReqExtra,
  Option as ReqOption,
  Subprodukt as ReqSubprodukt,
} from '@/entities/member/request/pliste_insert_request'
import router from '@/router'
import moment from 'moment'
import PlisteSimpleList from '@/entities/member/lists/pliste_simple_list'
import PlisteSimple from '@/entities/member/pliste_simple'
import AltePreisListe from '@/entities/member/alte_preis_liste'
import NeuePreislisteInfo from '@/entities/member/neue_preisliste_info'
import Filiale from '@/entities/member/filiale'
import MemberBaseLogic from '../member_base_logic'
import Swapper from '@/util/swapper'
import {
  Kategorie as KategorieComponent,
  KategorieZeitraum as KategorieZeitraumComponent,
} from '@/components/custom/kategorie'
import {
  Kategorie,
  KategorieZeitraum,
  Produkt,
  Subprodukt,
  ProduktExtra,
  Auswahl,
  PreislisteProdukt,
  PreislisteProduktExtra,
  PreislisteProduktOption,
  PreislisteProduktAuswahl,
  PreislisteProduktSubprodukt,
} from '@/components/custom/preisliste'

export default class NewPreislisteFilialeNeuMemcommonLogic extends MemberBaseLogic {
  gueltigVonRules: any[] = [
    (value) => {
      if (value) return true
      return 'Bitte Datum wählen'
    },
  ]

  kategorieBezeichnungRules = [
    (v: string) => {
      if (!v) return 'Bitte Bezeichnung angeben'
      else return true
    },
  ]

  kategorieWochentagRules = [
    (v) => {
      if (!v) return 'Bitte w#hlen'
      else return true
    },
  ]

  kategorieZeitraumZeitVonRules(index: number) {
    return [
      (v) => {
        if (!v) return 'Bitte angeben'
        else return true
      },
    ]
  }

  kategorieZeitraumZeitBisRules(index: number) {
    return [
      (v) => {
        if (!v) return 'Bitte angeben'
        else return true
      },
    ]
  }

  filiale?: Filiale = null
  bezeichnung?: string = null
  beschreibung?: string = null
  gueltigVon?: string = null
  gueltigBis?: string = null
  vorgaengerId: string = null

  neueKategorie: KategorieComponent = null

  gueltigVonVisible: boolean = false
  gueltigBisVisible: boolean = false
  zeitVonVisible: boolean = false
  zeitBisVisible: boolean = false

  kategorienWaehlenVisible: boolean = false
  produkteWaehlenVisible: boolean = false
  altePreislisteWaehlenVisible: boolean = false
  neueKategorieDialogVisible: boolean = false
  editKategorieDialogVisible: boolean = false

  extras: Produkt[] = []
  produkte: Produkt[] = []
  //kategorien: Kategorie[] = []
  alteListen: PlisteSimple[] = []
  preislisteKategorien: Kategorie[] = []
  aktuelleKategorie: Kategorie = null
  gewaehlteProdukte: Produkt[] = []
  preislistenSimple: PlisteSimple[] = []
  editKategorie: KategorieComponent = null
  editKategorieBackup: Kategorie = null

  insertWorking: boolean = false
  insertCompleted: boolean = false
  insertFormDisabled: boolean = false
  insertError: boolean = false

  moveKategorieUp(index: number) {
    Swapper.instance.pushUp(this.preislisteKategorien, index)
  }

  moveKategorieDown(index: number) {
    Swapper.instance.pushDown(this.preislisteKategorien, index)
  }

  ausListeErzeugen(alteListe: PlisteSimple) {
    var request = new IdRequest()
    request.id = alteListe.id

    Poster.postMember<RemoteResult<AltePreisListe>>(
      RestPaths.preislisteFromAltePreisliste,
      request
    ).then((response) => {
      if (response.data.success) {
        this.assignAlteListe(response.data.payload)
        this.altePreislisteWaehlenVisible = false
      }
    })
  }

  findProdukt(id: string) {
    return this.produkte.find((element) => element.id == id)
  }

  findProduktExtra(id: string) {
    return this.extras.find((element) => element.id == id)
  }

  assignAlteListe(p: AltePreisListe) {
    this.bezeichnung = p.bezeichnung
    this.gueltigVon = moment(p.gueltigVon, 'YYYY-MM-DDTHH:mm:ss').format(
      'YYYY-MM-DD'
    )

    p.kategorien.forEach((k) => {
      var kategorie = new Kategorie()
      kategorie.id = k.id
      kategorie.bezeichnung = k.bezeichnung
      this.preislisteKategorien.push(kategorie)

      k.produkte.forEach((pr) => {
        var preislisteProdukt = new PreislisteProdukt()
        var produkt = this.findProdukt(pr.produktId)
        preislisteProdukt.produkt = produkt
        preislisteProdukt.preis = pr.preis
        kategorie.produkte.push(preislisteProdukt)

        pr.extras.forEach((e) => {
          var preislisteExtra = new PreislisteProduktExtra()
          var extra = this.findProduktExtra(e.pduktId)
          console.log('pdukt id: ' + e.pduktId)
          console.log('extra hier schonmal gefunden: ' + extra.bezeichnung)
          var produktExtra = new ProduktExtra()
          produktExtra.pduktExtraId = e.pduktExtraId
          produktExtra.produkt = extra
          preislisteExtra.extra = produktExtra
          preislisteExtra.preis = e.preis
          preislisteProdukt.extras.push(preislisteExtra)
        })

        pr.auswahlen.forEach((a) => {
          var preislisteProduktAuswahl = new PreislisteProduktAuswahl()
          preislisteProdukt.produkt.auswahlen.forEach((ausw) => {
            if (a.auswahlId == ausw.id) {
              preislisteProduktAuswahl.auswahl = ausw

              a.optionen.forEach((o) => {
                var preislisteProduktOption = new PreislisteProduktExtra()
                var extra = this.findProduktExtra(o.pduktId)
                var produktExtra = new ProduktExtra()
                produktExtra.produkt = extra
                produktExtra.pduktExtraId = o.auswahlOptionId
                preislisteProduktOption.extra = produktExtra
                preislisteProduktOption.preis = o.preis
                preislisteProduktAuswahl.optionen.push(preislisteProduktOption)
              })
              return
            }
          })
          preislisteProdukt.auswahlen.push(preislisteProduktAuswahl)
        })

        pr.subprodukte.forEach((s) => {
          var preislisteProduktSubprodukt = new PreislisteProduktSubprodukt()
          var subprodukt = new Subprodukt()
          subprodukt.id = s.produktId
          preislisteProduktSubprodukt.subprodukt = subprodukt
          preislisteProdukt.subprodukte.push(preislisteProduktSubprodukt)

          s.optionen.forEach((o) => {
            var preislisteProdukt = new PreislisteProdukt()
            var produkt = this.findProdukt(o.produktId)
            preislisteProdukt.produkt = produkt
            preislisteProdukt.preis = o.preis
            preislisteProduktSubprodukt.optionen.push(preislisteProdukt)

            o.extras.forEach((e) => {
              var preislisteExtra = new PreislisteProduktExtra()
              var extra = this.findProduktExtra(e.pduktId)
              var produktExtra = new ProduktExtra()
              produktExtra.produkt = extra
              // preislisteExtra.extra = extra
              // preislisteExtra.preis = e.preis
              preislisteExtra.preis = e.preis
              preislisteExtra.pduktExtraId = e.pduktExtraId

              preislisteProdukt.extras.push(preislisteExtra)
            })

            o.auswahlen.forEach((a) => {
              var preislisteProduktAuswahl = new PreislisteProduktAuswahl()
              preislisteProdukt.produkt.auswahlen.forEach((ausw) => {
                if (a.auswahlId == ausw.id) {
                  preislisteProduktAuswahl.auswahl = ausw
                  a.optionen.forEach((o) => {
                    var preislisteOption = new PreislisteProduktExtra()
                    var option = this.findProduktExtra(o.auswahlOptionId)
                    var produktExtra = new ProduktExtra()
                    produktExtra.produkt = option
                    produktExtra.pduktExtraId = o.auswahlOptionId
                    preislisteOption.extra = produktExtra
                    preislisteOption.preis = o.preis
                    preislisteOption.index = o.index
                    preislisteProduktAuswahl.optionen.push(preislisteOption)
                  })
                  return
                }
              })

              preislisteProdukt.auswahlen.push(preislisteProduktAuswahl)
            })
          })
        })
      })
    })
  }

  altePreislisteAuswaehlen() {
    var request = new IdRequest()
    request.id = this.filiale.id

    Poster.postMember<RemoteResult<PlisteSimpleList>>(
      RestPaths.preislisteListeSimple,
      request
    ).then((response) => {
      if (response.data.success) {
        this.alteListen = response.data.payload.list
      }
    })

    this.altePreislisteWaehlenVisible = true
  }

  //Aus Kategorie eine Preislistenkategorie machen
  // kategorieHinzufuegen(kategorie: Kategorie) {
  //   var preislisteKategorie = new Kategorie()
  //   preislisteKategorie.id = kategorie.id
  //   preislisteKategorie.bezeichnung = kategorie.bezeichnung
  //   this.preislisteKategorien.push(preislisteKategorie)
  //   this.kategorienWaehlenVisible = false
  // }

  //Preisliste Kategorie löschen
  kategorieEntfernen(kategorie: Kategorie) {
    var index = this.preislisteKategorien.indexOf(kategorie)
    this.preislisteKategorien.splice(index, 1)
  }

  kategorieBearbeiten(kategorie: Kategorie) {
    var k = new KategorieComponent()
    this.editKategorieBackup = kategorie
    k.bezeichnung = kategorie.bezeichnung
    k.beschreibung = kategorie.beschreibung
    k.zeitbegrenzung = kategorie.zeitbegrenzung

    kategorie.zeitraueme.forEach((zeitraum) => {
      var z = new KategorieZeitraumComponent()
      z.wochentag = zeitraum.wochentag
      z.zeitVon = zeitraum.zeitVon
      z.zeitBis = zeitraum.zeitBis
      k.zeitraeume.push(z)
    })

    this.editKategorie = k
    this.editKategorieDialogVisible = true
  }

  kategorieBearbeitenAbbrechen() {
    this.editKategorieDialogVisible = false
    this.editKategorie = null
    this.editKategorieBackup = null
  }

  kategorieBearbeitenSpeichern() {
    this.editKategorieBackup.bezeichnung = this.editKategorie.bezeichnung
    this.editKategorieBackup.beschreibung = this.editKategorie.beschreibung
    this.editKategorieBackup.zeitbegrenzung = this.editKategorie.zeitbegrenzung

    this.editKategorieBackup.zeitraueme = []
    this.editKategorie.zeitraeume.forEach((zeitraum) => {
      var z = new KategorieZeitraum()
      z.wochentag = zeitraum.wochentag
      z.zeitVon = zeitraum.zeitVon
      z.zeitBis = zeitraum.zeitBis
      this.editKategorieBackup.zeitraueme.push(z)
    })

    this.editKategorieDialogVisible = false
    this.editKategorie = null
    this.editKategorieBackup = null
  }

  //Kategorie auswählen, zu der Produkt hinzugefügt werden
  //sollen, und den dialog anzeigen
  kategorieProdukteWaehlen(kategorie: Kategorie) {
    this.aktuelleKategorie = kategorie
    this.gewaehlteProdukte = []
    this.produkteWaehlenVisible = true
  }

  createProdukt(produkt: Produkt): PreislisteProdukt {
    var preislisteProdukt = new PreislisteProdukt()
    preislisteProdukt.produkt = produkt
    preislisteProdukt.preis = produkt.standardpreis
    produkt.extras.forEach((extra) => {
      var preislisteProduktExtra = new PreislisteProduktExtra()
      preislisteProduktExtra.extra = extra
      preislisteProduktExtra.preis = extra.produkt.standardpreis
      preislisteProdukt.extras.push(preislisteProduktExtra)
    })

    produkt.auswahlen.forEach((auswahl) => {
      var preislisteProduktAuswahl = new PreislisteProduktAuswahl()
      preislisteProduktAuswahl.auswahl = auswahl

      auswahl.optionen.forEach((option) => {
        var preislisteProduktExtra = new PreislisteProduktExtra()
        preislisteProduktExtra.extra = option
        preislisteProduktExtra.preis = option.produkt.standardpreis
        preislisteProduktAuswahl.optionen.push(preislisteProduktExtra)
      })

      preislisteProdukt.auswahlen.push(preislisteProduktAuswahl)
    })

    produkt.subprodukte.forEach((subprodukt) => {
      var preislisteProduktSubprodukt = new PreislisteProduktSubprodukt()
      preislisteProduktSubprodukt.subprodukt = subprodukt
      subprodukt.optionen.forEach((suboption) => {
        var preislisteProduktOption = this.createProdukt(suboption)
        preislisteProduktSubprodukt.optionen.push(preislisteProduktOption)
      })
      preislisteProdukt.subprodukte.push(preislisteProduktSubprodukt)
    })

    return preislisteProdukt
  }

  //gewählte Produkte zu PreislisteProdukte umwandeln
  //und der aktuellen Kategorie hinzufügen
  produkteHinzufuegen() {
    this.gewaehlteProdukte.forEach((produkt) => {
      this.aktuelleKategorie.produkte.push(this.createProdukt(produkt))
    })
    this.aktuelleKategorie = null
    this.produkteWaehlenVisible = false
  }

  produktEntfernen(args: any) {
    var kategorie = args.kategorie
    var produkt = args.produkt

    var index = kategorie.produkte.indexOf(produkt)
    kategorie.produkte.splice(index, 1)
  }

  produkteHinzufuegenAbbrechen() {
    this.gewaehlteProdukte = []
    this.aktuelleKategorie = null
    this.produkteWaehlenVisible = false
  }

  preislisteEinfuegen() {
    this.insertWorking = true
    this.insertFormDisabled = true
    this.insertError = false

    var request = new PlisteInsertRequest()
    request.id = uuidv4()
    request.bezeichnung = this.bezeichnung
    request.beschreibung = this.beschreibung
    request.filialeId = this.filiale.id
    request.vorgaengerId = this.vorgaengerId

    request.gueltigVon = moment(this.gueltigVon, 'YYYY-MM-DD')
      .startOf('day')
      .format('YYYY-MM-DDTHH:mm:ss')
    if (this.gueltigBis != null) {
      request.gueltigBis = moment(this.gueltigBis, 'YYYY-MM-DD')
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss')
    }

    this.preislisteKategorien.forEach((prlKategorie, index) => {
      var reqKategorie = new ReqKategorie()
      reqKategorie.id = prlKategorie.id
      reqKategorie.index = index
      reqKategorie.bezeichnung = prlKategorie.bezeichnung
      reqKategorie.beschreibung = prlKategorie.beschreibung
      reqKategorie.zeitbegrenzung = prlKategorie.zeitbegrenzung
      if (prlKategorie.zeitbegrenzung) {
        prlKategorie.zeitraueme.forEach((prlZeitraum) => {
          var reqZeitraum = new ReqKategorieZeitraum()
          reqZeitraum.wochentag = prlZeitraum.wochentag
          reqZeitraum.zeitVon = prlZeitraum.zeitVon
          reqZeitraum.zeitBis = prlZeitraum.zeitBis
          reqKategorie.zeitbegrenzungZeitraeume.push(reqZeitraum)
        })
      }
      request.kategorien.push(reqKategorie)

      prlKategorie.produkte.forEach((prlProdukt, index) => {
        var reqProdukt = new ReqProdukt()
        reqProdukt.produktId = prlProdukt.produkt.id
        reqProdukt.preis = prlProdukt.preis
        reqProdukt.index = index
        reqProdukt.shortcut = prlProdukt.shortcut
        reqKategorie.produkte.push(reqProdukt)

        prlProdukt.extras.forEach((prlExtra, index) => {
          var reqExtra = new ReqExtra()
          reqExtra.pduktExtraId = prlExtra.extra.pduktExtraId
          reqExtra.preis = prlExtra.preis
          reqExtra.index = index
          reqProdukt.extras.push(reqExtra)
        })

        prlProdukt.auswahlen.forEach((prlAuswahl, index) => {
          var reqAuswahl = new ReqAuswahl()
          reqAuswahl.auswahlId = prlAuswahl.auswahl.id
          reqAuswahl.index = index
          reqProdukt.auswahlen.push(reqAuswahl)

          prlAuswahl.optionen.forEach((prlOption, index) => {
            var reqOption = new ReqOption()
            reqOption.auswahlOptionId = prlOption.extra.pduktExtraId
            reqOption.preis = prlOption.preis
            reqOption.index = index

            reqAuswahl.optionen.push(reqOption)
          })
        })

        prlProdukt.subprodukte.forEach((prlSubprodukt, index) => {
          var reqSubprodukt = new ReqSubprodukt()
          reqSubprodukt.subproduktId = prlSubprodukt.subprodukt.id
          reqSubprodukt.index = index
          reqProdukt.subprodukte.push(reqSubprodukt)

          prlSubprodukt.optionen.forEach((prlOption, index) => {
            var reqSubproduktOption = new ReqProdukt()
            reqSubproduktOption.produktId = prlOption.produkt.id
            reqSubproduktOption.preis = prlOption.preis
            reqSubproduktOption.index = index

            reqSubprodukt.optionen.push(reqSubproduktOption)

            prlOption.extras.forEach((prlExtra, index) => {
              var reqExtra = new ReqExtra()
              reqExtra.pduktExtraId = prlExtra.extra.pduktExtraId
              reqExtra.preis = prlExtra.preis
              reqExtra.index = index
              reqSubproduktOption.extras.push(reqExtra)
            })

            prlOption.auswahlen.forEach((prlAuswahl, index) => {
              var reqAuswahl = new ReqAuswahl()
              reqAuswahl.auswahlId = prlAuswahl.auswahl.id
              reqAuswahl.index = index
              reqSubproduktOption.auswahlen.push(reqAuswahl)

              prlAuswahl.optionen.forEach((prlOption, index) => {
                var reqOption = new ReqOption()
                reqOption.auswahlOptionId = prlOption.extra.pduktExtraId
                reqOption.preis = prlOption.preis
                reqOption.index = index
                reqAuswahl.optionen.push(reqOption)
              })
            })
          })
        })
      })
    })

    Poster.postMember<RemoteResult<any>>(RestPaths.preislisteEinfuegen, request)
      .then((response) => {
        this.insertWorking = false
        if (response.data.success) {
          this.insertCompleted = true
        } else {
          this.insertError = true
          this.insertFormDisabled = false
        }
      })
      .catch(() => {
        this.insertWorking = false
        this.insertError = true
        this.insertFormDisabled = false
      })
  }

  navBack() {
    router.back()
  }

  init(filialeId: string, betriebId: string) {
    var filialeRequest = new IdRequest()
    filialeRequest.id = filialeId

    Poster.postMember<RemoteResult<Filiale>>(
      RestPaths.filialeDetails,
      filialeRequest
    ).then((response) => {
      this.filiale = response.data.payload
    })

    var request = new IdRequest()
    request.id = betriebId

    Poster.postMember<RemoteResult<NeuePreislisteInfo>>(
      RestPaths.preislisteNeueInfo,
      request
    ).then((response) => {
      if (response.data.success) {
        let payload = response.data.payload

        payload.extras.forEach((e) => {
          var extra = new Produkt()
          extra.id = e.id
          extra.bezeichnung = e.bezeichnung
          extra.artikelNr = e.artikelNr
          extra.standardpreis = e.standardpreis
          this.extras.push(extra)
        })

        payload.produkte.forEach((p) => {
          var produkt = new Produkt()
          produkt.id = p.id
          produkt.bezeichnung = p.bezeichnung
          produkt.artikelNr = p.artikelNr
          produkt.standardpreis = p.standardpreis

          p.extras.forEach((e) => {
            var produktExtra = new ProduktExtra()
            var extra = this.findProduktExtra(e.pduktId)
            produktExtra.produkt = extra
            produktExtra.pduktExtraId = e.pduktExtraId
            produkt.extras.push(produktExtra)
          })

          p.auswahlen.forEach((a) => {
            var auswahl = new Auswahl()
            auswahl.id = a.id
            auswahl.bezeichnung = a.bezeichnung

            a.optionen.forEach((o) => {
              var produktExtra = new ProduktExtra()
              var option = this.findProduktExtra(o.pduktId)
              produktExtra.produkt = option
              produktExtra.pduktExtraId = o.auswahlOptionId
              auswahl.optionen.push(produktExtra)
            })
            produkt.auswahlen.push(auswahl)
          })

          p.subprodukte.forEach((sp) => {
            var subprodukt = new Subprodukt()
            subprodukt.id = sp.id
            subprodukt.bezeichnung = sp.bezeichnung
            produkt.subprodukte.push(subprodukt)
            sp.produkte.forEach((spp) => {
              var produkt = new Produkt()
              produkt.id = spp.id
              produkt.bezeichnung = spp.bezeichnung
              produkt.artikelNr = spp.artikelNr

              spp.extras.forEach((e) => {
                var produktExtra = new ProduktExtra()
                var extra = this.findProduktExtra(e.pduktId)
                produktExtra.produkt = extra
                produktExtra.pduktExtraId = e.pduktExtraId
                produkt.extras.push(produktExtra)
              })

              spp.auswahlen.forEach((a) => {
                var auswahl = new Auswahl()
                auswahl.id = a.id
                auswahl.bezeichnung = a.bezeichnung

                a.optionen.forEach((o) => {
                  var produktExtra = new ProduktExtra()
                  var option = this.findProduktExtra(o.pduktId)
                  produktExtra.produkt = option
                  produktExtra.pduktExtraId = o.auswahlOptionId
                  auswahl.optionen.push(produktExtra)
                })
                produkt.auswahlen.push(auswahl)
              })

              subprodukt.optionen.push(produkt)
            })
          })

          this.produkte.push(produkt)
        })
      }
    })

    var simplePlistRequest = new IdRequest()
    simplePlistRequest.id = filialeId

    Poster.postMember<RemoteResult<PlisteSimpleList>>(
      RestPaths.preislisteListeSimple,
      simplePlistRequest
    ).then((response) => {
      if (response.data.success) {
        this.preislistenSimple = response.data.payload.list
      }
    })
  }

  neueKategorieErstellen() {
    this.neueKategorie = new KategorieComponent()
    this.neueKategorieDialogVisible = true
  }

  neueKategorieEinfuegen() {
    var kategorie = new Kategorie()
    kategorie.bezeichnung = this.neueKategorie.bezeichnung
    kategorie.beschreibung = this.neueKategorie.beschreibung
    kategorie.zeitbegrenzung = this.neueKategorie.zeitbegrenzung
    this.neueKategorie.zeitraeume.forEach((zeitraum) => {
      var z = new KategorieZeitraum()
      z.wochentag = zeitraum.wochentag
      z.zeitVon = zeitraum.zeitVon
      z.zeitBis = zeitraum.zeitBis
      kategorie.zeitraueme.push(z)
    })
    this.preislisteKategorien.push(kategorie)
    this.neueKategorieDialogVisible = false
  }

  neueKategorieAbbrechen() {
    this.neueKategorieDialogVisible = false
    this.neueKategorie = null
  }

  // neuerKategorieZeitraum() {
  //   this.neueKategorie.zeitraeume.push(new KategorieZeitraumNeu())
  // }

  // neuerKategorieEditZeitraum() {
  //   this.editKategorie.zeitraeume.push(new KategorieZeitraumNeu())
  // }
}
