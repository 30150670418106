
import RouteNames from '@/util/route_names'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class Bestaetigt extends Vue {
  zurUebersicht() {
    this.$router.push({ name: RouteNames.BESTELLUNGEN })
  }
}
