
import Vue from "vue";
import Component from "vue-class-component";
import TischEditMemCommon from "../memcommon/TischEditMemCommon.vue";
import TischEditMemcommonLogic from "@/logic/memcommon/tisch_edit_memcommon_logic";

@Component({components: {TischEditMemCommon}})
export default class TischEdit extends Vue {
  logic?: TischEditMemcommonLogic = null

  mounted() {
    this.logic = new TischEditMemcommonLogic()
    this.logic.init(this.$route.params.tischId)
  }
}
