import { extend } from 'vue/types/umd'
import TageskarteDetailsMemcommonLogic from '../memcommon/tageskarte_details_memcommon_logic'
import router from '@/router'
import RouteNames from '@/util/route_names'

export default class TageskarteDetailsLogic extends TageskarteDetailsMemcommonLogic {
  bearbeiten() {
    router.push({
      name: RouteNames.MEMBER.TAGESKARTE_EDIT,
      params: { preislisteId: this.preisliste.id },
    })
  }
}
