
import LoginLogic from '@/logic/member/login_logic'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class Login extends Vue {
  logic?: LoginLogic = null

  mounted() {
    this.logic = new LoginLogic()
    this.logic.init()
  }

  login() {
    this.logic.login()
  }

  passwortVergessen() {
    this.logic.passwortVergessen()
  }
}
