import Basiskategorie from '@/entities/member/basiskategorie'
import BasiskategorieInsertRequest from '@/entities/member/request/basiskategorie_insert_request'
import BasiskategorieUpdateRequest from '@/entities/member/request/basiskategorie_update_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import store from '@/store'
import { v4 as uuidv4 } from 'uuid'
import IdRequest from '@/entities/common/request/id_request'
import RemoteResult from '@/entities/common/remote_result'
import BasiskategorieList from '@/entities/member/lists/basiskategorie_list'
import MemberBaseLogic from '../member_base_logic'

export default class BasiskategorienMemcommonLogic extends MemberBaseLogic {
  liste: Basiskategorie[] = []
  neuDialogVisible: boolean = false
  editDialogVisible: boolean = false
  detailDialogVisible: boolean = false
  kategorie?: Kategorie = new Kategorie()
  editKategorie: Kategorie = null
  insertCompleted: boolean = false
  insertFormDisabled: boolean = false
  insertWorking: boolean = false
  insertError: boolean = false
  editCompleted: boolean = false
  editFormDisabled: boolean = false
  editWorking: boolean = false
  editError: boolean = false
  listWorking: boolean = false
  listError: boolean = false

  betriebId: string = null

  init() {
    this.listWorking = true

    var request = new IdRequest()
    request.id = this.betriebId

    Poster.postMember<RemoteResult<BasiskategorieList>>(
      RestPaths.artikelBasiskategorieList,
      request
    )
      .then((response) => {
        if (response.data.success) {
          this.liste = response.data.payload.list
          this.listWorking = false
        } else {
          this.listError = true
        }
      })
      .catch(() => {
        this.listWorking = false
        this.listError = true
      })
  }

  detailsAnzeigen(kategorie: Basiskategorie) {
    this.kategorie = kategorie
    this.detailDialogVisible = true
  }

  speichern() {
    this.editWorking = true
    this.editFormDisabled = true
    this.kategorie.bezeichnung = this.editKategorie.bezeichnung

    var request = new BasiskategorieUpdateRequest()
    request.id = this.kategorie.id
    request.bezeichnung = this.kategorie.bezeichnung

    Poster.postMember<RemoteResult<Basiskategorie>>(
      RestPaths.artikelBasiskategorieSpeichern,
      request
    )
      .then((response) => {
        this.editWorking = false
        if (response.data.success) {
          this.editDialogVisible = false
          this.editError = false
          this.editFormDisabled = false
        } else {
          this.editWorking = false
          this.editError = true
          this.editFormDisabled = false
        }
      })
      .catch(() => {
        this.editWorking = false
        this.editError = true
        this.editFormDisabled = false
      })
  }

  bearbeiten(kategorie: Basiskategorie, event) {
    this.editKategorie = new Kategorie()
    this.editKategorie.bezeichnung = kategorie.bezeichnung
    this.kategorie = kategorie
    this.editDialogVisible = true
    event.stopPropagation()
  }

  neueKategorieEinfuegen() {
    this.insertWorking = true
    this.insertFormDisabled = true
    this.insertError = false

    var request = new BasiskategorieInsertRequest()
    request.betriebId = store.state.user.betriebId
    request.id = uuidv4()
    request.bezeichnung = this.kategorie.bezeichnung

    Poster.postMember<RemoteResult<Basiskategorie>>(
      RestPaths.artikelBasiskategorieEinfuegen,
      request
    )
      .then((response) => {
        if (response.data.success) {
          this.liste.push(response.data.payload)
          this.insertWorking = false
          this.neuDialogVisible = false
        } else {
          this.insertWorking = false
          this.insertError = true
          this.insertFormDisabled = false
        }
      })
      .catch(() => {
        this.insertWorking = false
        this.insertError = true
        this.insertFormDisabled = false
      })
  }

  neueKategorie() {
    this.neuDialogVisible = true
    this.kategorie = new Kategorie()
  }
}

class Kategorie {
  id?: string
  bezeichnung?: string
}
