
import Vue from 'vue'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import ErrorBox from '../custom/ErrorBox.vue'
import FilialeLiefergebietZuordnenMemcommonLogic from '@/logic/memcommon/filiale_liefergebiet_zuordnen_memcommon_logic'
import Calculator from '../mixins/Calculator.vue'
import { mixins } from 'vue-class-component'
import { Liefergebiet } from '@/logic/memcommon/filiale_neu_memcommon_logic'

@Component({ components: { ErrorBox } })
export default class FilialeLiefergebietZuordnenMemCommon extends mixins(
  Calculator
) {
  @Prop() logic: FilialeLiefergebietZuordnenMemcommonLogic
  neuesLiefergebietVisible: boolean = false
  editLiefergebietVisible: boolean = false
  polygonZuordnenVisible: boolean = false

  navBack() {
    this.logic.navBack()
  }

  liefergebieteSpeichern() {
    this.logic.liefergebieteSpeichern()
  }

  liefergebietBearbeiten(gebiet: Liefergebiet) {
    this.logic.liefergebietBearbeiten(gebiet)
    this.editLiefergebietVisible = true
  }

  liefergebietBearbeitenAbbrechen() {
    this.editLiefergebietVisible = false
  }

  zuordnenBeginnen(gebiet: Liefergebiet) {
    this.logic.zuordnenBeginnen(gebiet)
    this.polygonZuordnenVisible = true
  }

  uploadKmlFile() {
    this.logic.uploadKmlFile()
  }

  neuesLiefergebietSpeichern() {
    this.logic.neuesLiefergebietSpeichern()
    this.neuesLiefergebietVisible = false
  }

  neuesLiefergebietAbbrechen() {
    this.neuesLiefergebietVisible = false
  }

  liefergebietSpeichern() {
    this.logic.liefergebietSpeichern()
    this.editLiefergebietVisible = false
  }

  liefergebietEntfernen(index: number) {
    this.logic.liefergebietEntfernen(index)
  }

  zuordnen() {
    this.logic.zuordnen()
    this.polygonZuordnenVisible = false
  }
}
