import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Filiale from '@/entities/member/filiale'
import PlisteSimpleList from '@/entities/member/lists/pliste_simple_list'
import Pliste from '@/entities/member/pliste'
import PlisteSimple from '@/entities/member/pliste_simple'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import MemberBaseLogic from '../member_base_logic'

export default abstract class PreislistenFilialeMemcommonLogic extends MemberBaseLogic {
  filiale?: Filiale
  preislisten: PlisteSimple[] = []
  inaktiveAnzeigen: boolean = false
  loadError: boolean = false

  abstract detailsAnzeigenPreisliste(p: PlisteSimple): void
  abstract detailsAnzeigenTageskarte(p: PlisteSimple): void
  abstract neuePreisliste(): void
  abstract neueTageskarte(): void

  detailsAnzeigen(p: PlisteSimple) {
    if (p.tageskarte) this.detailsAnzeigenTageskarte(p)
    else this.detailsAnzeigenPreisliste(p)
  }

  init(filialeId: string) {
    var request = new IdRequest()
    request.id = filialeId

    Poster.postMember<RemoteResult<Filiale>>(
      RestPaths.filialeDetails,
      request
    ).then((response) => {
      if (response.data.success) {
        this.filiale = response.data.payload
      }
    })

    Poster.postMember<RemoteResult<PlisteSimpleList>>(
      RestPaths.preislisteListeSimple,
      request
    ).then((response) => {
      if (response.data.success) {
        this.preislisten = response.data.payload.list
      }
    })
  }
}
