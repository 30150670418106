
import Vue from 'vue'
import TageskarteNeuLogic from '@/logic/member/tageskarte_neu_logic'
import vuexStore from '@/store'
import Component from 'vue-class-component'
import TageskarteNeuMemCommon from '../memcommon/TageskarteNeuMemCommon.vue'

@Component({ components: { TageskarteNeuMemCommon } })
export default class TageskarteNeu extends Vue {
  logic?: TageskarteNeuLogic = null

  mounted() {
    var filialeId = this.$route.params.filialeId
    var betriebId = vuexStore.state.user.betriebId

    this.logic = new TageskarteNeuLogic()
    this.logic.init(filialeId, betriebId)
  }
}
