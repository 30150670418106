
import SofortSelectLogic from '@/logic/bestellung_neu/sofort_select_logic'
import Vue from 'vue'
import Component from 'vue-class-component'
import FilialeHeader from '../custom/FilialeHeader.vue'
import NichtGueltig from '../custom/NichtGueltig.vue'
import ErrorBox from '../custom/ErrorBox.vue'

@Component({ components: { FilialeHeader, NichtGueltig, ErrorBox } })
export default class SofortSelect extends Vue {
  logic: SofortSelectLogic = new SofortSelectLogic()

  mounted() {
    if (!this.$store.state.isNeueBestellung) return
  }

  serviceRufen() {
    this.logic.serviceRufen()
  }

  selbstBestellen() {
    this.logic.selbstBestellen()
  }

  zurueckZurBestellung() {
    this.logic.zurueckZurBestellung()
  }
}
