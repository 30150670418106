import { KategorieZeitraum } from "./pliste_insert_request"

export default class PlisteUpdateRequest {
  id?: string
  bezeichnung?: string
  gueltigVon?: string
  gueltigBis?: string
  tageskarte: boolean = false
  zeitbegrenzung: boolean = false
  zeitVon?: string
  zeitBis?: string
  kategorien: Kategorie[] = []
}

class Kategorie {
  id?: string
  kategorieId?: string
  index: number
  bezeichnung: string
  beschreibung: string
  zeitbegrenung: boolean = false
  zeitbegrenzungZeitraeume: KategorieZeitraum[] = []
  produkte: Produkt[] = []
}

class Produkt {
  id?: string
  produktId?: string
  kategorieId?: string
  index?: number
  preis?: number
  extras: Extra[] = []
  auswahlen: Auswahl[] = []
  subprodukte: Subprodukt[] = []
  neu: boolean = false
  deaktivieren: boolean = false
  aktivieren: boolean = false
}

class Extra {
  id?: string
  extraId?: string
  preis?: number
  index?: number
}

class Auswahl {
  id?: string
  auswahlId?: string
  optionen: Option[] = []
  index?: number
}

class Subprodukt {
  id?: string
  subproduktId?: string
  index?: number
  optionen: Produkt[] = []
}

class Option {
  id?: string
  optionId?: string
  index?: number
  preis?: number
}

export { Kategorie, Produkt, Extra, Auswahl, Subprodukt, Option, KategorieZeitraum }
