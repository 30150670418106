import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Mitarbeiter from '@/entities/member/mitarbeiter'
import MitarbeiterUpdateRequest from '@/entities/member/request/mitarbeiter_update_request'
import router from '@/router'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import MitarbeiterEditMemcommonLogic from '../memcommon/mitarbeiter_edit_memcommon_logic'

export default class MitarbeiterEditLogic extends MitarbeiterEditMemcommonLogic {
  // mitarbeiter?: Mitarbeiter = null
  // editFormDisabled: boolean = false
  // editCompleted: boolean = false
  // editError: boolean = false
  // editWorking: boolean = false

  // init(id: string) {
  //   var request = new IdRequest()
  //   request.id = id

  //   Poster.postMember<RemoteResult<Mitarbeiter>>(
  //     RestPaths.urlBase + RestPaths.mitarbeiterDetailForEdit,
  //     request
  //   ).then((response) => {
  //     this.mitarbeiter = response.data.payload
  //   })
  // }

  // speichern() {
  //   this.editFormDisabled = true
  //   this.editError = false
  //   this.editWorking = true

  //   var request = new MitarbeiterUpdateRequest()
  //   request.id = this.mitarbeiter.id
  //   request.nachname = this.mitarbeiter.nachname
  //   request.vorname = this.mitarbeiter.vorname
  //   request.email = this.mitarbeiter.email
  //   request.telefon = this.mitarbeiter.telefon
  //   request.strasse = this.mitarbeiter.strasse
  //   request.plz = this.mitarbeiter.plz
  //   request.ort = this.mitarbeiter.ort
  //   request.admin = this.mitarbeiter.admin

  //   Poster.postMember<RemoteResult<Mitarbeiter>>(
  //     RestPaths.urlBase + RestPaths.mitarbeiterSpeichern,
  //     request
  //   )
  //     .then((response) => {
  //       this.editWorking = false

  //       if (response.data.success) {
  //         this.editCompleted = true
  //       } else {
  //         this.editError = true
  //         this.editFormDisabled = false
  //       }
  //     })
  //     .catch(() => {
  //       this.editWorking = false
  //       this.editError = true
  //       this.editFormDisabled = false
  //     })
  // }

  // goBack() {
  //   router.back()
  // }
}
