import router from "@/router"
import RouteNames from "@/util/route_names"
import MitarbeiterMemcommonLogic from "../memcommon/mitarbeiter_memcommon_logic"

export default class MitarbeiterLogic extends MitarbeiterMemcommonLogic {

  neuerMitarbeiter() : void {
    router.push({ name: RouteNames.MEMBER.MITARBEITER_NEU })
  }

  detailsAnzeigen(id: string) : void {
    router.push({
      name: RouteNames.MEMBER.MITARBEITER_DETAILS,
      params: { id: id },
    })
  }
}