import RemoteResult from '@/entities/common/remote_result'
import OeffnungstagVorlage from '@/entities/member/oeffnungstag_vorlage'
import OeffnungszeitZeitraum from '@/entities/member/oeffnungszeit_zeitraum'
import OeffnungszeitZeitraumInsertRequest, {
  VorlageData,
  ZeitraumData,
} from '@/entities/member/request/oeffnungszeit_zeitraum_insert_request'
import router from '@/router'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import { v4 as uuidv4 } from 'uuid'
import MemberBaseLogic from '../member_base_logic'

export default class OeffnungszeitNeuMemcommonLogic extends MemberBaseLogic {
  filialeId?: string = null

  insertCompleted: boolean = false
  insertFormDisabled: boolean = false
  insertError: boolean = false
  insertWorking: boolean = false

  gueltigVonVisible?: boolean = false
  gueltigBisVisible?: boolean = false
  gueltigVon?: string = null
  gueltigBis?: string = null

  wochentage: OeffnungstagVorlage[] = []
  formValid: boolean = true

  zeitEntfernen(vorlage, index) {
    vorlage.oeffnungszeiten.splice(index, 1)
  }

  extraZeitHinzufuegen(vorlage) {
    vorlage.addOeffnungszeit()
  }

  init(id: string) {
    this.filialeId = id

    var montag = new OeffnungstagVorlage(undefined)
    montag.wochentag = 1
    montag.bezeichnung = 'Montag'
    this.wochentage.push(montag)

    var dienstag = new OeffnungstagVorlage(undefined)
    dienstag.wochentag = 2
    dienstag.bezeichnung = 'Dienstag'
    this.wochentage.push(dienstag)

    var mittwoch = new OeffnungstagVorlage(undefined)
    mittwoch.wochentag = 3
    mittwoch.bezeichnung = 'Mittwoch'
    this.wochentage.push(mittwoch)

    var donnerstag = new OeffnungstagVorlage(undefined)
    donnerstag.wochentag = 4
    donnerstag.bezeichnung = 'Donnerstag'
    this.wochentage.push(donnerstag)

    var freitag = new OeffnungstagVorlage(undefined)
    freitag.wochentag = 5
    freitag.bezeichnung = 'Freitag'
    this.wochentage.push(freitag)

    var samstag = new OeffnungstagVorlage(undefined)
    samstag.wochentag = 6
    samstag.bezeichnung = 'Samstag'
    this.wochentage.push(samstag)

    var sonntag = new OeffnungstagVorlage(undefined)
    sonntag.wochentag = 7
    sonntag.bezeichnung = 'Sonntag'
    this.wochentage.push(sonntag)
  }

  oeffnungszeitenEinfuegen() {
    this.insertWorking = true
    this.insertFormDisabled = true
    this.insertError = false
    this.insertCompleted = false

    var request = new OeffnungszeitZeitraumInsertRequest()
    request.id = uuidv4()
    request.filialeId = this.filialeId
    request.gueltigVon = this.gueltigVon
    request.gueltigBis = this.gueltigBis

    this.wochentage.forEach((vorlage) => {
      if (vorlage.geoeffnet) {
        var vorlageData = new VorlageData()
        vorlageData.wochentag = vorlage.wochentag

        vorlage.oeffnungszeiten.forEach((o) => {
          var zeitraumData = new ZeitraumData()
          zeitraumData.zeitVon = o.zeitVon
          zeitraumData.zeitBis = o.zeitBis
          vorlageData.oeffnungszeiten.push(zeitraumData)
        })
        request.wochentage.push(vorlageData)
      }
    })

    Poster.postMember<RemoteResult<OeffnungszeitZeitraum>>(
      RestPaths.oeffnungszeitZeitraumEinfuegen,
      request
    ).then((response) => {
      this.insertWorking = false
      if(response.data.success) {
        this.insertCompleted = true
      } else {
        this.insertError = true
        this.insertFormDisabled = false
      }
    }).catch(() => {
      this.insertWorking = false
      this.insertError = true
      this.insertFormDisabled = false
    })
  }

  zurueckZurUebersicht() {
    router.back()
  }

  gebeOeffnungszeitMin(vorlage: OeffnungstagVorlage, index: number) : string {
    if(index == null) return null
    if(index > 0)
    return vorlage.oeffnungszeiten[index-1].zeitBis
  }

  checkExtraButtonDisabled(vorlage: OeffnungstagVorlage, index: number) {
    var zrData = vorlage.oeffnungszeiten[vorlage.oeffnungszeiten.length - 1]
    if (zrData)
      if (zrData.zeitVon == null || zrData.zeitBis == null) return true
    return false
  }
}
