import MitarbeiterListeRequest from '@/entities/member/request/mitarbeiter_liste_request'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import MitarbeiterList from '@/entities/member/lists/mitarbeiter_list'
import RestPaths from '@/util/rest_paths'
import Mitarbeiter from '@/entities/member/mitarbeiter'
import MemberBaseLogic from '../member_base_logic'

export default abstract class MitarbeiterMemcommonLogic extends MemberBaseLogic {
  liste: Mitarbeiter[] = []
  // selectedMitarbeiter: boolean = null

  loadError: boolean = false

  betriebId: string

  loadListe() {
    this.loadError = false
    var request = new MitarbeiterListeRequest()
    request.betriebId = this.betriebId
    request.showDeaktivierte = false

    Poster.postMember<RemoteResult<MitarbeiterList>>(
      RestPaths.mitarbeiterListe,
      request
    )
      .then((response) => {
        if (response.data.success) {
          this.liste = response.data.payload.list
        } else {
          this.loadError = true
        }
      })
      .catch(() => {
        this.loadError = true
      })
  }

  init() {
    this.loadListe()
  }

  abstract neuerMitarbeiter(): void

  abstract detailsAnzeigen(id: string): void
}
