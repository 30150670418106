import FilialeSimple from "@/entities/member/filiale_simple"
import Pliste from "@/entities/member/pliste"
import router from "@/router"
import RouteNames from "@/util/route_names"
import PreislistenMemcommonLogic from "../memcommon/preislisten_memcommon_logic"
import BetriebDetailsLogic from "./betrieb_details_logic"

export default class PreislistenLogic extends PreislistenMemcommonLogic {
  parent?: BetriebDetailsLogic = null
  
  replaceFiliale(): void {
    router.replace({
      name: RouteNames.SYSTEM.PREISLISTEN,
      query: { filiale: this.filiale },
    })
  }

  get filialen(): FilialeSimple[] {
    return this.parent.filialen
  }

  kategorienVerwalten(): void {
    router.push({ name: RouteNames.SYSTEM.KATEGORIEN })
  }

  navNeu(): void {
    router.push({ name: RouteNames.SYSTEM.PREISLISTE_NEU })
  }

  detailsAnzeigen(newValue: Pliste): void {
    router.push({
      name: RouteNames.SYSTEM.PREISLISTE_DETAILS,
      params: { id: newValue.id },
    })
  }

  init(parent: BetriebDetailsLogic, filialeId: string) {
    this.parent = parent
    this._filiale = filialeId
  }
}