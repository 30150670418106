import RemoteResult from '@/entities/common/remote_result'
import StringRequest from '@/entities/common/request/string_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import Validator from '@/util/validator'

export default class PasswortVergessenLogic {
  emailAdresse?: string
  emailRules = Validator.emailValidate
  passwortAngefordert: boolean = false
  formDisabled: boolean = false

  aendernCompleted: boolean = false

  init() {}

  passwortAnfordern() {
    this.formDisabled = true

    var request = new StringRequest()
    request.param = this.emailAdresse

    Poster.postUser<RemoteResult<any>>(
      RestPaths.kundePasswortVergessen,
      request
    ).then((response) => {
      if (response.data.success) {
        this.passwortAngefordert = true
      }
    })
  }
}
