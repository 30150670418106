
import Component from 'vue-class-component'
import MitarbeiterEditLogic from '@/logic/system/mitarbeiter_edit_logic'
import MitarbeiterEditMemCommon from '../memcommon/MitarbeiterEditMemCommon.vue'
import Vue from 'vue'

@Component({ components: { MitarbeiterEditMemCommon } })
export default class MitarbeiterEdit extends Vue {
  logic?: MitarbeiterEditLogic = null

  mounted() {
    this.logic = new MitarbeiterEditLogic()
    this.logic.init(this.$route.params.id)
  }
}
