
import Vue from 'vue'
import Component from 'vue-class-component'
import NewPreislisteFilialeNeuMemCommon from '../memcommon/NewPreislisteFilialeNeuMemCommon.vue'
import NewPreislisteFilialeNeuLogic from '@/logic/member/new_preisliste_filiale_neu_logic'
import store from '@/store'

@Component({ components: { NewPreislisteFilialeNeuMemCommon } })
export default class NewPreislisteFilialeNeu extends Vue {
  logic?: NewPreislisteFilialeNeuLogic = null

  mounted() {
    var betriebId = store.state.user.betriebId
    var filialeId = this.$route.params.filialeId

    this.logic = new NewPreislisteFilialeNeuLogic()
    this.logic.init(filialeId, betriebId)
  }
}
