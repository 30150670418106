import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Filiale from '@/entities/member/filiale'
import NeuePreislisteInfo from '@/entities/member/neue_preisliste_info'
import PlisteInsertRequest, {
  Kategorie as ReqKategorie,
  KategorieZeitraum as ReqKategorieZeitraum,
  Produkt as ReqProdukt,
  Auswahl as ReqAuswahl,
  Extra as ReqExtra,
  Option as ReqOption,
  Subprodukt as ReqSubprodukt,
} from '@/entities/member/request/pliste_insert_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import moment from 'moment'
import { PreislisteExtra } from './new_preisliste_edit_memcommon_logic'

export default class TageskarteNeuMemcommonLogic {
  filiale?: Filiale
  extras: Produkt[] = []
  produkte: Produkt[] = []
  gewaehlteProdukte: Produkt[] = []
  produkteWaehlenVisible: boolean = false
  datumDialogVisible: boolean = false
  datum?: string
  kategorie: Kategorie = new Kategorie()
  preislisteAktivieren: boolean = true

  insertWorking: boolean = false
  insertCompleted: boolean = false
  insertFormDisabled: boolean = false
  insertError: boolean = false

  init(filialeId: string, betriebId: string) {
    var filialeRequest = new IdRequest()
    filialeRequest.id = filialeId

    Poster.postMember<RemoteResult<Filiale>>(
      RestPaths.filialeDetails,
      filialeRequest
    ).then((response) => {
      if (response.data.success) {
        this.filiale = response.data.payload
      }
    })

    var request = new IdRequest()
    request.id = betriebId

    Poster.postMember<RemoteResult<NeuePreislisteInfo>>(
      RestPaths.preislisteNeueInfo,
      request
    ).then((response) => {
      if (response.data.success) {
        let payload = response.data.payload

        payload.extras.forEach((e) => {
          var extra = new Produkt()
          extra.id = e.id
          extra.bezeichnung = e.bezeichnung
          extra.artikelNr = e.artikelNr
          extra.standardpreis = e.standardpreis
          this.extras.push(extra)
        })

        payload.produkte.forEach((p) => {
          var produkt = new Produkt()
          produkt.id = p.id
          produkt.bezeichnung = p.bezeichnung
          produkt.artikelNr = p.artikelNr
          produkt.standardpreis = p.standardpreis

          p.extras.forEach((e) => {
            var produktExtra = new ProduktExtra()
            var extra = this.findProduktExtra(e.pduktId)
            produktExtra.produkt = extra
            produktExtra.pduktExtraId = e.pduktExtraId
            produkt.extras.push(produktExtra)
          })

          p.auswahlen.forEach((a) => {
            var auswahl = new Auswahl()
            auswahl.id = a.id
            auswahl.bezeichnung = a.bezeichnung

            a.optionen.forEach((o) => {
              var produktExtra = new ProduktExtra()
              var option = this.findProduktExtra(o.pduktId)
              produktExtra.produkt = option
              produktExtra.pduktExtraId = o.auswahlOptionId
              auswahl.optionen.push(produktExtra)
            })
            produkt.auswahlen.push(auswahl)
          })

          p.subprodukte.forEach((sp) => {
            var subprodukt = new Subprodukt()
            subprodukt.id = sp.id
            subprodukt.bezeichnung = sp.bezeichnung
            produkt.subprodukte.push(subprodukt)

            sp.produkte.forEach((spp) => {
              var produkt = new Produkt()
              produkt.id = spp.id
              produkt.bezeichnung = spp.bezeichnung
              produkt.artikelNr = spp.artikelNr
              produkt.standardpreis = spp.standardpreis

              spp.extras.forEach((e) => {
                var produktExtra = new ProduktExtra()
                var extra = this.findProduktExtra(e.pduktId)
                produktExtra.produkt = extra
                produktExtra.pduktExtraId = e.pduktExtraId
                produkt.extras.push(produktExtra)
              })

              spp.auswahlen.forEach((a) => {
                var auswahl = new Auswahl()
                auswahl.id = a.id
                auswahl.bezeichnung = a.bezeichnung

                a.optionen.forEach((o) => {
                  var produktExtra = new ProduktExtra()
                  var option = this.findProduktExtra(o.pduktId)
                  produktExtra.produkt = option
                  produktExtra.pduktExtraId = o.auswahlOptionId
                  auswahl.optionen.push(produktExtra)
                })
                produkt.auswahlen.push(auswahl)
              })
              subprodukt.optionen.push(produkt)
            })
          })
          this.produkte.push(produkt)
        })
      }
    })
  }

  findProduktExtra(id: string) {
    return this.extras.find((element) => element.id == id)
  }

  produkteHinzufuegenAnzeigen() {
    this.produkteWaehlenVisible = true
  }

  produkteHinzufuegenAbbrechen() {
    this.produkteWaehlenVisible = false
    this.gewaehlteProdukte = []
  }

  produkteHinzufuegen() {
    this.gewaehlteProdukte.forEach((produkt) => {
      this.kategorie.produkte.push(this.createProdukt(produkt))
    })
    this.produkteWaehlenVisible = false
  }

  preislisteEinfuegen() {
    this.insertWorking = true
    this.insertFormDisabled = true
    this.insertError = false

    var request = new PlisteInsertRequest()
    request.tageskarte = true
    request.aktivieren = this.preislisteAktivieren
    request.gueltigVon = moment(this.datum, 'YYYY-MM-DD')
      .startOf('day')
      .format('YYYY-MM-DDTHH:mm:ss')
    request.filialeId = this.filiale.id

    var reqKategorie = new ReqKategorie()
    reqKategorie.index = -1
    request.kategorien.push(reqKategorie)

    this.kategorie.produkte.forEach((prlProdukt, index) => {
      var reqProdukt = new ReqProdukt()
      reqProdukt.produktId = prlProdukt.produkt.id
      reqProdukt.preis = prlProdukt.preis
      reqProdukt.index = index
      reqKategorie.produkte.push(reqProdukt)

      prlProdukt.extras.forEach((prlExtra, index) => {
        var reqExtra = new ReqExtra()
        reqExtra.pduktExtraId = prlExtra.extra.pduktExtraId
        reqExtra.preis = prlExtra.preis
        reqExtra.index = index
        reqProdukt.extras.push(reqExtra)
      })

      prlProdukt.auswahlen.forEach((prlAuswahl, index) => {
        var reqAuswahl = new ReqAuswahl()
        reqAuswahl.auswahlId = prlAuswahl.auswahl.id
        reqAuswahl.index = index
        reqProdukt.auswahlen.push(reqAuswahl)

        prlAuswahl.optionen.forEach((prlOption, index) => {
          var reqOption = new ReqOption()
          reqOption.auswahlOptionId = prlOption.extra.pduktExtraId
          reqOption.preis = prlOption.preis
          reqOption.index = index

          reqAuswahl.optionen.push(reqOption)
        })
      })

      prlProdukt.subprodukte.forEach((prlSubprodukt, index) => {
        var reqSubprodukt = new ReqSubprodukt()
        reqSubprodukt.subproduktId = prlSubprodukt.subprodukt.id
        reqSubprodukt.index = index
        reqProdukt.subprodukte.push(reqSubprodukt)

        prlSubprodukt.optionen.forEach((prlOption, index) => {
          var reqSubproduktOption = new ReqProdukt()
          reqSubproduktOption.produktId = prlOption.produkt.id
          reqSubproduktOption.preis = prlOption.preis
          reqSubproduktOption.index = index

          reqSubprodukt.optionen.push(reqSubproduktOption)

          prlOption.extras.forEach((prlExtra, index) => {
            var reqExtra = new ReqExtra()
            reqExtra.pduktExtraId = prlExtra.extra.pduktExtraId
            reqExtra.preis = prlExtra.preis
            reqExtra.index = index
            reqSubproduktOption.extras.push(reqExtra)
          })

          prlOption.auswahlen.forEach((prlAuswahl, index) => {
            var reqAuswahl = new ReqAuswahl()
            reqAuswahl.auswahlId = prlAuswahl.auswahl.id
            reqAuswahl.index = index
            reqSubproduktOption.auswahlen.push(reqAuswahl)

            prlAuswahl.optionen.forEach((prlOption, index) => {
              var reqOption = new ReqOption()
              reqOption.auswahlOptionId = prlOption.extra.pduktExtraId
              reqOption.preis = prlOption.preis
              reqOption.index = index
              reqAuswahl.optionen.push(reqOption)
            })
          })
        })
      })
    })

    Poster.postMember<RemoteResult<any>>(RestPaths.preislisteEinfuegen, request)
      .then((response) => {
        this.insertWorking = false
        if (response.data.success) {
          this.insertCompleted = true
        } else {
          this.insertError = true
          this.insertFormDisabled = false
        }
      })
      .catch(() => {
        this.insertWorking = false
        this.insertError = true
        this.insertFormDisabled = false
      })
  }

  createProdukt(produkt: Produkt): PreislisteProdukt {
    var preislisteProdukt = new PreislisteProdukt()
    preislisteProdukt.produkt = produkt
    preislisteProdukt.preis = produkt.standardpreis
    produkt.extras.forEach((extra) => {
      var preislisteProduktExtra = new PreislisteProduktExtra()
      preislisteProduktExtra.extra = extra
      preislisteProduktExtra.preis = extra.produkt.standardpreis
      preislisteProdukt.extras.push(preislisteProduktExtra)
    })

    produkt.auswahlen.forEach((auswahl) => {
      var preislisteProduktAuswahl = new PreislisteProduktAuswahl()
      preislisteProduktAuswahl.auswahl = auswahl

      auswahl.optionen.forEach((option) => {
        var preislisteProduktExtra = new PreislisteProduktExtra()
        preislisteProduktExtra.extra = option
        preislisteProduktAuswahl.optionen.push(preislisteProduktExtra)
      })

      preislisteProdukt.auswahlen.push(preislisteProduktAuswahl)
    })

    produkt.subprodukte.forEach((subprodukt) => {
      var preislisteProduktSubprodukt = new PreislisteProduktSubprodukt()
      preislisteProduktSubprodukt.subprodukt = subprodukt
      subprodukt.optionen.forEach((suboption) => {
        var preislisteProduktOption = this.createProdukt(suboption)
        preislisteProduktSubprodukt.optionen.push(preislisteProduktOption)
      })
      preislisteProdukt.subprodukte.push(preislisteProduktSubprodukt)
    })

    return preislisteProdukt
  }
}

class Kategorie {
  id?: string
  bezeichnung?: string
  beschreibung?: string
  zeitbegrenzung: boolean = false
  zeitraueme: KategorieZeitraum[] = []
  produkte: PreislisteProdukt[] = []
}

class KategorieZeitraum {
  wochentag?: number
  zeitVon?: string
  zeitBis?: string
}

class Produkt {
  id?: string
  bezeichnung?: string
  artikelNr?: string
  standardpreis?: number

  extras: ProduktExtra[] = []
  auswahlen: Auswahl[] = []
  subprodukte: Subprodukt[] = []
}

class Subprodukt {
  id?: string
  bezeichnung?: string

  optionen: Produkt[] = []
}

class ProduktExtra {
  pduktExtraId?: string
  produkt?: Produkt
}

class Auswahl {
  id?: string
  bezeichnung?: string
  optionen: ProduktExtra[] = []
}

class PreislisteProdukt {
  produkt: Produkt
  extras: PreislisteProduktExtra[] = []
  auswahlen: PreislisteProduktAuswahl[] = []
  preis: number
  index: number = 0
  subprodukte: PreislisteProduktSubprodukt[] = []
}

class PreislisteProduktExtra {
  extra: ProduktExtra
  pduktExtraId: string
  preis: number
  index: number = 0
}

class PreislisteProduktOption {}

class PreislisteProduktAuswahl {
  id: string
  auswahl: Auswahl
  optionen: PreislisteProduktExtra[] = []
}

class PreislisteProduktSubprodukt {
  id: string
  subprodukt: Subprodukt
  optionen: PreislisteProdukt[] = []
}

export { Kategorie, PreislisteProdukt, Produkt }
