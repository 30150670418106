
import { Component, Vue } from 'vue-property-decorator'
import PreislisteDetailsLogic from '@/logic/system/preisliste_details_logic'
import PreislisteDetailsMemCommon from '../memcommon/PreislisteDetailsMemCommon.vue'

@Component({ components: { PreislisteDetailsMemCommon } })
export default class PreislisteDetails extends Vue {
  logic?: PreislisteDetailsLogic = null

  mounted() {
    this.logic = new PreislisteDetailsLogic()
    this.logic.init(this.$route.params.id)
  }
}
