
import { Component, Vue } from 'vue-property-decorator'
import PreislistenLogic from '@/logic/member/preislisten_logic'
import PreislistenMemCommon from '../memcommon/PreislistenMemCommon.vue'

@Component({ components: { PreislistenMemCommon } })
export default class Preislisten extends Vue {
  logic?: PreislistenLogic = null

  mounted() {
    this.logic = new PreislistenLogic()
    this.logic.init(this.$route.query.id as string)
  }
}
