import RemoteResult from "@/entities/common/remote_result"
import IdRequest from "@/entities/common/request/id_request"
import OeffnungszeitZeitraum from "@/entities/member/oeffnungszeit_zeitraum"
import Poster from "@/util/poster"
import RestPaths from "@/util/rest_paths"
import MemberBaseLogic from "../member_base_logic"
import OeffnungszeitFiliale from "@/entities/member/oeffnungszeit_filiale"

export default abstract class OeffnungszeitDetailsMemcommonLogic extends MemberBaseLogic {
  oeffnungszeit: OeffnungszeitFiliale = null

  abstract listeBearbeiten(): void 

  abstract ausnahmenHinzufuegen(): void

  listeLoeschen() {
    var request = new IdRequest()
    request.id = this.oeffnungszeit.filialeId

    Poster.postMember(RestPaths.oeffnungszeitZeitraumLoeschen,
        request).then()
  }

  init(id: string) {
    var request = new IdRequest()
    request.id = id

    Poster.postMember<RemoteResult<OeffnungszeitFiliale>>(RestPaths.oeffnungszeitFilialeDetails,
        request).then((response)=>{
          this.oeffnungszeit = response.data.payload          
        })
  }
}