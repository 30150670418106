
import Vue from 'vue'
import { Component, Emit, Prop, PropSync } from 'vue-property-decorator'

@Component
export default class LinkBox extends Vue {
  @Prop() header?: string
  @Prop() icon?: string
  @Prop() body?: string

  @Emit('linkClick')
  navLink() {}
}
