export default class OeffnungszeitZeitraumInsertRequest {
  id?: string
  filialeId?: string
  gueltigVon?: string
  gueltigBis?: string
  wochentage: VorlageData[] = []
}

class VorlageData {
  wochentag?: number
  oeffnungszeiten: ZeitraumData[] = []
}

class ZeitraumData {
  zeitVon?: string
  zeitBis?: string
}

export { VorlageData, ZeitraumData }
