
import OeffnungszeitenLogic from '@/logic/system/oeffnungszeiten_logic'
import Component from 'vue-class-component'
import BetriebDetails from './BetriebDetails.vue'
import OeffnungszeitenMemCommon from '../memcommon/OeffnungszeitenMemCommon.vue'
import Vue from 'vue'

@Component({ components: { OeffnungszeitenMemCommon } })
export default class Oeffnungszeiten extends Vue {
  logic?: OeffnungszeitenLogic = null

  mounted() {
    this.logic = new OeffnungszeitenLogic()
    this.logic.init((this.$parent as BetriebDetails).logic)
  }
}
