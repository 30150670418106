
import Vue from 'vue'
import Component from 'vue-class-component'
import FilialeHeader from '../custom/FilialeHeader.vue'
import NichtGueltig from '../custom/NichtGueltig.vue'
import KundendatenLogic from '@/logic/bestellung_neu/kundendaten_logic'

@Component({ components: { FilialeHeader, NichtGueltig } })
export default class Kundendaten extends Vue {
  $refs!: {
    form: HTMLFormElement
  }

  logic: KundendatenLogic = new KundendatenLogic()

  weiter() {
    var valid = this.$refs.form.validate()

    if (!valid) return

    this.logic.weiter()
  }

  mounted() {
    this.logic = new KundendatenLogic()
    this.logic.init()
  }
}
