
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import FilialeEditLogic from '@/logic/member/filiale_edit_logic'
import FilialeEditMemCommon from '../memcommon/FilialeEditMemCommon.vue'

@Component({ components: { FilialeEditMemCommon } })
export default class FilialeEdit extends Vue {
  logic?: FilialeEditLogic = null

  mounted() {
    this.logic = new FilialeEditLogic()
    this.logic.init(this.$route.params.id)
  }
}
