export default class PduktEditRequest {
  id?: string
  basiskategorieId?: string
  bezeichnung?: string
  beschreibung?: string
  artikelNr?: string
  bildId?: string
  bildNeu?: boolean = false
  bildNeuId?: string
  vegetarisch: boolean = false
  vegan: boolean = false
  hot1?: boolean = false
  hot2?: boolean = false
  hot3?: boolean = false
  standardpreis: number = 0.0
  inhaltsstoffe?: string[] = []
  extras: Extra[] = []
  auswahlen: Auswahl[] = []
  subprodukte: Subprodukt[] = []
}

class Extra {
  id?: string
  produktId?: string
}

class Auswahl {
  id?: string
  bezeichnung?: string
  beschreibung?: string
  errorText?: string
  required: boolean = false
  optionen: Option[] = []
}

class Subprodukt {
  id?: string
  bezeichnung?: string
  beschreibung?: string
  errorText?: string
  required: boolean = false
  optionen: Option[] = []
}

class Option {
  id?: string
  produktId?: string
}

export { Extra, Auswahl, Option, Subprodukt }
