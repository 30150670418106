import RemoteResult from '@/entities/common/remote_result'
import LiefergebietInsertListResponse from '@/entities/member/liefergebiet_insert_list_response'
import FilialeInsertRequest, {
  AbholungSettings,
  ReservierungSettings,
  SofortbestellungSettings,
  LieferungSettings,
  PaypalSettings,
} from '@/entities/member/request/filiale_insert_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import { v4 as uuidv4 } from 'uuid'
import store from '@/store'
import CheckLocationRequest from '@/entities/common/request/check_location_request'
import CheckLocationResponse from '@/entities/common/request/check_location_response'
import TimeZone from '@/entities/member/time_zone'
import TimeZoneList from '@/entities/member/lists/time_zone_list'
import MemberBaseLogic from '../member_base_logic'

export default class FilialeNeuMemcommonLogic extends MemberBaseLogic {
  errorOccured: boolean = false
  insertSuccess: boolean = false
  formDisabled: boolean = false

  adressCheckWorking: boolean = false
  adressCheckError: boolean = false

  bezeichnung?: string = null
  strasse?: string = null
  plz?: string = null
  ort?: string = null
  telefon?: string = null
  email?: string = null
  latitude?: number = null
  longitude?: number = null
  map?: any = null
  image: any = null
  imageUrl?: string = null
  tempImageId?: string = null
  kmlfile: any = null
  liefergebiete?: LiefergebietInsertListResponse = null
  timeZone?: number = 42

  timeZones: TimeZone[] = []

  verfuegbareGebiete: VerfuegbaresPolygon[] = []
  gewaehlteGebiete: VerfuegbaresPolygon[] = []
  gebiete: Liefergebiet[] = []
  aktuellesGebiet: Liefergebiet = null

  banner: any = null
  bannerUrl?: string = null
  tempBannerId?: string = null

  abholung = new Abholung()
  reservierung = new Reservierung()
  sofortbestellung = new Sofortbestellung()
  lieferung = new Lieferung()
  paypal = new Paypal()

  betriebId: string

  init() {
    Poster.postMember<RemoteResult<TimeZoneList>>(
      RestPaths.filialeTimeZones,
      null
    ).then((response) => {
      if (response.data.success) {
        this.timeZones = response.data.payload.list
      }
    })
  }

  previewImage() {
    this.imageUrl = URL.createObjectURL(this.image)
    this.uploadImage()
  }

  previewBanner() {
    this.bannerUrl = URL.createObjectURL(this.banner)
    this.uploadBanner()
  }

  uploadKmlFile() {
    var data = new FormData()
    data.append('file', this.kmlfile)

    fetch(RestPaths.urlBase + RestPaths.filialeParseKmlFile, {
      method: RestPaths.methodPost,
      body: data,
    })
      .then((response) => response.json())
      .then((data) => {
        this.liefergebiete = data.payload
        this.liefergebiete.gebiete.forEach((l) => {
          var vl = new VerfuegbaresPolygon()
          vl.bezeichnung = l.bezeichnung
          var vp = new Polygon()
          l.gebiet.points.forEach((p) => {
            var np = new Point()
            np.latitude = p.latitude
            np.longitude = p.longitude
            vp.punkte.push(np)
          })
          vl.polygon = vp
          this.verfuegbareGebiete.push(vl)
        })
      })
  }

  zuordnen() {
    this.gewaehlteGebiete.forEach((g) => {
      var poly = new Polygon()
      poly.bezeichnung = g.bezeichnung
      g.polygon.punkte.forEach((punkt) => {
        var point = new Point()
        point.latitude = punkt.latitude
        point.longitude = punkt.longitude
        poly.punkte.push(point)
      })
      this.aktuellesGebiet.gebiete.push(poly)
    })
  }

  uploadImage() {
    var data = new FormData()
    data.append('file', this.image)

    fetch(RestPaths.urlBase + RestPaths.imageSaveTemp, {
      method: RestPaths.methodPost,
      body: data,
    })
      .then((response) => response.json())
      .then((data) => {
        this.tempImageId = data.payload
      })
  }

  uploadBanner() {
    var data = new FormData()
    data.append('file', this.banner)

    fetch(RestPaths.urlBase + RestPaths.imageSaveTemp, {
      method: RestPaths.methodPost,
      body: data,
    })
      .then((response) => response.json())
      .then((data) => (this.tempBannerId = data.payload))
  }

  adresseUeberpruefen() {
    this.adressCheckWorking = true
    this.adressCheckError = false

    var request = new CheckLocationRequest()
    request.ort = this.ort
    request.plz = this.plz
    request.strasse = this.strasse

    Poster.postMember<RemoteResult<CheckLocationResponse>>(
      RestPaths.filialeCheckLocation,
      request
    )
      .then((response) => {
        if (response.data.success) {
          this.adressCheckWorking = false

          this.latitude = response.data.payload.longitude
          this.longitude = response.data.payload.latitude
          this.map = response.data.payload.imageData
        } else {
          this.adressCheckWorking = false
          this.adressCheckError = true
        }
      })
      .catch(() => {
        this.adressCheckWorking = false
        this.adressCheckError = true
      })
  }

  filialeEinfuegen() {
    this.formDisabled = true

    var request = new FilialeInsertRequest()
    request.id = uuidv4()
    request.bezeichnung = this.bezeichnung
    request.strasse = this.strasse
    request.plz = this.plz
    request.ort = this.ort
    request.telefon = this.telefon
    request.email = this.email
    request.latitude = this.latitude
    request.longitude = this.longitude
    request.timeZoneId = this.timeZone
    request.tempBildId = this.tempImageId
    request.tempBannerId = this.tempBannerId

    request.betriebId = store.state.user.betriebId

    request.supportsAbholung = this.abholung.supported
    if (this.abholung.supported) {
      var abholungSettings = new AbholungSettings()
      request.abholungSettings = abholungSettings

      abholungSettings.supportsVorbestellung =
        this.abholung.supportsVorbestellung
      abholungSettings.supportsMehrereTage = this.abholung.supportsMehrereTage
      abholungSettings.zeitVorSchliessung = this.abholung.zeitVorSchliessung
      abholungSettings.vorbestellungTage = this.abholung.vorbestellungTage
    }

    request.supportsReservierung = this.reservierung.supported
    if (this.reservierung.supported) {
      var reservierungSettings = new ReservierungSettings()
      request.reservierungSettings = reservierungSettings

      reservierungSettings.supportsVorbestellung =
        this.reservierung.supportsVorbestellung
      reservierungSettings.reservierungTage = this.reservierung.reservierungTage
      reservierungSettings.zeitVorSchliessung =
        this.reservierung.zeitVorSchliessung
    }

    request.supportsSofortbestellung = this.sofortbestellung.supported
    if (this.sofortbestellung.supported) {
      var sofortbestellungSettings = new SofortbestellungSettings()
      request.sofortbestellungSettings = sofortbestellungSettings

      sofortbestellungSettings.zeitVorSchliessung =
        this.sofortbestellung.zeitVorSchliessung
    }

    request.supportsLieferung = this.lieferung.supported
    if(this.lieferung.supported) {
      var lieferungSettings = new LieferungSettings()
      request.lieferungSettings = lieferungSettings

      lieferungSettings.supportsTermin = this.lieferung.supportsTermin
      lieferungSettings.terminTage = this.lieferung.terminTage
    }

    request.supportsPaypal = this.paypal.supported
    if (this.paypal.supported) {
      var paypalSettings = new PaypalSettings()
      paypalSettings.paypalAdresse = this.paypal.adresse
    }
    
    Poster.postMember<RemoteResult<string>>(
      RestPaths.filialeEinfuegen,
      request
    ).then((response) => {
      if (response.data.success) {
        this.insertSuccess = true
      } else {
        this.errorOccured = true
        this.formDisabled = false
      }
    })
  }
}

class Abholung {
  supported: boolean = false
  supportsVorbestellung: boolean = false
  supportsMehrereTage: boolean = false
  zeitVorSchliessung: number = 0
  vorbestellungTage: number = 0
}

class Reservierung {
  supported: boolean = false
  supportsVorbestellung: boolean = false
  zeitVorSchliessung: number = 0
  reservierungTage: number = 0
}

class Lieferung {
  supported: boolean = false
  supportsTermin: boolean = false
  terminTage: number = 0
}

class Sofortbestellung {
  supported: boolean = false
  zeitVorSchliessung: number = 0
}

class Paypal {
  supported: boolean = false
  adresse: string = null
}

class VerfuegbaresPolygon {
  bezeichnung: string = ''
  polygon: Polygon = null
}

class Liefergebiet {
  bezeichnung: string = ''
  lieferkosten: number = 0
  lieferzeitVon: number = 0
  lieferzeitBis: number = 0
  mindestbestellwert: number = 0
  kostenlosAb: number = 0
  priority: number = 0
  gebiete: Polygon[] = []
}

class Polygon {
  bezeichnung: string = null
  punkte: Point[] = []
}

class Point {
  latitude: number = 0
  longitude: number = 0
}

export { Liefergebiet, Polygon, Point, VerfuegbaresPolygon }
