import RemoteResult from '@/entities/common/remote_result'
import AuswertungBetrieb from '@/entities/member/auswertung_betrieb'
import Betrieb from '@/entities/member/betrieb'
import BetriebList from '@/entities/member/lists/betrieb_list'
import AuswertungRequest from '@/entities/member/request/auswertung_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'

export default class AuswertungLogic {
  betriebe?: Betrieb[] = []
  betrieb?: string = null
  datumVon?: string = null
  datumBis?: string = null
  vonMenuOpen: boolean = false
  bisMenuOpen: boolean = false
  auswertung?: AuswertungBetrieb = null

  init() {
    Poster.postMember<RemoteResult<BetriebList>>(
      RestPaths.betriebListe,
      {}
    ).then((response) => {
      this.betriebe = response.data.payload.list
    })
  }

  auswertungStarten() {
    var request = new AuswertungRequest()
    request.betriebId = this.betrieb
    request.datumVon = this.datumVon
    request.datumBis = this.datumBis

    Poster.postMember<RemoteResult<AuswertungBetrieb>>(
      RestPaths.auswertungBetrieb,
      request
    ).then((response) => {
      this.auswertung = response.data.payload
    })
  }
}
