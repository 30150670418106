export default class TischListeInsertRequest {
  filialeId?: string
  tische: Tisch[] = []
}

class Tisch {
  id?: string
  bezeichnung?: string
  personen?: number
  onlineReservierbar : Boolean = true
}

export {Tisch}