import router from '@/router'
import RouteNames from '@/util/route_names'
import ProduktDetailsMemcommonLogic from '../memcommon/produkt_details_memcommon_logic'

export default class ProduktDetailsLogic extends ProduktDetailsMemcommonLogic {
  produktBearbeiten(): void {
    router.push({
      name: RouteNames.MEMBER.PRODUKT_EDIT,
      params: { id: this.produkt.id },
    })
  }
}
