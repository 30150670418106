import BetriebDetailsLogic from "./betrieb_details_logic";
import OeffnungszeitAusnahmenMemcommonLogic from "../memcommon/oeffnungszeit_ausnahmen_memcommon_logic";
import FilialeSimple from "@/entities/member/filiale_simple";

export default class OeffnungszeitAusnahmenLogic extends OeffnungszeitAusnahmenMemcommonLogic {
  parent: BetriebDetailsLogic = null

  get filialen(): FilialeSimple[] {
    return this.parent.filialen
  }
}
