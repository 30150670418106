
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import TischDetailsLogic from '@/logic/member/tisch_details_logic'
import TischDetailsMemCommon from '../memcommon/TischDetailsMemCommon.vue'

@Component({ components: { TischDetailsMemCommon } })
export default class TischDetails extends Vue {
  logic?: TischDetailsLogic = null

  mounted() {
    this.logic = new TischDetailsLogic()
    this.logic.init(this.$route.params.id as string)
  }
}
