
import Vue from 'vue'
import Component from 'vue-class-component'
import NewPreislisteEditMemCommon from '../memcommon/NewPreislisteEditMemCommon.vue'
import NewPreislisteEditLogic from '@/logic/member/new_preisliste_edit_logic'
import store from '@/store'

@Component({ components: { NewPreislisteEditMemCommon } })
export default class NewPreislisteEdit extends Vue {
  logic?: NewPreislisteEditLogic = null

  mounted() {
    var preislisteId = this.$route.params.preislisteId
    var betriebId = store.state.user.betriebId

    this.logic = new NewPreislisteEditLogic()
    this.logic.init(preislisteId, betriebId)
  }
}
