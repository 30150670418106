
import BetriebDetailsLogic from '@/logic/system/betrieb_details_logic'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class BetriebDetails extends Vue {
  logic?: BetriebDetailsLogic = null

  mounted() {
    this.logic = new BetriebDetailsLogic()
    this.logic.init(this.$route.params.id)
  }

  navBestellverwaltung() {
    this.logic.navBestellverwaltung()
  }

  navPage() {
    this.logic.navPage()
  }

  navMitarbeiter() {
    this.logic.navMitarbeiter()
  }

  navFilialen() {
    this.logic.navFilialen()
  }

  navProdukte() {
    this.logic.navProdukte()
  }

  navPreislisten() {
    this.logic.navPreislisten()
  }

  navOeffnungszeiten() {
    this.logic.navOeffnungszeiten()
  }

  navTische() {
    this.logic.navTische()
  }

  navRechnung() {
    this.logic.sendRechnung()
  }
}
