
import Betrieb from '@/entities/member/betrieb'
import Vue from 'vue'
import Component from 'vue-class-component'
import BetriebeLogic from '@/logic/system/betriebe_logic'
import ToolbarButton from '../custom/ToolbarButton.vue'

@Component({ components: { ToolbarButton } })
export default class Betriebe extends Vue {
  logic?: BetriebeLogic = null

  detailsAnzeigen(betrieb: Betrieb) {
    this.logic.detailsAnzeigen(betrieb)
  }

  neuerBetrieb() {
    this.logic.neuerBetrieb()
  }

  mounted() {
    this.logic = new BetriebeLogic()
    this.logic.init()
  }
}
