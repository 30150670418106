
import { ProduktExtra } from '@/entities/common/artikel_aktuell'
import { Produkt } from '@/entities/common/artikel_aktuell'
import Component, { mixins } from 'vue-class-component'
import { Emit, Model, ModelSync, Prop } from 'vue-property-decorator'
import Calculator from '../mixins/Calculator.vue'

@Component
export default class ExtrasContainer extends mixins(Calculator) {
  //selectedExtras: ProduktExtra[]
  @Prop() produkt: Produkt
  expanded: boolean = false

  switchExpander() {
    this.expanded = !this.expanded
  }

  @Emit('zeigeExtra')
  zeigeExtra(extra: ProduktExtra) {}

  get firstExtras(): ProduktExtra[] {
    if (this.produkt && this.produkt.extras && this.produkt.extras.length > 3) {
      return this.produkt.extras.slice(0, 3)
    }
    return this.produkt.extras
  }

  get extraExtras(): ProduktExtra[] {
    if (this.produkt && this.produkt.extras && this.produkt.extras.length > 3) {
      return this.produkt.extras.slice(3, this.produkt.extras.length)
    }
    return []
  }
}
