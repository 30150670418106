import OeffnungstagVorlage, {
  ZeitraumData as ZrData,
} from '@/entities/member/oeffnungstag_vorlage'
import MemberBaseLogic from '../member_base_logic'
import IdRequest from '@/entities/common/request/id_request'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import OeffnungszeitZeitraum from '@/entities/member/oeffnungszeit_zeitraum'
import RestPaths from '@/util/rest_paths'
import OeffnungszeitZeitraumUpdateRequest, {
  VorlageData,
  ZeitraumData,
} from '@/entities/member/request/oeffnungszeit_zeitraum_update_request'
import OeffnungszeitFiliale from '@/entities/member/oeffnungszeit_filiale'

export default class OeffnungszeitFilialeMemcommonLogic extends MemberBaseLogic {
  filialeId?: string = null
  filialeBezeichnung?: string = null

  insertCompleted: boolean = false
  insertFormDisabled: boolean = false
  insertError: boolean = false
  insertWorking: boolean = false

  oeffnungszeit?: OeffnungszeitFiliale
  wochentage: OeffnungstagVorlage[] = []
  formValid: boolean = true

  zeitEntfernen(vorlage: OeffnungstagVorlage, index: number) {
    vorlage.oeffnungszeiten.splice(index, 1)
  }

  extraZeitHinzufuegen(vorlage: OeffnungstagVorlage) {
    vorlage.addOeffnungszeit()
  }

  init(id: string) {
    this.filialeId = id

    var montag = new OeffnungstagVorlage(undefined)
    montag.wochentag = 1
    montag.bezeichnung = 'Montag'
    this.wochentage.push(montag)

    var dienstag = new OeffnungstagVorlage(undefined)
    dienstag.wochentag = 2
    dienstag.bezeichnung = 'Dienstag'
    this.wochentage.push(dienstag)

    var mittwoch = new OeffnungstagVorlage(undefined)
    mittwoch.wochentag = 3
    mittwoch.bezeichnung = 'Mittwoch'
    this.wochentage.push(mittwoch)

    var donnerstag = new OeffnungstagVorlage(undefined)
    donnerstag.wochentag = 4
    donnerstag.bezeichnung = 'Donnerstag'
    this.wochentage.push(donnerstag)

    var freitag = new OeffnungstagVorlage(undefined)
    freitag.wochentag = 5
    freitag.bezeichnung = 'Freitag'
    this.wochentage.push(freitag)

    var samstag = new OeffnungstagVorlage(undefined)
    samstag.wochentag = 6
    samstag.bezeichnung = 'Samstag'
    this.wochentage.push(samstag)

    var sonntag = new OeffnungstagVorlage(undefined)
    sonntag.wochentag = 7
    sonntag.bezeichnung = 'Sonntag'
    this.wochentage.push(sonntag)

    var request = new IdRequest()
    request.id = id

    Poster.postMember<RemoteResult<OeffnungszeitFiliale>>(
      RestPaths.oeffnungszeitFilialeDetails,
      request
    ).then((response) => {
      this.oeffnungszeit = response.data.payload
      this.filialeBezeichnung = this.oeffnungszeit.filialeBezeichnung

      this.oeffnungszeit.vorlagen.forEach((element) => {
        this.wochentage.forEach((vorlage) => {
          if (element.wochentag === vorlage.wochentag) {
            vorlage.geoeffnet = true
            element.oeffnungszeiten.forEach((oeffnungsz) => {
              var zeitraumData = new ZrData()
              zeitraumData.zeitVon = oeffnungsz.zeitVon
              zeitraumData.zeitBis = oeffnungsz.zeitBis
              vorlage.oeffnungszeiten.push(zeitraumData)
            })
          }
        })
      })
    })
  }

  speichern() {
    this.insertFormDisabled = true
    this.insertWorking = true
    this.insertError = false

    var request = new OeffnungszeitZeitraumUpdateRequest()
    request.id = this.filialeId

    this.wochentage.forEach((vorlage) => {
      if (vorlage.geoeffnet) {
        var vorlageData = new VorlageData()
        vorlageData.wochentag = vorlage.wochentag

        vorlage.oeffnungszeiten.forEach((o) => {
          var zeitraumData = new ZeitraumData()
          zeitraumData.zeitVon = o.zeitVon
          zeitraumData.zeitBis = o.zeitBis
          vorlageData.oeffnungszeiten.push(zeitraumData)
        })
        request.wochentage.push(vorlageData)
      }
    })

    Poster.postMember<RemoteResult<boolean>>(
      RestPaths.oeffnungszeitFilialeSpeichern,
      request
    )
      .then((response) => {
        this.insertWorking = false
        if (response.data.success) {
          this.insertCompleted = true
        } else {
          this.insertError = true
        }
      })
      .catch(() => {
        this.insertWorking = false
        this.insertFormDisabled = false
        this.insertError = true
      })
  }

  gebeOeffnungszeitMin(vorlage: OeffnungstagVorlage, index: number): string {
    if (index == null) return null
    if (index > 0) return vorlage.oeffnungszeiten[index - 1].zeitBis
  }

  checkExtraButtonDisabled(vorlage: OeffnungstagVorlage, index: number) {
    var zrData = vorlage.oeffnungszeiten[vorlage.oeffnungszeiten.length - 1]
    if (zrData)
      if (zrData.zeitVon == null || zrData.zeitBis == null) return true
    return false
  }
}
