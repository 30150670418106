
import Vue from 'vue'
import Component from 'vue-class-component'
import RegistrierenLogic from '@/logic/bestellung/registrieren_logic'
import router from '@/router'
import RouteNames from '@/util/route_names'

@Component
export default class Registrieren extends Vue {
  logic?: RegistrierenLogic = null

  mounted() {
    this.logic = new RegistrierenLogic()
    this.logic.init()
  }

  registrieren() {
    this.logic.registrieren()
  }

  moveToAGB() {
    router.push({ name: RouteNames.AGB })
  }

  moveToDatenschutz() {
    router.push({ name: RouteNames.DATENSCHUTZ })
  }
}
