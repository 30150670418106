import CheckoutOrder from '@/entities/common/checkout_order'
import RemoteResult from '@/entities/common/remote_result'
import CheckoutRequest from '@/entities/common/request/checkout_request'
import IdRequest from '@/entities/common/request/id_request'
import router from '@/router'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'

export default class BezahlungWeiterleitenLogic {
  init(id: string) {
    var request = new CheckoutRequest()
    request.id = id
    request.checkoutMode = 1

    Poster.postUser<RemoteResult<CheckoutOrder>>(
      RestPaths.paymentPaypalCheckout,
      request
    ).then((response) => {
      window.location.href =
        'https://www.sandbox.paypal.com/checkoutnow?token=' +
        response.data.payload.orderId
    })
  }
}
