
import PlistePdukt from '@/entities/member/pliste_pdukt'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Calculator from '../mixins/Calculator.vue'

@Component
export default class PreislisteProduktRow extends mixins(Calculator) {
  @Prop() produkt: PlistePdukt
}
