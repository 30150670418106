
import RouteNames from '@/util/route_names'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class SystemPortal extends Vue {
  navSetup() {
    this.$router.push({name: RouteNames.SYSTEM.SETUP})
  }

  navBetriebe() {
    this.$router.push({name: RouteNames.SYSTEM.BETRIEBE})
  }
}
