import Bestellung from '@/entities/common/bestellung'
import RemoteResult from '@/entities/common/remote_result'
import ServiceAnfordernRequest from '@/entities/common/request/service_anfordern_request'
import router from '@/router'
import store from '@/store'
import BestellungData from '@/util/bestellung_data'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import { v4 as uuidv4 } from 'uuid'

export default class ServiceAnfordernLogic {
  bestellung?: Bestellung = null
  kommentare?: string = null
  rechnungAnfordern: boolean = false

  init() {
    this.bestellung = (store.state.bestellung as BestellungData).bestellung
  }

  absenden() {
    var request = new ServiceAnfordernRequest()
    request.id = uuidv4()
    request.bestellungId = this.bestellung.id
    request.kommentare = this.kommentare
    request.rechnungAnfordern = this.rechnungAnfordern

    Poster.postUser<RemoteResult<any>>(
      RestPaths.bestellungKundeServiceAnfordern,
      request
    ).then((response) => {
      if (response.data.success) {
        router.go(-1)
      }
    })
  }
}
