
import Vue from 'vue'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import MitarbeiterNeuLogic from '@/logic/member/mitarbeiter_neu_logic'

@Component
export default class MitarbeiterNeuMemCommon extends Vue {
  @Prop() logic: MitarbeiterNeuLogic

  $refs!: {
    form: HTMLFormElement
  }

  formHasErrors: boolean = false
  showPassword: boolean = false

  einfuegen() {
    this.formHasErrors = !this.$refs.form.validate()

    if (this.formHasErrors) {
      return
    }

    this.logic.einfuegen()
  }

  zurueck() {
    this.$router.back()
  }
}
