
import Vue from 'vue'
import Component from 'vue-class-component'
import BetriebEditLogic from '@/logic/system/betrieb_edit_logic'
import ErrorBox from '../custom/ErrorBox.vue'

@Component({ components: { ErrorBox } })
export default class BetriebEdit extends Vue {
  logic?: BetriebEditLogic = null

  datenSpeichern() {
    this.logic.datenSpeichern()
  }

  goBack() {
    this.logic.goBack()
  }

  mounted() {
    this.logic = new BetriebEditLogic()
    this.logic.init(this.$route.params.id)
  }
}
