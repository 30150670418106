import RemoteResult from "@/entities/common/remote_result"
import BetriebAnfragenRequest from "@/entities/common/request/betrieb_anfragen_request"
import Poster from "@/util/poster"
import RestPaths from "@/util/rest_paths"
import { response } from "express"

export default class BetriebAnfragenLogic {
  formDiabled: boolean = false
  insertSuccess: boolean = false

  bezeichnung?: string = null
  strasse?: string = null
  plz?: string = null
  ort?: string = null
  telefon?: string = null
  email?: string = null
  nameAnsprechpartner?: string = null

  supportsLieferung: boolean = false
  supportsAbholung: boolean = false
  supportsReservierung: boolean = false
  supportsSofort: boolean = false

  anzahlFilialen: string = null

  nachricht = null

  anzahlFilialenListe?: string[] = []

  anfragen() {
    this.formDiabled = true

    var request = new BetriebAnfragenRequest()
    request.bezeichnung = this.bezeichnung
    request.strasse = this.strasse
    request.plz = this.plz
    request.ort = this.ort
    request.email = this.email
    request.telefon = this.telefon
    request.ansprechpartner = this.nameAnsprechpartner
    request.nachricht = this.nachricht
    request.anzahlFilialen = this.anzahlFilialen
    request.supportsLieferung = this.supportsLieferung
    request.supportsAbholung = this.supportsAbholung
    request.supportsReservierung = this.supportsReservierung
    request.supportsSofort = this.supportsSofort

    Poster.postUser<RemoteResult<boolean>>(
      RestPaths.betriebAnfragen,
      request
    ).then(response => {
      if(response.data.success) {
        this.insertSuccess = true
      }
    })
  }

  init() {
    this.anzahlFilialenListe.push('1 Filiale')
    this.anzahlFilialenListe.push('2 Filialen')
    this.anzahlFilialenListe.push('3 bis 5 Filialen')
    this.anzahlFilialenListe.push('5 bis 10 Filialen')
    this.anzahlFilialenListe.push('mehr als 10 Filialen')

    // if(option == '1') {
    //   this.anzahlFilialen = this.anzahlFilialenListe[3];
    // }
  }
}