
import Vue from 'vue'
import OeffnungszeitenFilialeLogic from '@/logic/member/oeffnungszeiten_filiale_logic'
import OeffnungszeitenFilialeMemCommon from '../memcommon/OeffnungszeitenFilialeMemCommon.vue'
import Component from 'vue-class-component'

@Component({ components: { OeffnungszeitenFilialeMemCommon } })
export default class OeffnungszeitenFiliale extends Vue {
  logic?: OeffnungszeitenFilialeLogic = null

  mounted() {
    this.logic = new OeffnungszeitenFilialeLogic()
    this.logic.init(this.$route.params.filialeId)
  }
}
