
import Vue from 'vue'
import Component from 'vue-class-component'
import KategorienLogic from '@/logic/member/kategorien_logic'
import KategorienMemCommon from '../memcommon/KategorienMemCommon.vue'
import store from '@/store'

@Component({ components: { KategorienMemCommon } })
export default class Kategorien extends Vue {
  logic?: KategorienLogic = null

  mounted() {
    this.logic = new KategorienLogic()
    this.logic.betriebId = store.state.user.betriebId
    this.logic.init()
  }
}
