
import IdRequest from "@/entities/common/request/id_request";
import Vue from "vue";
import Component from "vue-class-component";
import Filiale from "../custom/Filiale.vue";
import store from "@/store";
import Poster from "@/util/poster";
import RemoteResult from "@/entities/common/remote_result";
import FilialeSimpleList from "@/entities/member/lists/filiale_simple_list";
import RestPaths from "@/util/rest_paths";
import { response } from "express";
import FilialeSimple from "@/entities/member/filiale_simple";
import StoreOps from "@/util/store_ops";

@Component
export default class FilialeSelect extends Vue {
  filialen: FilialeSimple[] = []
  
  mounted() {
    var request = new IdRequest()
    request.id = store.state.user.betriebId

    Poster.postMember<RemoteResult<FilialeSimpleList>>(RestPaths.filialeListeSimple, request)
      .then((response)=> {
        if(response.data.success) {
          this.filialen = response.data.payload.list
        }
      })
  }

  filialeWaehlen(filiale: FilialeSimple) {
    store.commit(StoreOps.FILIALE_SELECT, filiale)
  }
}
