
import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import { Emit, Model, Prop, PropSync } from 'vue-property-decorator'
import PreislisteProduktContainer from './PreislisteProduktContainer.vue'
import { Kategorie, PreislisteProdukt } from './NewPreislisteMemCommon.vue'
import ToolbarButton from '../custom/ToolbarButton.vue'
import { v4 as uuidv4 } from 'uuid'
import Swapper from '@/util/swapper'
import Calculator from '../mixins/Calculator.vue'

@Component({ components: { PreislisteProduktContainer, ToolbarButton } })
export default class PreislisteKategorieContainer extends mixins(Calculator) {
  @Prop() kategorie: Kategorie

  @Model('expanded', { default: true }) expanded: boolean
  expandedSync = false

  @Prop({ default: true }) readonly headerVisible: boolean

  @Emit('produkteHinzufuegen')
  produkteHinzufuegen() {}

  @Emit('kategorieEntfernen')
  kategorieEntfernen() {}

  @Emit('kategorieBearbeiten')
  kategorieBearbeiten() {}

  @Emit('moveUp')
  moveUp() {}

  @Emit('moveDown')
  moveDown() {}

  moveProduktUp(index: number) {
    Swapper.instance.pushUp(this.kategorie.produkte, index)
    //this.changeOrderUp(this.kategorie.produkte, index)
  }

  moveProduktDown(index: number) {
    Swapper.instance.pushDown(this.kategorie.produkte, index)
    //this.changeOrderDown(this.kategorie.produkte, index)
  }

  @Emit('produktEntfernen')
  produktEntfernen(produkt: PreislisteProdukt, kategorie: Kategorie) {
    return { produkt: produkt, kategorie: kategorie }
  }

  expand() {
    this.expandedSync = true
  }

  collapse() {
    this.expandedSync = false
  }

  mounted() {
    this.expandedSync = this.expanded
  }

  randomkey() {
    return uuidv4()
  }
}
