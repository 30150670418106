
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import NichtGueltig from '../custom/NichtGueltig.vue'
import KundendatenReservierungLogic from '@/logic/bestellung_neu/kundendaten_reservierung_logic'

@Component({ components: { NichtGueltig } })
export default class KundendatenReservierung extends Vue {
  $refs: {
    form: HTMLFormElement
  }

  logic?: KundendatenReservierungLogic = null
  
  mounted() {
    this.logic = new KundendatenReservierungLogic()
    this.logic.init()
  }

  weiter() {
    var valid = this.$refs.form.validate()
    if (!valid) return

    this.logic.weiter()
  }

  produkteVorbestellen() {
    this.logic.produkteVorbestellen()
  }
}
