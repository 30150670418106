import PlisteSimple from '@/entities/member/pliste_simple'
import PreislistenFilialeMemcommonLogic from '../memcommon/preislisten_filiale_memcommon_logic'
import router from '@/router'
import RouteNames from '@/util/route_names'

export default class PreislistenFilialeLogic extends PreislistenFilialeMemcommonLogic {
  detailsAnzeigenPreisliste(p: PlisteSimple): void {
    router.push({
      name: RouteNames.MEMBER.PREISLISTE_DETAILS,
      params: { id: p.id },
    })
  }
  detailsAnzeigenTageskarte(p: PlisteSimple): void {
    router.push({
      name: RouteNames.MEMBER.TAGESKARTE_DETAILS,
      params: {preislisteId: p.id}
    })
  }

  neuePreisliste(): void {
    router.push({
      name: RouteNames.MEMBER.PREISLISTE_FILIALE_NEU,
      params: { filialeId: this.filiale.id },
    })
  }

  neueTageskarte(): void {
    router.push({name: RouteNames.MEMBER.TAGESKARTE_NEU, params: {filialeId: this.filiale.id}})
  }
}
