class Kategorie {
  bezeichnung?: string
  beschreibung?: string
  zeitbegrenzung: boolean = false
  zeitraeume: KategorieZeitraum[] = []
}

class KategorieZeitraum {
  wochentag?: number
  zeitVon?: string
  zeitVonVisible: boolean = false
  zeitBis?: string
  zeitBisVisible: boolean = false
}

export { Kategorie, KategorieZeitraum }