
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'
import KategorienMemcommonLogic from '@/logic/memcommon/kategorien_memcommon_logic'
import ToolbarButton from '../custom/ToolbarButton.vue'
import ErrorBox from '../custom/ErrorBox.vue'
import Produktkategorie from '@/entities/common/produktkategorie'

@Component({ components: { ToolbarButton, ErrorBox } })
export default class KategorienMemCommon extends Vue {
  @Prop() logic: KategorienMemcommonLogic

  $refs: {
    editform: HTMLFormElement
    neuform: HTMLFormElement
  }

  neueKategorieEinfuegen() {
    this.logic.neueKategorieEinfuegen()
  }

  neueKategorie() {
    this.logic.neueKategorie()
  }

  bearbeiten(kategorie: Produktkategorie, event: Event) {
    this.logic.bearbeiten(kategorie)
    event.stopPropagation()
  }

  speichern() {
    var valid = this.$refs.editform.validate()
    if (valid) this.logic.speichern()
  }
}
