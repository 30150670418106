export default class InputValidator {
  static usernameRules = [
    (value: string) => {
      if (!value) return 'Bitte Benutzername angeben'
      if (value.length < 5) {
        return 'Benutzername muss aus min. 5 Zeichen bestehen'
      }
      return true
    },
  ]
}
