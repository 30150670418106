import FilialeSimple from "@/entities/member/filiale_simple";
import router from "@/router";
import RouteNames from "@/util/route_names";
import FilialenMemcommonLogic from "../memcommon/filialen_memcommon_logic";
import BetriebDetailsLogic from "./betrieb_details_logic";

export default class FilialenLogic extends FilialenMemcommonLogic {
  
  public shortcutsAnzeigen(): void {
    throw new Error("Method not implemented.");
  }

  parent: BetriebDetailsLogic = null
  
  public get filialen(): FilialeSimple[] {
    return this.parent.filialen
  }

  neueFiliale() {
    router.push({ name: RouteNames.SYSTEM.FILIALE_NEU })
  }

  detailsAnzeigen(filiale: FilialeSimple) {
    router.push({
      name: RouteNames.SYSTEM.FILIALE_DETAILS,
      params: { filialeId: filiale.id },
    })
  }
}