
import Vue from 'vue'
import Component from 'vue-class-component'
import VerzehrartLogic from '@/logic/bestellung_neu/verzehrart_logic'
import FilialeHeader from '../custom/FilialeHeader.vue'

@Component({ components: { FilialeHeader } })
export default class Verzehrart extends Vue {
  logic: VerzehrartLogic = new VerzehrartLogic()

  weiter() {
    this.logic.weiter()
  }
}
