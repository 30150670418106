
import Vue from 'vue'
import Component from 'vue-class-component'
import BasiskategorienLogic from '@/logic/member/basiskategorien_logic'
import ToolbarButton from '../custom/ToolbarButton.vue'
import ErrorBox from '../custom/ErrorBox.vue'
import { Prop, PropSync } from 'vue-property-decorator'

@Component({ components: { ToolbarButton, ErrorBox } })
export default class BasiskategorienMemCommon extends Vue {
  @Prop() logic: BasiskategorienLogic

  detailsAnzeigen(kategorie) {
    this.logic.detailsAnzeigen(kategorie)
  }

  speichern() {
    this.logic.speichern()
  }

  bearbeiten(kategorie, event) {
    this.logic.bearbeiten(kategorie, event)
  }

  neueKategorieEinfuegen() {
    this.logic.neueKategorieEinfuegen()
  }

  neueKategorie() {
    this.logic.neueKategorie()
  }
}
