
import FilialenLogic from '@/logic/system/filialen_logic'
import Vue from 'vue'
import Component from 'vue-class-component'
import BetriebDetails from './BetriebDetails.vue'
import FilialenMemCommon from '@/components/memcommon/FilialenMemCommon.vue'

@Component({ components: {FilialenMemCommon } })
export default class Filialen extends Vue {
  logic?: FilialenLogic = null

  mounted() {
    this.logic = new FilialenLogic()
    this.logic.parent = ((this.$parent as BetriebDetails).logic)
    this.logic.betriebId = ((this.$parent as BetriebDetails).logic.betrieb.id)
    this.logic.init()
  }
}
