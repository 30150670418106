export default class FilialeSearchRequest {
  uhrzeit?: string
  maxDistance?: number = 20
  maxResults?: number = 20
  requestType?: string = 'STANDORT'
  terminart?: string
  locationId?: number = 0
  betriebId?: string
  latitude?: number = 0.0
  longitude?: number = 0.0

  static STANDORT_GENAU: string = "STANDORT_GENAU"
}
