
import Vue from 'vue'
import Component from 'vue-class-component'
import BetriebDetails from './BetriebDetails.vue'
import MitarbeiterNeuLogic from '@/logic/system/mitarbeiter_neu_logic'
import MitarbeiterNeuMemCommon from '../memcommon/MitarbeiterNeuMemCommon.vue'

@Component({components: {MitarbeiterNeuMemCommon}})
export default class MitarbeiterNeu extends Vue {
  logic?: MitarbeiterNeuLogic = null

  mounted() {
    this.logic = new MitarbeiterNeuLogic()
    this.logic.parent = ((this.$parent as BetriebDetails).logic)
  }
}
