
import Component from 'vue-class-component'
import Produkte from '@/components/custom/Produkte.vue'
import { Vue, Watch } from 'vue-property-decorator'
import NichtGueltig from '../custom/NichtGueltig.vue'
import Shopping from './Shopping.vue'
import ShoppingLogic from '@/logic/bestellung_neu/shopping_logic'

@Component({ name: 'ShoppingProdukte', components: { Produkte, NichtGueltig } })
export default class ShoppingProdukte extends Vue {
  logic?: ShoppingLogic = null

  created() {
    this.logic = (this.$parent as Shopping).logic
  }

  @Watch('$parent.logic')
  watchLogic(value) {
    this.logic = value
  }
}
