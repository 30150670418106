export default class BPLieferungKundeRequest {
  id: string
  kundeNachname: string
  kundeVorname: string
  kundeTelefon: string
  kundeEmail: string
  kundeStrasse: string
  kundePlz: string
  kundeOrt: string
  kundeAdresszusatz: string
}