import Bestellung from '@/entities/common/bestellung'
import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'

export default class BestellungSofortLogic {
  bestellung?: Bestellung = null
  finished: () => void

  init(bestellung: Bestellung, finished: () => void) {
    this.bestellung = bestellung
    this.finished = finished
  }

  abschliessen() {
    var request = new IdRequest()
    request.id = this.bestellung.id

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterBestellungAbschliessen,
      request
    ).then((response) => {
      if (response.data.success) {
        this.bestellung.abgeschlossen = true
        this.finished()
      }
    })
  }

  stornieren() {
    var request = new IdRequest()
    request.id = this.bestellung.id

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterStornieren,
      request
    ).then((response) => {
      if (response.data.success) {
        this.bestellung.storniert = true
        this.bestellung.abgeschlossen = true
        this.finished()
      }
    })
  }
}
