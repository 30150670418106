
import BstlArtikel from '@/entities/common/bstl_artikel'
import Component, { mixins } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'
import Calculator from '../mixins/Calculator.vue'

@Component
export default class ArtikelRow extends mixins(Calculator) {
  @PropSync('artikel') artikelSync: BstlArtikel
  @Prop() backgroundColor?: string

  berechneExtras() {
    let extras = this.artikelSync.produkt.extras
    if (extras)
      return extras
        .map(
          (e) =>
            '+ ' +
            e.bezeichnung +
            ' (a ' +
            this.formatiereWaehrung(e.preis) +
            ')'
        )
        .join(', ')
  }
}
