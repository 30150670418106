
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import FilialeNeuLogic from '@/logic/member/filiale_neu_logic'
import FilialeNeuMemCommon from '../memcommon/FilialeNeuMemCommon.vue'

@Component({components: {FilialeNeuMemCommon}})
export default class FilialeNeu extends Vue {
  logic?: FilialeNeuLogic = null
  
  mounted() {
    this.logic = new FilialeNeuLogic()
    this.logic.betriebId = this.$store.state.betriebId
    this.logic.init()
  }
}
