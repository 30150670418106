
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import { Prop } from 'vue-property-decorator'
import OeffnungszeitAusnahmenFilialeMemcommonLogic from '@/logic/memcommon/oeffnungszeit_ausnahmen_filiale_memcommon_logic'
import { Oeffnung } from '@/logic/memcommon/oeffnungszeit_ausnahmen_filiale_memcommon_logic'

@Component
export default class OeffnungszeitAusnahmenFilialeMemCommon extends mixins(
  Calculator
) {
  @Prop() logic: OeffnungszeitAusnahmenFilialeMemcommonLogic

  getEventColor(date) {
    return this.logic.getEventColor(date)
  }

  gebeOeffnungszeitMin(index: number) {
    return this.logic.gebeOeffnungszeitMin(index)
  }

  gebeOeffnungzeitMinEdit(index: number) {
    return this.logic.gebeOeffnungszeitMinEdit(index)
  }

  zeitLoeschen(index: number) {
    this.logic.zeitLoeschen(index)
  }

  zeitLoeschenEdit(index: number) {
    this.logic.zeitLoeschenEdit(index)
  }

  functionEvents(date) {
    this.logic.functionEvents(date)
  }

  reloadDates(val) {
    this.logic.reloadDates(val)
  }

  ausnahmeErstellen() {
    this.logic.ausnahmeErstellen()
  }

  ausnahmeEinfuegen() {
    this.logic.ausnahmeEinfuegen()
  }

  oeffnungHinzufuegen() {
    this.logic.oeffnungHinzufuegen()
  }

  editOeffnungHinzufuegen() {
    this.logic.editOeffnungHinzufuegen()
  }

  ausnahmeLoeschen() {
    this.logic.ausnahmeLoeschen()
  }

  ausnahmeBearbeiten() {
    this.logic.ausnahmeBearbeiten()
  }

  ausnahmeSpeichern() {
    this.logic.ausnahmeSpeichern()
  }

  saveTimeVon(oeffnung: Oeffnung, index: number) {
    this.$refs['von-' + index][0].save(oeffnung.von)
  }

  saveTimeBis(oeffnung: Oeffnung, index: number) {
    this.$refs['bis-' + index][0].save(oeffnung.bis)
  }
}
