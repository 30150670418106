
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import Tisch from '@/entities/common/tisch'
import ToolbarButton from '../custom/ToolbarButton.vue'
import TischeMemcommonLogic from '@/logic/memcommon/tische_memcommon_logic'

@Component({ components: { ToolbarButton } })
export default class TischeMemCommon extends Vue {
  @Prop() logic: TischeMemcommonLogic

  tischSpeichern() {
    this.logic.tischSpeichern()
  }

  tischeEinfuegen() {
    this.logic.tischeEinfuegen()
  }

  removeTisch(index) {
    this.logic.removeTisch(index)
  }

  weitererTisch() {
    this.logic.weitererTisch()
  }

  neuerTisch() {
    this.logic.neuerTisch()
  }

  tischAnzeigen(tisch: Tisch) {
    this.logic.tischAnzeigen(tisch)
  }

  tischBearbeiten(tisch: Tisch, event) {
    this.logic.tischBearbeiten(tisch, event)
  }
}
