import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Mitarbeiter from '@/entities/member/mitarbeiter'
import MitarbeiterActivationResult from '@/entities/member/mitarbeiter_activation_result'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import MemberBaseLogic from '../member_base_logic'

export default abstract class MitarbeiterDetailsMemcommonLogic extends MemberBaseLogic {
  mitarbeiter?: Mitarbeiter = null
  tooLessAdminsDialogVisible: boolean = false
  loadWorking: boolean = false
  loadError: boolean = false

  editWorking: boolean = false
  editError: boolean = false

  init(id: string) {
    this.loadWorking = true
    this.loadError = false

    var request = new IdRequest()
    request.id = id

    Poster.postMember<RemoteResult<Mitarbeiter>>(
      RestPaths.mitarbeiterDetailForEdit,
      request
    )
      .then((response) => {
        if (response.data.success) {
          this.loadWorking = false
          this.mitarbeiter = response.data.payload
        } else {
          this.loadWorking = false
          this.loadError = true
        }
      })
      .catch(() => {
        this.loadWorking = false
        this.loadError = true
      })
  }

  aktivieren(completed: () => void) {
    this.editWorking = true
    this.editError = false

    var request = new IdRequest()
    request.id = this.mitarbeiter.id

    Poster.postMember<RemoteResult<any>>(
      RestPaths.mitarbeiterAktivieren,
      request
    ).then((response) => {
      if (response.data.success) {
        this.mitarbeiter.aktiv = true
        this.editWorking = false
        this.editError = true
        completed()
      } else {
        this.editWorking = false
        this.editError = true
      }
    }).catch(()=> {
      this.editWorking = false
      this.editError = true
    })
  }

  deaktivieren(completed: () => void) {
    this.editWorking = true
    this.editError = false

    var request = new IdRequest()
    request.id = this.mitarbeiter.id

    Poster.postMember<RemoteResult<MitarbeiterActivationResult>>(
      RestPaths.mitarbeiterDeaktivieren,
      request
    ).then((response) => {
      if (response.data.success) {
        this.editWorking = false
        this.editError = false
        if (response.data.payload.tooLessAdmins == false) {
          this.mitarbeiter.aktiv = false
          completed()
        } else {
          this.tooLessAdminsDialogVisible = true
        }
      } else {
        this.editWorking = false
        this.editError = true
      }
    }).catch(()=> {
      this.editWorking = false
      this.editError = true
    })
  }

  computePlzOrt() {
    if (!this.mitarbeiter.plz && !this.mitarbeiter.ort) return '---'
    return this.mitarbeiter.plz + ' ' + this.mitarbeiter.ort
  }

  abstract bearbeiten(): void
}
