
import Component, { mixins } from 'vue-class-component'
import { LoadState } from '../../entities/common/enums'

import BestaetigenSofort from './BestaetigenSofort.vue'
import BestaetigenAbholung from './BestaetigenAbholung.vue'
import BestaetigenReservierung from './BestaetigenReservierung.vue'
import Calculator from '../mixins/Calculator.vue'
import ArtikelRow from '../custom/ArtikelRow.vue'
import FilialeHeader from '../custom/FilialeHeader.vue'
import NichtGueltig from '../custom/NichtGueltig.vue'
import BestaetigenLogic from '@/logic/bestellung_neu/bestaetigen_logic'

@Component({
  components: {
    BestaetigenSofort,
    BestaetigenReservierung,
    BestaetigenAbholung,
    ArtikelRow,
    FilialeHeader,
    NichtGueltig,
  },
})
export default class Bestaetigen extends mixins(Calculator) {
  logic: BestaetigenLogic = null

  get bestaetigenLoading() {
    return this.logic.bestaetigenLoadState == LoadState.LOADING
  }

  mounted() {
    var isNeueBestellung = this.$store.state.isNeueBestellung
    if (!isNeueBestellung) return

    this.logic = new BestaetigenLogic()
    this.logic.init()
  }

  bestellungBestaetigen() {
    this.logic.bestellungBestaetigen()
  }
}
