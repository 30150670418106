
import BstlArtikel from '@/entities/common/bstl_artikel'
import ReservierungBestellungLogic from '@/logic/bestellung/reservierung_bestellung_logic'
import Vue from 'vue'
import Component from 'vue-class-component'
import ReservierungBestellung from './ReservierungBestellung.vue'

@Component
export default class ReservierungBestellungArtikel extends Vue {
  logic?: ReservierungBestellungLogic = null

  added(value: BstlArtikel) {
    this.logic.addArtikel(value)
  }

  mounted() {
    this.logic = (this.$parent as ReservierungBestellung).logic
    this.logic.createdListener = () => {
      if (this.logic.artikel == null) {
        this.logic.reloadArtikel(this.$route.params.id)
      }
    }
  }
}
