import Filiale from '@/components/custom/Filiale.vue'
import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import NeuePreislisteInfo from '@/entities/member/neue_preisliste_info'
import Pliste from '@/entities/member/pliste'
import PlistePdukt, {
  SubproduktOption,
  Auswahl as PduktAuswahl,
} from '@/entities/member/pliste_pdukt'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import moment from 'moment'
import MemberBaseLogic from '../member_base_logic'
import PlisteUpdateRequest, {
  Kategorie as ReqKategorie,
  Produkt as ReqProdukt,
  Extra as ReqExtra,
  Auswahl as ReqAuswahl,
  Subprodukt as ReqSubprodukt,
  Option as ReqOption,
} from '@/entities/member/request/pliste_update_request'
import { v4 as uuidv4 } from 'uuid'

export default class TageskarteEditMemcommonLogic extends MemberBaseLogic {
  filiale?: Filiale
  extras: Produkt[] = []
  produkte: Produkt[] = []
  gewaehlteProdukte: Produkt[] = []
  produkteWaehlenVisible: boolean = false
  datumDialogVisible: boolean = false
  datum?: string
  kategorie: Kategorie = new Kategorie()
  preislisteAktivieren: boolean = true
  preisliste: Pliste

  editWorking: boolean = false
  editCompleted: boolean = false
  editFormDisabled: boolean = false
  editError: boolean = false

  init(preislisteId: string, betriebId: string) {
    var request = new IdRequest()
    request.id = betriebId

    Poster.postMember<RemoteResult<NeuePreislisteInfo>>(
      RestPaths.preislisteNeueInfo,
      request
    ).then((response) => {
      if (response.data.success) {
        let payload = response.data.payload

        payload.extras.forEach((e) => {
          var extra = new Produkt()
          extra.id = e.id
          extra.bezeichnung = e.bezeichnung
          extra.artikelNr = e.artikelNr
          this.extras.push(extra)
        })

        payload.produkte.forEach((p) => {
          var produkt = new Produkt()
          produkt.id = p.id
          produkt.bezeichnung = p.bezeichnung
          produkt.artikelNr = p.artikelNr

          p.extras.forEach((e) => {
            var produktExtra = new ProduktExtra()
            var extra = this.findProduktExtra(e.pduktId)
            produktExtra.produkt = extra
            produktExtra.pduktExtraId = e.pduktExtraId
            produkt.extras.push(produktExtra)
          })

          p.auswahlen.forEach((a) => {
            var auswahl = new Auswahl()
            auswahl.id = a.id
            auswahl.bezeichnung = a.bezeichnung

            a.optionen.forEach((o) => {
              var produktExtra = new ProduktExtra()
              var option = this.findProduktExtra(o.pduktId)
              produktExtra.produkt = option
              produktExtra.pduktExtraId = o.auswahlOptionId
              auswahl.optionen.push(produktExtra)
            })
            produkt.auswahlen.push(auswahl)
          })

          p.subprodukte.forEach((sp) => {
            var subprodukt = new Subprodukt()
            subprodukt.id = sp.id
            subprodukt.bezeichnung = sp.bezeichnung
            produkt.subprodukte.push(subprodukt)

            sp.produkte.forEach((spp) => {
              var produkt = new Produkt()
              produkt.id = spp.id
              produkt.bezeichnung = spp.bezeichnung
              produkt.artikelNr = spp.artikelNr

              spp.extras.forEach((e) => {
                var produktExtra = new ProduktExtra()
                var extra = this.findProduktExtra(e.pduktId)
                produktExtra.produkt = extra
                produktExtra.pduktExtraId = e.pduktExtraId
                produkt.extras.push(produktExtra)
              })

              spp.auswahlen.forEach((a) => {
                var auswahl = new Auswahl()
                auswahl.id = a.id
                auswahl.bezeichnung = a.bezeichnung

                a.optionen.forEach((o) => {
                  var produktExtra = new ProduktExtra()
                  var option = this.findProduktExtra(o.pduktId)
                  produktExtra.produkt = option
                  produktExtra.pduktExtraId = o.auswahlOptionId
                  auswahl.optionen.push(produktExtra)
                })
                produkt.auswahlen.push(auswahl)
              })
              subprodukt.optionen.push(produkt)
            })
          })
          this.produkte.push(produkt)
        })
      }
    })

    var plisteRequest = new IdRequest()
    plisteRequest.id = preislisteId

    Poster.postMember<RemoteResult<Pliste>>(
      RestPaths.preislisteDetailsForEdit,
      plisteRequest
    ).then((response) => {
      this.preisliste = response.data.payload

      if (this.preisliste.gueltigVon != null) {
        this.datum = moment(
          this.preisliste.gueltigVon,
          'YYYY-MM-DDTHH:mm:ss'
        ).format('YYYY-MM-DD')
      }

      var kategorie = this.preisliste.kategorien[0]
      kategorie.produkte.forEach((produkt) => {
        var pProdukt = new PreislisteProdukt()
        pProdukt.id = produkt.id
        pProdukt.produkt = this.findProdukt(produkt.pduktId)
        pProdukt.preis = produkt.preis
        pProdukt.aktiv = produkt.aktiv
        this.kategorie.produkte.push(pProdukt)

        produkt.extras.forEach((extra) => {
          var pExtra = new PreislisteProduktExtra()
          pExtra.id = extra.id
          pExtra.extraId = extra.extraId
          pExtra.preis = extra.preis
          pExtra.bezeichnung = extra.bezeichnung
          pExtra.artikelNr = extra.artikelNr
          pProdukt.extras.push(pExtra)
        })

        produkt.auswahlen.forEach((auswahl) => {
          var pAuswahl = new PreislisteProduktAuswahl()
          pAuswahl.id = auswahl.id
          pAuswahl.auswahl = new Auswahl()
          var a = this.findAuswahl(auswahl.auswahlId, produkt)
          pAuswahl.auswahl.id = a.auswahlId
          pAuswahl.auswahl.bezeichnung = a.bezeichnung
          pAuswahl.auswahl.beschreibung = a.beschreibung
          pProdukt.auswahlen.push(pAuswahl)

          auswahl.optionen.forEach((option) => {
            var pOption = new PreislisteProduktExtra()
            pOption.id = option.id
            pOption.extraId = option.extraId
            pOption.preis = option.preis
            pOption.bezeichnung = option.bezeichnung
            pOption.artikelNr = option.artikelNr
            pAuswahl.optionen.push(pOption)
          })
        })

        produkt.subprodukte.forEach((subprodukt) => {
          var plSubprodukt = new PreislisteProduktSubprodukt()
          plSubprodukt.id = subprodukt.id
          plSubprodukt.subprodukt = new Subprodukt()
          plSubprodukt.subprodukt.id = subprodukt.subproduktId
          plSubprodukt.subprodukt.bezeichnung = subprodukt.bezeichnung
          pProdukt.subprodukte.push(plSubprodukt)
          subprodukt.optionen.forEach((option) => {
            var pSubproduktOption = new PreislisteProdukt()
            pSubproduktOption.produkt = this.findProduktExtra(option.pduktId)
            pSubproduktOption.preis = option.preis
            pSubproduktOption.id = option.id
            plSubprodukt.optionen.push(pSubproduktOption)

            option.extras.forEach((extra) => {
              var pExtra = new PreislisteProduktExtra()
              pExtra.id = extra.id
              pExtra.extraId = extra.extraId
              pExtra.preis = extra.preis
              pExtra.bezeichnung = extra.bezeichnung
              pExtra.artikelNr = extra.artikelNr
              pSubproduktOption.extras.push(pExtra)
            })

            option.auswahlen.forEach((auswahl) => {
              var pAuswahl = new PreislisteProduktAuswahl()
              pAuswahl.auswahl = new Auswahl()
              pAuswahl.auswahl.id = auswahl.auswahlId
              pAuswahl.auswahl.bezeichnung = auswahl.bezeichnung
              var a = this.findAuswahlSubprodukt(auswahl.auswahlId, option)

              pAuswahl.auswahl.bezeichnung = a.bezeichnung
              pAuswahl.auswahl.beschreibung = a.beschreibung
              pSubproduktOption.auswahlen.push(pAuswahl)

              auswahl.optionen.forEach((option) => {
                var pOption = new PreislisteProduktExtra()
                pOption.extraId = option.extraId
                pOption.preis = option.preis
                pOption.bezeichnung = option.bezeichnung
                pOption.artikelNr = option.artikelNr
                pAuswahl.optionen.push(pOption)
              })
            })
          })
        })
      })
    })
  }

  findProduktExtra(id: string) {
    return this.extras.find((element) => element.id == id)
  }

  produkteHinzufuegenAnzeigen() {
    this.produkteWaehlenVisible = true
  }

  produkteHinzufuegenAbbrechen() {
    this.produkteWaehlenVisible = false
    this.gewaehlteProdukte = []
  }

  produkteHinzufuegen() {
    this.gewaehlteProdukte.forEach((produkt) => {
      this.kategorie.produkte.push(this.createProdukt(produkt))
    })
    this.produkteWaehlenVisible = false
  }

  findProdukt(produktId: string): Produkt {
    var index = this.produkte.findIndex((element) => {
      return element.id === produktId
    })
    return this.produkte[index]
  }

  findFromExtra(extraId: string): Produkt {
    var index = this.extras.findIndex((element) => {
      return element.id === extraId
    })
    return this.extras[index]
  }

  findAuswahl(auswahlId: string, produkt: PlistePdukt): PduktAuswahl {
    var index = produkt.auswahlen.findIndex((element) => {
      return element.auswahlId === auswahlId
    })
    return produkt.auswahlen[index]
  }

  findAuswahlSubprodukt(
    auswahlId: string,
    option: SubproduktOption
  ): PduktAuswahl {
    var index = option.auswahlen.findIndex((element) => {
      return element.auswahlId === auswahlId
    })
    return option.auswahlen[index]
  }

  createProdukt(produkt: Produkt): PreislisteProdukt {
    var preislisteProdukt = new PreislisteProdukt()
    preislisteProdukt.produkt = produkt

    produkt.extras.forEach((extra) => {
      var preislisteProduktExtra = new PreislisteProduktExtra()
      preislisteProduktExtra.extra = extra
      preislisteProdukt.extras.push(preislisteProduktExtra)
    })

    produkt.auswahlen.forEach((auswahl) => {
      var preislisteProduktAuswahl = new PreislisteProduktAuswahl()
      preislisteProduktAuswahl.auswahl = auswahl

      auswahl.optionen.forEach((option) => {
        var preislisteProduktExtra = new PreislisteProduktExtra()
        preislisteProduktExtra.extra = option
        preislisteProduktAuswahl.optionen.push(preislisteProduktExtra)
      })

      preislisteProdukt.auswahlen.push(preislisteProduktAuswahl)
    })

    produkt.subprodukte.forEach((subprodukt) => {
      var preislisteProduktSubprodukt = new PreislisteProduktSubprodukt()
      preislisteProduktSubprodukt.subprodukt = subprodukt
      subprodukt.optionen.forEach((suboption) => {
        var preislisteProduktOption = this.createProdukt(suboption)
        preislisteProduktSubprodukt.optionen.push(preislisteProduktOption)
      })
      preislisteProdukt.subprodukte.push(preislisteProduktSubprodukt)
    })

    return preislisteProdukt
  }

  preislisteSpeichern() {
    this.editWorking = true
    this.editFormDisabled = true
    this.editError = false

    var request = new PlisteUpdateRequest()
    request.id = this.preisliste.id
    request.tageskarte = true

    var reqKategorie = new ReqKategorie()
    request.kategorien.push(reqKategorie)

    this.kategorie.produkte.forEach((prlProdukt, index) => {
      var reqProdukt = new ReqProdukt()
      reqProdukt.id = prlProdukt.id
      reqProdukt.produktId = prlProdukt.produkt.id
      reqProdukt.preis = prlProdukt.preis
      reqProdukt.index = prlProdukt.index
      reqProdukt.neu = prlProdukt.neu
      reqProdukt.deaktivieren = prlProdukt.deaktivieren
      reqProdukt.aktivieren = prlProdukt.aktivieren
      reqKategorie.produkte.push(reqProdukt)

      prlProdukt.extras.forEach((prlExtra, index) => {
        var reqExtra = new ReqExtra()
        reqExtra.id = prlExtra.id
        reqExtra.extraId = prlExtra.extraId
        reqExtra.index = index
        reqExtra.preis = prlExtra.preis
        reqProdukt.extras.push(reqExtra)
      })

      prlProdukt.auswahlen.forEach((prlAuswahl, index) => {
        var reqAuswahl = new ReqAuswahl()
        reqAuswahl.id = prlAuswahl.id
        reqAuswahl.auswahlId = prlAuswahl.auswahl.id
        reqAuswahl.index = index
        reqProdukt.auswahlen.push(reqAuswahl)

        prlAuswahl.optionen.forEach((prlOption, index) => {
          var reqOption = new ReqOption()
          reqOption.id = prlOption.id
          reqOption.optionId = prlOption.extraId
          reqOption.index = index
          reqOption.preis = prlOption.preis

          reqAuswahl.optionen.push(reqOption)
        })
      })

      prlProdukt.subprodukte.forEach((prlSubprodukt, index) => {
        var reqSubprodukt = new ReqSubprodukt()
        reqSubprodukt.id = prlSubprodukt.id
        reqSubprodukt.subproduktId = reqSubprodukt.subproduktId
        reqSubprodukt.index = index
        reqProdukt.subprodukte.push(reqSubprodukt)

        prlSubprodukt.optionen.forEach((prlOption, index) => {
          var reqSubproduktOption = new ReqProdukt()
          if (prlOption.id == null) {
            prlOption.id = uuidv4()
          } else {
            reqSubproduktOption.id = prlOption.id
          }
          reqSubproduktOption.produktId = prlOption.produkt.id
          reqSubproduktOption.index = index
          reqSubproduktOption.preis = prlOption.preis

          reqSubprodukt.optionen.push(reqSubproduktOption)

          prlOption.extras.forEach((prlExtra, index) => {
            var reqExtra = new ReqExtra()
            reqExtra.id = prlExtra.id
            reqExtra.extraId = prlExtra.extraId
            reqExtra.index = index
            reqExtra.preis = prlExtra.preis
            reqSubproduktOption.extras.push(reqExtra)
          })

          prlOption.auswahlen.forEach((prlAuswahl, index) => {
            var reqAuswahl = new ReqAuswahl()
            reqAuswahl.id = prlAuswahl.id
            reqAuswahl.auswahlId = prlAuswahl.auswahl.id
            reqAuswahl.index = index
            reqSubproduktOption.auswahlen.push(reqAuswahl)

            prlAuswahl.optionen.forEach((prlOption, index) => {
              var reqOption = new ReqOption()
              reqOption.id = prlOption.id
              reqOption.optionId = prlOption.extraId
              reqOption.index = index
              reqOption.preis = prlOption.preis
              reqAuswahl.optionen.push(reqOption)
            })
          })
        })
      })
    })

    Poster.postMember<RemoteResult<any>>(RestPaths.preislisteUpdate, request)
      .then((response) => {
        this.editWorking = false
        if (response.data.success) {
          this.editCompleted = true
        } else {
          this.editError = true
          this.editFormDisabled = false
        }
      })
      .catch(() => {
        this.editWorking = false
        this.editError = true
        this.editFormDisabled = false
      })
  }
}

class Kategorie {
  id?: string
  bezeichnung?: string
  beschreibung?: string
  zeitbegrenzung: boolean = false
  zeitraeume: KategorieZeitraum[] = []
  produkte: PreislisteProdukt[] = []
}

class KategorieZeitraum {
  wochentag?: number
  zeitVon?: string
  zeitBis?: string
}

class Produkt {
  id?: string
  bezeichnung?: string
  artikelNr?: string

  extras: ProduktExtra[] = []
  auswahlen: Auswahl[] = []
  subprodukte: Subprodukt[] = []
}

class Subprodukt {
  id?: string
  bezeichnung?: string

  optionen: Produkt[] = []
}

class ProduktExtra {
  pduktExtraId?: string
  produkt?: Produkt
}

class Auswahl {
  id?: string
  bezeichnung?: string
  beschreibung?: string
  optionen: ProduktExtra[] = []
}

class PreislisteProdukt {
  id: string
  neu: boolean = false
  deaktivieren: boolean = false
  aktivieren: boolean = false
  aktiv: boolean = true
  produkt: Produkt
  extras: PreislisteProduktExtra[] = []
  auswahlen: PreislisteProduktAuswahl[] = []
  subprodukte: PreislisteProduktSubprodukt[] = []
  preis: number
  index: number = 0
}

class PreislisteProduktExtra {
  id: string
  extraId: string
  extra: ProduktExtra
  pduktExtraId: string
  preis: number
  index: number = 0
  bezeichnung: string
  artikelNr: string
}

class PreislisteProduktAuswahl {
  id: string
  auswahl: Auswahl
  optionen: PreislisteProduktExtra[] = []
}

class PreislisteProduktSubprodukt {
  id: string
  subprodukt: Subprodukt
  optionen: PreislisteProdukt[] = []
}

export { Kategorie, PreislisteProdukt, Produkt }
