import FilialeSimple from "@/entities/member/filiale_simple"
import router from "@/router"
import RouteNames from "@/util/route_names"
import OeffnungszeitenMemcommonLogic from "../memcommon/oeffnungszeiten_memcommon_logic"
import BetriebDetailsLogic from "./betrieb_details_logic"

export default class OeffnungszeitenLogic extends OeffnungszeitenMemcommonLogic {
  parent: BetriebDetailsLogic = null

  get filialen(): FilialeSimple[] {
    return this.parent.filialen
  }

  replaceFiliale(id: string): void {
    router.replace({
      name: RouteNames.SYSTEM.OEFFNUNGSZEITEN,
      query: { id: this.selectedFiliale },
    })
  }

  ausnahmenHinzufuegen() {
    router.push({ name: RouteNames.SYSTEM.OEFFNUNGSZEIT_AUSNAHMEN })
  }

  zeigeDetails(oeffnungszeit) {
    router.push({
      name: RouteNames.SYSTEM.OEFFNUNGSZEIT_DETAILS,
      params: { id: oeffnungszeit.id },
    })
  }

  neueOeffnungszeit() {
    router.push({
      name: RouteNames.SYSTEM.OEFFNUNGSZEIT_NEU,
      params: { id: this.selectedFiliale },
    })
  }

  init(parent: BetriebDetailsLogic) {
    this.parent = parent
  }
}