import RemoteResult from '@/entities/common/remote_result'
import Tisch from '@/entities/common/tisch'
import FilialeSimple from '@/entities/member/filiale_simple'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import router from '@/router'
import RouteNames from '@/util/route_names'
import IdRequest from '@/entities/common/request/id_request'
import FilialeSimpleList from '@/entities/member/lists/filiale_simple_list'
import store from '@/store'
import TischeMemcommonLogic from '../memcommon/tische_memcommon_logic'

export default class TischeLogic extends TischeMemcommonLogic {
  private _filialen: FilialeSimple[]

  get filialen(): FilialeSimple[] {
    return this._filialen
  }

  private set filialen(value: FilialeSimple[]) {
    this._filialen = value
  }

  init() {
    super.init()

    var request = new IdRequest()
    request.id = store.state.user.betriebId

    Poster.postMember<RemoteResult<FilialeSimpleList>>(
      RestPaths.filialeListeSimple,
      request
    ).then((response) => {
      this.filialen = response.data.payload.list
    })
  }

  tischAnzeigen(tisch: Tisch) {
    router.push({
      name: RouteNames.MEMBER.TISCH_DETAILS,
      params: { id: tisch.id },
    })
  }
}
