
import { Component, Vue } from 'vue-property-decorator'
import OeffnungszeitNeuLogic from '@/logic/member/oeffnungszeit_neu_logic'
import OeffnungszeitNeuMemCommon from '../memcommon/OeffnungszeitNeuMemCommon.vue'

@Component({components: {OeffnungszeitNeuMemCommon}})
export default class OeffnungszeitNeu extends Vue {
  logic?: OeffnungszeitNeuLogic = null

  mounted() {
    this.logic = new OeffnungszeitNeuLogic()
    this.logic.init(this.$route.params.id)
  }
}
