
import { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import Component from 'vue-class-component'
import Kuechenaufgabe, { Abholung } from '@/entities/member/kuechenaufgabe'
import ArtikelRow from '../custom/ArtikelRow.vue'
import {
  AbholungStatus,
  AbholzeitType,
  Bezahlart,
} from '@/entities/common/enums'
import { Prop } from 'vue-property-decorator'
import AbholungStatusUpdateRequest from '@/entities/member/request/abholung_status_update_request'
import RestPaths from '@/util/rest_paths'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'

@Component({ components: { ArtikelRow } })
export default class KuecheDetailAbholung extends mixins(Calculator) {
  @Prop() aufgabe: Kuechenaufgabe

  get annehmenVisible(): boolean {
    return this.aufgabe.abholung.status === AbholungStatus.ABGESENDET
  }

  get ablehnenVisible(): boolean {
    return this.aufgabe.abholung.status === AbholungStatus.ABGESENDET
  }

  get inZubereitungVisible(): boolean {
    return this.aufgabe.abholung.status === AbholungStatus.ANGENOMMEN
  }

  get abholbereitVisible(): boolean {
    return this.aufgabe.abholung.status === AbholungStatus.IN_BEARBEITUNG
  }

  get abgeholtVisible(): boolean {
    return this.aufgabe.abholung.status === AbholungStatus.ABHOLBEREIT
  }

  formatiereGewuenschteAbholzeit(): string {
    if (!this.aufgabe) return null
    if (this.aufgabe.abholung.type == AbholzeitType.BALDEST) {
      return 'schnellstmöglich'
    } else if (this.aufgabe.abholung.type == AbholzeitType.UHRZEIT) {
      return this.formatiereDatumUhrzeit(this.aufgabe.abholung.gewAbholzeit)
    }
  }

  formatiereStatus(): string {
    if (!this.aufgabe) return null
    switch (this.aufgabe.abholung.status) {
      case AbholungStatus.ABGESENDET:
        return 'noch nicht angenommen'
      case AbholungStatus.ABGELEHNT:
        return 'abgelehnt'
      case AbholungStatus.IN_BEARBEITUNG:
        return 'in Zubereitung'
      case AbholungStatus.ABHOLBEREIT:
        return 'abholbereit'
      case AbholungStatus.ABGEHOLT:
        return 'abgeholt'
      case AbholungStatus.NICHT_ABGEHOLT:
        return 'nicht abgeholt'
    }
    return '---'
  }

  formatiereBezahlart(): string {
    if (!this.aufgabe) return null
    switch (this.aufgabe.bezahlart) {
      case Bezahlart.BARZAHLUNG:
        return 'Zahlung an Kasse'
      case Bezahlart.PAYPAL:
        return 'PayPal'
    }
    return '---'
  }

  formatiereTisch(): string {
    if (!this.aufgabe) return null
    if (!this.aufgabe.abholung) return null

    if (this.aufgabe.abholung.tischBezeichnung) {
      return this.aufgabe.abholung.tischBezeichnung
    } else return '---'
  }

  annehmen() {
    var request = new AbholungStatusUpdateRequest()
    request.id = this.aufgabe.id
    request.status = AbholungStatus.ANGENOMMEN

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterStatusUpdateAbholung,
      request
    ).then((response) => {
      if (response.data.success) {
        this.aufgabe.abholung.status = AbholungStatus.ANGENOMMEN
      }
    })
  }

  ablehnen() {
    var request = new AbholungStatusUpdateRequest()
    request.id = this.aufgabe.id
    request.status = AbholungStatus.ABGELEHNT

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterStatusUpdateAbholung,
      request
    ).then((response) => {
      if (response.data.success) {
        this.aufgabe.abholung.status = AbholungStatus.ABGELEHNT
      }
    })
  }

  inZubereitung() {
    var request = new AbholungStatusUpdateRequest()
    request.id = this.aufgabe.id
    request.status = AbholungStatus.IN_BEARBEITUNG

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterStatusUpdateAbholung,
      request
    ).then((response) => {
      if (response.data.success) {
        this.aufgabe.abholung.status = AbholungStatus.IN_BEARBEITUNG
      }
    })
  }

  abholbereit() {
    var request = new AbholungStatusUpdateRequest()
    request.id = this.aufgabe.id
    request.status = AbholungStatus.ABHOLBEREIT

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterStatusUpdateAbholung,
      request
    ).then((response) => {
      if (response.data.success) {
        this.aufgabe.abholung.status = AbholungStatus.ABHOLBEREIT
      }
    })
  }

  abgeholt() {
    var request = new AbholungStatusUpdateRequest()
    request.id = this.aufgabe.id
    request.status = AbholungStatus.ABGEHOLT

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterStatusUpdateAbholung,
      request
    ).then((response) => {
      if (response.data.success) {
        this.aufgabe.abholung.status = AbholungStatus.ABGEHOLT
      }
    })
  }
}
