import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Betrieb from '@/entities/member/betrieb'
import BetriebUpdateRequest from '@/entities/member/request/betrieb_update_request'
import router from '@/router'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'

export default class BetriebEditLogic {
  betrieb?: Betrieb = null
  editWorking: boolean = false
  editCompleted: boolean = false
  editFormDisabled: boolean = false
  editError: boolean = false

  datenSpeichern() {
    this.editError = false
    this.editWorking = true
    this.editFormDisabled = true

    var request = new BetriebUpdateRequest()
    request.id = this.betrieb.id
    request.bezeichnung = this.betrieb.bezeichnung
    request.strasse = this.betrieb.strasse
    request.plz = this.betrieb.plz
    request.ort = this.betrieb.ort
    request.telefon = this.betrieb.telefon
    request.email = this.betrieb.email
    request.website = this.betrieb.website
    request.path = this.betrieb.path

    Poster.postMember<RemoteResult<any>>(RestPaths.betriebSpeichern, request)
      .then((response) => {
        this.editWorking = false
        if (response.data.success) {
          this.editCompleted = true
        } else {
          this.editError = true
          this.editFormDisabled = false
        }
      })
      .catch(() => {
        this.editError = true
        this.editFormDisabled = false
      })
  }

  init(id: string) {
    var request = new IdRequest()
    request.id = id

    Poster.postMember<RemoteResult<Betrieb>>(
      RestPaths.betriebDetails,
      request
    ).then((response) => {
      this.betrieb = response.data.payload
    })
  }

  goBack() {
    router.back()
  }
}
