
import BestellungFiliale from '@/entities/common/bestellung_filiale'
import RestPaths from '@/util/rest_paths'
import Vue from 'vue'
import Component from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'

@Component
export default class FilialeHeader extends Vue {
  @PropSync('filiale')
  filialeSync: BestellungFiliale

  getBaseStyle() {
    if (this.filialeSync == null) return
    var result =
      'background-image: url("' +
      RestPaths.imageBasePath +
      this.filialeSync.bannerId +
      '_cropped.jpg");background-size: cover'

    return result
  }

  berechneBild() {
    if (this.filialeSync == null || this.filialeSync.bildId == null) return
    return (
      RestPaths.imageBasePath + this.filialeSync.bildId + "_klein.jpg"
    )
  }
}
