
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import ArtikelRow from '../custom/ArtikelRow.vue'
import FilialeHeader from '../custom/FilialeHeader.vue'
import Filiale from '../custom/Filiale.vue'
import DetailsLieferungLogic from '@/logic/bestellung/details_lieferung_logic'
import Bestellung from '@/entities/common/bestellung'
@Component({ components: { ArtikelRow, FilialeHeader, Filiale } })
export default class DetailsLieferung extends mixins(Calculator) {
  logic?: DetailsLieferungLogic = null

  reloadBestellung() {
    return this.logic.reloadBestellung()
  }

  computeGewuenschteLieferzeit() {
    return this.logic.computeGewuenschteLieferzeit()
  }

  bestimmeBild(bestellung: Bestellung) {
    return this.logic.bestimmeBild(bestellung)
  }

  formatiereStatus() {
    return this.logic.formatiereStatus()
  }

  mounted() {
    this.logic = new DetailsLieferungLogic()
    this.logic.init(navigator)
  }

  berechneGesamtpreis() {
    return this.logic.berechneGesamtpreis()
  }
}
