
import TageskarteDetailsMemcommonLogic from '@/logic/memcommon/tageskarte_details_memcommon_logic'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import PreislisteProduktRow from '../custom/PreislisteProduktRow.vue'
import Calculator from '../mixins/Calculator.vue'

@Component({ components: { PreislisteProduktRow } })
export default class TageskarteDetailsMemCommon extends mixins(Calculator) {
  @Prop() logic: TageskarteDetailsMemcommonLogic

  aktivierenDialogVisible: boolean = false
  deaktivierenDialogVisible: boolean = false

  bearbeiten() {
    this.logic.bearbeiten()
  }

  aktivieren() {
    this.logic.aktivieren()
  }

  deaktivieren() {
    this.logic.deaktivieren()
  }
}
