import RemoteResult from '@/entities/common/remote_result'
import GeoeffnetRequest from '@/entities/common/request/geoeffnet_request'
import IdRequest from '@/entities/common/request/id_request'
import Filiale from '@/entities/member/filiale'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import moment from 'moment'
import MemberBaseLogic from '../member_base_logic'

export default abstract class FilialeDetailsMemcommonLogic extends MemberBaseLogic {
  filiale?: Filiale = null

  aktivierenDialogVisible: boolean = false
  deaktivierenDialogVisible: boolean = false

  editWorking: boolean = false
  editCompleted: boolean = false
  editError: boolean = false

  loadError = false

  get bildId(): string {
    if (this.filiale)
      return (
        RestPaths.urlBase + RestPaths.imageImage + '/' + this.filiale.bildId
      )
  }

  get bildUrl(): string {
    if (!this.filiale || !this.filiale.bildId) return null
    return RestPaths.imageBasePath + this.filiale.bildId + '_klein.jpg'
  }

  showAktivierenDialog() {
    this.aktivierenDialogVisible = true
  }

  abstract oeffnungszeitenBearbeiten()

  abstract oeffnungszeitenFilialeBearbeiten()

  abstract preislistenBearbeiten()

  abstract tischeBearbeiten()

  aktivieren() {
    this.editWorking = true
    this.editError = false

    var request = new IdRequest()
    request.id = this.filiale.id

    Poster.postMember<RemoteResult<any>>(RestPaths.filialeAktivieren, request)
      .then((response) => {
        if (response.data.success) {
          this.aktivierenDialogVisible = false
          this.editWorking = false
          this.filiale.aktiv = true
        } else {
          this.editWorking = false
          this.editError = true
        }
      })
      .catch(() => {
        this.editWorking = false
        this.editError = true
      })
  }

  showDeaktivierenDialog() {
    this.deaktivierenDialogVisible = true
  }

  deaktivieren() {
    this.editWorking = true
    this.editError = false

    var request = new IdRequest()
    request.id = this.filiale.id

    Poster.postMember<RemoteResult<any>>(RestPaths.filialeDeaktivieren, request)
      .then((response) => {
        if (response.data.success) {
          this.deaktivierenDialogVisible = false
          this.editWorking = false
          this.filiale.aktiv = false
        } else {
          this.editWorking = false
          this.editError = true
        }
      })
      .catch(() => {
        this.editWorking = false
        this.editError = true
      })
  }

  checkOeffnung() {
    var zeitpunkt = moment().format()
    var request = new GeoeffnetRequest()
    request.filialeId = this.filiale.id
    request.zeitpunkt = zeitpunkt

    Poster.postMember<any>(RestPaths.filialeCheckOeffnung, request).then()
  }

  testOeffnung() {
    Poster.postMember<any>("filiale/check", {}).then()
  }

  get qrCode() {
    return (
      RestPaths.urlBase + RestPaths.imageQrCodeFiliale + '/' + this.filiale.id
    )
  }

  filialeBearbeiten() {
    this.navBearbeiten()
  }

  filialeLiefergebietZuordnen() {
    this.liefergebietZuordnen()
  }

  init(id: string) {
    var id = id
    var request = new IdRequest()
    request.id = id

    Poster.postMember<RemoteResult<Filiale>>(
      RestPaths.filialeDetails,
      request
    ).then((response) => {
      if (response.data.success) {
        this.filiale = response.data.payload
      } else {
        this.loadError = true
      }
    })
  }

  abstract navBearbeiten(): void

  abstract liefergebietZuordnen(): void

  //abstract get isAdmin(): boolean
}
