
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, PropSync } from 'vue-property-decorator'

@Component
export default class ToolbarButton extends Vue {
  @PropSync('icon') iconSync: string
  @PropSync('tooltip') tooltipSync: string
  @PropSync('iconColor') iconColorSync: string
  @PropSync('disabled') disabledSync: boolean

  @Emit('click')
  click() {}
}
