import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import FilialeSimple from '@/entities/member/filiale_simple'
import FilialeSimpleList from '@/entities/member/lists/filiale_simple_list'
import store from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import OeffnungszeitAusnahmenMemcommonLogic from '../memcommon/oeffnungszeit_ausnahmen_memcommon_logic'

export default class OeffnungszeitAusnahmenLogic extends OeffnungszeitAusnahmenMemcommonLogic {
  private _filialen: FilialeSimple[]

  get filialen(): FilialeSimple[] {
    return this._filialen
  }

  set filialen(value: FilialeSimple[]) {
    this._filialen = value
  }

  init() {
    super.init()

    var request = new IdRequest()
    request.id = store.state.user.betriebId

    Poster.postMember<RemoteResult<FilialeSimpleList>>(
      RestPaths.filialeListeSimple,
      request
    ).then((response) => {
      this.filialen = response.data.payload.list
    })
  }
}
