
import Vue from 'vue'
import Component from 'vue-class-component'
import SystemUser from '@/entities/member/system_user'
import SystemUserList from '@/entities/member/lists/system_user_list'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import RestPaths from '@/util/rest_paths'
import ToolbarButton from '../custom/ToolbarButton.vue'
import RouteNames from '@/util/route_names'

@Component({components: {ToolbarButton}})
export default class SystemAccounts extends Vue {
  users: SystemUser[] = null

  neuerBenutzer() {
    this.$router.push({name: RouteNames.SYSTEM.SYSTEM_ACCOUNT_NEU})
  }

  detailsAnzeigen(user: SystemUser) {
    this.$router.push({name: RouteNames.SYSTEM.SYSTEM_ACCOUNT_DETAILS, params: {username: user.username}})
  }

  mounted() {
    Poster.postMember<RemoteResult<SystemUserList>>(
      RestPaths.mitarbeiterSystemAccounts,
      null
    ).then((response) => {
      this.users = response.data.payload.list
    })
  }
}
