
import Vue from 'vue'
import Component from 'vue-class-component'
import BetriebDetails from './BetriebDetails.vue'
import FilialeNeuLogic from '@/logic/system/filiale_neu_logic'
import FilialeNeuMemCommon from '../memcommon/FilialeNeuMemCommon.vue'

@Component({components: {FilialeNeuMemCommon}})
export default class FilialeNeu extends Vue {
  logic?: FilialeNeuLogic = null

  mounted() {
    this.logic = new FilialeNeuLogic()
    this.logic.betriebId = (this.$parent as BetriebDetails).logic.betrieb.id
  }
}
