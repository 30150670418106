
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ProduktEditLogic from '@/logic/member/produkt_edit_logic'
import ProduktEditMemCommon from '@/components/memcommon/ProduktEditMemCommon.vue'
import store from '@/store'

@Component({ components: { ProduktEditMemCommon } })
export default class ProduktEdit extends Vue {
  logic?: ProduktEditLogic = null

  mounted() {
    this.logic = new ProduktEditLogic()
    this.logic.betriebId = store.state.user.betriebId
    this.logic.init(this.$route.params.id)
  }
}
