import LoginResult from '@/entities/common/login_result'
import RemoteResult from '@/entities/common/remote_result'
import LoginRequest from '@/entities/common/request/login_request'
import router from '@/router'
import store from '@/store'
import UserInfo from '@/store/user_info'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import StoreOps from '@/util/store_ops'
import { v4 as uuidv4 } from 'uuid'

export default class UserPageLogic {
  drawer: boolean = false
  websocket = null
  loginDialogVisible: boolean = false
  showPassword: boolean = false
  accountMenuVisible: boolean = false
  loginError: boolean = false

  benutzername?: string = null
  passwort?: string = null
  loggedIn?: boolean = false

  init() {
    var user = store.state.user as UserInfo
    if (!user || !user.identifier) {
      var identifier = uuidv4()
      store.commit(StoreOps.UPDATE_IDENTIFIER, identifier)
    }

    store.watch(
      (state) => state.user.key,
      (newValue) => {
        var memberkey = newValue
        this.loggedIn = memberkey != null
      }, {immediate: true, deep: true}
    )
  }

  passwortVergessen() {
    this.loginDialogVisible = false
    router.push({ name: RouteNames.PASSWORT_VERGESSEN })
  }

  logout() {
    store.commit(StoreOps.LOGOUT)
  }

  login() {
    this.loginError = false
    var request = new LoginRequest()
    request.username = this.benutzername
    request.password = this.passwort

    Poster.postUser<RemoteResult<LoginResult>>(
      RestPaths.login,
      request
    ).then((response) => {
      if (response.data.payload.success) {
        this.loginDialogVisible = false
        store.commit(StoreOps.LOGIN_KUNDE, response.data.payload)
      } else {
        this.loginError = true
      }
    })
  }

  neuRegistrieren() {
    this.loginDialogVisible = false
    router.push({ name: RouteNames.REGISTRIEREN })
  }

  navHome() {
    router.push({ name: RouteNames.NEU.HOME })
  }

  showUserLogIn() {
    this.loginDialogVisible = true
  }

  navBestellungen() {
    router.push({ name: RouteNames.BESTELLUNGEN })
  }

  navMitarbeiter() {
    router.push({ name: RouteNames.MEMBER.PORTAL })
  }

  navBetriebAnfragen() {
    router.push({ name: RouteNames.BETRIEB_ANFRAGEN })
  }

  navMeinAccount() {
    router.push({name: RouteNames.MEIN_ACCOUNT})
  }
}
