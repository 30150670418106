export default class RestPaths {
  private static bestellungKundeBase = 'bestellung_kunde/'
  private static bestellungMitarbeiterBase = 'bestellung_mitarbeiter/'
  private static betriebBase = 'betrieb/'
  private static filialeBase = 'filiale/'
  private static kundeBase = 'kunde/'
  private static mitarbeiterBase = 'mitarbeiter/'
  private static nachrichtBase = 'nachricht/'
  private static preislisteBase = 'preisliste/'
  private static oeffnungszeitBase = 'oeffnungszeit/'
  private static produktBase = 'produkt/'
  private static inhaltsstoffBase = 'inhaltsstoffe/'
  private static tischBase = 'tisch/'
  private static loginBase = 'login/'
  private static paymentBase = 'payment/'
  private static artikelBase = 'artikel/'
  private static imageBase = 'images/'
  private static locationBase = 'locations/'
  private static systemBase = 'system/'
  private static auswertungBase = 'auswertung/'
  private static bestellungpendingBase = 'bestellung_pending/'

  // public static imageBasePath = 'http://192.168.178.38/images/'
  // public static urlBase = 'http://192.168.178.38/api/rs/'
  // public static urlBaseWs = 'ws://192.168.178.38/api/ws/'

  // public static imageBasePath = 'http://localhost/images/'
  // public static urlBase = 'http://localhost:8080/rs/'
  // public static urlBaseWs = 'ws://localhost:8080/ws/'

  public static imageBasePath = 'https://mangeroo.de/images/'
  public static urlBase = 'https://mangeroo.de/api/rs/'
  public static urlBaseWs = 'wss://mangeroo.de/api/ws/'

  public static methodPost = 'POST'
  public static methodGet = 'GET'
  public static modeCors = 'cors'
  public static contentType = 'Content-Type'
  public static applicationJson = 'application/json;charset=utf-8'

  public static systemSetup = this.systemBase + 'setup'

  public static auswertungBetrieb = this.auswertungBase + 'betrieb'
  public static auswertungFiliale = this.auswertungBase + 'filiale'
  public static auswertungRechnungVersenden =
    this.auswertungBase + 'rechnung_versenden'

  public static artikelPdukteNachZeitSingle =
    this.artikelBase + 'pdukte_nach_zeit_single'
  public static artikelPdukteNachZeit = this.artikelBase + 'pdukte_nach_zeit'
  public static artikelPduktListe = this.artikelBase + 'pdukt_liste'
  public static artikelPduktDetails = this.artikelBase + 'pdukt_details'
  public static artikelBasiskategorieList =
    this.artikelBase + 'basiskategorie_list'
  public static artikelBasiskategorieEinfuegen =
    this.artikelBase + 'basiskategorie_einfuegen'
  public static artikelBasiskategorieSpeichern =
    this.artikelBase + 'basiskategorie_update'
  public static artikelPduktEinfuegen = this.artikelBase + 'pdukt_einfuegen'
  public static artikelPduktUpdate = this.artikelBase + 'pdukt_speichern'
  public static artikelProduktkategorieList =
    this.artikelBase + 'produktkategorie_list'
  public static artikelAusverkauftList = this.artikelBase + 'ausverkauft_liste'
  public static artikelAusverkauftInsert =
    this.artikelBase + 'ausverkauft_insert'
  public static artikelAusverkauftDelete =
    this.artikelBase + 'ausverkauft_loeschen'
  public static artikelProduktAktivieren =
    this.artikelBase + 'produkt_aktivieren'
  public static artikelProduktDeaktivieren =
    this.artikelBase + 'produkt_deaktivieren'
  public static artikelPduktShortcuts = this.artikelBase + 'pdukt_shortcuts'
  public static artikelInsertPduktShortcuts =
    this.artikelBase + 'insert_pdukt_shortcuts'
  public static artikelUpdatePduktShortcuts =
    this.artikelBase + 'update_pdukt_shortcuts'

  public static bestellungKundeSync = this.bestellungKundeBase + 'sync'
  public static bestellungKundeReservierungEinbuchen =
    this.bestellungKundeBase + 'reservierung_einbuchen'
  public static bestellungKundeEinfuegen =
    this.bestellungKundeBase + 'einfuegen'
  public static bestellungKundeEinfuegenArtikelliste =
    this.bestellungKundeBase + 'einfuegen_artikelliste'
  public static bestellungKundeDetails = this.bestellungKundeBase + 'details'
  public static bestellungKundeStornieren =
    this.bestellungKundeBase + 'stornieren'
  public static bestellungKundeListe = this.bestellungKundeBase + 'liste'
  public static bestellungKundeUpdate = this.bestellungKundeBase + 'update'
  public static bestellungKundeServiceAnfordern =
    this.bestellungKundeBase + 'service_anfordern'
  public static bestellungKundeNachbestellungEinfuegen =
    this.bestellungKundeBase + 'nachbestellung_einfuegen'
  public static bestellungKundeTischBuchen =
    this.bestellungKundeBase + 'tisch_buchen'
  public static bestellungKundeTischBuchungsartWaehlen =
    this.bestellungKundeBase + 'tisch_buchungsart_waehlen'
  public static bestellungKundeKundendatenLieferung =
    this.bestellungKundeBase + 'kundendaten_lieferung'
  public static bestellungKundeFilialeAktuell =
    this.bestellungKundeBase + 'filiale_aktuell'

  public static bestellungMitarbeiterNeueBestellungen =
    this.bestellungMitarbeiterBase + 'neue_bestellungen'
  public static bestellungMitarbeiterDetails =
    this.bestellungMitarbeiterBase + 'details'
  public static bestellungMitarbeiterUpdateServiceanforderungStatus =
    this.bestellungMitarbeiterBase + 'update_serviceanforderung_status'
  public static bestellungMitarbeiterUebersichtListe =
    this.bestellungMitarbeiterBase + 'uebersicht_liste'
  public static bestellungMitarbeiterAktuelleBestellungen =
    this.bestellungMitarbeiterBase + 'aktuelle_bestellungen'
  public static bestellungMitarbeiterBestellungAbschliessen =
    this.bestellungMitarbeiterBase + 'bestellung_abschliessen'
  public static bestellungMitarbeiterListeNachFiliale =
    this.bestellungMitarbeiterBase + 'liste_nach_filiale'
  public static bestellungMitarbeiterListeComplex =
    this.bestellungMitarbeiterBase + 'liste_complex'
  public static bestellungMitarbeiterNeuesteBestellungen =
    this.bestellungMitarbeiterBase + 'neueste_bestellungen'
  public static bestellungMitarbeiterServiceanforderungListe =
    this.bestellungMitarbeiterBase + 'serviceanforderung_liste'
  public static bestellungMitarbeiterUpdateNachbestellungStatus =
    this.bestellungMitarbeiterBase + 'update_nachbestellung_status'
  public static bestellungMitarbeiterReservierungEinfuegen =
    this.bestellungMitarbeiterBase + 'reservierung_einfuegen'
  public static bestellungMitarbeiterKuechenaufgaben =
    this.bestellungMitarbeiterBase + 'kuechenaufgaben'
  public static bestellungMitarbeiterReservierungenListe =
    this.bestellungMitarbeiterBase + 'reservierungen_liste'
  public static bestellungMitarbeiterReservierungenListeSimple =
    this.bestellungMitarbeiterBase + 'reservierungen_liste_simple'
  public static bestellungMitarbeiterStornieren =
    this.bestellungMitarbeiterBase + 'stornieren'
  public static bestellungMitarbeiterStatusUpdateAbholung =
    this.bestellungMitarbeiterBase + 'status_update_abholung'
  public static bestellungMitarbeiterStatusUpdateReservierung =
    this.bestellungMitarbeiterBase + 'status_update_reservierung'
  public static bestellungMitarbeiterStatusUpdateSofortbestellung =
    this.bestellungMitarbeiterBase + 'status_update_sofortbestellung'
  public static bestellungMitarbeiterStatusUpdateLieferung =
    this.bestellungMitarbeiterBase + 'status_update_lieferung'
  public static bestellungMitarbeiterKuecheUpdate =
    this.bestellungMitarbeiterBase + 'kueche_update'
  public static bestellungMitarbeiterSync =
    this.bestellungMitarbeiterBase + 'sync'
  public static bestellungMitarbeiterBestellungenNachFiliale =
    this.bestellungMitarbeiterBase + 'bestellungen_nach_filiale'
  public static bestellungMitarbeiterLieferungen =
    this.bestellungMitarbeiterBase + 'lieferungen'

  public static bestellungpendingBeginnen =
    this.bestellungpendingBase + 'beginnen'
  public static bestellungpendingAbholungTermin =
    this.bestellungpendingBase + 'abholung_termin'
  public static bestellungpendingLieferungTermin =
    this.bestellungpendingBase + 'lieferung_termin'
  public static bestellungpendingArtikelHinzufuegen =
    this.bestellungpendingBase + 'artikel_hinzufuegen'
  public static bestellungpendingLieferungKunde =
    this.bestellungpendingBase + 'lieferung_kunde'
  public static bestellungpendingAbholungKunde =
    this.bestellungpendingBase + 'abholung_kunde'
  public static bestellungpendingArtikelAendern =
    this.bestellungpendingBase + 'artikel_aendern'
  public static bestellungpendingAbholungVerzehrart =
    this.bestellungpendingBase + 'abholung_verzehrart'
  public static bestellungpendingAufgeben =
    this.bestellungpendingBase + 'aufgeben'

  public static betriebEinfuegen = this.betriebBase + 'einfuegen'
  public static betriebSpeichern = this.betriebBase + 'speichern'
  public static betriebListe = this.betriebBase + 'liste'
  public static betriebDetails = this.betriebBase + 'details'
  public static betriebDetailsByPath = this.betriebBase + 'details_bypath'
  public static betriebLoeschen = this.betriebBase + 'loeschen'
  public static betriebAktivieren = this.betriebBase + 'aktivieren'
  public static betriebDetaktivieren = this.betriebBase + 'deaktivieren'
  public static betriebUncheckedListe = this.betriebBase + 'unchecked_liste'
  public static betriebUncheckedDetails = this.betriebBase + 'uncheckd_details'
  public static betriebUncheckedEinfuegen =
    this.betriebBase + 'unchecked_einfuegen'
  public static betriebUncheckedSpeichern =
    this.betriebBase + 'unchecked_speichern'
  public static betriebUncheckedAufnehmen =
    this.betriebBase + 'uncheckd_aufnehmen'
  public static betriebUncheckedLoeschen =
    this.betriebBase + 'unchecked_loeschen'
  public static betriebAnfragen = this.betriebBase + 'anfragen'
  public static betriebAnfragenListe = this.betriebBase + 'anfrage_liste'
  public static betriebAnfrageDetails = this.betriebBase + 'anfrage_details'

  public static filialeEinfuegen = this.filialeBase + 'einfuegen'
  public static filialeSpeichern = this.filialeBase + 'speichern'
  public static filialeListe = this.filialeBase + 'liste'
  public static filialeListeSimple = this.filialeBase + 'liste_simple'
  public static filialeListeBestellungSuche =
    this.filialeBase + 'liste_bestellung_suche'
  public static filialeBestellungSuche = this.filialeBase + 'bestellung_suche'
  public static filialeBestellungSucheTisch =
    this.filialeBase + 'bestellung_suche_tisch'
  public static filialeDetails = this.filialeBase + 'details'
  public static filialeLoeschen = this.filialeBase + 'loeschen'
  public static filialeDeaktivieren = this.filialeBase + 'deaktivieren'
  public static filialeAktivieren = this.filialeBase + 'aktivieren'
  public static filialeBeobachen = this.filialeBase + 'beobachen'
  public static filialeDetailsMitTisch = this.filialeBase + 'details_mit_tisch'
  public static filialeParseKmlFile = this.filialeBase + 'parse_kml_file'
  public static filialeLiefergebieteEinfuegen =
    this.filialeBase + 'liefergebiete_einfuegen'
  public static filialePrintGeolocation = this.filialeBase + 'print_geolocation'
  public static filialeCheckLocation = this.filialeBase + 'check_location'
  public static filialeTimeZones = this.filialeBase + 'time_zones'
  public static filialeCheckOeffnung = this.filialeBase + 'check_oeffnung'
  public static filialeDetailed = this.filialeBase + 'detailed'

  public static kundeRegistrieren = this.kundeBase + 'registrieren'
  public static kundeEinfuegen = this.kundeBase + 'einfuegen'
  public static kundeBenutzerRegistrieren =
    this.kundeBase + 'benutzer_registrieren'
  public static kundeListe = this.kundeBase + 'liste'
  public static kundeForEdit = this.kundeBase + 'for_edit'
  public static kundeSpeichern = this.kundeBase + 'speichern'
  public static kundeLoeschen = this.kundeBase + 'loeschen'
  public static kundeEigeneDaten = this.kundeBase + 'eigene_daten'
  public static kundeEigeneDatenBenutzer =
    this.kundeBase + 'eigene_daten_benutzer'
  public static kundeEigeneDatenEdit = this.kundeBase + 'eigene_daten_edit'
  public static kundeEigeneDatenSpeichern =
    this.kundeBase + 'eigene_daten_speichern'
  public static kundeDeaktivieren = this.kundeBase + 'deaktivieren'
  public static kundeAktivieren = this.kundeBase + 'aktivieren'
  public static kundePasswortAendern = this.kundeBase + 'passwort_aendern'
  public static kundePasswortAendernDirekt =
    this.kundeBase + 'passwort_aendern_direkt'
  public static kundePasswortVergessen = this.kundeBase + 'passwort_vergessen'
  public static kundePasswortAendernValid =
    this.kundeBase + 'passwort_aendern_valid'

  public static mitarbeiterListe = this.mitarbeiterBase + 'liste'
  public static mitarbeiterEinfuegen = this.mitarbeiterBase + 'einfuegen'
  public static mitarbeiterDetailForEdit = this.mitarbeiterBase + 'for_edit'
  public static mitarbeiterSpeichern = this.mitarbeiterBase + 'speichern'
  public static mitarbeiterLoeschen = this.mitarbeiterBase + 'loeschen'
  public static mitarbeiterEigeneDaten = this.mitarbeiterBase + 'eigene_daten'
  public static mitarbeiterDeaktivieren = this.mitarbeiterBase + 'deaktivieren'
  public static mitarbeiterAktivieren = this.mitarbeiterBase + 'aktivieren'
  public static mitarbeiterPasswortAendern =
    this.mitarbeiterBase + 'passwort_aendern'
  public static mitarbeiterSystemAccounts =
    this.mitarbeiterBase + 'system_accounts'
  public static mitarbeiterSystemAccountInsert =
    this.mitarbeiterBase + 'system_account_insert'
  public static mitarbeiterSystemAktivieren =
    this.mitarbeiterBase + 'system_aktivieren'
  public static mitarbeiterSystemDeaktivieren =
    this.mitarbeiterBase + 'system_deaktivieren'
  public static mitarbeiterSystemDetails =
    this.mitarbeiterBase + 'system_details'
  public static mitarbeiterSystemUpdate = this.mitarbeiterBase + 'system_update'

  public static nachrichtListe = this.nachrichtBase + 'liste'
  public static nachrichtDetails = this.nachrichtBase + 'details'

  public static preislisteEinfuegen = this.preislisteBase + 'einfuegen'
  public static preislisteListe = this.preislisteBase + 'liste'
  public static preislisteListeSimple = this.preislisteBase + 'liste_simple'
  public static preislisteDetails = this.preislisteBase + 'details'
  public static preislisteDetailsForEdit =
    this.preislisteBase + 'details_for_edit'
  public static preislisteNachDatum = this.preislisteBase + 'nach_datum'
  public static preislistePreisTagEinfuegen =
    this.preislisteBase + 'preis_tag_einfuegen'
  public static preislisteUpdate = this.preislisteBase + 'update'
  public static preislisteAktivieren = this.preislisteBase + 'aktivieren'
  public static preislisteDeaktivieren = this.preislisteBase + 'deaktivieren'
  public static preislisteFromAltePreisliste =
    this.preislisteBase + 'from_alte_preisliste'
  public static preislisteNeueInfo = this.preislisteBase + 'neue_info'

  public static oeffnungszeitZeitraumListe =
    this.oeffnungszeitBase + 'zeitraum_liste'
  public static oeffnungszeitZeitraumDetails =
    this.oeffnungszeitBase + 'zeitraum_details'
  public static oeffnungszeitFilialeDetails =
    this.oeffnungszeitBase + 'filiale_details'
  public static oeffnungszeitZeitraumEinfuegen =
    this.oeffnungszeitBase + 'zeitraum_einfuegen'
  public static oeffnungszeitOeffnungszeitEinfuegen =
    this.oeffnungszeitBase + 'oeffnungszeit_einfuegen'
  public static oeffnungszeitOeffnungszeitSpeichern =
    this.oeffnungszeitBase + 'oeffnungszeit_speichern'
  public static oeffnungszeitOeffnungszeitLoeschen =
    this.oeffnungszeitBase + 'oeffnungszeit_loeschen'
  public static oeffnungszeitZeitraumSpeichern =
    this.oeffnungszeitBase + 'zeitraum_speichern'
  public static oeffnungszeitFilialeSpeichern =
    this.oeffnungszeitBase + 'filiale_speichern'
  public static oeffnungszeitListe = this.oeffnungszeitBase + 'liste'
  public static oeffnungszeitZeitraumLoeschen =
    this.oeffnungszeitBase + 'zeitraum_loeschen'
  public static oeffnungszeitOeffnungstage =
    this.oeffnungszeitBase + 'oeffnungstage'
  public static oeffnungszeitOeffnungKalender =
    this.oeffnungszeitBase + 'oeffnung_kalender'
  public static oeffnungszeitOeffnungszeiten =
    this.oeffnungszeitBase + 'oeffnungszeiten'
  public static oeffnungszeitOverview = this.oeffnungszeitBase + 'overview'

  public static produktListeFiliale = this.produktBase + 'liste_filiale'
  public static produktListeFiltered = this.produktBase + 'liste_filtered'
  public static produktEinfuegen = this.produktBase + 'einfuegen'
  public static produktUpdate = this.produktBase + 'update'
  public static produktForEdit = this.produktBase + 'for_edit'
  public static produktDetails = this.produktBase + 'details'
  public static produktListeNachDatum = this.produktBase + 'liste_nach_datum'
  public static produktDeaktivieren = this.produktBase + 'deaktivieren'
  public static produktAktivieren = this.produktBase + 'aktivieren'
  public static produktKategorieListe = this.produktBase + 'kategorie_liste'
  public static produktKategorieForEdit =
    this.produktBase + 'kategorie_for_edit'
  public static produktKategorieEinfuegen =
    this.produktBase + 'kategorie_einfuegen'
  public static produktKategorieUpdate = this.produktBase + 'kategorie_update'
  public static produktKategorieLoeschen =
    this.produktBase + 'kategorie_loeschen'

  public static inhaltsstoffeListe = this.inhaltsstoffBase + 'liste'
  public static inhaltsstoffeInsert = this.inhaltsstoffBase + 'insert'
  public static inhaltsstoffeUpdate = this.inhaltsstoffBase + 'update'

  public static tischListe = this.tischBase + 'liste'
  public static tischDetails = this.tischBase + 'details'
  public static tischEinfuegen = this.tischBase + 'einfuegen'
  public static tischEinfuegenListe = this.tischBase + 'einfuegen_liste'
  public static tischSpeichern = this.tischBase + 'speichern'
  public static tischVerfuegbarkeitPruefen =
    this.tischBase + 'verfuegbarkeit_pruefen'
  public static tischSperrungEinfuegen = this.tischBase + 'sperrung_einfuegen'
  public static tischSperrungListe = this.tischBase + 'sperrung_liste'
  public static tischLoeschen = this.tischBase + 'loeschen'
  public static tischSperrungLoeschen = this.tischBase + 'sperrung_loeschen'
  public static tischPruefen = this.tischBase + 'pruefen'
  public static tischCheckFreeSlots = this.tischBase + 'check_free_slots'
  public static tischMitFiliale = this.tischBase + 'mit_filiale'
  public static tischAktivieren = this.tischBase + 'aktivieren'
  public static tischDeaktivieren = this.tischBase + 'deaktivieren'
  public static tischUpdateReihenfolge = this.tischBase + 'update_reihenfolge'

  public static login = this.loginBase + 'login'
  public static loginUpdateFcm = this.loginBase + 'update_fcm'
  public static loginUpdateToken = this.loginBase + 'update_token'

  public static paymentPaypalCheckout = this.paymentBase + 'paypal_checkout'
  public static paymentPaypalCheckoutNeueBestellung =
    this.paymentBase + 'paypal_checkout_neue_bestellung'
  public static paymentPaypalCheckoutCheck =
    this.paymentBase + 'paypal_checkout_check'
  public static paymentPaypalCheckoutComplete = this.paymentBase + 'complete'
  public static paymentPaypalAbschliessen = this.paymentBase + 'abschliessen'

  public static imageSaveTemp = this.imageBase + 'save_temp'
  public static imageImage = this.imageBase + 'image2'
  public static imageQrCode = this.imageBase + 'qrcode'
  public static imageQrCodeFiliale = this.imageBase + 'qrcode_filiale'

  public static locationByQuery = this.locationBase + 'by_query'
  public static locationNext = this.locationBase + 'next'
  public static locationListByText = this.locationBase + 'list_by_text'
  public static locationListByTextOSM = this.locationBase  + 'list_by_text_osm'
  public static locationLocationByCoordinatesOSM = this.locationBase + 'location_by_coordinates_osm'
  public static locationPlaceById = this.locationBase + 'place_by_id'
}
