
import Vue from 'vue'
import Component from 'vue-class-component'
import BetriebNeuLogic from '@/logic/system/betrieb_neu_logic'

@Component
export default class BetriebNeu extends Vue {
  $refs!: {
    form: HTMLFormElement
  }

  logic?: BetriebNeuLogic = null

  mounted() {
    var anfrage = this.$route.params.anfrage
    this.logic = new BetriebNeuLogic()
    this.logic.init(anfrage)
  }

  einfuegen() {
    var valid = this.$refs.form.validate()
    if (!valid) return

    this.logic.einfuegen()
  }
}
