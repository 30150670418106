
import ReservierungenBestellungLogic from '@/logic/bestellung/reservierung_bestellung_logic'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class ReservierungBestellung extends Vue {
  logic?: ReservierungenBestellungLogic = null

  created() {
    this.logic = new ReservierungenBestellungLogic()
    this.logic.init()
  }
}
