
import Vue from 'vue'
import Component from 'vue-class-component'
import ShoppingLieferungLogic from '@/logic/bestellung_neu/shopping_lieferung_logic'

@Component
export default class ShoppingLieferung extends Vue {
  logic?: ShoppingLieferungLogic = null

  mounted() {
    this.logic = new ShoppingLieferungLogic()
    this.logic.init()
  }
}
