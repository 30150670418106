
import Vue from 'vue'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import LinkBox from '@/components/custom/LinkBox.vue'
import ToolbarButton from '../custom/ToolbarButton.vue'
import ErrorBox from '../custom/ErrorBox.vue'
import ProdukteMemcommonLogic from '@/logic/memcommon/produkte_memcommon_logic'
import Pdukt from '@/entities/member/pdukt'
import vuexStore from '@/store'
import { Role } from '@/store/role'

@Component({ components: { LinkBox, ToolbarButton, ErrorBox } })
export default class ProdukteMemCommon extends Vue {
  @Prop() logic: ProdukteMemcommonLogic

  loadListe() {
    this.logic.loadListe()
  }

  detailsAnzeigen(produkt: Pdukt) {
    this.logic.detailsAnzeigen(produkt)
  }

  inhaltsstoffeVerwalten() {
    this.logic.inhaltsstoffeVerwalten()
  }

  kategorienVerwalten() {
    this.logic.kategorienVerwalten()
  }

  extrasVerwalten() {
    this.logic.extrasVerwalten()
  }

  produktBearbeiten(produkt: Pdukt) {
    this.logic.produktBearbeiten(produkt)
  }

  neuesProdukt() {
    this.logic.neuesProdukt()
  }
}
