export default class MitarbeiterUpdateRequest {
  id?: string
  nachname?: string
  vorname?: string
  strasse?: string
  plz?: string
  ort?: string
  email?: string
  telefon?: string
  admin?: boolean
  filiale?: string
}