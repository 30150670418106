import BestellungOeffnungszeit from "./bestellung_oeffnungszeit"

export default class BestellungFiliale {
  filialeId?: string = null
  filialeBezeichnung?: string = null
  betriebId?: string = null
  betriebBezeichnung?: string = null
  strasse?: string = null
  plz?: string = null
  ort?: string = null
  telefon?: string = null
  email?: string = null
  bildId?: string = null
  bannerId?: string = null
  oeffnungszeitArray?: any[] = []
  oeffnungszeitListe?: BestellungOeffnungszeit[] = []
  latitude?: number = 0.0
  longitude?: number = 0.0
  distance?: number = 0.0
  supportsAbholung: boolean = false
  supportsReservierung: boolean = false
  supportsSofortbestellung: boolean = false
  supportsPaypal: boolean = false
  supportsLieferung: boolean = false
  zurZeitGeoeffnet: boolean = false
  abholungSettings: AbholungSettings = null
  reservierungSettings: ReservierungSettings = null
  sofortbestellungSettings: SofortbestellungSettings = null
  lieferungSettings: LieferungSettings = null
}

class AbholungSettings {
  supportsVorbestellung?: boolean
  supportsMehrereTage?: boolean
  zeitVorSchliessung?: number
  vorbestellungTage?: number
}

class ReservierungSettings {
  supportsVorbestellung?: boolean
  zeitVorSchliessung?: number
  reservierungTage?: number
}

class SofortbestellungSettings {
  zeitVorSchliessung?: number
}

class LieferungSettings {
  supportsTermin?: boolean
  terminTage?: number
  liefergebietId?: string
  lieferkosten?: number
  lieferzeitVon?: number
  lieferzeitBis?: number
  mindestbestellwert?: number
  kostenlosAb?: number
}

export {AbholungSettings, ReservierungSettings, SofortbestellungSettings, LieferungSettings}