import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import BetriebAnfrage from '@/entities/member/betrieb_anfrage'
import BetriebInsertRequest from '@/entities/member/request/betrieb_insert_request'
import router from '@/router'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import { v4 as uuidv4 } from 'uuid'

export default class BetriebNeuLogic {
  bezeichnung?: string = null
  strasse?: string = null
  plz?: string = null
  ort?: string = null
  telefon?: string = null
  mobil?: string = null
  email?: string = null
  website?: string = null
  path?: string = null
  nummer: number = 0

  init(anfrage: string) {
    if(anfrage) {
      var request = new IdRequest()
      request.id = anfrage

      Poster.postMember<RemoteResult<BetriebAnfrage>>(RestPaths.betriebAnfrageDetails, request).then((response)=> {
        if(response.data.success) {
          var pl = response.data.payload
          this.bezeichnung = pl.bezeichnung
          this.strasse = pl.strasse
          this.plz = pl.plz
          this.ort = pl.ort
          this.telefon = pl.telefon
          this.email = pl.email
        }
      })

    }
  }

  plzRule = (v) => {
    var regex = /\d{5}/
    var okay = regex.test(v)
    if (okay) return null
    return 'Bitte gültige PLZ angeben'
  }

  einfuegen() {
    var request = new BetriebInsertRequest()
    request.id = uuidv4()
    request.bezeichnung = this.bezeichnung
    request.strasse = this.strasse
    request.plz = this.plz
    request.ort = this.ort
    request.telefon = this.telefon
    request.mobil = this.mobil
    request.email = this.email
    request.website = this.website
    request.path = this.path
    request.nummer = this.nummer

    Poster.postMember(RestPaths.betriebEinfuegen, request).then((response) => {
      router.back()
    })
  }
}
