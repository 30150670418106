
import Component from 'vue-class-component'
import { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import ArtikelRow from '../custom/ArtikelRow.vue'
import FilialeHeader from '../custom/FilialeHeader.vue'
import ReservierungVorbestellungLogic from '@/logic/bestellung/reservierung_vorbestellung_logic'
import ReservierungVorbestellung from './ReservierungVorbestellung.vue'

@Component({ components: { ArtikelRow, FilialeHeader } })
export default class ReservierungVorbestellungBestaetigen extends mixins(
  Calculator
) {
  logic?: ReservierungVorbestellungLogic = null

  
  mounted() {
    this.logic = (this.$parent as ReservierungVorbestellung).logic
  }

  backToOverview() {
    this.logic.backToOverview()
  }

  bestellungBestaetigen() {
    this.logic.bestellungBestaetigen()
  }
}
