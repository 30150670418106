import IdRequest from '@/entities/common/request/id_request'
import FilialeSimple from '@/entities/member/filiale_simple'
import Pliste from '@/entities/member/pliste'
import router from '@/router'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import store from '@/store'
import PreislistenMemcommonLogic from '../memcommon/preislisten_memcommon_logic'
import RemoteResult from '@/entities/common/remote_result'
import FilialeSimpleList from '@/entities/member/lists/filiale_simple_list'

export default class PreislistenLogic extends PreislistenMemcommonLogic {
  private _filialen: FilialeSimple[]

  replaceFiliale(): void {
    router.replace({
      name: RouteNames.MEMBER.PREISLISTEN,
      query: { filiale: this.filiale },
    })
  }

  get filialen(): FilialeSimple[] {
    return this._filialen
  }

  kategorienVerwalten(): void {
    router.push({ name: RouteNames.MEMBER.KATEGORIEN })
  }

  navNeu(): void {
    router.push({ name: RouteNames.MEMBER.PREISLISTE_NEU })
  }

  detailsAnzeigen(newValue: Pliste): void {
    router.push({
      name: RouteNames.MEMBER.PREISLISTE_DETAILS,
      params: { id: newValue.id },
    })
  }

  init(id: string) {
    var request = new IdRequest()
    request.id = store.state.user.betriebId

    Poster.postMember<RemoteResult<FilialeSimpleList>>(
      RestPaths.filialeListeSimple,
      request
    ).then((response) => {
      this._filialen = response.data.payload.list
      this.filiale = id
    })
  }
}
