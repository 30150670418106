import LoginResult from "@/entities/common/login_result"
import FilialeSimple from "@/entities/member/filiale_simple"

export default class UserInfo {
  loggedIn: boolean = false
  vorname?: string
  nachname?: string
  username?: string
  role?: string
  key?: string
  identifier?: string
  betriebId?: string
  admin: boolean = false
  expires?: string
  filiale: FilialeSimple

  fillWithLoginData(data: LoginResult) {
    this.loggedIn = true
    this.vorname = data.vorname
    this.nachname = data.nachname
    this.username = data.username
    this.role = data.role
    this.key = data.key
    this.betriebId = data.betriebId
    this.admin = data.admin
    this.expires = data.expires
  }

  clear() {
    this.loggedIn = false
    this.vorname = null
    this.nachname = null
    this.username = null
    this.role = null
    this.key = null
    this.betriebId = null
    this.admin = false
    this.expires = null
  }
}