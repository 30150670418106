
import Vue from 'vue'
import Component from 'vue-class-component'
import OeffnungszeitenFilialeMemcommonLogic from '@/logic/memcommon/oeffnungszeiten_filiale_memcommon_logic'
import OeffnungszeitZeitraum from '@/entities/member/oeffnungszeit_zeitraum'
import { Prop } from 'vue-property-decorator'
import ToolbarButton from '../custom/ToolbarButton.vue'

@Component({ components: { ToolbarButton } })
export default class OeffnungszeitenFilialeMemCommon extends Vue {
  @Prop() logic?: OeffnungszeitenFilialeMemcommonLogic

  zeigeDetails(oeffnungszeit: OeffnungszeitZeitraum) {
    this.logic.zeigeDetails(oeffnungszeit)
  }

  ausnahmenHinzufuegen() {
    this.logic.ausnahmenHinzufuegen()
  }

  neueOeffnungszeit() {
    this.logic.neueOeffnungszeit()
  }
}
