
import Component from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import Calculator from '@/components/mixins/Calculator.vue'
import { PreislisteProdukt } from '@/entities/common/pliste_produkt_zeit'
import ProdukteLogic from '@/logic/produkte_logic'
import FilialeHeader from './FilialeHeader.vue'
import Artikel from './Artikel.vue'

@Component({ components: { FilialeHeader, Artikel } })
export default class Produkte extends mixins(Calculator) {
  @Prop() logic: ProdukteLogic

  artikelWaehlen(artikel: PreislisteProdukt) {
    this.logic.artikelWaehlen(artikel)
  }

  bestellungEinfuegen() {
    this.logic.bestellungEinfuegen()
  }
}
