import RemoteResult from '@/entities/common/remote_result'
import Tisch from '@/entities/common/tisch'
import TischUpdateRequest from '@/entities/member/request/tisch_update_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import TischInsertListeRequest, {
  Tisch as TischRequest,
} from '@/entities/member/request/tisch_insert_liste_request'
import { v4 as uuidv4 } from 'uuid'
import IdRequest from '@/entities/common/request/id_request'
import Filiale from '@/entities/member/filiale'
import TischList from '@/entities/member/lists/tisch_list'
import MemberBaseLogic from '../member_base_logic'
import router from '@/router'
import RouteNames from '@/util/route_names'
import Swapper from '@/util/swapper'
import TischReihenfolgeUpdateRequest, {
  TischReihenfolge,
} from '@/entities/member/request/tisch_reihenfolge_update_request'
import { response } from 'express'

export default class TischeFilialeMemcommonLogic extends MemberBaseLogic {
  filiale?: Filiale

  tische: Tisch[] = []
  neueTische?: TischNeu[] = []
  neuDialogVisible: boolean = false
  editDialogVisible: boolean = false
  reihenfolgeDialogVisible: boolean = false
  aktuellerTisch?: Tisch = null
  editTisch?: Tisch = null

  loadError: boolean = false

  editWorking: boolean = false
  editError: boolean = false
  editFormDisabled: boolean = false

  insertWorking: boolean = false
  insertError: boolean = false
  insertFormDisabled: boolean = false

  moveUp(index: number) {
    Swapper.instance.pushUp(this.tische, index)
  }

  moveDown(index: number) {
    Swapper.instance.pushDown(this.tische, index)
  }

  init(filialeId: string) {
    var request = new IdRequest()
    request.id = filialeId

    Poster.postMember<RemoteResult<Filiale>>(
      RestPaths.filialeDetails,
      request
    ).then((response) => {
      if (response.data.success) {
        this.filiale = response.data.payload
      }
    })

    Poster.postMember<RemoteResult<TischList>>(RestPaths.tischListe, request)
      .then((response) => {
        if (response.data.success) {
          this.tische = response.data.payload.list
        } else {
          this.loadError = true
        }
      })
      .catch(() => {
        this.loadError = true
      })

    this.neueTische.push(new TischNeu())
  }

  tischSpeichern() {
    this.editWorking = true
    this.editError = false
    this.editFormDisabled = true

    this.aktuellerTisch.tischnummer = this.editTisch.tischnummer
    this.aktuellerTisch.plaetze = this.editTisch.plaetze

    var request = new TischUpdateRequest()
    request.id = this.aktuellerTisch.id
    request.bezeichnung = this.aktuellerTisch.tischnummer
    request.personen = this.aktuellerTisch.plaetze

    Poster.postMember<RemoteResult<unknown>>(RestPaths.tischSpeichern, request)
      .then((result) => {
        this.editWorking = false
        if (result.data.success) {
          this.editDialogVisible = false
          this.editTisch = null
          this.aktuellerTisch = null
          this.editFormDisabled = false
        } else {
          this.editFormDisabled = false
          this.editError = true
        }
      })
      .catch(() => {
        this.editWorking = false
        this.editFormDisabled = false
        this.editError = true
      })
  }

  tischeEinfuegen() {
    this.insertWorking = true
    this.insertError = false
    this.insertFormDisabled = true

    var request = new TischInsertListeRequest()
    request.filialeId = this.filiale.id

    this.neueTische.forEach((element) => {
      var tisch = new TischRequest()
      tisch.id = uuidv4()
      tisch.bezeichnung = element.bezeichnung
      tisch.personen = element.personen
      tisch.onlineReservierbar = element.onlineReservierbar
      request.tische.push(tisch)
    })

    Poster.postMember<RemoteResult<any>>(RestPaths.tischEinfuegenListe, request)
      .then((response) => {
        this.insertWorking = false
        this.insertFormDisabled = false
        if (response.data.success) {
          this.neuDialogVisible = false
        } else {
          this.insertError = true
          this.insertFormDisabled = false
        }
      })
      .catch(() => {
        this.insertWorking = false
        this.insertFormDisabled = false
        this.insertError = true
      })
  }

  removeTisch(index) {
    this.neueTische.splice(index, 1)
  }

  weitererTisch() {
    this.neueTische.push(new TischNeu())
  }

  neuerTisch() {
    this.neuDialogVisible = true
  }

  reihenfolgeFestlegen() {
    this.reihenfolgeDialogVisible = true
  }

  reihenfolgeSpeichern() {
    var request = new TischReihenfolgeUpdateRequest()
    request.filialeId = this.filiale.id

    this.tische.forEach((tisch, index) => {
      var r = new TischReihenfolge()
      r.id = tisch.id
      r.index = index
    })

    Poster.postMember<RemoteResult<any>>(
      RestPaths.tischUpdateReihenfolge,
      request
    ).then((response) => {
      if (response.data.success) {
        this.reihenfolgeDialogVisible = false
      }
    })
  }

  reihenfolgeAbbrechen() {
    this.reihenfolgeDialogVisible = false
  }

  tischAnzeigen(tisch: Tisch) {
    router.push({
      name: RouteNames.MEMBER.TISCH_DETAILS,
      params: { id: tisch.id },
    })
  }

  tischBearbeiten(tisch: Tisch, event) {
    this.aktuellerTisch = tisch
    this.editTisch = new Tisch()
    this.editTisch.plaetze = tisch.plaetze
    this.editTisch.tischnummer = tisch.tischnummer
    this.editDialogVisible = true
    event.stopPropagation()
  }
}

class TischNeu {
  bezeichnung: string = ''
  personen: number = 2
  onlineReservierbar: boolean = true
}
