
import Vue from 'vue'
import Component from 'vue-class-component'
import FilialeHeader from '../custom/FilialeHeader.vue'
import ServiceAnfordernLogic from '@/logic/bestellung/service_anfordern_logic'

@Component({ components: { FilialeHeader } })
export default class ServiceAnfordern extends Vue {
  logic?: ServiceAnfordernLogic = null

  mounted() {
    this.logic = new ServiceAnfordernLogic()
    this.logic.init()
  }

  absenden() {
    this.logic.absenden()
  }
}
