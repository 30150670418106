
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import FilialeHeader from '../custom/FilialeHeader.vue'
import WarenkorbArtikel from './sub/WarenkorbArtikel.vue'
import NichtGueltig from '../custom/NichtGueltig.vue'
import WarenkorbLogic from '@/logic/bestellung_neu/warenkorb_logic'

@Component({ components: { FilialeHeader, WarenkorbArtikel, NichtGueltig } })
export default class Warenkorb extends mixins(Calculator) {
  logic: WarenkorbLogic = new WarenkorbLogic()

  goToShopping() {
    this.logic.goToShopping()
  }

  goToBestaetigen() {
    this.logic.goToBestaetigen()
  }

  increment(artikel) {
    this.logic.increment(artikel)
  }

  decrement(artikel) {
    this.logic.decrement(artikel)
  }

  remove(artikel) {
    this.logic.remove(artikel)
  }

  mounted() {
    var isNeueBestellung = this.$store.state.isNeueBestellung
    if (!isNeueBestellung) return

    this.logic = new WarenkorbLogic()
    this.logic.init()
  }
}
