
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import moment from 'moment'
import { AbholzeitType } from '@/entities/common/enums'
import FilialeHeader from '../custom/FilialeHeader.vue'
import NichtGueltig from '../custom/NichtGueltig.vue'
import TerminAbholungLogic from '@/logic/bestellung_neu/termin_abholung_logic'

@Component({ components: { FilialeHeader, NichtGueltig } })
export default class TerminSelect extends Vue {
  logic?: TerminAbholungLogic = null

  dateMenuVisible: boolean = false
  timeMenuOpen: boolean = false

  abholtypes = [
    { label: 'Schnellstmöglich', value: AbholzeitType.BALDEST },
    { label: 'Termin wählen', value: AbholzeitType.UHRZEIT },
  ]

  get terminPanelVisible(): boolean {
    if (this.logic.selectedAbholType == AbholzeitType.UHRZEIT) return true
    return false
  }

  formatSelectedDate(): string {
    if (this.logic.selectedDate == null) return null
    return this.formatDate(this.logic.selectedDate)
  }

  formatDate(date: string): string {
    moment.locale('de')
    return moment(date).format('LL')
  }

  allowedDates(val) {
    let result = false
    this.logic.geoeffneteTage.forEach((toCompare) => {
      if (toCompare.datum == val) {
        result = true
      }
    })
    return result
  }

  terminAuswaehlen() {
    this.logic.terminAuswaehlen()
  }

  mounted() {
    this.logic = new TerminAbholungLogic()
    this.logic.init()
  }
}
