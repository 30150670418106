
import { AbholungData } from '@/util/neue-bestellung-data'
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import { Prop, PropSync } from 'vue-property-decorator'
import Bestellung from '@/entities/common/bestellung'
import BestaetigenLogic from '@/logic/bestellung_neu/bestaetigen_logic'
import { Verzehrart } from '@/entities/common/enums'

@Component
export default class BestaetigenAbholung extends mixins(Calculator) {
  @PropSync('data')
  abholung: AbholungData

  @Prop()
  logic: BestaetigenLogic

  berechneVerzehrart() {
    switch (this.abholung.verzehrart) {
      case Verzehrart.MITNAHME:
        return 'Verzehr im Restaurant'
      case Verzehrart.MITNAHME:
        return 'Zum Mitnehmen'
    }
  }

  berechneTisch() {
    if (this.abholung.tischBezeichnung) {
      return this.abholung.tischBezeichnung
    }
    return '---'
  }

  mounted() {}
}
