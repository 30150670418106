export default class FilialeLiefergebieteInsertRequest {
  filialeId?: string
  gebiete: Gebiet[] = []
}

class Gebiet {
  bezeichnung?: string
  lieferkosten?: number
  lieferzeitVon?: number
  lieferzeitBis?: number
  mindestbestellwert?: number
  kostenlosAb?: number
  priority?: number
  gebiete: Einzelgebiet[] = []
  beschreibung?: string
}

class Einzelgebiet {
  punkte: Einzelpunkt[] = []
}

class Einzelpunkt {
  latitude?: number
  longitude?: number
}

export{Gebiet, Einzelgebiet, Einzelpunkt}
