
import Component, { mixins } from 'vue-class-component'
import 'vue-select/dist/vue-select.css'
import Calculator from '../mixins/Calculator.vue'
import FilialeHeader from '../custom/FilialeHeader.vue'
import OeffnungLabel from './sub/OeffnungLabel.vue'
import BestellungFiliale from '@/entities/common/bestellung_filiale'
import FilialeSelectLogic from '@/logic/bestellung_neu/filiale_select_logic'

@Component({ components: { FilialeHeader, OeffnungLabel } })
export default class FilialeSelect extends mixins(Calculator) {
  logic: FilialeSelectLogic = null
  noImage = '/img/no_image.png'

  connect() {
    this.positionErmitteln()
  }

  mounted() {
    this.logic = new FilialeSelectLogic()
    this.logic.init()
  }

  berechneGeoeffnet(filiale: BestellungFiliale) {
    return this.logic.berechneGeoeffnet(filiale)
  }

  formatDistance(meters?: number) {
    return this.logic.formatDistance(meters)
  }

  computeName(value?: BestellungFiliale) {
    if (value) return value.ort
  }

  berechneBildId(filiale: BestellungFiliale) {
    return this.logic.berechneBildId(filiale)
  }

  lieferung(filiale: BestellungFiliale) {
    this.logic.lieferung(filiale)
  }

  abholung(filiale: BestellungFiliale) {
    this.logic.abholung(filiale)
  }

  reservierung(filiale: BestellungFiliale) {
    this.logic.reservierung(filiale)
  }

  sofortbestellung(filiale: BestellungFiliale) {
    this.logic.sofortbestellung(filiale)
  }

  searchNearest() {
    this.logic.searchNearestByLocation()
  }

  positionErmitteln() {
    this.logic.positionErmitteln(navigator)
  }

  detailsAnzeigen(filiale: BestellungFiliale) {
    this.logic.detailsAnzeigen(filiale)
  }
}
