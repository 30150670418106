export default class OeffnungszInsertRequest {
  id?: string
  filialeId?: string
  datum?: string
  geoeffnet?: boolean
  oeffnungszeiten: ZeitraumData[] = []
  abholzeiten: ZeitraumData[] = []
}

class ZeitraumData {
  zeitVon?: string
  zeitBis?: string
}

export { ZeitraumData }
