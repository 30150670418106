
import UserInfo from '@/store/user_info'
import Vue from 'vue'
import store from '@/store'
import Component from 'vue-class-component'

@Component
export default class Account extends Vue {
  userInfo: UserInfo = null
  showPasswortDialog: boolean = false
  showPasswort: boolean = false

  neuesPasswort: string = null
  neuesPasswortWiederholen: string = null

  passwortAendern() {
    this.showPasswortDialog = true
  }

  mounted() {
    this.userInfo = store.state.user
  }
}
