
import Bestellung from '@/entities/common/bestellung'
import Component, { mixins } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import Calculator from '../mixins/Calculator.vue'
import { AbholungStatus, LieferungStatus } from '@/entities/common/enums'
import BestellungSimple from '@/entities/member/bestellung_simple'

@Component
export default class BestellverwaltungRow extends mixins(Calculator) {
  @Prop() bestellung: BestellungSimple
  @Prop() color: any

  @Emit('detailsAnzeigen')
  detailsAnzeigen() {}

  formatAbholungStatus(status: string): string {
    if (status == null) return null
    switch (status) {
      case AbholungStatus.ABGESENDET:
        return 'noch nicht angenommen'
      case AbholungStatus.ABGELEHNT:
        return 'abgelehnt'
      case AbholungStatus.IN_BEARBEITUNG:
        return 'in Zubereizung'
      case AbholungStatus.ABHOLBEREIT:
        return 'abholbereit'
      case AbholungStatus.ANGENOMMEN:
        return 'angenommen'
      case AbholungStatus.ABGEHOLT:
        return 'abgeholt'
      case AbholungStatus.NICHT_ABGEHOLT:
        return 'nicht abgeholt'
    }
  }

  formatLieferungStatus(status: string): string {
    if (status == null) return null
    switch (status) {
      case LieferungStatus.ABGESENDET:
        return 'noch nicht angenommen'
      case LieferungStatus.ABGELEHNT:
        return 'abgelehnt'
      case LieferungStatus.ANGENOMMEN:
        return 'angenommen'
      case LieferungStatus.IN_ZUBEREITUNG:
        return 'in Zubereitung'
      case LieferungStatus.ZUBEREITET:
        return 'zubereitet'
      case LieferungStatus.IN_LIEFERUNG:
        return 'in Lieferung'
      case LieferungStatus.GELIEFERT:
        return 'geliefert'
    }
  }
}
