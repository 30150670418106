import RemoteResult from "@/entities/common/remote_result"
import IdRequest from "@/entities/common/request/id_request"
import FilialeSimple from "@/entities/member/filiale_simple"
import FilialeSimpleList from "@/entities/member/lists/filiale_simple_list"
import router from "@/router"
import store from "@/store"
import Poster from "@/util/poster"
import RestPaths from "@/util/rest_paths"
import RouteNames from "@/util/route_names"
import OeffnungszeitenMemcommonLogic from "../memcommon/oeffnungszeiten_memcommon_logic"

export default class OeffnungszeitenLogic extends OeffnungszeitenMemcommonLogic {
  private _filialen: FilialeSimple[]
  
  replaceFiliale(id: string): void {
        router.replace(
    {
      name: RouteNames.MEMBER.OEFFNUNGSZEITEN,
      query: { id: id },
    })
  }
  
  get filialen(): FilialeSimple[] {
    return this._filialen
  }

  set filialen(value: FilialeSimple[]) {
    this._filialen = value
  }

  ausnahmenHinzufuegen(): void {
    router.push({
      name: RouteNames.MEMBER.OEFFNUNGSZEIT_AUSNAHMEN,
    })
  }

  zeigeDetails(oeffnungszeit): void {
    router.push({
      name: RouteNames.MEMBER.OEFFNUNGSZEIT_DETAILS,
      params: { id: oeffnungszeit.id },
    })
  }

  neueOeffnungszeit(): void {
    router.push({
      name: RouteNames.MEMBER.OEFFNUNGSZEIT_NEU,
      params: { id: this.selectedFiliale },
    })
  }

  init(id: string) {
    var request = new IdRequest()
    request.id = store.state.user.betriebId

    Poster.postMember<RemoteResult<FilialeSimpleList>>(
      RestPaths.filialeListeSimple,
      request
    ).then((response) => {
      this.filialen = response.data.payload.list
      this.selectedFiliale = id
    })
  }
}