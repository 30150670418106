
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ProdukteLogic from '@/logic/member/produkte_logic'
import ProdukteMemCommon from '@/components/memcommon/ProdukteMemCommon.vue'
import store from '@/store'

@Component({ components: { ProdukteMemCommon } })
export default class Produkte extends Vue {
  logic?: ProdukteLogic = null

  mounted() {
    this.logic = new ProdukteLogic()
    this.logic.betriebId = store.state.user.betriebId
    this.logic.init()
  }
}
