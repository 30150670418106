import Inhaltsstoff from './inhaltsstoff'

export default class BstlArtikel {
  id = ''
  produkt?: Produkt = new Produkt()
  kommentare?: string = ''
  anzahl?: number = 0
  extras: Extra[] = []
}

class Extra {
  id?: string
  preislisteExtraId?: string
  bezeichnung?: string
  beschreibung?: string
  artikelNr?: string
  bildId?: string
  vegetarisch: boolean = false
  vegan: boolean = false
  preis?: number
  // produkt: Produkt = new Produkt()
}

class Auswahl {
  id?: string
  preislisteAuswahlId?: string
  optionId?: string
  bezeichnung?: string
  beschreibung?: string
  option: Extra = new Extra()
}

class Subprodukt {
  id?: string
  preislisteSubproduktId?: string
  optionId?: string
  bezeichnung?: string
  beschreibung?: string
  produkt: Produkt = new Produkt()
}

class Produkt {
  preislisteProduktId?: string
  bezeichnung?: string
  beschreibung?: string
  artikelNr?: string
  inhaltsstoffe?: Inhaltsstoff[]
  bildId?: string
  preis?: number
  extras?: Extra[] = []
  auswahlen?: Auswahl[] = []
  subprodukte?: Subprodukt[] = []
}

export {Extra, Auswahl, Produkt, Subprodukt}