import Inhaltsstoff from "@/entities/common/inhaltsstoff"
import RemoteResult from "@/entities/common/remote_result"
import IdRequest from "@/entities/common/request/id_request"
import InhaltsstoffList from "@/entities/member/lists/inhaltsstoff_list"
import InhaltsstoffInsertRequest from "@/entities/member/request/inhaltsstoff_insert_request"
import InhaltsstoffUpdateRequest from "@/entities/member/request/inhaltsstoff_update_request"
import Poster from "@/util/poster"
import RestPaths from "@/util/rest_paths"
import {v4 as uuidv4} from 'uuid'
import TischeLogic from "../member/tische_logic"
import InhaltsstoffeMemcommonLogic from "../memcommon/inhaltsstoffe_memcommon_logic"
import BetriebDetailsLogic from "./betrieb_details_logic"

export default class InhaltsstoffeLogic extends InhaltsstoffeMemcommonLogic {
  parent?: BetriebDetailsLogic

  // inhaltsstoffe?: Inhaltsstoff[] = []
  // inhaltsstoff: Inhaltsstoff = new Inhaltsstoff()
  // neuerInhaltsstoff: Inhaltsstoff = new Inhaltsstoff()
  // editDialogOpen: boolean = false
  // newDialogOpen: boolean = false
  // editInhaltsstoff: Inhaltsstoff = null

  // bezeichnungRules = [(v) => !!v || 'Bitte Bezeichnung angeben']

  // einfuegenFormDisabled: boolean = false
  // einfuegenError: boolean = false

  // speichern() {
  //   this.inhaltsstoff.bezeichnung = this.editInhaltsstoff.bezeichnung
  //   this.inhaltsstoff.beschreibung = this.editInhaltsstoff.beschreibung

  //   var request = new InhaltsstoffUpdateRequest()
  //   request.id = this.inhaltsstoff.id
  //   request.bezeichnung = this.inhaltsstoff.bezeichnung
  //   request.beschreibung = this.inhaltsstoff.beschreibung

  //   Poster.postMember(
  //     RestPaths.urlBase + RestPaths.inhaltsstoffeUpdate,
  //     request
  //   ).then(() => {
  //     this.editDialogOpen = false
  //     this.editInhaltsstoff = null
  //     this.inhaltsstoff = null
  //   })
  // }

  // einfuegen() {
  //   this.einfuegenFormDisabled = true
  //   this.einfuegenError = false

  //   var request = new InhaltsstoffInsertRequest()
  //   request.id = uuidv4()
  //   request.betriebId = this.parent.betrieb.id
  //   request.bezeichnung = this.neuerInhaltsstoff.bezeichnung
  //   request.beschreibung = this.neuerInhaltsstoff.beschreibung

  //   Poster.postMember<RemoteResult<Inhaltsstoff>>(
  //     RestPaths.urlBase + RestPaths.inhaltsstoffeInsert,
  //     request
  //   ).then((response) => {
  //     if(response.data.success) {
  //       this.newDialogOpen = false
  //     } else {
  //       this.einfuegenError = true
  //     }
  //   })
  // }

  // bearbeiten(inhaltsstoff: Inhaltsstoff) {
  //   this.inhaltsstoff = inhaltsstoff
  //   this.editInhaltsstoff = new Inhaltsstoff()
  //   this.editInhaltsstoff.bezeichnung = inhaltsstoff.bezeichnung
  //   this.editInhaltsstoff.beschreibung = inhaltsstoff.beschreibung
  //   this.editDialogOpen = true
  // }

  // neuenErstellen() {
  //   this.inhaltsstoff = new Inhaltsstoff()
  //   this.newDialogOpen = true
  // }

  // init() {
  //   var request = new IdRequest()
  //   request.id = this.parent.betrieb.id

  //   Poster.postMember<RemoteResult<InhaltsstoffList>>(
  //     RestPaths.urlBase + RestPaths.inhaltsstoffeListe,
  //     request
  //   ).then((response) => {
  //     this.inhaltsstoffe = response.data.payload.list
  //   })
  // }
}