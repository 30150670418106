
import Vue from 'vue'
import Component from 'vue-class-component'
import NewPreislisteEditMemCommon from '../memcommon/NewPreislisteEditMemCommon.vue'
import NewPreislisteEditLogic from '@/logic/system/new_preisliste_edit_logic'
import BetriebDetails from './BetriebDetails.vue'

@Component({ components: { NewPreislisteEditMemCommon } })
export default class NewPreislisteEdit extends Vue {
  logic?: NewPreislisteEditLogic = null

  mounted() {
    var betriebId = (this.$parent as BetriebDetails).logic.betrieb.id
    var preislisteId = this.$route.params.id

    this.logic = new NewPreislisteEditLogic()
    this.logic.init(preislisteId, betriebId)
  }
}
