
import { Component, Prop, PropSync } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import ToolbarButton from '../custom/ToolbarButton.vue'
import OeffnungszeitenMemcommonLogic from '@/logic/memcommon/oeffnungszeiten_memcommon_logic'

@Component({ components: { ToolbarButton } })
export default class OeffnungszeitenMemCommon extends mixins(Calculator) {
  @Prop() logic: OeffnungszeitenMemcommonLogic

  ausnahmenHinzufuegen() {
    this.logic.ausnahmenHinzufuegen()
  }

  zeigeDetails(oeffnungszeit) {
    this.logic.zeigeDetails(oeffnungszeit)
  }

  neueOeffnungszeit() {
    this.logic.neueOeffnungszeit()
  }
}
