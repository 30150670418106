
import MitarbeiterDetailsLogic from '@/logic/member/mitarbeiter_details_logic'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import MitarbeiterDetailsMemCommon from '@/components/memcommon/MitarbeiterDetailsMemCommon.vue'

@Component({components: {MitarbeiterDetailsMemCommon}})
export default class MitarbeiterDetails extends Vue {
  logic?: MitarbeiterDetailsLogic = null

  mounted() {
    this.logic = new MitarbeiterDetailsLogic()
    this.logic.init(this.$route.params.id)
  }
}
