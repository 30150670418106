import store from '@/store'
import UserInfo from '@/store/user_info'
import axios, { AxiosResponse } from 'axios'
import RestPaths from './rest_paths'

export default class Poster {
  static postMember<T>(path: string, request: object): Promise<AxiosResponse<T, any>> {
    var user = store.state.user as UserInfo
    return axios.post<T>(RestPaths.urlBase + path, request, {
      headers: {
        'Content-Type': RestPaths.applicationJson,
        loadKey: user.key,
      },
    })
  }

  static postUser<T>(path: string, request: object): Promise<AxiosResponse<T, any>> {
    var user = store.state.user as UserInfo
    return axios.post<T>(RestPaths.urlBase + path, request, {
      headers: {
        'Content-Type': RestPaths.applicationJson,
        loadKey: user.key,
      },
    })    
  }
}