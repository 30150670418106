import LoginResult from '@/entities/common/login_result'
import RemoteResult from '@/entities/common/remote_result'
import LoginRequest from '@/entities/common/request/login_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import store from '@/store'
import router from '@/router'
import StoreOps from '@/util/store_ops'

export default class LoginLogic {
  passwort?: string = null
  username?: string = null
  passwortVisible: boolean = false
  loginError: boolean = false

  init() {}

  login() {
    this.loginError = false

    var request = new LoginRequest()
    request.username = this.username
    request.password = this.passwort

    Poster.postUser<RemoteResult<LoginResult>>(
      RestPaths.login,
      request
    ).then((response) => {
      if (response.data.payload.success) {
        store.commit(StoreOps.LOGIN_MEMBER, response.data.payload)
        router.go(-1)
      } else {
        this.loginError = true
      }
    })
  }

  passwortVergessen() {}
}
