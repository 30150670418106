
import ProdukteLogic from '@/logic/system/produkte_logic'
import Vue from 'vue'
import Component from 'vue-class-component'
import BetriebDetails from './BetriebDetails.vue'
import ProdukteMemCommon from '@/components/memcommon/ProdukteMemCommon.vue'

@Component({ components: { ProdukteMemCommon} })
export default class Produkte extends Vue {
  logic?: ProdukteLogic = null

  mounted() {
    this.logic = new ProdukteLogic()
    this.logic.betriebId=(this.$parent as BetriebDetails).logic.betrieb.id
    this.logic.init()
  }
}
