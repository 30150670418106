
import PreislistenLogic from '@/logic/system/preislisten_logic'
import Component from 'vue-class-component'
import BetriebDetails from './BetriebDetails.vue'
import PreislistenMemCommon from '../memcommon/PreislistenMemCommon.vue'
import Vue from 'vue'

@Component({ components: { PreislistenMemCommon }})
export default class Preislisten extends Vue {
  logic?: PreislistenLogic = null

  mounted() {
    this.logic = new PreislistenLogic()
    this.logic.init(
      (this.$parent as BetriebDetails).logic,
      this.$route.query.id as string
    )
  }
}
