
import { mixins } from 'vue-class-component'
import { Component, Prop } from 'vue-property-decorator'
import Calculator from '../mixins/Calculator.vue'
import OeffnungszeitDetailsLogic from '@/logic/member/oeffnungszeit_details_logic'
import ToolbarButton from '../custom/ToolbarButton.vue'

@Component({ components: { ToolbarButton } })
export default class OeffnungszeitDetailsMemCommon extends mixins(Calculator) {
  @Prop() logic: OeffnungszeitDetailsLogic

  listeBearbeiten() {
    this.logic.listeBearbeiten()
  }

  ausnahmenHinzufuegen() {
    this.logic.ausnahmenHinzufuegen()
  }

  formatWochentag(wochentag) {
    if (wochentag === undefined) return null
    switch (wochentag) {
      case 1:
        return 'Montag'
      case 2:
        return 'Dienstag'
      case 3:
        return 'Mittwoch'
      case 4:
        return 'Donnerstag'
      case 5:
        return 'Freitag'
      case 6:
        return 'Samstag'
      case 7:
        return 'Sonntag'
    }
    return null
  }
}
