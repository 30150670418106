
import { Component, Prop, PropSync } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import PreislisteDetailsMemcommonLogic from '@/logic/memcommon/preisliste_details_memcommon_logic'
import ToolbarButton from '../custom/ToolbarButton.vue'
import PreislisteProduktRow from '../custom/PreislisteProduktRow.vue'

@Component({ components: { ToolbarButton, PreislisteProduktRow } })
export default class PreislisteDetailsMemCommon extends mixins(Calculator) {
  @Prop() logic: PreislisteDetailsMemcommonLogic

  aktivierenDialogVisible: boolean = false
  deaktivierenDialogVisible: boolean = false

  bearbeiten() {
    this.logic.bearbeiten()
  }

  aktivieren() {
    this.logic.aktivieren()
    this.aktivierenDialogVisible = false
  }

  deaktivieren() {
    this.logic.deaktivieren()
    this.deaktivierenDialogVisible = false
  }
}
