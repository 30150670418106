
import Vue from 'vue'
import Component from 'vue-class-component'
import BetriebDetails from './BetriebDetails.vue'
import KategorienLogic from '@/logic/system/kategorien_logic'
import KategorienMemCommon from '../memcommon/KategorienMemCommon.vue'

@Component({ components: { KategorienMemCommon } })
export default class Kategorien extends Vue {
  logic?: KategorienLogic = null

  mounted() {
    this.logic = new KategorienLogic()
    this.logic.betriebId = (this.$parent as BetriebDetails).logic.betrieb.id
    this.logic.init()
  }
}
