import LoginResult from '@/entities/common/login_result'
import RemoteResult from '@/entities/common/remote_result'
import LoginRequest from '@/entities/common/request/login_request'
import router from '@/router'
import store from '@/store'
import UserInfo from '@/store/user_info'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import StoreOps from '@/util/store_ops'
import { v4 as uuidv4 } from 'uuid'
import MemberBaseLogic from './member_base_logic'

export default class MemberPageLogic extends MemberBaseLogic {
  drawer: boolean = false
  websocket?: WebSocket
  accountMenuOpen?: boolean = false
  loginDialogOpen?: boolean = false

  username?: string = ''
  password?: string = ''

  loggedId?: boolean = false
  loginError?: boolean = false

  get isAdmin() {
    return store.state.user.admin
  }

  get user(): UserInfo {
    return store.state.user
  }

  init() {
    var user = store.state.user as UserInfo
    if (user == null || user.key == null) {
      store.commit(StoreOps.UPDATE_IDENTIFIER, uuidv4())
    }

    store.watch(
      (state) => state.user.loggedIn,
      (newValue) => {
        this.loggedId = newValue

        if (newValue != null) {
          this.websocket = new WebSocket(RestPaths.urlBaseWs + 'member')
          this.websocket.onopen = this.webserviceOpened
          this.websocket.onmessage = this.webserviceOnMessage
        }
      },
      { immediate: true, deep: true }
    )
  }

  logout() {
    store.commit(StoreOps.LOGOUT)
  }

  login() {
    var request = new LoginRequest()
    request.username = this.username
    request.password = this.password

    Poster.postUser<RemoteResult<LoginResult>>(RestPaths.login, request).then(
      (response) => {
        var resp = response.data.payload
        if (resp.success) {
          store.commit(StoreOps.LOGIN_MEMBER, response.data.payload)
          this.loginDialogOpen = false
          this.loginError = false
        } else {
          this.loginError = true
        }
      }
    ).catch(()=> {
      this.loginError = true
    })
  }

  showMemberLogin() {
    this.loginDialogOpen = true
  }

  navHome() {
    router.push({ name: RouteNames.NEU.HOME })
  }

  navBestellliste() {
    router.push({ name: RouteNames.MEMBER.BESTELLLISTE })
  }

  navService() {
    router.push({ name: RouteNames.MEMBER.SERVICE })
  }

  navKueche() {
    router.push({ name: RouteNames.MEMBER.KUECHE })
  }

  navLieferungen() {
    router.push({ name: RouteNames.MEMBER.LIEFERUNGEN })
  }

  navBestellungen() {
    router.push({ name: RouteNames.MEMBER.BESTELLUNGEN })
  }

  navReservierungen() {
    router.push({ name: RouteNames.MEMBER.RESERVIERUNGEN })
  }

  navAuswertungen() {
    router.push({ name: RouteNames.MEMBER.AUSWERTUNGEN })
  }

  navMitarbeiter() {
    router.push({ name: RouteNames.MEMBER.MITARBEITER })
  }

  navProdukte() {
    router.push({ name: RouteNames.MEMBER.PRODUKTE })
  }

  navVerfuegbarkeit() {
    router.push({ name: RouteNames.MEMBER.VERFUEGBARKEIT })
  }

  navInhaltsstoffe() {
    router.push({ name: RouteNames.MEMBER.INHALTSSTOFFE })
  }

  navFilialen() {
    router.push({ name: RouteNames.MEMBER.FILIALEN })
  }

  navPreislisten() {
    router.push({ name: RouteNames.MEMBER.PREISLISTEN })
  }

  navOeffnungszeiten() {
    router.push({ name: RouteNames.MEMBER.OEFFNUNGSZEITEN })
  }

  navTische() {
    router.push({ name: RouteNames.MEMBER.TISCHE })
  }

  webserviceOpened() {
    this.websocket?.send(store.state.user.key)
  }

  webserviceOnMessage(message: MessageEvent) {
    var parsedObject = JSON.parse(message.data)
    store.commit(StoreOps.POST_INCOMING_MESSAGE, parsedObject)
  }
}
