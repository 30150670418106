import Bestellung from '@/entities/common/bestellung'
import CheckoutOrder from '@/entities/common/checkout_order'
import RemoteResult from '@/entities/common/remote_result'
import CheckoutRequest from '@/entities/common/request/checkout_request'
import store from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'

export default class BezahlenLogic {
  bestellung?: Bestellung = null

  init() {
    this.bestellung = store.state.bestellung.bestellung
  }

  weiter() {
    var request = new CheckoutRequest()
    request.id = this.bestellung.id
    request.checkoutMode = 1

    Poster.postUser<RemoteResult<CheckoutOrder>>(
      RestPaths.paymentPaypalCheckout,
      request
    ).then((response) => {
      if (response.data.success) {
        window.location.href =
          'https://www.sandbox.paypal.com/checkoutnow?token=' +
          response.data.payload.orderId
      }
    })
  }
}
