import RemoteResult from '@/entities/common/remote_result'
import Mitarbeiter from '@/entities/member/mitarbeiter'
import router from '@/router'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'

export default class EigeneDatenLogic {
  eigeneDaten: Mitarbeiter = null

  init() {
    Poster.postMember<RemoteResult<Mitarbeiter>>(
      RestPaths.mitarbeiterEigeneDaten,
      {}
    ).then((response) => {
      this.eigeneDaten = response.data.payload
    })
  }

  passwortAendern() {
    router.push({ name: RouteNames.MEMBER.PASSWORT_AENDERN })
  }
}
