
import Vue from 'vue'
import Component from 'vue-class-component'
import BezahlenBestaetigtLogic from '@/logic/bestellung/bezahlen_bestaetigt_logic'
import FilialeHeader from '../custom/FilialeHeader.vue'

@Component({ components: { FilialeHeader } })
export default class BezahlenBestaetigt extends Vue {
  logic?: BezahlenBestaetigtLogic = null

  mounted() {
    this.logic = new BezahlenBestaetigtLogic()
    this.logic.init(this.$route.params.bestellungId)
  }

  zurBestellung() {
    this.logic.zurBestellung()
  }
}
