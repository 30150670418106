import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Mitarbeiter from '@/entities/member/mitarbeiter'
import router from '@/router'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import MitarbeiterDetailsMemcommonLogic from '../memcommon/mitarbeiter_details_memcommon_logic'

export default class MitarbeiterDetailsLogic extends MitarbeiterDetailsMemcommonLogic {
  // mitarbeiter?: Mitarbeiter = null

  bearbeiten() {
    router.push({
      name: RouteNames.SYSTEM.MITARBEITER_EDIT,
      params: { id: this.mitarbeiter.id },
    })
  }

  // aktivieren(completed: () => void) {
  //   var request = new IdRequest()
  //   request.id = this.mitarbeiter.id

  //   Poster.postMember(
  //     RestPaths.urlBase + RestPaths.mitarbeiterAktivieren,
  //     request
  //   ).then(() => {
  //     this.mitarbeiter.aktiv = true
  //     completed()
  //   })
  // }

  // deaktivieren(completed: () => void) {
  //   var request = new IdRequest()
  //   request.id = this.mitarbeiter.id

  //   Poster.postMember(
  //     RestPaths.urlBase + RestPaths.mitarbeiterDeaktivieren,
  //     request
  //   ).then(() => {
  //     this.mitarbeiter.aktiv = false
  //     completed()
  //   })
  // }

  // computePlzOrt() {
  //   if (!this.mitarbeiter.plz && !this.mitarbeiter.ort) return '---'
  //   return this.mitarbeiter.plz + ' ' + this.mitarbeiter.ort
  // }

  // init(id: string) {
  //   var request = new IdRequest()
  //   request.id = id

  //   Poster.postMember<RemoteResult<Mitarbeiter>>(
  //     RestPaths.urlBase + RestPaths.mitarbeiterDetailForEdit,
  //     request
  //   ).then((response) => {
  //     this.mitarbeiter = response.data.payload
  //   })
  // }
}
