import router from '@/router'
import RouteNames from '@/util/route_names'
import PreislisteDetailsMemcommonLogic from '../memcommon/preisliste_details_memcommon_logic'

export default class PreislisteDetailsLogic extends PreislisteDetailsMemcommonLogic {
  bearbeiten() {
    router.push({
      name: RouteNames.SYSTEM.PREISLISTE_EDIT,
      params: { id: this.preisliste.id },
    })
  }
}
