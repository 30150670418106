
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import FilialeInfoLogic from '@/logic/bestellung/filiale_info_logic'

@Component
export default class FilialeInfo extends mixins(Calculator) {
  logic: FilialeInfoLogic = new FilialeInfoLogic()

  mounted() {
    this.logic.init(this.$route.params.filialeId)
  }

  formatiereWochentag(index: number): string {
    switch (index) {
      case 1:
        return 'Montag'
      case 2:
        return 'Dienstag'
      case 3:
        return 'Mittwoch'
      case 4:
        return 'Donnerstag'
      case 5:
        return 'Freitag'
      case 6:
        return 'Samstag'
      case 7:
        return 'Sonntag'
    }
  }
}
