
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import ToolbarButton from '../custom/ToolbarButton.vue'
import { Emit, Model, Prop } from 'vue-property-decorator'
import { Kategorie } from '@/logic/memcommon/new_preisliste_edit_memcommon_logic'
import { PreislisteProdukt } from '@/logic/memcommon/new_preisliste_edit_memcommon_logic'
import PreislisteProduktEditContainer from './PreislisteProduktEditContainer.vue'
import Swapper from '@/util/swapper'

@Component({ components: { ToolbarButton, PreislisteProduktEditContainer } })
export default class PreislisteKategorieEditContainer extends mixins(
  Calculator
) {
  @Prop() kategorie: Kategorie

  @Model('expanded', { default: true }) expanded: boolean

  expandedSync = false

  @Prop({ default: true }) readonly headerVisible: boolean

  @Emit('produkteHinzufuegen')
  produkteHinzufuegen() {}

  @Emit('kategorieEntfernen')
  kategorieEntfernen() {}

  @Emit('kategorieBearbeiten')
  kategorieBearbeiten() {}

  @Emit('moveUp')
  moveUp() {}

  @Emit('moveDown')
  moveDown() {}

  @Emit('produktDeaktivieren')
  produktDeaktivieren(produkt: PreislisteProdukt) {
    return produkt
  }

  @Emit('produktAktivieren')
  produktAktivieren(produkt: PreislisteProdukt) {
    return produkt
  }

  expand() {
    this.expandedSync = true
  }

  collapse() {
    this.expandedSync = false
  }

  mounted() {
    this.expandedSync = this.expanded
  }

  moveProduktUp(index: number) {
    Swapper.instance.pushUp(this.kategorie.produkte, index)
  }

  moveProduktDown(index: number) {
    Swapper.instance.pushDown(this.kategorie.produkte, index)
  }
}
