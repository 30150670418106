
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import ArtikelRow from '../custom/ArtikelRow.vue'
import FilialeHeader from '../custom/FilialeHeader.vue'
import WarenkorbArtikel from '../bestellung-neu/sub/WarenkorbArtikel.vue'
import NachbestellungLogic from '@/logic/bestellung/nachbestellung_logic'
import Nachbestellung from './Nachbestellung.vue'

@Component({ components: { ArtikelRow, FilialeHeader, WarenkorbArtikel } })
export default class NachbestellungBestaetigen extends mixins(Calculator) {
  logic?: NachbestellungLogic = null

  mounted() {
    this.logic = (this.$parent as Nachbestellung).logic
  }

  backToOverview() {
    this.logic.backToOverview()
  }

  bestellungAufgeben() {
    this.logic.bestellungAufgeben()
  }
}
