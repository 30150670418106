
import Component from 'vue-class-component'
import ArtikelEdit from '@/components/custom/ArtikelEdit.vue'
import NachbestellungLogic from '@/logic/bestellung/nachbestellung_logic'
import BstlArtikel from '@/entities/common/bstl_artikel'
import Nachbestellung from './Nachbestellung.vue'
import Vue from 'vue'
import { Watch } from 'vue-property-decorator'
import Shopping from '../bestellung-neu/Shopping.vue'
import ShoppingLogic from '@/logic/bestellung_neu/shopping_logic'

@Component
export default class NachbestellungArtikelEdit extends Vue {
  logic?: NachbestellungLogic = null

  added(value: BstlArtikel) {
    this.logic.addArtikel(value)
  }

  @Watch('$parent.logic', { immediate: true, deep: false })
  watchParentLogic() {
    this.logic = (this.$parent as Nachbestellung).logic
    this.logic.createdListener = () => {
      if (this.logic.artikel == null) {
        this.logic.reloadArtikel(this.$route.params.id)
      }
    }
  }

  mounted() {
    this.logic = (this.$parent as Nachbestellung).logic
    this.logic.createdListener = () => {
      if (this.logic.artikel == null) {
        this.logic.reloadArtikel(this.$route.params.id)
      }
    }
  }
}
