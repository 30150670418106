
import Component from 'vue-class-component'
import OeffnungszeitNeuLogic from '@/logic/system/oeffnungszeit_neu_logic'
import OeffnungszeitNeuMemCommon from '../memcommon/OeffnungszeitNeuMemCommon.vue'
import Vue from 'vue'

@Component({components: {OeffnungszeitNeuMemCommon}})
export default class OeffnungszeitNeu extends Vue {
  logic?: OeffnungszeitNeuLogic = null

  mounted() {
    this.logic = new OeffnungszeitNeuLogic()
    this.logic.init(this.$route.params.id)
  }
}
