
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import FilialeDetailsLogic from '@/logic/member/filiale_details_logic'
import FilialeDetailsMemCommon from '../memcommon/FilialeDetailsMemCommon.vue'

@Component({ components: { FilialeDetailsMemCommon } })
export default class FilialeDetail extends Vue {
  logic?: FilialeDetailsLogic = null

  mounted() {
    this.logic = new FilialeDetailsLogic()
    this.logic.init(this.$route.params.id)
  }
}
