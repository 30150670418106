
import Vue from 'vue'
import Component from 'vue-class-component'
import { Model, Prop, PropSync } from 'vue-property-decorator'

@Component
export default class Expander extends Vue {
  @Prop() expanded: boolean
  expandedSync: boolean = false

  @PropSync('header')
  headerSync: string

  invert() {
    if(this.expandedSync) {
      this.expandedSync = false
    } else {
      this.expandedSync = true
    }
  }

  mounted() {
    this.expandedSync = this.expanded
  }
}
