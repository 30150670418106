
import Vue from 'vue'
import Component from 'vue-class-component'
import TischeFilialeMemCommon from '../memcommon/TischeFilialeMemCommon.vue'
import TischeFilialeLogic from '@/logic/member/tische_filiale_logic'
@Component({ components: { TischeFilialeMemCommon } })
export default class TischeFiliale extends Vue {
  logic?: TischeFilialeLogic = null

  mounted() {
    this.logic = new TischeFilialeLogic()
    this.logic.init(this.$route.params.filialeId)
  }
}
