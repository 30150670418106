import Vue from "vue";

export default class Swapper extends Vue {

  static instance: Swapper = new Swapper()

  pushUp(arr: any[], index: number) {
        if (index === 0) {
          return;
        }

        let priorIndex = index - 1;

        let itemCopy = {...arr[index]};
        let priorItemCopy = {...arr[priorIndex]};

        // Swap array position with prior element
        this.$set(arr, priorIndex, itemCopy);
        this.$set(arr, index, priorItemCopy);
    //this.swap(arr, index, 1)
  }

  pushDown(arr: any[], index: number) {
       if (index === arr.length - 1) {
          return;
        }

        let subsequentIndex = index + 1;

        let itemCopy = {...arr[index]};
        let subsequentItemCopy = {...arr[subsequentIndex]};

        // Swap array positions with subsequent element
        this.$set(arr, subsequentIndex, itemCopy);
        this.$set(arr, index, subsequentItemCopy);
  }

  private static swap(array, index, index2) {
    let rows = [array[index], array[index2]]
    array.splice(index, 1, rows[1], rows[0])
  }

  swapElements(array, index, index2) {}
}
