export default class TischReihenfolgeUpdateRequest {
  filialeId: string
  list: TischReihenfolge[] = []
}

class TischReihenfolge {
  id: string
  index: number
}

export {TischReihenfolge}