import Bestellung from '@/entities/common/bestellung'
import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import router from '@/router'
import store from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'

export default class BezahlenBestaetigtLogic {
  bestellung?: Bestellung = null
  completed: boolean = false
  loadError: boolean = false

  init(id: string) {
    this.bestellung = store.state.bestellung.bestellung

    var request = new IdRequest()
    request.id = id

    Poster.postUser<RemoteResult<Bestellung>>(
      RestPaths.bestellungKundeDetails,
      request
    ).then((response) => {
      if (response.data.success) {
        this.bestellung = response.data.payload

        this.bezahlungAbschliessen()
      }
    })
  }

  bezahlungAbschliessen() {
    var request = new IdRequest()
    request.id = this.bestellung.id

    Poster.postUser<RemoteResult<any>>(
      RestPaths.paymentPaypalAbschliessen,
      request
    )
      .then((response) => {
        if (response.data.success) {
          this.completed = true
          router.push({
            name: RouteNames.BESTELLUNG.DETAILS,
            params: { id: this.bestellung.id },
          })
        } else {
          this.loadError = true
        }
      })
      .catch(() => {
        this.loadError = true
      })
  }

  zurBestellung() {
    router.push({
      name: RouteNames.BESTELLUNG.DETAILS,
      params: { id: this.bestellung.id },
    })
  }
}
