import { AbholzeitType, Terminart } from '@/entities/common/enums'
import BestellungData from '@/util/bestellung_data'
import NachbestellungData from '@/util/nachbestellung-data'
import Nbd from '@/util/neue-bestellung-data'
import moment from 'moment'
import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import VuexPersist from 'vuex-persist'
import { v4 as uuidv4 } from 'uuid'
import LoginResult from '@/entities/common/login_result'
import UserInfo from './user_info'
import StoreOps from '@/util/store_ops'
import SocketMessage from '@/util/socket_message'
import BstlArtikel from '@/entities/common/bstl_artikel'
import Bestellung from '@/entities/common/bestellung'
import FilialeSimple from '@/entities/member/filiale_simple'
import TischMitFiliale from '@/entities/common/tisch_mit_filiale'

interface MangerooState {
  neueBestellungData: Nbd
  nachbestellungData: NachbestellungData
  bestellung: BestellungData
  incomingMessage: SocketMessage
  isNeueBestellung: boolean
  user: UserInfo
  standort: Standort
}

class Standort {
  bezeichnung?: string
  land?: string
  strasse?: string
  plz?: string
  ort?: string
  latitude?: number
  longitude?: number
}

Vue.use(Vuex)

var vuexStore: Store<MangerooState> = null

var cookiesEnabled = navigator.cookieEnabled

if (cookiesEnabled) {
  var vuexLocalStorage = new VuexPersist({
    key: 'vuex',
    storage: window.localStorage,
  })

  vuexStore = new Vuex.Store<MangerooState>({
    plugins: [vuexLocalStorage.plugin],
    state: (): MangerooState => ({
      neueBestellungData: new Nbd(),
      nachbestellungData: new NachbestellungData(),
      bestellung: new BestellungData(),
      incomingMessage: null,
      isNeueBestellung: false,
      user: new UserInfo(),
      standort: new Standort
    }),
    mutations: {
      [StoreOps.FILIALE_SELECT](state: MangerooState, data: FilialeSimple) {
        state.user.filiale = data
      },
      [StoreOps.LOGIN_KUNDE](state: MangerooState, data: LoginResult) {
        state.user.loggedIn = true
        state.user.vorname = data.vorname
        state.user.nachname = data.nachname
        state.user.username = data.username
        state.user.role = data.role
        state.user.key = data.key
        state.user.betriebId = data.betriebId
        state.user.admin = data.admin
        state.user.expires = data.expires
      },
      [StoreOps.LOGIN_MEMBER](state: MangerooState, data: LoginResult) {
        state.user.loggedIn = true
        state.user.vorname = data.vorname
        state.user.nachname = data.nachname
        state.user.username = data.username
        state.user.role = data.role
        state.user.key = data.key
        state.user.betriebId = data.betriebId
        state.user.admin = data.admin
        state.user.expires = data.expires
      },
      [StoreOps.LOGOUT](state: MangerooState) {
        state.user.loggedIn = false
        state.user.vorname = null
        state.user.nachname = null
        state.user.username = null
        state.user.role = null
        state.user.key = null
        state.user.betriebId = null
        state.user.admin = false
        state.user.expires = null
      },
      [StoreOps.POST_INCOMING_MESSAGE](
        state: MangerooState,
        payload: SocketMessage
      ) {
        state.incomingMessage = payload
      },
      [StoreOps.UPDATE_NEU_LIEFERUNG_TERMIN](state: MangerooState, payload) {
        var type = payload.type
        state.neueBestellungData.lieferungData.abholzeitType = type
        if (type == AbholzeitType.BALDEST) {
          state.neueBestellungData.lieferungData.datum =
            moment().format('yyyy-MM-DD')
          state.neueBestellungData.lieferungData.gewaehlteUhrzeit =
            moment().format('HH:mm:ss')
        } else if (type == AbholzeitType.UHRZEIT) {
          state.neueBestellungData.lieferungData.datum = payload.datum
          state.neueBestellungData.lieferungData.gewaehlteUhrzeit =
            payload.uhrzeit
        }
      },
      [StoreOps.UPDATE_NEU_ABHOLUNG_TERMIN](state: MangerooState, payload) {
        var type = payload.type
        console.log('The type is: ' + type)
        state.neueBestellungData.abholungData.abholzeitType = type
        if (type == AbholzeitType.BALDEST) {
          state.neueBestellungData.abholungData.datum =
            moment().format('yyyy-MM-DD')
          state.neueBestellungData.abholungData.gewaehlteUhrzeit =
            moment().format('yyyy-MM-DDTHH:mm')
        } else if (type == AbholzeitType.UHRZEIT) {
          state.neueBestellungData.abholungData.datum = payload.datum
          state.neueBestellungData.abholungData.gewaehlteUhrzeit =
            payload.uhrzeit
        }
      },
      [StoreOps.UPDATE_NBD_ABH_VERZEHRART](state: MangerooState, payload) {
        state.neueBestellungData.abholungData.verzehrart = payload
      },
      [StoreOps.BESTELLUNG_STARTEN](state: MangerooState, payload) {
        state.neueBestellungData = new Nbd()
        state.neueBestellungData.terminart = payload.terminart
        state.neueBestellungData.filiale = payload.filiale
        state.neueBestellungData.bestellungId = payload.id
        state.isNeueBestellung = true

        if (payload.terminart == Terminart.ABHOLUNG) {
          if (
            !state.neueBestellungData.filiale.abholungSettings
              .supportsVorbestellung
          ) {
            state.neueBestellungData.abholungData.gewaehlteUhrzeit =
              moment().format('yyyy-MM-DDTHH:mm')
            state.neueBestellungData.abholungData.abholzeitType =
              AbholzeitType.BALDEST
          }
        }

        if (payload.terminart == Terminart.LIEFERUNG) {
          state.neueBestellungData.lieferungData.kundeStrasse = state.standort.strasse
          state.neueBestellungData.lieferungData.kundePlz = state.standort.plz
          state.neueBestellungData.lieferungData.kundeOrt = state.standort.ort
        }
      },
      [StoreOps.BESTELLUNG_DIREKT_STARTEN](state: MangerooState, payload) {
        state.neueBestellungData.bestellungId = payload.bestellungId
        state.neueBestellungData.terminart = Terminart.ABHOLUNG
        state.neueBestellungData.abholungData.datum =
          moment().format('yyyy-MM-DD')
        state.neueBestellungData.abholungData.gewaehlteUhrzeit =
          moment().format('yyyy-MM-DDTHH:mm')
        state.neueBestellungData.abholungData.abholzeitType =
          AbholzeitType.BALDEST
        state.neueBestellungData.filiale = payload.filiale
        state.neueBestellungData.abholungData.direktabholung = true
        state.isNeueBestellung = true
      },
      [StoreOps.BESTELLUNG_DIREKT_STARTEN_TISCH](
        state: MangerooState,
        payload
      ) {
        state.neueBestellungData.bestellungId = payload.bestellungId
        state.neueBestellungData.terminart = Terminart.ABHOLUNG
        state.neueBestellungData.abholungData.tischId = payload.tisch
        state.neueBestellungData.abholungData.tischBezeichnung =
          payload.tisch.tischnummer
        state.neueBestellungData.abholungData.datum =
          moment().format('yyyy-MM-DD')
        state.neueBestellungData.abholungData.gewaehlteUhrzeit =
          moment().format('yyyy-MM-DDTHH:mm')
        state.neueBestellungData.abholungData.abholzeitType =
          AbholzeitType.BALDEST
        state.neueBestellungData.filiale = payload.filiale
        state.neueBestellungData.abholungData.direktabholung = true
        state.isNeueBestellung = true
      },
      [StoreOps.REMOVE_ARTIKEL_BESTELLUNG_NEU](
        state: MangerooState,
        payload: BstlArtikel
      ) {
        var index = state.neueBestellungData.artikelliste.findIndex((x) => {
          x.id === payload.id
        })
        if (index > -1) {
          state.neueBestellungData.artikelliste.splice(index, 1)
        }
      },
      [StoreOps.BEGINNE_BESTELLUNG](state: MangerooState, payload: Bestellung) {
        state.bestellung = new BestellungData()
        state.bestellung.bestellung = payload
      },
      [StoreOps.BEGINNE_VORBESTELLUNG](
        state: MangerooState,
        payload: Bestellung
      ) {
        state.bestellung = new BestellungData()
        state.bestellung.bestellung = payload
      },
      [StoreOps.BEGINNE_NACHBESTELLUNG](
        state: MangerooState,
        payload: Bestellung
      ) {
        state.bestellung = new BestellungData()
        state.bestellung.bestellung = payload
      },

      [StoreOps.CLEAR_NEUE_BESTELLUNG](state: MangerooState) {
        state.neueBestellungData = new Nbd()
        state.isNeueBestellung = false
      },
      [StoreOps.UPDATE_BESTELLUNG](state: MangerooState, payload: Bestellung) {
        state.bestellung.bestellung = payload
      },
      [StoreOps.UPDATE_BESTELLUNG_ARTIKEL_BESTELLUNG](
        state: MangerooState,
        payload
      ) {
        state.bestellung.artikelliste = payload
      },
      [StoreOps.UPDATE_IDENTIFIER](state: MangerooState, payload: string) {
        state.user.identifier = payload
      },
      [StoreOps.UPDATE_NBD_ARTIKELLISTE](
        state: MangerooState,
        payload: BstlArtikel[]
      ) {
        state.neueBestellungData.artikelliste = payload
      },
      [StoreOps.UPDATE_NBD_RESERVIERUNG_TERMIN](state: MangerooState, payload) {
        const data = state.neueBestellungData.reservierungData
        data.anzahlPersonen = payload.personen
        data.datum = payload.datum
        data.tisch = payload.tisch
        data.zeitVon = payload.uhrzeitVon
        data.zeitBis = payload.uhrzeitBis
      },
      [StoreOps.UPDATE_NBD_RESERV_KUNDE](
        state: MangerooState,
        payload: string
      ) {
        state.neueBestellungData.reservierungData.datum = payload
      },
      [StoreOps.UPDATE_NBD_RESERV_KUNDE](state: MangerooState, payload: any) {
        var data = state.neueBestellungData.reservierungData
        data.kundeNachname = payload.kundeNachname
        data.kundeVorname = payload.kundeVorname
        data.kundeTelefon = payload.kundeTelefon
        data.kundeEmail = payload.kundeEmail
      },
      [StoreOps.UPDATE_NBD_LIEFERUNG_KUNDE](
        state: MangerooState,
        payload: any
      ) {
        var data = state.neueBestellungData.lieferungData
        data.kundeNachname = payload.kundeNachname
        data.kundeVorname = payload.kundeVorname
        data.kundeTelefon = payload.kundeTelefon
        data.kundeEmail = payload.kundeEmail
        data.kundeStrasse = payload.kundeStrasse
        data.kundePlz = payload.kundePlt
        data.kundeOrt = payload.kundeOrt
        data.kundeAdresszusatz = payload.kundeAdresszusatz
      },
      [StoreOps.UPDATE_NBD_ABH_KUNDE](state: MangerooState, payload) {
        state.neueBestellungData.abholungData.kundeNachname =
          payload.kundeNachname
        state.neueBestellungData.abholungData.kundeVorname =
          payload.kundeVorname
        state.neueBestellungData.abholungData.kundeTelefon =
          payload.kundeTelefon
        state.neueBestellungData.abholungData.kundeEmail = payload.kundeEmail
      },
      [StoreOps.UPDATE_NBD_SOF_TISCH](state: MangerooState, payload) {
        state.neueBestellungData.sofortbestellungData.tisch = payload
      },
      [StoreOps.UPDATE_QR_CODE_TISCH](state: MangerooState, payload) {
        state.neueBestellungData.bestellungId = payload.id
        state.neueBestellungData.terminart = Terminart.SOFORTBESTELLUNG
        state.neueBestellungData.filiale = payload.filialeTisch.filiale
        state.neueBestellungData.sofortbestellungData.tisch =
          payload.filialeTisch.tisch
      },
      [StoreOps.UPDATE_STANDORT](state: MangerooState, payload) {
        state.standort.bezeichnung = payload.bezeichnung
        state.standort.latitude = payload.latitude
        state.standort.longitude = payload.longitude
        state.standort.land = payload.land
        state.standort.plz = payload.plz
        state.standort.ort = payload.ort
        state.standort.strasse = payload.strasse
      }
    },
  })
}

export default vuexStore
