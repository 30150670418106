
import LoginLogic from '@/logic/system/login_logic'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class Login extends Vue {
  logic: LoginLogic = null

  login() {
    this.logic.login()
  }

  passwortVergessen() {}

  mounted() {
    this.logic = new LoginLogic()
    this.logic.init()
  }
}
