
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import MitarbeiterEditMemcommonLogic from '@/logic/memcommon/mitarbeiter_edit_memcommon_logic'
import ToolbarButton from '../custom/ToolbarButton.vue'
import ErrorBox from '../custom/ErrorBox.vue'
import { Prop, PropSync } from 'vue-property-decorator'

@Component({ components: { ToolbarButton, ErrorBox } })
export default class MitarbeiterEditMemCommon extends mixins(Calculator) {
  @Prop() logic: MitarbeiterEditMemcommonLogic

  goBack() {
    this.logic.goBack()
  }

  speichern() {
    this.logic.speichern()
  }
}
