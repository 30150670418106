
import { AnforderungStatus } from '@/entities/common/enums'
import Serviceanforderung from '@/entities/common/serviceanforderung'
import Component, { mixins } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import Calculator from '../mixins/Calculator.vue'

@Component
export default class ServiceRow extends mixins(Calculator) {
  @Prop() anforderung: Serviceanforderung
  duration: string = null
  intervalId: any = null

  @Watch('anforderung.status', { immediate: true })
  watchStatus() {
    if (this.anforderung.status == AnforderungStatus.ERLEDIGT) {
      clearInterval(this.intervalId)
    }
  }

  created() {
    this.berechneDuration()
    if (
      this.intervalId == null &&
      !(this.anforderung.status == AnforderungStatus.ERLEDIGT)
    ) {
      this.intervalId = setInterval(this.berechneDuration, 1000)
    }
  }

  beforeDestroy() {
    clearInterval(this.intervalId)
  }

  berechneDuration() {
    var dur = this.berechneZeitraum(this.anforderung.zeitpunkt)
    this.duration = dur.stringValue
  }

  @Emit('linkClicked')
  linkClicked() {}
}
