
import Vue from 'vue'
import Component from 'vue-class-component'
import moment from 'moment'
import { AbholzeitType } from '@/entities/common/enums'
import FilialeHeader from '../custom/FilialeHeader.vue'
import NichtGueltig from '../custom/NichtGueltig.vue'
import TerminLieferungLogic from '@/logic/bestellung_neu/termin_lieferung_logic'

@Component({ components: { FilialeHeader, NichtGueltig } })
export default class TerminLieferung extends Vue {
  logic?: TerminLieferungLogic = null

  dateMenuVisible: boolean = false
  timeMenuOpen: boolean = false

  get terminPanelVisible(): boolean {
    if (this.logic.selectedLieferType == AbholzeitType.UHRZEIT) return true
    return false
  }

  formatSelectedDate(): string {
    if (this.logic.selectedDate == null) return null
    return this.formatDate(this.logic.selectedDate)
  }

  formatDate(data: string): string {
    moment.locale('de')
    return moment(data).format('LL')
  }

  allowedDates(val) {
    let result = false
    this.logic.geoeffneteTage.forEach((toCompare) => {
      if (toCompare.datum == val) {
        result = true
      }
    })
    return result
  }

  terminAuswaehlen() {
    this.logic.terminAuswaehlen()
  }

  mounted() {
    this.logic = new TerminLieferungLogic()
    this.logic.init()
  }
}
