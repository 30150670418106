import OeffnungstagVorlage, {
  ZeitraumData as ZrData,
} from '@/entities/member/oeffnungstag_vorlage'
import OeffnungszeitZeitraum from '@/entities/member/oeffnungszeit_zeitraum'
import {
  VorlageData,
  ZeitraumData,
} from '@/entities/member/request/oeffnungszeit_zeitraum_update_request'
import OeffnungszeitZeitraumUpdateRequest from '@/entities/member/request/oeffnungszeit_zeitraum_update_request'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import RestPaths from '@/util/rest_paths'
import IdRequest from '@/entities/common/request/id_request'
import router from '@/router'
import MemberBaseLogic from '../member_base_logic'

export default class OeffnungszeitEditMemcommonLogic extends MemberBaseLogic {
  editFormDisabled?: boolean = false
  editCompleted: boolean = false
  editWorking: boolean = false
  editError: boolean = false

  oeffnungszeit?: OeffnungszeitZeitraum = null
  wochentage: OeffnungstagVorlage[] = []
  gueltigVon?: string = null
  gueltigBis?: string = null
  gueltigVonVisible: boolean = false
  gueltigBisVisible: boolean = false

  gueltigVonRule = [
    (v) => {
      if (!!!v) return 'Bitte Datum angeben'
      return false
    },
  ]

  zeitEntfernen(vorlage, index) {
    vorlage.oeffnungszeiten.splice(index, 1)
  }

  extraZeitHinzufuegen(vorlage: OeffnungstagVorlage) {
    vorlage.addOeffnungszeit()
  }

  speichern() {
    this.editFormDisabled = true
    this.editWorking = true
    this.editError = false

    var request = new OeffnungszeitZeitraumUpdateRequest()
    request.id = this.oeffnungszeit.id
    console.log('gueltig von: ' + this.gueltigVon)
    request.gueltigVon = this.gueltigVon
    request.gueltigBis = this.gueltigBis

    this.wochentage.forEach((vorlage) => {
      if (vorlage.geoeffnet) {
        var vorlageData = new VorlageData()
        vorlageData.wochentag = vorlage.wochentag

        vorlage.oeffnungszeiten.forEach((o) => {
          var zeitraumData = new ZeitraumData()
          zeitraumData.zeitVon = o.zeitVon
          zeitraumData.zeitBis = o.zeitBis
          vorlageData.oeffnungszeiten.push(zeitraumData)
        })
        request.wochentage.push(vorlageData)
      }
    })

    Poster.postMember<RemoteResult<boolean>>(
      RestPaths.oeffnungszeitZeitraumSpeichern,
      request
    )
      .then((response) => {
        this.editWorking = false
        if (response.data.success) {
          this.editCompleted = true
        } else {
          this.editError = true
          this.editFormDisabled = false
        }
      })
      .catch(() => {
        this.editWorking = false
        this.editFormDisabled = false
        this.editError = true
      })
  }

  navBack() {
    router.back()
  }

  gebeOeffnungszeitMin(vorlage: OeffnungstagVorlage, index: number): string {
    if (index == null) return null
    if (index > 0) return vorlage.oeffnungszeiten[index - 1].zeitBis
  }

  checkExtraButtonDisabled(vorlage: OeffnungstagVorlage, index: number) {
    var zrData = vorlage.oeffnungszeiten[vorlage.oeffnungszeiten.length - 1]
    if (zrData)
      if (zrData.zeitVon == null || zrData.zeitBis == null) return true
    return false
  }

  init(id: string) {
    var montag = new OeffnungstagVorlage(true)
    montag.wochentag = 1
    montag.bezeichnung = 'Montag'
    this.wochentage.push(montag)

    var dienstag = new OeffnungstagVorlage(true)
    dienstag.wochentag = 2
    dienstag.bezeichnung = 'Dienstag'
    this.wochentage.push(dienstag)

    var mittwoch = new OeffnungstagVorlage(true)
    mittwoch.wochentag = 3
    mittwoch.bezeichnung = 'Mittwoch'
    this.wochentage.push(mittwoch)

    var donnerstag = new OeffnungstagVorlage(true)
    donnerstag.wochentag = 4
    donnerstag.bezeichnung = 'Donnerstag'
    this.wochentage.push(donnerstag)

    var freitag = new OeffnungstagVorlage(true)
    freitag.wochentag = 5
    freitag.bezeichnung = 'Freitag'
    this.wochentage.push(freitag)

    var samstag = new OeffnungstagVorlage(true)
    samstag.wochentag = 6
    samstag.bezeichnung = 'Samstag'
    this.wochentage.push(samstag)

    var sonntag = new OeffnungstagVorlage(true)
    sonntag.wochentag = 7
    sonntag.bezeichnung = 'Sonntag'
    this.wochentage.push(sonntag)

    var request = new IdRequest()
    request.id = id

    Poster.postMember<RemoteResult<OeffnungszeitZeitraum>>(
      RestPaths.oeffnungszeitZeitraumDetails,
      request
    ).then((response) => {
      this.oeffnungszeit = response.data.payload

      this.gueltigVon = this.oeffnungszeit.gueltigVon
      this.gueltigBis = this.oeffnungszeit.gueltigBis

      this.oeffnungszeit.vorlagen.forEach((element) => {
        this.wochentage.forEach((vorlage) => {
          if (element.wochentag === vorlage.wochentag) {
            vorlage.geoeffnet = true
            element.oeffnungszeiten.forEach((oeffnungsz) => {
              var zeitraumData = new ZrData()
              zeitraumData.zeitVon = oeffnungsz.zeitVon
              zeitraumData.zeitBis = oeffnungsz.zeitBis
              vorlage.oeffnungszeiten.push(zeitraumData)
            })
          }
        })
      })
    })
  }
}
