
import RouteNames from '@/util/route_names'
import Vue from 'vue'
import Component from 'vue-class-component'
import BetriebDetails from './BetriebDetails.vue'
import ToolbarButton from '../custom/ToolbarButton.vue'
import BetriebPageLogic from '@/logic/system/betrieb_page_logic'

@Component({ components: { ToolbarButton } })
export default class BetriebPage extends Vue {
  logic?: BetriebPageLogic = null

  mounted() {
    this.logic = new BetriebPageLogic()
    this.logic.parent = (this.$parent as BetriebDetails).logic
  }

  betriebBearbeiten() {
    this.logic.betriebBearbeiten()
  }
}
