
import { Vue, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import Produkte from '../custom/Produkte.vue'
import ReservierungVorbestellungLogic from '@/logic/bestellung_neu/reservierung_vorbestellung_logic'
import ReservierungVorbestellung from './ReservierungVorbestellung.vue'

@Component({ components: { Produkte } })
export default class ReservierungVorbestellungProdukte extends Vue {
  logic?: ReservierungVorbestellungLogic = null

  mounted() {
    this.logic = (this.$parent as ReservierungVorbestellung).logic
  }

  // @Watch('$parent.logic')
  // watchLogic(value) {
  //   this.logic = value
  // }
}
