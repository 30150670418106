
import { Component, PropSync } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import ToolbarButton from '../custom/ToolbarButton.vue'
import PreislistenMemcommonLogic from '@/logic/memcommon/preislisten_memcommon_logic'

@Component({ components: { ToolbarButton } })
export default class PreislistenMemCommon extends mixins(Calculator) {
  @PropSync('logic') logicSync: PreislistenMemcommonLogic

  kategorienVerwalten() {
    this.logicSync.kategorienVerwalten()
  }

  navNeu() {
    this.logicSync.navNeu()
  }

  detailsAnzeigen(newValue) {
    this.logicSync.detailsAnzeigen(newValue)
  }

  // mounted() {
  //   this.logicSync = new PreislistenLogic()
  //   this.logicSync.init(this.$route.query.id as string)
  // }
}
