import Bestellung from '@/entities/common/bestellung'
import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import LieferungStatusUpdateRequest from '@/entities/member/request/lieferung_status_update_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import { response } from 'express'

export default class BestellungLieferungLogic {
  private _bestellung?: Bestellung

  get bestellung(): Bestellung {
    return this._bestellung
  }

  set bestellung(newValue: Bestellung) {
    this._bestellung = newValue
  }

  init(bestellung: Bestellung) {
    this._bestellung = bestellung
  }

  updateStatus(status: string, finish?: () => void) {
    var request = new LieferungStatusUpdateRequest()
    request.id = this._bestellung.id
    request.lieferungStatus = status

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterStatusUpdateLieferung,
      request
    ).then((response) => {
      if(!response.data.success) {

      }
      this._bestellung.lieferung.lieferungStatus = status
      if(finish) {
        finish()
      }
    }).catch(() => {})
  }

  stornieren(finish?: () => void) {
    var request = new IdRequest()
    request.id = this._bestellung.id

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterStornieren,
      request
    ).then((response) => {
      if(response.data.success) {
        this._bestellung.storniert = true
        this._bestellung.abgeschlossen = true
        if(finish) {
          finish()
        }
      }
    })
  }
}
