
import { NachbestellungStatus } from '@/entities/common/enums'
import Nachbestellung from '@/entities/common/nachbestellung'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Calculator from '../mixins/Calculator.vue'
import ArtikelRow from './ArtikelRow.vue'

@Component({ components: { ArtikelRow } })
export default class NachbestellungSheet extends mixins(Calculator) {
  @Prop({ required: true }) nachbestellung: Nachbestellung

  formatiereStatus() {
    switch (this.nachbestellung.status) {
      case NachbestellungStatus.ANGENOMMEN:
        return 'angenommen'
      case NachbestellungStatus.BESTELLT:
        return 'offen'
      case NachbestellungStatus.ERLEDIGT:
        return 'erledigt'
    }
  }
}
