
import Component from 'vue-class-component'
import Produkte from '../custom/Produkte.vue'
import { Vue, Watch } from 'vue-property-decorator'
import NichtGueltig from '../custom/NichtGueltig.vue'
import ShoppingLieferungLogic from '@/logic/bestellung_neu/shopping_lieferung_logic'
import ShoppingLieferung from './ShoppingLieferung.vue'

@Component({
  name: 'ShoppingProdukteLieferung',
  components: { Produkte, NichtGueltig },
})
export default class ShoppingProdukteLieferung extends Vue {
  logic?: ShoppingLieferungLogic = null

  created() {
    this.logic = (this.$parent as ShoppingLieferung).logic
  }

  @Watch('$parent.logic')
  watchLogic(value) {
    this.logic = value
  }
}
