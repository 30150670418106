
import BetriebAnfrageDetailsLogic from '@/logic/system/betrieb_anfrage_details_logic'
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'

@Component
export default class BetriebAnfrageDetails extends mixins(Calculator) {
  logic?: BetriebAnfrageDetailsLogic = null

  mounted() {
    this.logic = new BetriebAnfrageDetailsLogic()
    this.logic.init(this.$route.params.id)
  }

  betriebAufnehmen() {
    this.logic.betriebAufnehmen()
  }
}
