
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import OeffnungszeitAusnahmenLogic from '@/logic/system/oeffnungszeit_ausnahmen_logic'
import BetriebDetails from './BetriebDetails.vue'
import OeffnungszeitAusnahmenMemCommon from '../memcommon/OeffnungszeitAusnahmenMemCommon.vue'

@Component({components: {OeffnungszeitAusnahmenMemCommon}})
export default class OeffnungszeitAusnahmen extends mixins(Calculator) {
  logic?: OeffnungszeitAusnahmenLogic = null

  mounted() {
    this.logic = new OeffnungszeitAusnahmenLogic()
    this.logic.parent = (this.$parent as BetriebDetails).logic
    this.logic.init()
  }
}
