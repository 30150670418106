import router from '@/router'
import RouteNames from '@/util/route_names'
import MitarbeiterMemcommonLogic from '../memcommon/mitarbeiter_memcommon_logic'

export default class MitarbeiterLogic extends MitarbeiterMemcommonLogic {
  detailsAnzeigen(id: string) {
    router.push({
      name: RouteNames.SYSTEM.MITARBEITER_DETAILS,
      params: { id: id },
    })
  }

  neuerMitarbeiter() {
    router.push({ name: RouteNames.SYSTEM.MITARBEITER_NEU })
  }
}
