import PlisteProduktZeit, {
  PreislisteProdukt,
} from '@/entities/common/pliste_produkt_zeit'
import ProdukteLogic from '@/logic/produkte_logic'
import store from '@/store'
import BstlArtikel from '@/entities/common/bstl_artikel'
import BestellungFiliale from '@/entities/common/bestellung_filiale'
import router from '@/router'
import RouteNames from '@/util/route_names'
import Formatter from '../formatter'
import StoreOps from '@/util/store_ops'
import PreislisteZeitraumRequest from '@/entities/common/request/preisliste_zeitpunkt_request'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import RestPaths from '@/util/rest_paths'
import BPArtikelHinzufuegenRequest from '@/entities/common/pending/bp_artikel_hinzufuegen_request'
import ConverterUtil from '@/util/converter_util'

export default class ShoppingLieferungLogic implements ProdukteLogic {
  get hasMindestbestellwert() {
    return (
      store.state.neueBestellungData.filiale.lieferungSettings
        .mindestbestellwert != 0
    )
  }

  get mindestbestellwert() {
    return store.state.neueBestellungData.filiale.lieferungSettings
      .mindestbestellwert
  }

  get kostenlosAb() {
    return store.state.neueBestellungData.filiale.lieferungSettings.kostenlosAb
  }

  preisliste: PlisteProduktZeit = null
  warenkorb: BstlArtikel[] = []
  filiale: BestellungFiliale = null
  created = false
  artikel: PreislisteProdukt = null

  artikelWaehlen(produkt: PreislisteProdukt): void {
    this.artikel = produkt
    router.push({
      name: RouteNames.NEU.SHOPPING_LIEFERUNG.ARTIKEL,
      params: { id: this.artikel.id },
    })
  }

  get warenkorbGefuellt(): boolean {
    var warenkorbpreis = Formatter.berechnePreisListe(this.warenkorb)
    if (warenkorbpreis - this.mindestbestellwert >= 0) return true
    return false
  }

  bestellungEinfuegen(): void {
    this.weiter()
  }

  weiter() {
    store.commit(StoreOps.UPDATE_NBD_ARTIKELLISTE, this.warenkorb)
    router.push({ name: RouteNames.NEU.WARENKORB_LIEFERUNG })
  }

  createdListener: () => void

  reloadArtikel(id: string) {
    this.preisliste.kategorien.forEach((kategorie) => {
      kategorie.produkte.forEach((p) => {
        if (p.id == id) {
          this.artikel = p
          return
        }
      })
    })
  }

  init() {
    var nbd = store.state.neueBestellungData
    this.filiale = nbd.filiale
    this.warenkorb = nbd.artikelliste
    var terminart = nbd.terminart
    var datum =
      nbd.lieferungData.datum + 'T' + nbd.lieferungData.gewaehlteUhrzeit

    var request = new PreislisteZeitraumRequest()
    request.filialeId = nbd.filiale.filialeId
    request.zeitpunkt = datum

    Poster.postUser<RemoteResult<PlisteProduktZeit>>(
      RestPaths.artikelPdukteNachZeitSingle,
      request
    ).then((response) => {
      if (response.data.success) {
        this.preisliste = response.data.payload
        this.created = true
        if (this.createdListener) {
          this.createdListener()
        }
      }
    })
  }

  addArtikel(artikel: BstlArtikel) {
    var request = new BPArtikelHinzufuegenRequest()
    request.id = store.state.neueBestellungData.bestellungId
    request.artikelData = ConverterUtil.convertBstlArtikel(artikel)

    Poster.postUser<RemoteResult<string>>(
      RestPaths.bestellungpendingArtikelHinzufuegen,
      request
    ).then((response) => {
      artikel.id = response.data.payload
      this.warenkorb.push(artikel)
      router.go(-1)
    })
  }
}
