
import Component from 'vue-class-component'
import Produkte from '@/components/custom/Produkte.vue'
import Vue from 'vue'
import ReservierungVorbestellungLogic from '@/logic/bestellung/reservierung_vorbestellung_logic'
import ReservierungVorbestellung from './ReservierungVorbestellung.vue'
import { Watch } from 'vue-property-decorator'

@Component({
  components: { Produkte },
})
export default class ReservierungVorbestellungProdukte extends Vue {
  logic?: ReservierungVorbestellungLogic = null

  mounted() {
    this.logic = (this.$parent as ReservierungVorbestellung).logic
  }

  // @Watch('$parent.logic')
  // watchLogic(value) {
  //   this.logic = value
  // }
}
