import FilialeDetailed from "@/entities/common/filiale_detailed";
import RemoteResult from "@/entities/common/remote_result";
import IdRequest from "@/entities/common/request/id_request";
import Poster from "@/util/poster";
import RestPaths from "@/util/rest_paths";

export default class FilialeInfoLogic {
  filiale: FilialeDetailed = null

  init(id: string) {
    var request = new IdRequest()
    request.id = id

    Poster.postUser<RemoteResult<FilialeDetailed>>(RestPaths.filialeDetailed, request).then((response) => {
      if(response.data.success) {
        this.filiale = response.data.payload
      }
    })
  }
}