import Bestellung from '@/entities/common/bestellung'
import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import store from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import StoreOps from '@/util/store_ops'

export default class DetailsLogic {
  bestellung?: Bestellung = null

  init(id: string) {
    var id = id
    var request = new IdRequest()
    request.id = id

    Poster.postUser<RemoteResult<Bestellung>>(
      RestPaths.bestellungKundeDetails,
      request
    ).then((response) => {
      this.bestellung = response.data.payload
      store.commit(StoreOps.UPDATE_BESTELLUNG, this.bestellung)
    })
  }
}
