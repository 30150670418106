
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Model, Prop } from 'vue-property-decorator'
import { Kategorie, KategorieZeitraum } from './kategorie'

@Component
export default class KategorieEdit extends Vue {
  @Prop() editMode: boolean = false
  @Prop() kategorie: Kategorie
  @Model() dialogVisible: boolean = false

  $refs: {
    editForm: HTMLFormElement
  }

  wochentage = [
    { index: 1, bezeichnung: 'Montag' },
    { index: 2, bezeichnung: 'Dienstag' },
    { index: 3, bezeichnung: 'Mittwoch' },
    { index: 4, bezeichnung: 'Donnerstag' },
    { index: 5, bezeichnung: 'Freitag' },
    { index: 6, bezeichnung: 'Samstag' },
    { index: 7, bezeichnung: 'Sonntag' },
  ]

  bezeichnungRules = [
    (v: string) => {
      if (!v) return 'Bitte Bezeichnung angeben'
      else return true
    },
  ]

  wochentagRules = [
    (v) => {
      if (!v) return 'Bitte w#hlen'
      else return true
    },
  ]

  zeitraumVonRules(index: number) {
    return [
      (v) => {
        if (!v) return 'Bitte angeben'
        else return true
      },
    ]
  }

  zeitraumBisRules(index: number) {
    return [
      (v) => {
        if (!v) return 'Bitte angeben'
        else return true
      },
    ]
  }

  neuerZeitraum() {
    this.kategorie.zeitraeume.push(new KategorieZeitraum())
  }

  @Emit('kategorieAbbrechen')
  kategorieAbbrechen() {}

  kategorieBestaetigen() {
    var valid = this.$refs.editForm.validate()
    if (valid) this.kategorieBestaetigenFinal()
  }

  @Emit('kategorieBestaetigen')
  kategorieBestaetigenFinal() {}
}
