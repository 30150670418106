
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import ArtikelRow from '../custom/ArtikelRow.vue'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import Bestellung from '@/entities/common/bestellung'
import BestellungLieferungLogic from '@/logic/member/bestellung_lieferung_logic'
import { AbholzeitType, LieferungStatus } from '@/entities/common/enums'

@Component({ components: { ArtikelRow } })
export default class BestellungLieferung extends mixins(Calculator) {
  @Prop() bestellung?: Bestellung
  logic?: BestellungLieferungLogic = null

  @Watch('bestellung', { immediate: true, deep: true })
  watchBestellung(newValue: Bestellung) {
    this.logic.init(newValue)
  }

  berechneGesamtpreis() {
    var gesamtpreis = this.berechnePreisListe(this.bestellung.artikelliste)
    if (this.bestellung.lieferung.lieferkosten) {
      gesamtpreis = gesamtpreis + this.bestellung.lieferung.lieferkosten
    }
    return gesamtpreis
  }

  bestimmeLieferzeitpunkt(): string {
    if (this.bestellung == null) return null
    if (this.bestellung.lieferung.zeitType == AbholzeitType.BALDEST) {
      return 'Schnellstmöglich'
    } else if (this.bestellung.lieferung.zeitType == AbholzeitType.UHRZEIT) {
      return this.formatiereDatumUhrzeit(
        this.bestellung.lieferung.lieferzeitpunkt
      )
    }
    return null
  }

  bestimmeStatus(): string {
    if (this.bestellung == null) return null
    switch (this.bestellung.lieferung.lieferungStatus) {
      case LieferungStatus.ABGESENDET:
        return 'Noch nicht angenommen'
      case LieferungStatus.ABGELEHNT:
        return 'Bestellung abgelehnt'
      case LieferungStatus.ANGENOMMEN:
        return 'Bestellung angenommen'
      case LieferungStatus.IN_ZUBEREITUNG:
        return 'In Zubereitung'
      case LieferungStatus.IN_LIEFERUNG:
        return 'In Lieferung'
      case LieferungStatus.GELIEFERT:
        return 'Geliefert'
    }
    return null
  }

  get annehmenVisible(): boolean {
    return (
      this.bestellung.lieferung.lieferungStatus == LieferungStatus.ABGESENDET
    )
  }

  get ablehnenVisible(): boolean {
    return (
      this.bestellung.lieferung.lieferungStatus == LieferungStatus.ABGESENDET
    )
  }

  get inZubereitungVisible(): boolean {
    return (
      this.bestellung.lieferung.lieferungStatus == LieferungStatus.ANGENOMMEN
    )
  }

  get inLieferungVisible(): boolean {
    return (
      this.bestellung.lieferung.lieferungStatus ==
      LieferungStatus.IN_ZUBEREITUNG
    )
  }

  get getliefertVisible(): boolean {
    return (
      this.bestellung.lieferung.lieferungStatus == LieferungStatus.IN_LIEFERUNG
    )
  }

  get stornierenVisible(): boolean {
    return true
  }

  @Emit('bestellung-abgeschlossen')
  bestellungAbgeschlossen() {}

  annehmen() {
    this.logic.updateStatus(LieferungStatus.ANGENOMMEN)
  }

  ablehnen() {
    this.logic.updateStatus(LieferungStatus.ABGELEHNT, () => {
      this.bestellung.abgeschlossen = true
      this.bestellungAbgeschlossen()
    })
  }

  inZubereitung() {
    this.logic.updateStatus(LieferungStatus.IN_ZUBEREITUNG)
  }

  inLieferung() {
    this.logic.updateStatus(LieferungStatus.IN_LIEFERUNG)
  }

  geliefert() {
    this.logic.updateStatus(LieferungStatus.GELIEFERT, () => {
      this.bestellung.abgeschlossen = true
      this.bestellungAbgeschlossen()
    })
  }

  stornieren() {
    this.logic.stornieren(() => {
      this.bestellungAbgeschlossen()
    })
  }

  mounted() {
    this.logic = new BestellungLieferungLogic()
    this.logic.init(this.bestellung)
  }

  @Watch('logic.details')
  watchDetails() {
    document.getElementById('rightPane').scrollTo({ top: 0 })
    window.scrollTo({ top: 0 })
  }
}
