
import Component from 'vue-class-component'
import BetriebDetails from './BetriebDetails.vue'
import ProduktExtrasLogic from '@/logic/system/produkt_extras_logic'
import ProduktExtrasMemCommon from '../memcommon/ProduktExtrasMemCommon.vue'
import Vue from 'vue'

@Component({ components: {ProduktExtrasMemCommon } })
export default class ProduktExtras extends Vue {
  logic?: ProduktExtrasLogic = null

  mounted() {
    this.logic = new ProduktExtrasLogic()
    this.logic.betriebId = (this.$parent as BetriebDetails).logic.betrieb.id
    this.logic.init()
  }
}
