import {
  BuchungBestellart,
  Reservierungart,
  Terminart,
} from '@/entities/common/enums'
import RemoteResult from '@/entities/common/remote_result'
import BestellungInsertRequest from '@/entities/common/request/bestellung_insert_request'
import IdRequest from '@/entities/common/request/id_request'
import FilialeSimple from '@/entities/member/filiale_simple'
import ReservierungTischList from '@/entities/member/lists/reservierung_tisch_list'
import ReservierungenFilialeRequest from '@/entities/member/request/reservierungen_filiale_request'
import { Tisch } from '@/entities/member/request/tisch_insert_liste_request'
import ReservierungTisch from '@/entities/member/reservierung_tisch'
import router from '@/router'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import store from '@/store'
import FilialeSimpleList from '@/entities/member/lists/filiale_simple_list'
import Reservierung from '@/entities/member/reservierung'
import SocketMessageType from '@/util/socket_message_types'
import Bestellung from '@/entities/common/bestellung'

export default class ReservierungenLogic {
  filialen?: FilialeSimple[] = []
  _filiale?: string = null
  _datum?: string = null
  datumVisible: boolean = false
  reservierungen?: any = null
  uhrzeiten?: any[] = []
  neueReservierung = {
    zeitVon: null,
    zeitBis: null,
    tisch: null,
    nachname: '',
    vorname: '',
    email: '',
    telefon: '',
    personen: 2,
    personenListe: [],
    uhrzeitVonListe: [],
    uhrzeitBisListe: [],
  }
  newReservierungVisible: boolean = false
  categories?: any[] = []
  events?: any[] = []
  tischListe?: ReservierungTisch[] = []
  selectedEvent?: any = null
  selectedOpen: boolean = false
  tag: any = null
  updateListener?: () => void

  get filiale(): string {
    return this._filiale
  }

  set filiale(value: string) {
    this._filiale = value
    router.replace({
      name: RouteNames.MEMBER.RESERVIERUNGEN,
      query: { filiale: this._filiale, datum: this._datum },
    })
    if (this._filiale != null && this._datum != null) {
      this.updateReservierungen()
    }
  }

  get datum(): string {
    return this._datum
  }

  set datum(value: string) {
    this._datum = value
    router.replace({
      name: RouteNames.MEMBER.RESERVIERUNGEN,
      query: { filiale: this._filiale, datum: this._datum },
    })

    if (this._filiale != null && this._datum != null) {
      this.updateReservierungen()
    }
  }

  bestellungAnzeigen(bestellung) {
    router.push({
      name: RouteNames.MEMBER.BESTELLUNG,
      params: { id: bestellung.id },
    })
  }

  neueReservierungEinfuegen() {
    var request = new BestellungInsertRequest()
    request.id = uuidv4()
    request.terminart = Terminart.RESERVIERUNG
    request.filialeId = this._filiale
    request.createReservierung()
    request.reservierung.zeitVon = this.neueReservierung.zeitVon
    request.reservierung.zeitBis = this.neueReservierung.zeitBis
    request.reservierung.tischId = this.neueReservierung.tisch.tischId
    request.reservierung.personen = this.neueReservierung.personen
    request.reservierung.kundeNachname = this.neueReservierung.nachname
    request.reservierung.kundeVorname = this.neueReservierung.vorname
    request.reservierung.kundeTelefon = this.neueReservierung.telefon
    request.reservierung.kundeEmail = this.neueReservierung.email
    request.reservierung.bestellart = BuchungBestellart.SERVICE
    request.reservierung.art = Reservierungart.MITARBEITER

    Poster.postMember<RemoteResult<Reservierung>>(
      RestPaths.bestellungMitarbeiterReservierungEinfuegen,
      request
    ).then((response) => {
      if (response.data.success) {
        var reservierung = response.data.payload
        if (reservierung.zeitVon && reservierung.zeitBis) {
          var event = {
            name: reservierung.kundeNachname,
            start: this._datum + ' ' + reservierung.zeitVon,
            end: this._datum + ' ' + reservierung.zeitBis,
            category: reservierung.tischNummer,
            kundeNachname: reservierung.kundeNachname,
            kundeVorname: reservierung.kundeVorname,
            kundeTelefon: reservierung.kundeTelefon,
            kundeEmail: reservierung.kundeEmail,
            personen: reservierung.anzahlPersonen,
            id: reservierung.id,
          }
          this.events.push(event)
        }
      }
      this.updateReservierungen()
      this.newReservierungVisible = false
    })
  }

  createNewReserv(tisch: string) {
    for (var i = 0; i < this.tischListe.length; i++) {
      var t = this.tischListe[i]
      if (t.tischNummer == tisch) {
        this.neueReservierung.tisch = t
        for (var i = 1; i < t.plaetze; i++) {
          this.neueReservierung.personenListe.push(i)
        }

        var geoeffnetVon = moment(this.tag.geoeffnetVon, 'HH:mm:ss')
        var geoeffnetBis = moment(this.tag.geoeffnetBis, 'HH:mm:ss')

        while (!geoeffnetVon.isAfter(geoeffnetBis)) {
          this.neueReservierung.uhrzeitVonListe.push(
            geoeffnetVon.format('HH:mm')
          )
          this.neueReservierung.uhrzeitBisListe.push(
            geoeffnetVon.format('HH:mm')
          )
          geoeffnetVon.add(1, 'hours')
        }

        this.newReservierungVisible = true

        break
      }
    }
  }

  showEvent(event) {
    this.selectedEvent = event
    this.selectedOpen = true
  }

  reservierungStornieren() {
    var request = new IdRequest()
    request.id = this.selectedEvent.id

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterStornieren,
      request
    ).then((response) => {
      if (response.data.success) {
        this.events.splice(this.events.indexOf(this.selectedEvent), 1)
        this.selectedEvent = null
        this.selectedOpen = false
      }
    })
  }

  fetchEvents(start) {
    var request = new ReservierungenFilialeRequest()
    request.datum = start.date
    request.filialeId = this._filiale

    Poster.postMember<RemoteResult<ReservierungTischList>>(
      RestPaths.bestellungMitarbeiterReservierungenListe,
      request
    ).then((response) => {
      this.tag = response.data.payload
      this.tischListe = response.data.payload.list
      var newEvents = []
      var kategorien = []
      this.tischListe.forEach((element) => {
        kategorien.push(element.tischNummer)
        element.reservierungen.forEach((reservierung) => {
          if (reservierung.zeitVon && reservierung.zeitBis) {
            var event = {
              name: reservierung.kundeNachname,
              start: reservierung.zeitVon,
              end: reservierung.zeitBis,
              category: element.tischNummer,
              kundeNachname: reservierung.kundeNachname,
              kundeVorname: reservierung.kundeVorname,
              kundeTelefon: reservierung.kundeTelefon,
              kundeEmail: reservierung.kundeEmail,
              personen: reservierung.anzahlPersonen,
              id: reservierung.id,
            }
            newEvents.push(event)
          }
        })
      })
      this.categories = kategorien
      this.events = newEvents
    })
  }

  formatTime(time) {
    return time.format('HH:mm')
  }

  createNewReservierung(tisch: Tisch, uhrzeit: string) {
    var uhrzeitVon = moment(uhrzeit, 'HH:mm')
    var uhrzeitBis = uhrzeitVon.clone().add(2, 'hours')

    this.neueReservierung.zeitVon = uhrzeitVon
    this.neueReservierung.zeitBis = uhrzeitBis
    this.neueReservierung.tisch = tisch

    this.newReservierungVisible = true
  }

  updateReservierungen() {
    var request = new ReservierungenFilialeRequest()
    request.datum = this._datum
    request.filialeId = this._filiale

    Poster.postMember<RemoteResult<ReservierungTischList>>(
      RestPaths.bestellungMitarbeiterReservierungenListe,
      request
    ).then((response) => {
      this.reservierungen = response.data.payload.list

      let from = moment(this.reservierungen.geoeffnetVon, 'HH:mm:ss')
      let to = moment(this.reservierungen.geoeffnetBis, 'HH:mm:ss')

      while (from.isBefore(to)) {
        this.uhrzeiten.push(from.format('HH:mm'))
        from.add(15, 'minutes')
      }

      if (this.updateListener) {
        this.updateListener()
      }
    })
  }

  init(filiale: string, datum: string) {
    var request = new IdRequest()
    request.id = store.state.user.betriebId

    Poster.postMember<RemoteResult<FilialeSimpleList>>(
      RestPaths.filialeListeSimple,
      request
    ).then((response) => {
      this.filialen = response.data.payload.list
      this.filiale = filiale
      this.datum = datum
    })

    store.watch(
      (state) => state.incomingMessage,
      (newValue) => {
        if (
          newValue &&
          newValue.type == SocketMessageType.BESTELLUNG_INSERTED
        ) {
          var bestellung = JSON.parse(newValue.payload) as Bestellung
          if (
            bestellung.terminart == Terminart.RESERVIERUNG &&
            bestellung.filiale.filialeId == this._filiale
          ) {
            if (
              moment(bestellung.reservierung.zeitVon).format('yyyy-MM-DD') ==
              this._datum
            ) {
              var reservierung = bestellung.reservierung

              if (bestellung.reservierung.zeitVon && reservierung.zeitBis) {
                var event = {
                  name: reservierung.kundeNachname,
                  start: reservierung.zeitVon,
                  end: reservierung.zeitBis,
                  category: reservierung.tischBezeichnung,
                  kundeNachname: reservierung.kundeNachname,
                  kundeVorname: reservierung.kundeVorname,
                  kundeTelefon: reservierung.kundeTelefon,
                  kundeEmail: reservierung.kundeEmail,
                  personen: reservierung.personen,
                  id: reservierung.id,
                }
                this.events.push(event)
              }
            }
          }
        }
      }
    )
  }
}
