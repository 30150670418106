
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'

@Component
export default class ErrorBox extends Vue {
  @Prop({ default: false }) showButton: boolean
  @Prop({ default: 'Es ist ein Fehler aufgetreten' }) errorText: string

  @Emit('reloadClicked')
  reloadClicked() {}
}
