import FilialeSimple from '@/entities/member/filiale_simple'
import MitarbeiterNeuMemcommonLogic from '../memcommon/mitarbeiter_neu_memcommon_logic'
import BetriebDetailsLogic from './betrieb_details_logic'

export default class MitarbeiterNeuLogic extends MitarbeiterNeuMemcommonLogic {
  parent: BetriebDetailsLogic = null

  get filialen(): FilialeSimple[] {
    return this.parent.filialen
  }

  get betriebId(): string {
    return this.parent.betrieb.id
  }
}
