
import AuswertungLogic from '@/logic/system/auswertung_logic'
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'

@Component
export default class Auswertung extends mixins(Calculator) {
  logic?: AuswertungLogic = null

  mounted() {
    this.logic = new AuswertungLogic()
    this.logic.init()
  }

  auswertungStarten() {
    this.logic.auswertungStarten()
  }
}
