
import BestellungFiliale from '@/entities/common/bestellung_filiale'
import moment from 'moment'
import Vue from 'vue'
import { Component, PropSync } from 'vue-property-decorator'

@Component
export default class OeffnungLabel extends Vue {
  @PropSync('filiale') filialeSync?: BestellungFiliale

  heuteGeoeffnet: boolean = false

  mounted() {
    if (!this.filialeSync) return
    if (this.filialeSync.zurZeitGeoeffnet) {
      this.heuteGeoeffnet = true
      return
    }
    var result = null
    var heute = moment()
    this.filialeSync.oeffnungszeitListe.forEach((element) => {
      var datum = moment(element.datum)

      var isToday = datum.isSame(heute, 'day')
      if (isToday) {
        if (!element.geoeffnet) {
          result = false
          return
        } else {
          result = true
          return
        }
      }
    })
    this.heuteGeoeffnet = result
  }
}
