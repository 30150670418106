
import RemoteResult from '@/entities/common/remote_result'
import Kuechenaufgabe from '@/entities/member/kuechenaufgabe'
import Poster from '@/util/poster'
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import KuechenaufgabeList from '@/entities/member/lists/kuechenaufgabe_list'
import RestPaths from '@/util/rest_paths'
import { Watch } from 'vue-property-decorator'
import KuechenaufgabeRequest from '@/entities/member/request/kuechenaufgabe_request'
import moment from 'moment'
import KuecheRow from './KuecheRow.vue'
import ArtikelRow from '../custom/ArtikelRow.vue'
import SocketMessageType from '@/util/socket_message_types'
import KuecheUpdateRequest from '@/entities/member/request/kueche_update_request'
import { KuecheStatus } from '@/entities/common/enums'
import KuecheDetailAbholung from './KuecheDetailAbholung.vue'
import KuecheDetailLieferung from './KuecheDetailLieferung.vue'
import store from '@/store'

@Component({
  components: {
    KuecheRow,
    ArtikelRow,
    KuecheDetailAbholung,
    KuecheDetailLieferung,
  },
})
export default class Kueche extends mixins(Calculator) {
  filiale: string = null
  aufgaben: Kuechenaufgabe[] = []
  aufgabe: Kuechenaufgabe = null
  index: number = 0

  bearbeiten() {
    var request = new KuecheUpdateRequest()
    request.nachbestellungId = this.aufgabe.nachbestellungId
    request.bestellungId = this.aufgabe.id
    request.status = KuecheStatus.IN_BEARBEITUNG

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterKuecheUpdate,
      request
    ).then((response) => {
      if (response.data.success) {
        this.aufgabe.kuecheStatus = KuecheStatus.IN_BEARBEITUNG
      }
    })
  }

  erledigt() {
    var request = new KuecheUpdateRequest()
    request.nachbestellungId = this.aufgabe.nachbestellungId
    request.bestellungId = this.aufgabe.id
    request.status = KuecheStatus.ERLEDIGT

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterKuecheUpdate,
      request
    ).then((response) => {
      if (response.data.success) {
        this.aufgabe.kuecheStatus = KuecheStatus.ERLEDIGT
        var index = this.aufgaben.indexOf(this.aufgabe)
        this.aufgaben.splice(index, 1)
        if (this.aufgaben.length > 0) {
          this.aufgabe = this.aufgaben.at(index)
        }
      }
    })
  }

  loadAufgaben() {
    var request = new KuechenaufgabeRequest()
    request.datum = moment().format('YYYY-MM-DD')
    request.filialeId = this.filiale

    Poster.postMember<RemoteResult<KuechenaufgabeList>>(
      RestPaths.bestellungMitarbeiterKuechenaufgaben,
      request
    ).then((response) => {
      this.aufgaben = response.data.payload.list
      this.resize()
    })
  }

  waehleAufgabe(aufgabe: Kuechenaufgabe, index: number) {
    this.index = index
    this.aufgabe = aufgabe
  }

  @Watch('$store.state.incomingMessage')
  watchIncomingMessage(message) {
    if (message.type == SocketMessageType.KUECHENAUFGABE_INSERTED) {
      var payload = JSON.parse(message.payload) as Kuechenaufgabe
      this.aufgaben.push(payload)
      this.aufgaben.sort((a: Kuechenaufgabe, b: Kuechenaufgabe) => {
        let aMillis = moment(a.bestellzeitpunkt).valueOf()
        let bMillis = moment(b.bestellzeitpunkt).valueOf()

        return aMillis - bMillis
      })
      new Audio('http://localhost:8080/images/bing.mp3').play()
    }
  }

  mounted() {
    this.filiale = store.state.user.filiale.id
    this.loadAufgaben()
  }

  resize() {
    var leftPane = document.getElementById('leftPane')
    var rightPane = document.getElementById('rightPane')
    var rectLeft = leftPane.getBoundingClientRect()
    var rectRight = rightPane.getBoundingClientRect()
    const newHeightLeft = window.innerHeight - rectLeft.top
    const newHeightRight = window.innerHeight - rectRight.top
    leftPane.style.height = newHeightLeft - 12 + 'px'
    rightPane.style.height = newHeightRight - 12 + 'px'
  }
}
