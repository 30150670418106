
import { AbholungStatus, AbholzeitType } from '@/entities/common/enums'
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import ArtikelRow from '../custom/ArtikelRow.vue'
import { Emit, Prop, PropSync, Watch } from 'vue-property-decorator'
import Bestellung from '@/entities/common/bestellung'
import BestellungAbholungLogic from '@/logic/member/bestellung_abholung_logic'
import BestellungenLogic from '@/logic/member/bestellungen_logic'

@Component({ components: { ArtikelRow } })
export default class BestellungAbholung extends mixins(Calculator) {
  @Prop() bestellung?: Bestellung
  logic?: BestellungAbholungLogic = null

  @Watch('bestellung', { immediate: true, deep: true })
  watchBestellung(newValue: Bestellung) {
    this.logic.init(newValue)
  }

  bestimmeAbholzeitpunkt() {
    if (this.bestellung == null) return null
    if (this.bestellung.abholung.type == AbholzeitType.BALDEST) {
      return 'Schnellstmöglich'
    } else if (this.bestellung.abholung.type == AbholzeitType.UHRZEIT) {
      return (
        this.formatiereDatumUhrzeit(this.bestellung.abholung.gewaehlterAbholZp)
      )
    }
    return null
  }

  bestimmeStatus() {
    if (this.bestellung == null) return null
    switch (this.bestellung.abholung.status) {
      case AbholungStatus.ABGEHOLT:
        return 'abgeholt'
      case AbholungStatus.ABGESENDET:
        return 'nicht angenommen'
      case AbholungStatus.ABGELEHNT:
        return 'abgelehnt'
      case AbholungStatus.ABHOLBEREIT:
        return 'abholbereit'
      case AbholungStatus.ANGENOMMEN:
        return 'angenommen'
      case AbholungStatus.NICHT_ABGEHOLT:
        return 'nicht abgeholt'
    }
  }

  get annehmenVisible(): boolean {
    return this.bestellung.abholung.status == AbholungStatus.ABGESENDET
  }

  get ablehnenVisible(): boolean {
    return this.bestellung.abholung.status == AbholungStatus.ABGESENDET
  }

  get abholbereitVisible(): boolean {
    return this.bestellung.abholung.status == AbholungStatus.ANGENOMMEN
  }

  get abgeholtVisible(): boolean {
    return this.bestellung.abholung.status == AbholungStatus.ABHOLBEREIT
  }

  get nichtAbgeholtVisible(): boolean {
    return this.bestellung.abholung.status == AbholungStatus.ABHOLBEREIT
  }

  get stornierenVisible(): boolean {
    return true
    // return !this.bestellung.abgeschlossen && !this.bestellung.storniert
  }

  @Emit('bestellung-abgeschlossen')
  bestellungAbgeschlossen() {}

  abgeholt() {
    this.logic.updateStatus(AbholungStatus.ABGEHOLT, () => {
      this.bestellung.abgeschlossen = true
      this.bestellungAbgeschlossen()
    })
  }

  nichtAbgeholt() {
    this.logic.updateStatus(AbholungStatus.NICHT_ABGEHOLT, () => {
      this.bestellung.abgeschlossen = true
      this.bestellungAbgeschlossen()
    })
  }

  abholbereit() {
    this.logic.updateStatus(AbholungStatus.ABHOLBEREIT)
  }

  ablehnen() {
    this.logic.updateStatus(AbholungStatus.ABGELEHNT, () => {
      this.bestellung.abgeschlossen = true
      this.bestellungAbgeschlossen()
    })
  }

  annehmen() {
    this.logic.updateStatus(AbholungStatus.ANGENOMMEN)
  }

  stornieren() {
    this.logic.stornieren(() => {
      this.bestellungAbgeschlossen()
    })
  }

  mounted() {
    this.logic = new BestellungAbholungLogic()
    this.logic.init(this.bestellung)
  }

  beforeDestroy() {
  }

  @Watch('logic.details')
  watchDetails() {
    document.getElementById('rightPane').scrollTo({ top: 0 })
    window.scrollTo({ top: 0 })
  }
}
