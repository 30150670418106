
import ProduktDetailsMemcommonLogic from '@/logic/memcommon/produkt_details_memcommon_logic'
import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import ToolbarButton from '../custom/ToolbarButton.vue'
import Calculator from '../mixins/Calculator.vue'

@Component({ components: { ToolbarButton } })
export default class ProduktDetailsMemCommon extends mixins(Calculator) {
  @Prop() logic: ProduktDetailsMemcommonLogic

  produktBearbeiten() {
    this.logic.produktBearbeiten()
  }

  aktivieren() {
    this.logic.aktivieren()
  }

  deaktivieren() {
    this.logic.deaktivieren()
  }

  istEinMenu() {
    if (this.logic.produkt.menu) return true
  }
}
