
import BetriebAnfrage from '@/entities/member/betrieb_anfrage'
import BetriebAnfrageLogic from '@/logic/system/betrieb_anfrage_logic'
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'

@Component
export default class BetriebAnfragen extends mixins(Calculator) {
  logic?: BetriebAnfrageLogic = null

  details(anfrage: BetriebAnfrage) {
    this.logic.details(anfrage)
  }

  mounted() {
    this.logic = new BetriebAnfrageLogic()
    this.logic.init()
  }
}
