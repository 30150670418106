
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import NichtGueltig from '../custom/NichtGueltig.vue'
import TerminQrcodeLogic from '@/logic/bestellung_neu/termin_qrcode_logic'

@Component({ components: { NichtGueltig } })
export default class TerminQrCode extends Vue {
  logic: TerminQrcodeLogic = null

  buchungsartWaehlen() {
    this.logic.buchungsartWaehlen()
  }

  tischBuchen() {
    this.logic.tischBuchen()
  }

  mounted() {
    this.logic = new TerminQrcodeLogic()
    this.logic.init(this.$route.params.id)
  }
}
