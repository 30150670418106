
import SetupLogic from '@/logic/system/setup_logic'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class Setup extends Vue {
  logic?: SetupLogic = null

  mounted() {
    this.logic = new SetupLogic()
  }

  systemEinrichten() {
    this.logic.systemEinrichten()
  }
}
