import { Bezahlart } from '@/entities/common/enums'
import BestellungInsertRequest from '@/entities/common/request/bestellung_insert_request'
import router from '@/router'
import store from '@/store'
import Nbd from '@/util/neue-bestellung-data'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import StoreOps from '@/util/store_ops'
import { v4 as uuidv4 } from 'uuid'

export default class KundendatenReservierungLogic {
  bestellungCompleted: boolean = false
  formValid: boolean = true
  kundeNachname?: string = null
  kundeVorname?: string = null
  kundeEmail?: string = null
  kundeTelefon?: string = null

  nachnameRules: any[] = [(v: any) => !!v || 'Bitte Nachname angeben']

  vornameRules: any[] = [(v: any) => !!v || 'Bitte Vorname angeben']

  emailRules: any[] = [
    (v: any) => !!v || 'Bitte E-Mail angeben',
    (v: string) => /.+@.+\..+/.test(v) || 'Bitte gültige E-Mail angeben',
  ]

  telefonRules: any[] = [(v) => !!v || 'Bitte Telefon angeben']

  init() {
  }

  weiter() {
    store.commit(StoreOps.UPDATE_NBD_RESERV_KUNDE, {
      kundeNachname: this.kundeNachname,
      kundeVorname: this.kundeVorname,
      kundeTelefon: this.kundeTelefon,
      kundeEmail: this.kundeEmail,
    })

    var request = new BestellungInsertRequest()

    var nbd = store.state.neueBestellungData as Nbd
    request.id = nbd.bestellungId
    request.terminart = nbd.terminart
    request.identifier = store.state.user.key
    request.filialeId = nbd.filiale.filialeId
    request.bezahlart = Bezahlart.BARZAHLUNG

    request.createReservierung()
    request.reservierung.id = uuidv4()
    request.reservierung.tischId = nbd.reservierungData.tisch.id
    request.reservierung.kundeNachname = this.kundeNachname
    request.reservierung.kundeVorname = this.kundeVorname
    request.reservierung.kundeTelefon = this.kundeTelefon
    request.reservierung.kundeEmail = this.kundeEmail
    request.reservierung.zeitVon = nbd.reservierungData.zeitVon
    request.reservierung.zeitBis = nbd.reservierungData.zeitBis
    request.reservierung.personen = nbd.reservierungData.anzahlPersonen
    request.reservierung.art = 'ONLINE'
    request.reservierung.bestellart = 'SELBST'

    Poster.postUser(RestPaths.bestellungKundeEinfuegen,
      request
    ).then(() => {
      this.bestellungCompleted = true
    })
  }

  produkteVorbestellen() {
    router.push({
      name: RouteNames.NEU.RESERVIETUNG_VORBESTELLUNG.PRODUKTE,
    })
  }
}