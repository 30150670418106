
import FilialenLogic from '@/logic/member/filialen_logic'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import FilialenMemCommon from '@/components/memcommon/FilialenMemCommon.vue'
import store from '@/store'

@Component({ components: {FilialenMemCommon } })
export default class Filialen extends Vue {
  logic?: FilialenLogic = null

  mounted() {
    this.logic = new FilialenLogic()
    this.logic.betriebId = store.state.user.betriebId
    this.logic.init()
  }
}
