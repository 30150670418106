import IdRequest from '@/entities/common/request/id_request'
import MemberBaseLogic from '../member_base_logic'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import Tisch from '@/entities/common/tisch'
import RestPaths from '@/util/rest_paths'
import TischUpdateRequest from '@/entities/member/request/tisch_update_request'

export default class TischEditMemcommonLogic extends MemberBaseLogic {
  tisch?: Tisch = null

  editCompleted: boolean = false
  editWorking: boolean = false
  editError: boolean = false
  editFormDisabled: boolean = false

  init(id: string) {
    var request = new IdRequest()
    request.id = id

    Poster.postMember<RemoteResult<Tisch>>(
      RestPaths.tischDetails,
      request
    ).then((response) => {
      if (response.data.success) {
        this.tisch = response.data.payload
      }
    })
  }

  speichern() {
    var request = new TischUpdateRequest()
    request.id = this.tisch.id
    request.bezeichnung = this.tisch.tischnummer
    request.personen = this.tisch.plaetze
    request.onlineReservierbar = this.tisch.onlineReservierbar

    Poster.postMember<RemoteResult<any>>(RestPaths.tischSpeichern, request)
    .then((response)=> {
      if(response.data.success) {
        this.editCompleted = true
      }
    })
  }
}
