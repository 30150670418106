
import MitarbeiterDetailsLogic from '@/logic/system/mitarbeiter_details_logic'
import Vue from 'vue'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import ToolbarButton from '../custom/ToolbarButton.vue'
import ErrorBox from '../custom/ErrorBox.vue'
import { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'

@Component({ components: { ToolbarButton, ErrorBox } })
export default class MitarbeiterDetails extends mixins(Calculator) {
  @Prop() logic: MitarbeiterDetailsLogic
  aktivierenDialogVisible: boolean = false
  deaktivierenDialogVisible: boolean = false

  bearbeiten() {
    this.logic.bearbeiten()
  }

  aktivieren() {
    this.logic.aktivieren(() => {
      this.aktivierenDialogVisible = false
    })
  }

  deaktivieren() {
    this.logic.deaktivieren(() => {
      this.deaktivierenDialogVisible = false
    })
  }

  computePlzOrt() {
    return this.logic.computePlzOrt()
  }

  // mounted() {
  //   this.logic = new MitarbeiterDetailsLogic()
  //   this.logic.init(this.$route.params.id)
  // }
}
