export default class BetriebUpdateRequest {
  id?: string
  bezeichnung?: string
  path?: string
  strasse?: string
  plz?: string
  ort?: string
  telefon?: string
  mobil?: string
  email?: string
  website?: string
}