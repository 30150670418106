export default class ArtikelData {
  id?: string
  anzahl: number = 0
  kommentare?: string
  produkt: Produkt

  constructor() {
    this.produkt = new Produkt()
  }

  static createProdukt() {
    return new Produkt()
  }

  static createExtra() {
    return new Extra()
  }

  static createAuswahl() {
    return new Auswahl()
  }
}

class Produkt {
  id?: string
  preislisteProduktId?: string
  extras: Extra[] = []
  auswahlen: Auswahl[] = []
  subprodukte: Subprodukt[] = []
}

class Extra {
  id?: string
  preislisteProduktExtraId?: string
  produkt?: Produkt
}

class Auswahl {
  id?: string
  auswahlId?: string
  optionId?: string
  produkt?: Produkt
}

class Subprodukt {
  id?: string
  subproduktId?: string
  optionId?: string
  produkt?: Produkt
}

export { Produkt, Extra, Auswahl, Subprodukt }
