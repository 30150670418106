import OeffnungszeitZeitraum from '@/entities/member/oeffnungszeit_zeitraum'
import OeffnungszeitenFilialeMemcommonLogic from '../memcommon/oeffnungszeiten_filiale_memcommon_logic'
import router from '@/router'
import RouteNames from '@/util/route_names'

export default class OeffnungszeitenFilialeLogic extends OeffnungszeitenFilialeMemcommonLogic {
  zeigeDetails(oeffnungszeit: OeffnungszeitZeitraum) {
    router.push({
      name: RouteNames.MEMBER.OEFFNUNGSZEIT_DETAILS,
      params: { id: oeffnungszeit.id },
    })
  }

  ausnahmenHinzufuegen() {
    router.push({
      name: RouteNames.MEMBER.OEFFNUNGSZEIT_AUSNAHMEN_FILIALE,
    })
  }

  neueOeffnungszeit() {
    router.push({
      name: RouteNames.MEMBER.OEFFNUNGSZEIT_NEU,
      params: { id: this.filiale.id },
    })
  }
}
