
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import MitarbeiterNeuLogic from '@/logic/member/mitarbeiter_neu_logic'
import MitarbeiterNeuMemCommon from '../memcommon/MitarbeiterNeuMemCommon.vue'

@Component({ components: { MitarbeiterNeuMemCommon } })
export default class MitarbeiterNeu extends Vue {

  logic?: MitarbeiterNeuLogic = null

  mounted() {
    this.logic = new MitarbeiterNeuLogic()
    this.logic.init()
  }
}
