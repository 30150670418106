
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import { Prop } from 'vue-property-decorator'
import TischeMemcommonLogic from '@/logic/memcommon/tische_memcommon_logic'
import TischeFilialeMemcommonLogic from '@/logic/memcommon/tische_filiale_memcommon_logic'
import Tisch from '@/entities/common/tisch'
import ToolbarButton from '../custom/ToolbarButton.vue'

@Component({ components: { ToolbarButton } })
export default class TischeFilialeMemCommon extends mixins(Calculator) {
  @Prop() logic: TischeFilialeMemcommonLogic

  tischSpeichern() {
    this.logic.tischSpeichern()
  }

  tischeEinfuegen() {
    this.logic.tischeEinfuegen()
  }

  neueTischeAbbrechen() {}

  removeTisch(index) {
    this.logic.removeTisch(index)
  }

  weitererTisch() {
    this.logic.weitererTisch()
  }

  moveUp(index: number) {
    this.logic.moveUp(index)
  }

  moveDown(index: number) {
    this.logic.moveDown(index)
  }

  neuerTisch() {
    this.logic.neuerTisch()
  }

  reihenfolgeFestlegen() {
    this.logic.reihenfolgeFestlegen()
  }

  reihenfolgeSpeichern() {
    this.logic.reihenfolgeSpeichern()
  }

  reihenfolgeAbbrechen() {
    this.logic.reihenfolgeAbbrechen()
  }

  tischAnzeigen(tisch: Tisch) {
    this.logic.tischAnzeigen(tisch)
  }

  tischBearbeiten(tisch: Tisch, event) {
    this.logic.tischBearbeiten(tisch, event)
  }
}
