
import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import router from '@/router'
import store from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class BezahlungBestaetigen extends Vue {
  bezahlungWirdBestaetigt: boolean = false
  bezahlungBestaetigt: boolean = false
  bestaetigenError: boolean = false

  bezahlungBestaetigen() {
    this.bezahlungWirdBestaetigt = true

    var request = new IdRequest()
    request.id = this.$route.params.bestellungId

    Poster.postUser<RemoteResult<any>>(
      RestPaths.paymentPaypalAbschliessen,
      request
    )
      .then((response) => {
        if (response.data.success) {
          this.bezahlungBestaetigt = true
          this.bezahlungWirdBestaetigt = false
          router.push({
            name: RouteNames.BESTELLUNG.DETAILS,
            params: { id: this.$route.params.bestellungId },
          })
        } else {
          this.bestaetigenError = true
        }
      })
      .catch(() => (this.bestaetigenError = true))
  }
}
