
import OeffnungszeitEditLogic from '@/logic/member/oeffnungszeit_edit_logic'
import Component from 'vue-class-component'
import OeffnungszeitEditMemCommon from '../memcommon/OeffnungszeitEditMemCommon.vue'
import Vue from 'vue'

@Component({components: {OeffnungszeitEditMemCommon}})
export default class OeffnungszeitEdit extends Vue {

  logic?: OeffnungszeitEditLogic = null

  mounted() {
    this.logic = new OeffnungszeitEditLogic()
    this.logic.init(this.$route.params.id)
  }
}
