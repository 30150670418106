
import Vue from 'vue'
import Component from 'vue-class-component'
import ShoppingLogic from '@/logic/bestellung_neu/shopping_logic'

@Component
export default class Shopping extends Vue {
  logic?: ShoppingLogic = null

  mounted() {
    this.logic = new ShoppingLogic()
    this.logic.init()
  }
}
