import BestellungFiliale from '@/entities/common/bestellung_filiale'
import BstlArtikel from '@/entities/common/bstl_artikel'
import { Terminart } from '@/entities/common/enums'
import BPArtikelAnzahlRequest from '@/entities/common/pending/bp_artikel_anzahl_request'
import RemoteResult from '@/entities/common/remote_result'
import router from '@/router'
import store from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import StoreOps from '@/util/store_ops'

export default class WarenkorbLogic {
  warenkorb: BstlArtikel[] = []
  filiale?: BestellungFiliale = null

  init() {
    this.warenkorb = store.state.neueBestellungData.artikelliste
    this.filiale = store.state.neueBestellungData.filiale
  }

  goToShopping() {
    router.push({ name: RouteNames.NEU.SHOPPING.PRODUKTE })
  }

  goToBestaetigen() {
    var terminart = store.state.neueBestellungData.terminart
    if (terminart == Terminart.ABHOLUNG) {
      var direkt = store.state.neueBestellungData.abholungData.direktabholung
      if (direkt) {
        router.push({ name: RouteNames.NEU.BESTAETIGEN })
      } else {
        router.push({ name: RouteNames.NEU.KUNDENDATEN })
      }
    } else if (terminart == Terminart.SOFORTBESTELLUNG) {
      router.push({ name: RouteNames.NEU.BESTAETIGEN })
    } else if (terminart == Terminart.RESERVIERUNG) {
      router.push({ name: RouteNames.NEU.KUNDENDATEN })
    }
  }

  increment(artikel: BstlArtikel) {
    var request = new BPArtikelAnzahlRequest()
    request.id = store.state.neueBestellungData.bestellungId
    request.artikelId = artikel.id
    request.anzahl = artikel.anzahl + 1

    Poster.postUser<RemoteResult<any>>(
      RestPaths.bestellungpendingArtikelAendern,
      request
    ).then(() => {
      artikel.anzahl += 1
    })
  }

  decrement(artikel: BstlArtikel) {
    if (artikel.anzahl > 1) {
      var request = new BPArtikelAnzahlRequest()
      request.id = store.state.neueBestellungData.bestellungId
      request.artikelId = artikel.id
      request.anzahl = artikel.anzahl - 1

      Poster.postUser<RemoteResult<any>>(
        RestPaths.bestellungpendingArtikelAendern,
        request
      ).then(() => {
        artikel.anzahl -= 1
      })
    }
  }

  remove(artikel: BstlArtikel) {
    var request = new BPArtikelAnzahlRequest()
    request.id = store.state.neueBestellungData.bestellungId
    request.artikelId = artikel.id
    request.loeschen = true

    Poster.postUser<RemoteResult<any>>(
      RestPaths.bestellungpendingArtikelAendern,
      request
    ).then(() => {
      store.commit(StoreOps.REMOVE_ARTIKEL_BESTELLUNG_NEU, artikel)

      this.warenkorb.splice(
        this.warenkorb.findIndex((x) => {
          return x.id === artikel.id
        }),
        1
      )
      if (this.warenkorb.length == 0) {
        router.go(-1)
      }
    })
  }
}
