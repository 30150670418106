
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import Tisch from '@/entities/common/tisch'
import TischeLogic from '@/logic/member/tische_logic'
import TischeMemCommon from '@/components/memcommon/TischeMemCommon.vue'

@Component({ name: 'Tische', components: { TischeMemCommon } })
export default class Tische extends Vue {
  logic?: TischeLogic = null

  mounted() {
    this.logic = new TischeLogic()
    this.logic.init()
  }
}
