
import Component, { mixins } from 'vue-class-component'
import BestellverwaltungRow from '../custom/BestellverwaltungRow.vue'
import Calculator from '../mixins/Calculator.vue'
import Bestellung from '@/entities/common/bestellung'
import { AbholungStatus, LieferungStatus } from '@/entities/common/enums'
import ArtikelRow from '../custom/ArtikelRow.vue'
import BestellverwaltungLogic from '@/logic/member/bestellverwaltung_logic'
import BestellungSimple from '@/entities/member/bestellung_simple'

@Component({ components: { BestellverwaltungRow, ArtikelRow } })
export default class BestellungVerwaltung extends mixins(Calculator) {
  $refs: {
    datumVonDialog: HTMLFormElement
    datumBisDialog: HTMLFormElement
  }

  logic?: BestellverwaltungLogic = null

  filterDialogVisible: boolean = false
  datumVonDialogVisible: boolean = false
  datumBisDialogVisible: boolean = false

  checkstates = [
    { name: 'beliebig', value: 0 },
    { name: 'ja', value: 1 },
    { name: 'nein', value: 2 },
  ]

  detailsAnzeigen(b: BestellungSimple) {
    this.logic.bestellungAnzeigen(b)
  }

  formatiereAbholungStatus(s: string): string {
    if (s == null) return null
    switch (s) {
      case AbholungStatus.ABGESENDET:
        return 'Noch nicht angenommen'
      case AbholungStatus.ABGELEHNT:
        return 'Abgelehnt'
      case AbholungStatus.ANGENOMMEN:
        return 'Angenommen'
      case AbholungStatus.IN_BEARBEITUNG:
        return 'In Zubereitung'
      case AbholungStatus.ABHOLBEREIT:
        return 'Abholbereit'
      case AbholungStatus.ABGEHOLT:
        return 'Abgeholt'
      case AbholungStatus.NICHT_ABGEHOLT:
        return 'Nicht abgeholt'
    }
  }

  formatiereLieferungStatus(s: string): string {
    if (s == null) return null
    switch (s) {
      case LieferungStatus.ABGESENDET:
        return 'Noch nicht angenommen'
      case LieferungStatus.ABGELEHNT:
        return 'Abgelehnt'
      case LieferungStatus.ANGENOMMEN:
        return 'Angenommen'
      case LieferungStatus.IN_ZUBEREITUNG:
        return 'In Zubereitung'
      case LieferungStatus.ZUBEREITET:
        return 'Zubereitet'
      case LieferungStatus.IN_LIEFERUNG:
        return 'In Lieferung'
      case LieferungStatus.GELIEFERT:
        return 'Geliefert'
    }
  }

  mounted() {
    this.logic = new BestellverwaltungLogic()
    this.logic.init()
    this.resize()

    window.onresize = () => {
      this.resize()
    }
  }

  aktualisieren() {
    this.logic.aktualisieren()
    this.filterDialogVisible = false
  }

  resize() {
    var leftPane = document.getElementById('leftPane')
    var rightPane = document.getElementById('rightPane')
    var rectLeft = leftPane.getBoundingClientRect()
    var rectRight = rightPane.getBoundingClientRect()
    const newHeightLeft = window.innerHeight - rectLeft.top
    const newHeightRight = window.innerHeight - rectRight.top
    leftPane.style.height = newHeightLeft - 20 + 'px'
    rightPane.style.height = newHeightRight - 20 + 'px'
  }
}
