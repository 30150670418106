
import { SofortbestellungData } from '@/util/neue-bestellung-data'
import Vue from 'vue'
import Component from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'

@Component
export default class BestaetigenSofort extends Vue {
  @PropSync('data')
  sofortbestellung: SofortbestellungData

  mounted() {
  }
}
