
import MeinAccountLogic from '@/logic/bestellung/mein_account_logic'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class MeinAccount extends Vue {
  logic?: MeinAccountLogic = null

  mounted() {
    this.logic = new MeinAccountLogic()
    this.logic.init()
  }

  passwortAendern() {
    this.logic.passwortAendern()
  }
}
