import RemoteResult from "@/entities/common/remote_result"
import StringRequest from "@/entities/common/request/string_request"
import Poster from "@/util/poster"
import RestPaths from "@/util/rest_paths"
import Validator from "@/util/validator"

export default class PasswortAendernLogic {
  neuesPasswort?: string = ''
  neuesPasswortWiederholen?: string = ''

  passwortGeaendert: boolean = false

  passwordRules: any[] = Validator.strongPasswordRules
  passwordWiederholenRules: any[] = [Validator.strongPasswordRules, this.verifyPassword]

  verifyPassword() {
    if(this.neuesPasswort !== this.neuesPasswortWiederholen) {
      return 'Passwörter müssen übereinstimmen'
    }
  }

  passwortAendern() {
    var request = new StringRequest()
    request.param = this.neuesPasswort

    Poster.postUser<RemoteResult<Boolean>>(RestPaths.kundePasswortAendernDirekt
      , request).then((response) => {
      if(response.data.success) {
        this.passwortGeaendert = true
      }
    })
  }

}