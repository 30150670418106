
import Vue from 'vue'
import Component from 'vue-class-component'
import InhaltsstoffeLogic from '@/logic/member/inhaltsstoffe_logic'
import InhaltsstoffeMemcommon from '@/components/memcommon/InhaltsstoffeMemCommon.vue'

@Component({ components: { InhaltsstoffeMemcommon } })
export default class Inhaltsstoffe extends Vue {

  logic?: InhaltsstoffeLogic = null

  mounted() {
    this.logic = new InhaltsstoffeLogic()
    this.logic.init()
  }
}
