
import SystemPageLogic from '@/logic/system_page_logic'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class SystemPage extends Vue {
  logic?: SystemPageLogic = null

  mounted() {
    this.logic = new SystemPageLogic()
    this.logic.init()
  }

  navHome() {
    this.logic.navHome()
  }

  navSetup() {
    this.logic.navSetup()
  }

  navBetriebe() {
    this.logic.navBetriebe()
  }

  navAuswertungen() {
    this.logic.navAuswertungen()
  }

  navAnfragen() {
    this.logic.navAnfragen()
  }

  navAccount() {
    this.logic.navAccount()
  }

  navSystemAccounts() {
    this.logic.navSystemAccounts()
  }

  login() {
    this.logic.login()
  }

  logout() {
    this.logic.logout()
  }

  showLoginDialog() {
    this.logic.showLoginDialog()
  }
}
