
import RestPaths from '@/util/rest_paths'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import FilialeDto from '@/entities/common/bestellung_filiale'

const FilialeProps = Vue.extend({
  props: {
    filiale: FilialeDto,
  },
})

@Component({
  name: 'Filiale',
})
export default class Filiale extends FilialeProps {
  mounted() {}
}
