export default class BetriebAnfragenRequest {
  bezeichnung?: string
  anzahlFilialen?: string
  strasse?: string
  plz?: string
  ort?: string
  email?: string
  telefon?: string
  website?: string
  ansprechpartner?: string
  nachricht?: string
  supportsLieferung?: boolean
  supportsAbholung? : boolean
  supportsReservierung?: boolean
  supportsSofort?: boolean
}