
import FilialeDetailsMemcommonLogic from '@/logic/memcommon/filiale_details_memcommon_logic'
import { mixins } from 'vue-class-component'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import ToolbarButton from '../custom/ToolbarButton.vue'
import Calculator from '../mixins/Calculator.vue'

@Component({ components: { ToolbarButton } })
export default class FilialeDetailsMemCommon extends mixins(Calculator) {
  @Prop() logic: FilialeDetailsMemcommonLogic

  showAktivierenDialog() {
    this.logic.showAktivierenDialog()
  }

  oeffnungszeitenBearbeiten() {
    this.logic.oeffnungszeitenBearbeiten()
  }

  oeffnungszeitenFilialeBearbeiten() {
    this.logic.oeffnungszeitenFilialeBearbeiten()
  }

  preislistenBearbeiten() {
    this.logic.preislistenBearbeiten()
  }

  tischeBearbeiten() {
    this.logic.tischeBearbeiten()
  }

  aktivieren() {
    this.logic.aktivieren()
  }

  showDeaktivierenDialog() {
    this.logic.showDeaktivierenDialog()
  }

  deaktivieren() {
    this.logic.deaktivieren()
  }

  filialeBearbeiten() {
    this.logic.filialeBearbeiten()
  }

  liefergebietZuordnen() {
    this.logic.liefergebietZuordnen()
  }
}
