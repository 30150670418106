
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import BetriebDetails from './BetriebDetails.vue'
import ProduktNeuLogic from '@/logic/system/produkt_neu_logic'
import ProduktNeuMemCommon from '../memcommon/ProduktNeuMemCommon.vue'

@Component({components: {ProduktNeuMemCommon}})
export default class ProduktNeu extends Vue {
  logic?: ProduktNeuLogic = null

  mounted() {
    this.logic = new ProduktNeuLogic()
    this.logic.betriebId = (this.$parent as BetriebDetails).logic.betrieb.id
    this.logic.init()
  }
}
