
import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Serviceanforderung from '@/entities/common/serviceanforderung'
import ServiceanforderungList from '@/entities/member/lists/serviceanforderung_list'
import FilialeSimple from '@/entities/member/filiale_simple'
import FilialeSimpleList from '@/entities/member/lists/filiale_simple_list'
import vuexStore from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import ServiceRow from './ServiceRow.vue'
import { AnforderungStatus } from '@/entities/common/enums'
import ServiceanforderungStatusUpdateRequest from '@/entities/member/request/serviceanforderung_status_update_request'
import moment from 'moment'
import SocketMessageType from '@/util/socket_message_types'

@Component({ components: { ServiceRow } })
export default class Service extends Vue {
  filialen: FilialeSimple[] = []
  filiale: string = null

  kategorien: ServiceKategorie[] = []
  anforderungen: Serviceanforderung[] = []
  aktuelleAnforderung: Serviceanforderung = null

  dialogVisible: boolean = false

  private updateStatus(status: string) {
    var request = new ServiceanforderungStatusUpdateRequest()
    request.id = this.aktuelleAnforderung.id
    request.status = status

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterUpdateServiceanforderungStatus,
      request
    ).then((response) => {
      if (response.data.success) {
        this.aktuelleAnforderung.status = status
        this.dialogVisible = false
        this.fuelleKategorien()
      }
    })
  }

  fuelleKategorien() {
    this.kategorien = []
    let kategorieOffen = new ServiceKategorie()
    kategorieOffen.status = AnforderungStatus.OFFEN
    kategorieOffen.position = 2

    let kategorieAngenommen = new ServiceKategorie()
    kategorieAngenommen.status = AnforderungStatus.ANGENOMMEN
    kategorieAngenommen.position = 1

    let kategorieErledigt = new ServiceKategorie()
    kategorieErledigt.status = AnforderungStatus.ERLEDIGT
    kategorieErledigt.position = 0

    this.kategorien.push(kategorieOffen)
    this.kategorien.push(kategorieAngenommen)
    this.kategorien.push(kategorieErledigt)

    this.anforderungen.forEach((anforderung) => {
      if (anforderung.status == AnforderungStatus.OFFEN) {
        kategorieOffen.anforderungen.push(anforderung)
      } else if (anforderung.status == AnforderungStatus.ANGENOMMEN) {
        kategorieAngenommen.anforderungen.push(anforderung)
      } else if (anforderung.status == AnforderungStatus.ERLEDIGT) {
        kategorieErledigt.anforderungen.push(anforderung)
      }
    })

    kategorieOffen.anforderungen.sort(this.serviceSort)
    kategorieAngenommen.anforderungen.sort(this.serviceSort)
    kategorieErledigt.anforderungen.sort(this.serviceSort)
  }

  serviceSort(a: Serviceanforderung, b: Serviceanforderung): number {
    let aMillis = moment(a.zeitpunkt).valueOf()
    let bMillis = moment(b.zeitpunkt).valueOf()

    return aMillis - bMillis
  }

  annehmen() {
    this.updateStatus(AnforderungStatus.ANGENOMMEN)
  }

  erledigt() {
    this.updateStatus(AnforderungStatus.ERLEDIGT)
  }

  zeigeAnforderung(anforderung: Serviceanforderung) {
    this.aktuelleAnforderung = anforderung
    this.dialogVisible = true
  }

  formatiereStatus(status: string) {
    switch (status) {
      case AnforderungStatus.OFFEN:
        return 'Offen'
      case AnforderungStatus.ANGENOMMEN:
        return 'Angenommen'
      case AnforderungStatus.ERLEDIGT:
        return 'Erledigt'
    }
  }

  // @Watch('$store.state.incomingMessage')
  // watchIncomingMessage(message) {
  //   if (message.type == SocketMessageType.SERVICE_INSERTED) {
  //     var payload = JSON.parse(message.payload) as Serviceanforderung
  //     this.anforderungen.push(payload)
  //   }
  // }

  @Watch('filiale')
  watchFiliale() {
    var request = new IdRequest()
    request.id = this.filiale

    Poster.postMember<RemoteResult<ServiceanforderungList>>(
      RestPaths.bestellungMitarbeiterServiceanforderungListe,
      request
    ).then((response) => {
      this.anforderungen = response.data.payload.list

      this.anforderungen.sort((a, b) => {
        var aMillis = moment(a.zeitpunkt).valueOf()
        var bMillis = moment(b.zeitpunkt).valueOf()
        return aMillis - bMillis
      })
      this.fuelleKategorien()
    })
  }

  mounted() {
    var request = new IdRequest()
    request.id = vuexStore.state.user.betriebId

    Poster.postMember<RemoteResult<FilialeSimpleList>>(
      RestPaths.filialeListeSimple,
      request
    ).then((response) => {
      if (response.data.success) {
        this.filialen = response.data.payload.list
      }
    })

    vuexStore.watch(
      (state) => state.incomingMessage,
      (newValue) => {
        if (newValue && newValue.type == SocketMessageType.SERVICE_INSERTED) {
          var payload = JSON.parse(newValue.payload) as Serviceanforderung
          this.anforderungen.unshift(payload)
          this.fuelleKategorien()
        }
      }
    )
  }
}

class ServiceKategorie {
  position: number
  status: string
  anforderungen: Serviceanforderung[] = []
}
