
import Bestellung from '@/entities/common/bestellung'
import {
  AbholungStatus,
  AbholzeitType,
  Terminart,
} from '@/entities/common/enums'
import { Zeitraum } from '@/logic/formatter'
import { time } from 'console'
import moment from 'moment'
import Component, { mixins } from 'vue-class-component'
import { Emit, PropSync, Watch } from 'vue-property-decorator'
import Calculator from '../mixins/Calculator.vue'

@Component
export default class BestellungRow extends mixins(Calculator) {
  @PropSync('bestellung') bestellungSync: Bestellung
  @PropSync('color') colorSync: any
  @PropSync('update') updateSync: boolean

  duration: Zeitraum = null

  mounted() {
    setInterval(() => {
      if (this.bestellungSync != null) {
        var time: string = null

        if (this.bestellungSync.terminart == Terminart.ABHOLUNG) {
          if (this.bestellungSync.abholung.type == AbholzeitType.BALDEST) {
            time = this.bestellungSync.bestellZp
          } else {
            time = this.bestellungSync.abholung.gewaehlterAbholZp
          }
        } else if (this.bestellungSync.terminart == Terminart.RESERVIERUNG) {
          var uhrzeit = this.bestellungSync.reservierung.zeitVon
          time = uhrzeit
        } else if (this.bestellungSync.terminart == Terminart.LIEFERUNG) {
          if (this.bestellungSync.lieferung.zeitType == AbholzeitType.BALDEST) {
            time = this.bestellungSync.bestellZp
          } else {
            time = this.bestellungSync.lieferung.lieferzeitpunkt
          }
        }

        if (time) {
          this.duration = this.berechneZeitraum(time)
        }
      }
    }, 1000)
  }

  @Watch('updateSync')
  watchUpdate() {}

  abholungBackColor() {
    if (this.bestellungSync.abholung.status == AbholungStatus.ABGESENDET)
      return 'green'
    return null
  }

  // convertMilliSecondsIntoLegibleString(milliSecondsIn) {
  //   var secsIn = milliSecondsIn / 1000
  //   var milliSecs = milliSecondsIn % 1000

  //   var hours = secsIn / 3600,
  //     remainder = secsIn % 3600,
  //     minutes = remainder / 60,
  //     seconds = remainder % 60

  //   return hours + 'h: ' + minutes + 'm: ' + seconds + 's: ' + milliSecs + 'ms'
  // }

  @Emit('detailsAnzeigen')
  detailsAnzeigen() {}

  formatTerminart() {
    switch (this.bestellungSync.terminart) {
      case Terminart.ABHOLUNG:
        return 'Abholung'
      case Terminart.RESERVIERUNG:
        return 'Reservierung'
      case Terminart.SOFORTBESTELLUNG:
        return 'Bestellung vor Ort'
      case Terminart.LIEFERUNG:
        return 'Lieferung'
    }
  }

  formatAbholungStatus(status: string): string {
    switch (status) {
      case AbholungStatus.ABGEHOLT:
        return 'abgeholt'
      case AbholungStatus.ABGELEHNT:
        return 'abgelehnt'
      case AbholungStatus.ABGESENDET:
        return 'abgesendet'
      case AbholungStatus.ABHOLBEREIT:
        return 'abholbereit'
      case AbholungStatus.ANGENOMMEN:
        return 'angenommen'
      case AbholungStatus.NICHT_ABGEHOLT:
        return 'nicht abgeholt'
      default:
        return null
    }
  }
}
