
import Component from 'vue-class-component'
import { Prop, Emit } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import Calculator from '@/components/mixins/Calculator.vue'
import { PreislisteProdukt } from '@/entities/common/pliste_produkt_zeit'

@Component
export default class Artikel extends mixins(Calculator) {
  @Prop() produkt: PreislisteProdukt

  @Emit('selected')
  produktSelected(produkt: PreislisteProdukt) {}
}
