import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Pdukt from '@/entities/member/pdukt'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import MemberBaseLogic from '../member_base_logic'

export default abstract class ProduktDetailsMemcommonLogic extends MemberBaseLogic {
  produkt?: Pdukt = null
  aktivierenDialogVisible: boolean = false
  deaktivierenDialogVisible: boolean = false
  editWorking: boolean = false
  editError: boolean = false
  loadError: boolean = false

  get bildUrl() {
    if (this.produkt.bildId != null)
      return RestPaths.imageBasePath + this.produkt.bildId + '_produkt.jpg'
  }

  get merkmale() {
    var array = []
    if (this.produkt.vegetarisch) {
      array.push('vegetarisch')
    }
    if (this.produkt.vegan) {
      array.push('vegan')
    }
    if (array.length > 0) return array.join(', ').trim()
    return '---'
  }

  abstract produktBearbeiten(): void

  aktivieren() {
    this.editWorking = true
    this.editError = false

    var request = new IdRequest()
    request.id = this.produkt.id

    Poster.postMember<RemoteResult<any>>(
      RestPaths.artikelProduktAktivieren,
      request
    )
      .then((response) => {
        this.editWorking = false
        if (response.data.success) {
          this.aktivierenDialogVisible = false
          this.produkt.aktiv = true
        } else {
          this.editError = true
        }
      })
      .catch(() => {
        this.editError = true
      })
  }

  deaktivieren() {
    this.editWorking = true
    this.editError = false

    var request = new IdRequest()
    request.id = this.produkt.id

    Poster.postMember<RemoteResult<any>>(
      RestPaths.artikelProduktDeaktivieren,
      request
    )
      .then((response) => {
        this.editWorking = false

        if (response.data.success) {
          this.deaktivierenDialogVisible = false
          this.produkt.aktiv = false
        } else {
          this.editError = true
        }
      })
      .catch(() => {
        this.editError = true
      })
  }

  istEinMenu() {
    if (this.produkt.menu) return true
  }

  init(id: string) {
    var request = new IdRequest()
    request.id = id

    Poster.postMember<RemoteResult<Pdukt>>(
      RestPaths.artikelPduktDetails,
      request
    )
      .then((response) => {
        if (response.data.success) {
          this.produkt = response.data.payload
        } else {
          this.loadError = true
        }
      })
      .catch(() => {
        this.loadError = true
      })
  }
}
