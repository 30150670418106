import BestellungFiliale from '@/entities/common/bestellung_filiale'
import BstlArtikel from '@/entities/common/bstl_artikel'
import DateTimeCapsule from '@/entities/common/data_time_capsule'
import PlisteProduktZeitListe from '@/entities/common/lists/pliste_produkt_zeit_liste'
import PlisteProduktZeit, {
  PreislisteProdukt,
} from '@/entities/common/pliste_produkt_zeit'
import RemoteResult from '@/entities/common/remote_result'
import BestellungInsertArtikellisteRequest from '@/entities/common/request/bestellung_insert_artikelliste_request'
import PreislisteZeitraumRequest from '@/entities/common/request/preisliste_zeitpunkt_request'
import ProdukteLogic from '@/logic/produkte_logic'
import router from '@/router'
import store from '@/store'
import ConverterUtil from '@/util/converter_util'
import Nbd from '@/util/neue-bestellung-data'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'

export default class ReservierungVorbestellungLogic implements ProdukteLogic {
  
  filiale?: BestellungFiliale = null
  preislisten?: PlisteProduktZeit[] = []
  warenkorb?: BstlArtikel[] = []
  created: boolean = false
  artikel: PreislisteProdukt = null
  createdListener: () => void
  bestellungCompleted: boolean = false

  artikelWaehlen(produkt: PreislisteProdukt): void {
    this.artikel = produkt
    router.push({
      name: RouteNames.NEU.RESERVIETUNG_VORBESTELLUNG.ARTIKEL,
      params: { id: this.artikel.id },
    })
  }

  get warenkorbGefuellt(): boolean {
    return this.warenkorb.length > 0
  }

  bestellungEinfuegen(): void {
    router.push({
      name: RouteNames.NEU.RESERVIETUNG_VORBESTELLUNG.BESTAETIGEN,
    })
  }

  reloadArtikel(id: string) {
    this.preislisten.forEach((pliste: PlisteProduktZeit) => {
      pliste.kategorien.forEach((kategorie) => {
        kategorie.produkte.forEach((p) => {
          if (p.id == id) {
            this.artikel = p
            return
          }
        })
      })
    })
  }

  addArtikel(artikel: BstlArtikel) {
    this.warenkorb.push(artikel)
    router.go(-1)
  }

  geheZurBestellung() {
    router.push({
      name: RouteNames.BESTELLUNG.DETAILS,
      params: { id: store.state.neueBestellungData.bestellungId },
    })
  }

  bestellungBestaetigen() {
    var request = new BestellungInsertArtikellisteRequest()
    request.bestellungId = store.state.neueBestellungData.bestellungId
    request.vorbestellung = true

    this.warenkorb.forEach((element) => {
      request.artikelliste.push(ConverterUtil.convertBstlArtikel(element))
    })

    Poster.postUser<RemoteResult<DateTimeCapsule>>(
      RestPaths.bestellungKundeEinfuegenArtikelliste,
      request
    ).then((response) => {
      if (response.data.success) {
        this.bestellungCompleted = true
      }
    })
  }

  get hasMindestbestellwert() {
    return false
  }

  get mindestbestellwert() {
    return 0
  }

  get kostenlosAb() {
    return 0
  }

  init() {
    var request = new PreislisteZeitraumRequest()
    var nbd = store.state.neueBestellungData as Nbd
    request.filialeId = nbd.filiale.filialeId
    request.zeitpunkt = nbd.reservierungData.zeitVon

    Poster.postUser<RemoteResult<PlisteProduktZeitListe>>(
      RestPaths.artikelPdukteNachZeit,
      request
    ).then((response) => {
      if (response.data.success) {
        this.preislisten = response.data.payload.list
        this.created = true
        if (this.createdListener) {
          this.createdListener()
        }
      }
    })
  }
}
