import OeffnungszeitZeitraum from '@/entities/member/oeffnungszeit_zeitraum'
import OeffnungszeitenFilialeMemcommonLogic from '../memcommon/oeffnungszeiten_filiale_memcommon_logic'
import router from '@/router'
import RouteNames from '@/util/route_names'

export default class OeffnungszeitenFilialeLogic extends OeffnungszeitenFilialeMemcommonLogic {
  ausnahmenHinzufuegen() {
    router.push({
      name: RouteNames.SYSTEM.OEFFNUNGSZEIT_AUSNAHMEN_FILIALE,
      params: { filialeId: this.filiale.id },
    })
  }

  neueOeffnungszeit() {
    router.push({
      name: RouteNames.SYSTEM.OEFFNUNGSZEIT_NEU,
      params: { filialeId: this.filiale.id },
    })
  }

  zeigeDetails(oeffnungszeit: OeffnungszeitZeitraum) {
    router.push({
      name: RouteNames.SYSTEM.OEFFNUNGSZEIT_DETAILS,
      params: { id: oeffnungszeit.id },
    })
  }
}
