import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import FilialeSimple from '@/entities/member/filiale_simple'
import PlisteList from '@/entities/member/lists/pliste_list'
import Pliste from '@/entities/member/pliste'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import MemberBaseLogic from '../member_base_logic'

export default abstract class PreislistenMemcommonLogic extends MemberBaseLogic {
  _filiale: string = null
  preislisten: Pliste[] = []
  inaktiveAnzeigen: boolean = false
  loadError: boolean = false

  get filiale() {
    return this._filiale
  }

  abstract replaceFiliale(): void

  abstract get filialen(): FilialeSimple[]

  set filiale(value: string) {
    this.loadError = false
    this._filiale = value

    this.replaceFiliale()

    if (this.filiale == null) return

    var request = new IdRequest()
    request.id = value

    Poster.postMember<RemoteResult<PlisteList>>(
      RestPaths.preislisteListe,
      request
    ).then((response) => {
      if (response.data.success) {
        this.preislisten = response.data.payload.list
      } else {
        this.loadError = true
      }
    }).catch(()=>{
      this.loadError = true
    })
  }

  abstract kategorienVerwalten(): void

  abstract navNeu(): void

  abstract detailsAnzeigen(newValue: Pliste): void
}
