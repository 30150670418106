
import ReservierungVorbestellungLogic from '@/logic/bestellung_neu/reservierung_vorbestellung_logic'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class ReservierungVorbestellung extends Vue {
  logic?: ReservierungVorbestellungLogic = null
  
  created() {
    this.logic = new ReservierungVorbestellungLogic()
    this.logic.init()
  }
}
