import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Filiale from '@/entities/member/filiale'
import FilialeSimple from '@/entities/member/filiale_simple'
import FilialeSimpleList from '@/entities/member/lists/filiale_simple_list'
import router from '@/router'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import FilialenMemcommonLogic from '../memcommon/filialen_memcommon_logic'

export default class FilialenLogic extends FilialenMemcommonLogic {
  public shortcutsAnzeigen(): void {
    router.push({name: RouteNames.MEMBER.PDUKT_SHORTCUTS})
  }

  public detailsAnzeigen(filiale: FilialeSimple): void {
    router.push({
      name: RouteNames.MEMBER.FILIALE_DETAILS,
      params: { id: filiale.id },
    })
  }

  public neueFiliale(): void {
    router.push({ name: RouteNames.MEMBER.FILIALE_NEU })
  }
}
