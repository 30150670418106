import Bestellung from '@/entities/common/bestellung'
import BestellungFiliale from '@/entities/common/bestellung_filiale'
import PlisteProduktZeit, {
  PreislisteProdukt,
} from '@/entities/common/pliste_produkt_zeit'
import router from '@/router'
import RouteNames from '@/util/route_names'
import ProdukteLogic from '@/logic/produkte_logic'
import store from '@/store'
import BestellungData from '@/util/bestellung_data'
import PreislisteZeitraumRequest from '@/entities/common/request/preisliste_zeitpunkt_request'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import PlisteProduktZeitListe from '@/entities/common/lists/pliste_produkt_zeit_liste'
import RestPaths from '@/util/rest_paths'
import BstlArtikel from '@/entities/common/bstl_artikel'
import NachbestellenInsertRequest from '@/entities/common/request/nachbestellen_insert_request'
import { v4 as uuidv4 } from 'uuid'
import ConverterUtil from '@/util/converter_util'
import StoreOps from '@/util/store_ops'
import Nachbestellung from '@/entities/common/nachbestellung'

export default class ReservierungBestellungLogic implements ProdukteLogic {
  filiale?: BestellungFiliale = null
  preislisten?: PlisteProduktZeit[] = []
  warenkorb?: BstlArtikel[] = []
  created: boolean = false
  artikel: PreislisteProdukt = null
  bestellung: Bestellung = null
  bestellungCompleted: boolean = false

  insertWorking: boolean = false
  insertError: boolean = false
  insertCompleted: boolean = false

  createdListener: () => void

  artikelWaehlen(produkt: PreislisteProdukt): void {
    this.artikel = produkt
    router.push({
      name: RouteNames.BESTELLUNG.RESERVIERUNG_BESTELLUNG.ARTIKEL,
      params: { id: this.artikel.id },
    })
  }

  get warenkorbGefuellt(): boolean {
    return this.warenkorb.length > 0
  }

  bestellungEinfuegen(): void {
    store.commit(StoreOps.UPDATE_BESTELLUNG_ARTIKEL_BESTELLUNG, this.warenkorb)
    router.push({
      name: RouteNames.BESTELLUNG.RESERVIERUNG_BESTELLUNG.BESTAETIGEN,
    })
  }

  init() {
    var bestellungData = store.state.bestellung as BestellungData
    this.bestellung = bestellungData.bestellung
    this.warenkorb = bestellungData.artikelliste

    var request = new PreislisteZeitraumRequest()
    request.zeitpunkt = this.bestellung.reservierung.zeitVon
    request.filialeId = this.bestellung.filiale.filialeId

    Poster.postUser<RemoteResult<PlisteProduktZeitListe>>(
      RestPaths.artikelPdukteNachZeit,
      request
    ).then((response) => {
      this.preislisten = response.data.payload.list
      this.created = true
      if (this.createdListener) {
        this.createdListener()
      }
    })
  }

  reloadArtikel(id: string) {
    this.preislisten.forEach((pliste: PlisteProduktZeit) => {
      pliste.kategorien.forEach((kategorie) => {
        kategorie.produkte.forEach((p) => {
          if (p.id == id) {
            this.artikel = p
            return
          }
        })
      })
    })
  }

  addArtikel(artikel: BstlArtikel) {
    this.warenkorb.push(artikel)
    router.go(-1)
  }

  bestellungAufgeben() {
    this.insertWorking = true
    this.insertError = false
    this.insertCompleted = false

    var request = new NachbestellenInsertRequest()
    request.id = uuidv4()
    request.bestellungId = this.bestellung.id

    this.warenkorb.forEach((element) => {
      request.artikel.push(ConverterUtil.convertBstlArtikel(element))
    })

    Poster.postUser<RemoteResult<Nachbestellung>>(
      RestPaths.bestellungKundeNachbestellungEinfuegen,
      request
    )
      .then((response) => {
        this.insertWorking = false
        if (response.data.success) {
          this.bestellungCompleted = true
          this.insertCompleted = true
        } else {
          this.insertError = true
        }
      })
      .catch(() => {
        this.insertWorking = false
        this.insertError = true
      })
  }

  backToOverview() {
    router.push({
      name: RouteNames.BESTELLUNG.DETAILS,
      params: { id: this.bestellung.id },
    })
  }

  get hasMindestbestellwert() {
    return false
  }

  get mindestbestellwert() {
    return 0
  }

  get kostenlosAb() {
    return 0
  }
}
