
import BstlArtikel from '@/entities/common/bstl_artikel'
import Formatter from '@/logic/formatter'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class Calculator extends Vue {
  formatiereDatumUhrzeit(value: string): string {
    return Formatter.formatiereDatumUhrzeit(value)
  }

  formatiereUhrzeit(value: string): string {
    return Formatter.formatiereUhrzeit(value)
  }

  formatiereDatum(value: string): string {
    return Formatter.formatiereDatum(value)
  }

  formatiereWaehrung(value: number): string {
    return Formatter.formatiereWaehrung(value)
  }

  berechneZeitraum(value: string) {
    return Formatter.berechneZeitraum(value)
  }

  berechnePreisListe(artikelliste) {
    return Formatter.berechnePreisListe(artikelliste)
  }

  berechnePreis(artikel: BstlArtikel) {
    return Formatter.berechnePreis(artikel)
  }
}
