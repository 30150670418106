const SocketMessageType = {
  ABHOLUNG_STATUS_UPDATE: 'ABHOLUNG_STATUS_UPDATE',
  LIEFERUNG_STATUS_UPDATE: 'LIEFERUNG_STATUS_UPDATE',
  BESTELLUNG_INSERTED: 'BESTELLUNG_INSERTED',
  BESTELLUNG_UPDATED: 'BESTELLUNG_UPDATED',
  BESTELLUNG_STORNIERT: 'BESTELLUNG_STORNIERT',
  SERVICE_INSERTED: 'SERVICE_INSERTED',
  NACHBESTELLUNG_INSERTED: 'NACHBESTELLUNG_INSERTED',
  VORBESTELLUNG_INSERTED: 'VORBESTELLUNG_INSERTED',
  ARTIKELLISTE_INSERTED: 'ARTIKELLISTE_INSERTED',
  KUECHENAUFGABE_INSERTED: 'KUECHENAUFGABE_INSERTED',
}

export default SocketMessageType