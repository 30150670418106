import RemoteResult from '@/entities/common/remote_result'
import BetriebAnfrage from '@/entities/member/betrieb_anfrage'
import BetriebAnfrageList from '@/entities/member/lists/betrieb_anfrage_list'
import router from '@/router'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'

export default class BetriebAnfrageLogic {
  anfragen?: BetriebAnfrage[] = []

  details(anfrage: BetriebAnfrage) {
    router.push({
      name: RouteNames.SYSTEM.ANFRAGE_DETAILS,
      params: { id: anfrage.id },
    })
  }

  init() {
    Poster.postMember<RemoteResult<BetriebAnfrageList>>(
      RestPaths.betriebAnfragenListe,
      {}
    ).then((response) => {
      this.anfragen = response.data.payload.list
    })
  }
}
