import Bestellung from '@/entities/common/bestellung'
import { LoadState } from '@/entities/common/enums'
import BestellungList from '@/entities/common/lists/bestellung_list'
import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import BestellungSimple from '@/entities/member/bestellung_simple'
import FilialeSimple from '@/entities/member/filiale_simple'
import BestellungSimpleList from '@/entities/member/lists/bestellung_simple_list'
import FilialeSimpleList from '@/entities/member/lists/filiale_simple_list'
import BestellungenRequest from '@/entities/member/request/bestellungen_request'
import router from '@/router'
import store from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'

export default class BestellverwaltungLogic {
  loadState: string = LoadState.IDLE

  filialen: FilialeSimple[] = []
  filter: Filter = new Filter()

  bestellungen: BestellungSimple[] = []
  bestellung: Bestellung = null
  selected: BestellungSimple[] = []

  init() {
    var request = new IdRequest()
    request.id = store.state.user.betriebId

    Poster.postMember<RemoteResult<FilialeSimpleList>>(
      RestPaths.filialeListeSimple,
      request
    ).then((response) => {
      this.filialen = response.data.payload.list
    })
  }

  bestellungAnzeigen(bs: BestellungSimple) {
    var request = new IdRequest()
    request.id = bs.id

    Poster.postMember<RemoteResult<Bestellung>>(
      RestPaths.bestellungMitarbeiterDetails,
      request
    ).then((response) => {
      this.bestellung = response.data.payload
    })
  }

  aktualisieren() {
    this.loadState = LoadState.LOADING

    var request = new BestellungenRequest()
    request.betriebId = store.state.user.betriebId
    request.filialeBeliebig = this.filter.filialeBeliebig
    request.filialeId = this.filter.filiale

    request.abgeschlossene = this.filter.abgeschlossene
    request.stornierte = this.filter.stornierte
    request.bezahlte = this.filter.bezahlte
    request.datumVon = this.filter.datumVon
    request.datumBis = this.filter.datumBis

    Poster.postMember<RemoteResult<BestellungSimpleList>>(
      RestPaths.bestellungMitarbeiterListeComplex,
      request
    ).then((response) => {
      this.bestellungen = response.data.payload.list
      this.loadState = LoadState.COMPLETED
    })
  }
}

class Filter {
  filialeBeliebig: boolean = true
  filiale: string = null
  datumBeliebig: boolean = true
  datumVon: string = null
  datumBis: string = null
  abgeschlossene: number = 0
  stornierte: number = 0
  bezahlte: number = 0
  bestellarten: number[] = []
}
