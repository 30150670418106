
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Calculator from '../mixins/Calculator.vue'
import PreislisteKategorieEditContainer from './PreislisteKategorieEditContainer.vue'
import TageskarteEditMemcommonLogic from '@/logic/memcommon/tageskarte_edit_memcommon_logic'
import router from '@/router'

@Component({ components: { PreislisteKategorieEditContainer } })
export default class TageskarteEditMemCommon extends mixins(Calculator) {
  @Prop() logic: TageskarteEditMemcommonLogic

  produkteHinzufuegenAnzeigen() {
    this.logic.produkteHinzufuegenAnzeigen()
  }

  navBack() {
    router.back()
  }

  preislisteSpeichern() {
    this.logic.preislisteSpeichern()
  }

  produkteHinzufuegenAbbrechen() {
    this.logic.produkteHinzufuegenAbbrechen()
  }

  produkteHinzufuegen() {
    this.logic.produkteHinzufuegen()
  }
}
