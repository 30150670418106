
import Bestellung from '@/entities/common/bestellung'
import BestellungFiliale from '@/entities/common/bestellung_filiale'
import { Terminart } from '@/entities/common/enums'
import RestPaths from '@/util/rest_paths'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'

@Component
export default class BestellungRow extends Vue {
  @Prop() bestellung: Bestellung

  bestimmeBild(filiale: BestellungFiliale): string {
    if (filiale.bildId != null) {
      return RestPaths.imageBasePath + filiale.bildId + '_klein.jpg'
    }
  }

  computeTerminartIcon(value: string): string {
    switch (value) {
      case Terminart.ABHOLUNG:
        return '$vuetify.icons.abholung'
      case Terminart.RESERVIERUNG:
        return '$vuetify.icons.reservierung'
      case Terminart.SOFORTBESTELLUNG:
        return '$vuetify.icons.sofortbestellung'
      case Terminart.LIEFERUNG:
        return 'mdi-moped-outline'
    }
  }

  @Emit('detailsAnzeigen')
  detailsAnzeigen() {}
}
