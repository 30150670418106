export default class StaticLogic {
  private static _instance: StaticLogic = null

  static get instance() {
    if(this._instance == null) {
      this._instance = new StaticLogic()
    }
    return this._instance
  }

  private constructor() {
  }
  
  webSocket: WebSocket
}