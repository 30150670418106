
import { ReservierungStatus } from '@/entities/common/enums'
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import ArtikelRow from '../custom/ArtikelRow.vue'
import { Emit, Prop, PropSync, Watch } from 'vue-property-decorator'
import Bestellung from '@/entities/common/bestellung'
import BestellungReservierungLogic from '@/logic/member/bestellung_reservierung_logic'
import BestellungenLogic from '@/logic/member/bestellungen_logic'

@Component({ components: { ArtikelRow } })
export default class BestellungReservierung extends mixins(Calculator) {
  @Prop() bestellung: Bestellung
  logic?: BestellungReservierungLogic = null

  @Watch('bestellung')
  watchBestellung(newValue: Bestellung) {
    this.logic.init(newValue, this.bestellungAbgeschlossen)
  }

  mounted() {
    this.logic = new BestellungReservierungLogic()
    this.logic.init(this.bestellung, this.bestellungAbgeschlossen)
  }

  @Emit('bestellung-abgeschlossen')
  bestellungAbgeschlossen() {}

  stornieren() {
    this.logic.stornieren()
  }

  annehmen() {
    this.logic.annehmen()
  }

  einbuchen() {
    this.logic.einbuchen()
  }

  formatiereStatus() {
    if (this.bestellung == null) return null
    switch (this.bestellung.reservierung.status) {
      case ReservierungStatus.ANGENOMMEN:
        return 'angenommen'
      case ReservierungStatus.RESERVIERUNG_ANGENOMMEN:
        return 'Reservierung angenommen'
      case ReservierungStatus.RESERVIERT:
        return 'reserviert'
      case ReservierungStatus.EINGEBUCHT:
        return 'eingebucht'
    }
  }
}
