import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import BetriebAnfrage from '@/entities/member/betrieb_anfrage'
import router from '@/router'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'

export default class BetriebAnfrageDetailsLogic {
  anfrage?: BetriebAnfrage = null

  init(id: string) {
    var request = new IdRequest()
    request.id = id

    Poster.postMember<RemoteResult<BetriebAnfrage>>(
      RestPaths.betriebAnfrageDetails,
      request
    ).then((response) => {
      this.anfrage = response.data.payload
    })
  }

  betriebAufnehmen() {
    router.push({
      name: RouteNames.SYSTEM.BETRIEB_NEU,
      params: { anfrage: this.anfrage.id },
    })
  }
}
