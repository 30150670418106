
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import FilialeLiefergebietZuordnenMemCommon from '../memcommon/FilialeLiefergebietZuordnenMemCommon.vue'
import FilialeLiefergebietZuordnenLogic from '@/logic/member/filiale_liefergebiet_zuordnen_logic'

@Component({components: {FilialeLiefergebietZuordnenMemCommon}})
export default class FilialeLiefergebietZuordnen extends Vue {
  logic?: FilialeLiefergebietZuordnenLogic = null

  mounted() {
    this.logic = new FilialeLiefergebietZuordnenLogic()
    this.logic.init(this.$route.params.id)
  }
}

