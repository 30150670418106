
import OeffnungszeitDetailsLogic from '@/logic/system/oeffnungszeit_details_logic'
import { Component, Vue } from 'vue-property-decorator'
import OeffnungszeitDetailsMemCommon from '../memcommon/OeffnungszeitDetailsMemCommon.vue'

@Component({components: {OeffnungszeitDetailsMemCommon}})
export default class OeffnungszeitDetails extends Vue {
  logic?: OeffnungszeitDetailsLogic = null

  mounted() {
    this.logic = new OeffnungszeitDetailsLogic()
    this.logic.init(this.$route.params.id)
  }
}
