import BestellungFiliale from '@/entities/common/bestellung_filiale'
import BstlArtikel from '@/entities/common/bstl_artikel'
import store from '@/store'
import router from '@/router'
import RouteNames from '@/util/route_names'
import StoreOps from '@/util/store_ops'
import Formatter from '../formatter'

export default class WarenkorbLieferungLogic {
  warenkorb: BstlArtikel[] = []
  mindestbestellwert: number = 0
  kostenlosAb: number = 0
  lieferkosten: number = 0
  filiale?: BestellungFiliale = null
  
  get weiterButtonDisabled() : boolean {
    var preisWarenkorb = Formatter.berechnePreisListe(this.warenkorb)
    return (this.mindestbestellwert - preisWarenkorb) > 0
  }

  init() {
    var nbd = store.state.neueBestellungData
    this.warenkorb = nbd.artikelliste
    this.filiale = nbd.filiale
    this.mindestbestellwert = nbd.filiale.lieferungSettings.mindestbestellwert
    this.kostenlosAb = nbd.filiale.lieferungSettings.kostenlosAb
    this.lieferkosten = nbd.filiale.lieferungSettings.lieferkosten
  }

  goToShopping() {
    router.go(-1);
    //router.push({ name: RouteNames.NEU.SHOPPING.PRODUKTE })
  }

  goToBestaetigen() {
    router.push({ name: RouteNames.NEU.KUNDENDATEN_LIEFERUNG })
  }

  increment(artikel: BstlArtikel) {
    artikel.anzahl += 1
  }

  decrement(artikel: BstlArtikel) {
    if (artikel.anzahl > 1) artikel.anzahl -= 1
  }

  remove(artikel: BstlArtikel) {
    store.commit(StoreOps.REMOVE_ARTIKEL_BESTELLUNG_NEU, artikel)

    this.warenkorb.splice(
      this.warenkorb.findIndex((x) => {
        return x.id === artikel.id
      }),
      1
    )
    if (this.warenkorb.length == 0) {
      router.go(-1)
    }
  }
}
