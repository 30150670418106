import router from '@/router'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import StoreOps from '@/util/store_ops'
import store from '@/store'
import RemoteResult from '@/entities/common/remote_result'
import LoginResult from '@/entities/common/login_result'
import LoginRequest from '@/entities/common/request/login_request'

export default class SystemPageLogic {
  drawer: boolean = true
  accountMenuOpen?: boolean = false
  loginDialogOpen?: boolean = false

  username?: string = ''
  password?: string = ''

  loggedIn?: boolean = false

  init() {
    store.watch(
      (state) => state.user.loggedIn,
      (newValue) => {
        this.loggedIn = newValue
        if (!newValue) {
          router.push(RouteNames.LOGIN_SYSTEM)
        }
      },
      { immediate: true, deep: true }
    )
  }

  navHome() {}

  navSetup() {
    router.push({ name: RouteNames.SYSTEM.SETUP })
  }

  navBetriebe() {
    router.push({ name: RouteNames.SYSTEM.BETRIEBE })
  }

  navAuswertungen() {
    router.push({ name: RouteNames.SYSTEM.AUSWERTUNG })
  }

  navAnfragen() {
    router.push({ name: RouteNames.SYSTEM.ANFRAGEN })
  }

  navAccount() {
    router.push({ name: RouteNames.SYSTEM.ACCOUNT })
  }

  navSystemAccounts() {
    router.push({ name: RouteNames.SYSTEM.SYSTEM_ACCOUNTS })
  }

  login() {
    var request = new LoginRequest()
    request.username = this.username
    request.password = this.password

    Poster.postUser<RemoteResult<LoginResult>>(
      RestPaths.login,
      request
    ).then((response) => {
      var payload = response.data.payload
      store.commit(StoreOps.LOGIN_MEMBER, payload)
      this.loginDialogOpen = false
    })
  }

  logout() {
    store.commit(StoreOps.LOGOUT)
  }

  showLoginDialog() {
    this.loginDialogOpen = true
  }
}
