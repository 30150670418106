export default class OeffnungszeitZeitraumUpdateRequest {
  id?: string
  gueltigVon?: string
  gueltigBis?: string
  wochentage: VorlageData[] = []
}

class VorlageData {
  wochentag?: number
  oeffnungszeiten: ZeitraumData[] = []
}

class ZeitraumData {
  zeitVon?: string
  zeitVonVisible: boolean
  zeitBis?: string
  zeitBisVisible: boolean
}

export { VorlageData, ZeitraumData }
