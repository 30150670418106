
import { Component, Vue } from 'vue-property-decorator'
import OeffnungszeitDetailsLogic from '@/logic/member/oeffnungszeit_details_logic'
import OeffnungszeitDetailsMemCommon from '../memcommon/OeffnungszeitDetailsMemCommon.vue'

@Component({ components: { OeffnungszeitDetailsMemCommon } })
export default class OeffnungszeitDetails extends Vue {
  logic?: OeffnungszeitDetailsLogic = null

  mounted() {
    this.logic = new OeffnungszeitDetailsLogic()
    this.logic.init(this.$route.params.id)
  }
}
