import RemoteResult from '@/entities/common/remote_result'
import StringRequest from '@/entities/common/request/string_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'

export default class PasswortAendernLogic {
  neuesPasswort?: string = ''
  neuesPasswortWiederholden?: string = ''

  passwortGeaendert: boolean = false

  passwortRules: any[] = [
    (x) => {
      if (!!!x) return 'Bitte Passwort angeben'
    },
    (x) => {
      if (x.length < 6) {
        return 'Bitte min. 6 Zeichen'
      }
    },
  ]

  passwortWiederholenRules: any[] = [
    (x) => {
      if (!!!x) return 'Bitte Passwort angeben'
    },
    (x) => {
      if (x.length < 6) {
        return 'Bitte min. 6 Zeichen'
      }
    },
    this.verifyPassword,
  ]

  verifyPassword() {
    if (this.neuesPasswort !== this.neuesPasswortWiederholden) {
      return 'Passwörter müssen übereinstimmen'
    }
  }

  passwortAendern() {
    var request = new StringRequest()
    request.param = this.neuesPasswort

    Poster.postMember<RemoteResult<any>>(
      RestPaths.mitarbeiterPasswortAendern,
      request
    ).then((response) => {
      if (response.data.success) {
        this.passwortGeaendert = true
      }
    })
  }
}