
import Vue from 'vue'
import Component from 'vue-class-component'
import BetriebDetails from './BetriebDetails.vue'
import InhaltsstoffeLogic from '@/logic/system/inhaltsstoffe_logic'
import InhaltsstoffeMemcommon from '../memcommon/InhaltsstoffeMemCommon.vue'

@Component({ components: { InhaltsstoffeMemcommon } })
export default class Inhaltsstoffe extends Vue {
  logic?: InhaltsstoffeLogic = null

  mounted() {
    this.logic = new InhaltsstoffeLogic()
    this.logic.betriebId = ((this.$parent as BetriebDetails).logic.betrieb.id)
    this.logic.init()
  }
}
