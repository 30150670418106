
import Vue from 'vue'
import Component from 'vue-class-component'
import NachbestellungLogic from '@/logic/bestellung/nachbestellung_logic'

@Component
export default class Nachbestellung extends Vue {
  logic?: NachbestellungLogic = new NachbestellungLogic()

  created() {
    this.logic = new NachbestellungLogic()
    this.logic.init()
  }
}
