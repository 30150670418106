
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import Bestellung from '@/entities/common/bestellung'
import RouteNames from '@/util/route_names'
import { LoadState, Terminart } from '@/entities/common/enums'
import BestellverwaltungLogic from '@/logic/system/bestellverwaltung_logic'
import BetriebDetails from './BetriebDetails.vue'

@Component({ name: 'Bestellverwaltung' })
export default class Bestellverwaltung extends mixins(Calculator) {
  $refs: {
    datumVonDialog: HTMLFormElement
    datumBisDialog: HTMLFormElement
  }

  logic?: BestellverwaltungLogic = null

  get indicatorLoadState() {
    return this.logic.loadState == LoadState.LOADING ? true : false
  }

  datumVonDialogVisible = false
  datumBisDialogVisible = false

  checkstates = [
    { name: 'beliebig', value: 0 },
    { name: 'ja', value: 1 },
    { name: 'nein', value: 2 },
  ]

  headers = [
    { text: 'Bestellart', value: 'terminart' },
    { text: 'Zeitpunkt', value: 'bestellZp' },
    { text: 'Abg.', value: 'abgeschlossen' },
    { text: 'Aktionen', value: 'actions' },
  ]

  calcBoolIcon(value: boolean): string {
    return value ? 'mdi-check' : 'mdi-minus'
  }

  calcBoolColor(value: boolean): string {
    return value ? 'green' : 'gray'
  }

  formatTerminart(bestellung: Bestellung) {
    switch (bestellung.terminart) {
      case Terminart.ABHOLUNG:
        return 'Abholung'
      case Terminart.SOFORTBESTELLUNG:
        return 'Sofort'
      case Terminart.RESERVIERUNG:
        return 'Reservierung'
    }
  }

  formatBestellZp(bestellung: Bestellung) {
    return this.formatiereDatumUhrzeit(bestellung.bestellZp)
  }

  bestellungAnzeigen(bestellung: Bestellung) {

    this.$router.push({
      name: RouteNames.MEMBER.BESTELLUNG,
      params: { id: bestellung.id },
    })
  }

  aktualisieren() {
    this.logic.aktualisieren()
  }

  mounted() {
    this.logic = new BestellverwaltungLogic()
    this.logic.init((this.$parent as BetriebDetails).logic)
  }
}
