import Bestellung from '@/entities/common/bestellung'
import BestellungList from '@/entities/common/lists/bestellung_list'
import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import FilialeSimple from '@/entities/member/filiale_simple'
import FilialeSimpleList from '@/entities/member/lists/filiale_simple_list'
import AbholungStatusUpdateRequest from '@/entities/member/request/abholung_status_update_request'
import BestellungFilialeDatumRequest from '@/entities/member/request/bestellung_filiale_datum_request'
import router from '@/router'
import store from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import SocketMessageType from '@/util/socket_message_types'
import Formatter from '../formatter'

export default class BestellungenLogic {
  index = 0
  inited: boolean = false
  _filiale?: string = null
  menuVisible: boolean = false
  //_datum?: string = null
  bestellungen?: Bestellung[] = []
  details?: Bestellung = null

  filterVisible: boolean = false

  filter: Filter = new Filter()

  update: boolean = false

  get filiale() {
    return this._filiale
  }

  set filiale(value: string) {
    this._filiale = value
  }

  removeBestellung() {
    var index = this.bestellungen.indexOf(this.details)
    this.bestellungen.splice(index, 1)
    if (this.bestellungen.length > 0) {
      this.details = this.bestellungen.at(index)
    }
  }

  updateBestellungen(completed: () => void) {
    var request = new IdRequest()
    request.id = this.filiale
    Poster.postMember<RemoteResult<BestellungList>>(
      RestPaths.bestellungMitarbeiterAktuelleBestellungen,
      request
    )
      .then((response) => {
        this.bestellungen = response.data.payload.list
        if (this.bestellungen.length > 0) {
          this.details = this.bestellungen.at(0)
        }
        //this.resize()}
        completed()
      })
      .catch(() => {})
  }

  tick() {
    if (this.bestellungen == null) return
    this.bestellungen.forEach((bestellung) => {
      this.update = !this.update
      bestellung.duration = Formatter.berechneZeitraum(
        bestellung.bestellZp
      ).stringValue
    })
  }

  init(filiale: string, datum: string, completed: () => void) {
    store.watch(
      (state) => state.incomingMessage,
      (newValue) => {
        if (newValue) {
          if (newValue.type == SocketMessageType.BESTELLUNG_INSERTED) {
            var payload = JSON.parse(newValue.payload) as Bestellung
            this.bestellungen.unshift(payload)
            new Audio('http://localhost:8080/images/bing.mp3').play()
          }
        }
      }
    )

    this.filiale = store.state.user.filiale.id
    this.updateBestellungen(completed)
  }

  updateStatus(status: string, finish?: () => void) {
    var request = new AbholungStatusUpdateRequest()
    request.id = this.details.id
    request.status = status

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterStatusUpdateAbholung,
      request
    )
      .then((response) => {
        if (!response.data.success) {
        }
        this.details.abholung.status = status
        if (finish) {
          finish()
        }
      })
      .catch(() => {})
  }

  stornieren(finish: () => void) {
    var request = new IdRequest()
    request.id = this.details.id

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterStornieren,
      request
    ).then((response) => {
      if (response.data.success) {
        this.details.storniert = true
        this.details.abgeschlossen = true
        finish()
      }
    })
  }

  annehmen() {}

  einbuchen() {}

  abschliessen() {
    var request = new IdRequest()
    request.id = this.details.id

    Poster.postMember<RemoteResult<any>>(
      RestPaths.bestellungMitarbeiterBestellungAbschliessen,
      request
    ).then((response) => {
      if (response.data.success) {
        this.details.abgeschlossen = true
        //this.finished()
      }
    })
  }
}

class Filter {
  stornierte = false
  abgeschlossene = false
}
