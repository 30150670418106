import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import FilialeSimple from '@/entities/member/filiale_simple'
import FilialeSimpleList from '@/entities/member/lists/filiale_simple_list'
import vuexStore from '@/store'
import { Role } from '@/store/role'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import MemberBaseLogic from '../member_base_logic'

export default abstract class FilialenMemcommonLogic extends MemberBaseLogic {
  liste: FilialeSimple[]
  betriebId: string = null
  listError: boolean = false
  listWorking: boolean = false

  get isAdmin(): boolean {
    var role = vuexStore.state.user.role
    if (role == Role.SYSTEM) return true
    if (role == Role.MITARBEITER) {
      if (vuexStore.state.user.admin) return true
    }
    return false
  }

  init() {
    this.loadListe()
  }

  loadListe() {
    console.log('load liste')
    this.listError = false
    this.listWorking = true

    var request = new IdRequest()
    request.id = this.betriebId

    Poster.postMember<RemoteResult<FilialeSimpleList>>(
      RestPaths.filialeListeSimple,
      request
    )
      .then((response) => {
        console.log(response.data)
        this.listWorking = false
        if (response.data.success) {
          this.liste = response.data.payload.list
          console.log('liste wurde festgelegt')
        } else {
          this.listError = true
        }
      })
      .catch(() => {
        console.log('error in loading')
        this.listWorking = false
        this.listError = true
      })
  }

  abstract detailsAnzeigen(filiale: FilialeSimple): void

  abstract neueFiliale(): void

  abstract shortcutsAnzeigen(): void
}
