
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'
import { PreislisteProdukt } from '@/logic/memcommon/new_preisliste_edit_memcommon_logic'
import ToolbarButton from '../custom/ToolbarButton.vue'
import Expander from '../custom/Expander.vue'
import Swapper from '@/util/swapper'

@Component({
  name: 'PreislisteProduktEditContainer',
  components: { ToolbarButton, Expander, PreislisteProduktEditContainer },
})
export default class PreislisteProduktEditContainer extends Vue {
  @Prop({ required: true }) produkt!: PreislisteProdukt

  @Emit('produktDeaktivieren')
  produktDeaktivieren() {}

  @Emit('produktAktivieren')
  produktAktivieren() {}

  @Emit('moveUp')
  moveUp() {}

  @Emit('moveDown')
  moveDown() {}

  moveExtraUp(index: number) {
    Swapper.instance.pushUp(this.produkt.extras, index)
  }

  moveExtraDown(index: number) {
    Swapper.instance.pushDown(this.produkt.extras, index)
  }

  moveAuswahlUp(index: number) {
    Swapper.instance.pushUp(this.produkt.auswahlen, index)
  }

  moveAuswahlDown(index: number) {
    Swapper.instance.pushDown(this.produkt.auswahlen, index)
  }

  moveAuswahlOptionUp(auswahlIndex: number, optionIndex: number) {
    Swapper.instance.pushUp(
      this.produkt.auswahlen[auswahlIndex].optionen,
      optionIndex
    )
  }

  moveAuswahlOptionDown(auswahlIndex: number, optionIndex: number) {
    Swapper.instance.pushDown(
      this.produkt.auswahlen[auswahlIndex].optionen,
      optionIndex
    )
  }

  moveSubproduktUp(index: number) {
    Swapper.instance.pushUp(this.produkt.subprodukte, index)
  }

  moveSubproduktDown(index: number) {
    Swapper.instance.pushDown(this.produkt.subprodukte, index)
  }
}
