
import Vue from 'vue'
import Component from 'vue-class-component'
import SystemUserInsertRequest from '@/entities/member/request/system_user_insert_request'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import RestPaths from '@/util/rest_paths'

@Component
export default class SystemAccountNeu extends Vue {
  username: string = null
  nachname: string = null
  vorname: string = null
  nickname: string = null
  passwort: string = null
  passwortWiederholen: string = null

  insertWorking: boolean = false
  insertCompleted: boolean = false
  insertError: boolean = false
  insertFormDisabled: boolean = false

  navZurueck() {
    this.$router.back()
  }

  benutzerEinfuegen() {
    this.insertFormDisabled = true
    this.insertWorking = true
    this.insertError = false

    var request = new SystemUserInsertRequest()
    request.username = this.username
    request.nachname = this.nachname
    request.vorname = this.vorname
    request.nickname = this.nickname
    request.passwort = this.passwort

    Poster.postMember<RemoteResult<any>>(
      RestPaths.mitarbeiterSystemAccountInsert,
      request
    )
      .then((response) => {
        this.insertWorking = false
        if (response.data.success) {
          this.insertCompleted = true
        } else {
          this.insertError = true
          this.insertFormDisabled = false
        }
      })
      .catch(() => {
        this.insertWorking = false
        this.insertError = true
        this.insertFormDisabled = false
      })
  }
}
