import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Tisch from '@/entities/common/tisch'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import MemberBaseLogic from '../member_base_logic'
import router from '@/router'
import RouteNames from '@/util/route_names'

export default class TischDetailsMemcommonLogic extends MemberBaseLogic {
  tisch?: Tisch = null
  deaktivierenDialogVisible: boolean = false
  aktivierenDialogVisible: boolean = false

  editWorking: boolean = false
  editError: boolean = false
  loadError: boolean = false

  tischGeloescht: boolean = false

  init(id: string) {
    var request = new IdRequest()
    request.id = id

    Poster.postMember<RemoteResult<Tisch>>(RestPaths.tischDetails, request)
      .then((response) => {
        if (response.data.success) {
          this.tisch = response.data.payload
        } else {
          this.loadError = true
        }
      })
      .catch(() => {
        this.loadError = true
      })
  }

  get qrCode() {
    return RestPaths.urlBase + RestPaths.imageQrCode + '/' + this.tisch.id
  }

  loeschen() {
    var request = new IdRequest()
    request.id = this.tisch.id

    Poster.postMember<RemoteResult<any>>(RestPaths.tischLoeschen, request).then(
      (response) => {
        if (response.data.success) {
          this.tischGeloescht = true
        }
      }
    )
  }

  aktivieren() {
    this.editWorking = true
    this.editError = false

    var request = new IdRequest()
    request.id = this.tisch.id

    Poster.postMember<RemoteResult<Boolean>>(RestPaths.tischAktivieren, request)
      .then((response) => {
        this.editWorking = false
        if (response.data.success) {
          this.tisch.aktiv = true
          this.aktivierenDialogVisible = false
        } else {
          this.editError = true
        }
      })
      .catch(() => {
        this.editWorking = false
        this.editError = true
      })
  }

  deaktivieren() {
    this.editWorking = true
    this.editError = false

    var request = new IdRequest()
    request.id = this.tisch.id

    Poster.postMember<RemoteResult<Boolean>>(
      RestPaths.tischDeaktivieren,
      request
    )
      .then((response) => {
        this.editWorking = false
        if (response.data.success) {
          this.tisch.aktiv = false
          this.deaktivierenDialogVisible = false
        } else {
          this.editError = true
        }
      })
      .catch(() => {
        this.editWorking = false
        this.editError = true
      })
  }

  tischBearbeiten() {
    router.push({
      name: RouteNames.MEMBER.TISCH_EDIT,
      params: { tischId: this.tisch.id },
    })
  }
}
