import Bestellung from '@/entities/common/bestellung'
import {
  AbholungStatus,
  LieferungStatus,
  Terminart,
} from '@/entities/common/enums'
import BestellungQueryRequest from '@/entities/common/request/bestellung_query_request'
import router from '@/router'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import Formatter from './formatter'
import store from '@/store'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import BestellungList from '@/entities/common/lists/bestellung_list'
import StoreOps from '@/util/store_ops'
import BestellungFiliale from '@/entities/common/bestellung_filiale'

export default class BestellungenLogic {
  bestellungen?: Bestellung[] = []

  formatiereLieferungStatus(bestellung: Bestellung): string {
    switch (bestellung.lieferung.lieferungStatus) {
      case LieferungStatus.ABGESENDET:
        return 'Bestellung noch nicht angenommen'
      case LieferungStatus.ANGENOMMEN:
        return 'Bestellung angenommen'
      case LieferungStatus.ABGELEHNT:
        return 'Bestellung abgelehnt'
      case LieferungStatus.IN_ZUBEREITUNG:
        return 'Bestellung in Zubereitung'
      case LieferungStatus.IN_LIEFERUNG:
        return 'Bestellung wird ausgeliefert'
      case LieferungStatus.GELIEFERT:
        return 'Bestellung ausgeliefert'
    }
  }

  formatiereAbholungStatus(bestellung: Bestellung): string {
    switch (bestellung.abholung.status) {
      case AbholungStatus.ABGEHOLT:
        return 'abgeholt'
      case AbholungStatus.ABGELEHNT:
        return 'abgelehnt'
      case AbholungStatus.ABGESENDET:
        return 'noch nicht angenommen'
      case AbholungStatus.ABHOLBEREIT:
        return 'abholbereit'
      case AbholungStatus.ANGENOMMEN:
        return 'angenommen'
      case AbholungStatus.NICHT_ABGEHOLT:
        return 'nicht abgeholt'
    }
  }

  formatTerminart(value: string): string {
    switch (value) {
      case Terminart.ABHOLUNG:
        return 'Abholung'
      case Terminart.RESERVIERUNG:
        return 'Tischreservierung'
      case Terminart.SOFORTBESTELLUNG:
        return 'Bestellung vor Ort'
    }
  }

  bestimmeBild(filiale: BestellungFiliale) {
    if (filiale.bildId != null) {
      return RestPaths.urlBase + RestPaths.imageImage + '/' + filiale.bildId
    }
  }

  detailsAnzeigen(bestellung: Bestellung) {
    store.commit(StoreOps.UPDATE_BESTELLUNG, bestellung)
    router.push({
      name: RouteNames.BESTELLUNG.DETAILS,
      params: { id: bestellung.id },
    })
  }

  init() {
    var request = new BestellungQueryRequest()
    request.abgeschlossen = false
    request.identifier = store.state.user.identifier

    Poster.postUser<RemoteResult<BestellungList>>(
      RestPaths.bestellungKundeListe,
      request
    ).then((response) => {
      this.bestellungen = response.data.payload.list
    })
  }
}
