import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import AuswertungBetrieb from '@/entities/member/auswertung_betrieb'
import AuswertungFiliale from '@/entities/member/auswertung_filiale'
import FilialeSimple from '@/entities/member/filiale_simple'
import FilialeSimpleList from '@/entities/member/lists/filiale_simple_list'
import AuswertungRequest from '@/entities/member/request/auswertung_request'
import vuexStore from '@/store'
import store from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import { response } from 'express'

export default class AuswertungLogic {
  datumVon?: string = null
  datumBis?: string = null
  filiale?: string = null
  filialen: FilialeSimple[] = []
  auswertung?: AuswertungBetrieb = null
  auswertungFiliale?: AuswertungFiliale = null
  listWorking: boolean = false
  listError: boolean = false

  vonMenuOpen?: boolean = false
  bisMenuOpen?: boolean = false

  get auswertenButtonDisabled() {
    return this.datumBis == null
  }

  init() {
    this.loadFilialen()
  }

  loadFilialen() {
    var request = new IdRequest()
    request.id = vuexStore.state.user.betriebId

    Poster.postMember<RemoteResult<FilialeSimpleList>>(
      RestPaths.filialeListeSimple,
      request
    ).then((response) => {
      if (response.data.success) {
        this.filialen = response.data.payload.list
      }
    })
  }

  auswertenFiliale() {
    this.listWorking = true

    var request = new AuswertungRequest()
    request.betriebId = this.filiale
    request.datumVon = this.datumVon
    request.datumBis = this.datumBis

    Poster.postMember<RemoteResult<AuswertungFiliale>>(RestPaths.auswertungFiliale, request).then((response)=> {
      if(response.data.success) {
        this.auswertungFiliale = response.data.payload
      }
      this.listWorking = false
    })
  }

  auswerten() {
    this.listWorking = true

    var request = new AuswertungRequest()
    request.betriebId = store.state.user.betriebId
    request.datumVon = this.datumVon
    request.datumBis = this.datumBis

    Poster.postMember<RemoteResult<AuswertungBetrieb>>(
      RestPaths.auswertungBetrieb,
      request
    )
      .then((response) => {
        this.listWorking = false

        if (response.data.success) {
          this.auswertung = response.data.payload
          this.listError = false
        } else {
          this.listError = true
        }
      })
      .then(() => {
        this.listWorking = false
        this.listError = true
      })
  }
}
