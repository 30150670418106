import FilialeSimple from '@/entities/member/filiale_simple'
import MitarbeiterInsertRequest from '@/entities/member/request/mitarbeiter_insert_request'
import Validator from '@/util/validator'
import { v4 as uuidv4 } from 'uuid'
import Poster from '@/util/poster'
import RemoteResult from '@/entities/common/remote_result'
import RestPaths from '@/util/rest_paths'
import MemberBaseLogic from '../member_base_logic'
import InputValidator from '@/util/input_validator'

export default abstract class MitarbeiterNeuMemcommonLogic extends MemberBaseLogic {
  abstract get filialen(): FilialeSimple[]

  filialeId?: string = null
  nachname?: string = null
  vorname?: string = null
  benutzerAnlegen: boolean = true
  strasse?: string = null
  plz?: string = null
  ort?: string = null
  telefon?: string = null
  email?: string = null
  benutzername?: string = null
  passwort?: string = null
  passwortWiederholen?: string = null
  admin: boolean = false

  passwortFalsch: boolean = false
  formDisabled: boolean = false
  einfuegenFertig: boolean = false
  insertError = false
  insertWorking: boolean = false

  abstract get betriebId(): string

  plzRules: any[] = Validator.plzValidate
  emailRules: any[] = Validator.emailValidate

  get passcheck() {
    return this.passwort === this.passwortWiederholen
      ? null
      : 'Passwörter nicht gleich'
  }

  get usernameRules() {
    return InputValidator.usernameRules
  }

  passwordRules: any[] = [
    (x: string) => {
      if (!x) return 'Bitte Passwort angeben'
      return true
    },
    (x: string) => {
      if (x && x.length < 6) {
        return 'Bitte min. 6 Zeichen'
      }
      return true
    },
  ]

  passwordWiederholenRules: any[] = [
    (x: string) => {
      if (!x) return 'Bitte Passwort angeben'
    },
    (x: string) => {
      if (x && x.length < 6) {
        return 'Bitte min. 6 Zeichen'
      }
    },
    //this.verifyPasswort,
  ]

  verifyPasswort() {
    if (this.passwort === this.passwortWiederholen) {
      return true
    } else {
      return 'Passwörter müssen übereinstimmen'
    }
  }

  einfuegen() {
    this.formDisabled = true
    this.insertError = false
    this.insertWorking = true

    var request = new MitarbeiterInsertRequest()
    request.id = uuidv4()
    request.betriebId = this.betriebId
    request.nachname = this.nachname
    request.vorname = this.vorname
    request.strasse = this.strasse
    request.plz = this.plz
    request.ort = this.ort
    request.telefon = this.telefon
    request.email = this.email
    request.benutzername = this.benutzername
    request.benutzerAnlegen = this.benutzerAnlegen
    request.passwort = this.passwort
    request.admin = this.admin

    Poster.postMember<RemoteResult<string>>(
      RestPaths.mitarbeiterEinfuegen,
      request
    )
      .then((response) => {
        this.insertWorking = false
        if (response.data.success) {
          this.einfuegenFertig = true
        } else {
          this.insertError = true
          this.formDisabled = false
        }
      })
      .catch(() => {
        this.insertWorking = false
        this.insertError = true
        this.formDisabled = false
      })
  }
}
