
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import { Prop } from 'vue-property-decorator'
import PreislistenFilialeMemcommonLogic from '@/logic/memcommon/preislisten_filiale_memcommon_logic'
import Pliste from '@/entities/member/pliste'
import PlisteSimple from '@/entities/member/pliste_simple'
import ToolbarButton from '../custom/ToolbarButton.vue'

@Component({ components: { ToolbarButton } })
export default class PreislistenFilialeMemCommon extends mixins(Calculator) {
  @Prop() logic: PreislistenFilialeMemcommonLogic

  detailsAnzeigen(p: PlisteSimple) {
    this.logic.detailsAnzeigen(p)
  }

  formatiereTageskarte(preisliste: PlisteSimple) {
    if (preisliste.tageskarte) {
      return 'Tageskarte'
    } else {
      return 'Preisliste allgemein'
    }
  }

  neuePreisliste() {
    this.logic.neuePreisliste()
  }

  neueTageskarte() {
    this.logic.neueTageskarte()
  }
}
