
import { Component, Vue } from 'vue-property-decorator'
import OeffnungszeitenLogic from '@/logic/member/oeffnungzeiten_logic'
import OeffnungszeitenMemCommon from '../memcommon/OeffnungszeitenMemCommon.vue'

@Component({ components: { OeffnungszeitenMemCommon } })
export default class Oeffnungszeiten extends Vue {
  logic?: OeffnungszeitenLogic = null

  mounted() {
    this.logic = new OeffnungszeitenLogic()
    this.logic.init(this.$route.query.id as string)
  }
}
