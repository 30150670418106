import Bestellung from "@/entities/common/bestellung";
import router from "@/router";
import store from "@/store";
import RouteNames from "@/util/route_names";
import StoreOps from "@/util/store_ops";

export default class DetailsSofortLogic {
  bestellung?: Bestellung = null

  init() {
    this.bestellung = store.state.bestellung.bestellung
  }

  serviceAnfordern() {
    router.push({ name: RouteNames.BESTELLUNG.SERVICE_ANFORDERN })
  }

  nachbestellen() {
    store.commit(StoreOps.BEGINNE_NACHBESTELLUNG, this.bestellung)
    router.push({ name: RouteNames.BESTELLUNG.NACHBESTELLEN.PRODUKTE })
  }

  bezahlen() {
    router.push({ name: RouteNames.BESTELLUNG.BEZAHLEN })
  }
}