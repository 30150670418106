
import NichtGueltig from '../custom/NichtGueltig.vue'
import Component, { mixins } from 'vue-class-component'
import FilialeHeader from '../custom/FilialeHeader.vue'
import WarenkorbArtikel from './sub/WarenkorbArtikel.vue'
import WarenkorbLieferungLogic from '@/logic/bestellung_neu/warenkorb_lieferung_logic'
import Calculator from '../mixins/Calculator.vue'

@Component({ components: { NichtGueltig, FilialeHeader, WarenkorbArtikel } })
export default class WarenkorbLieferung extends mixins(Calculator) {
  logic: WarenkorbLieferungLogic = new WarenkorbLieferungLogic()

  goToShopping() {
    this.logic.goToShopping()
  }

  goToBestaetigen() {
    this.logic.goToBestaetigen()
  }

  increment(artikel) {
    this.logic.increment(artikel)
  }

  decrement(artikel) {
    this.logic.decrement(artikel)
  }

  remove(artikel) {
    this.logic.remove(artikel)
  }

  get gesamtpreis(): number {
    var grundpreis = this.berechnePreisListe(this.logic.warenkorb)
    grundpreis = grundpreis + this.logic.lieferkosten
    return grundpreis
  }

  mounted() {
    this.logic = new WarenkorbLieferungLogic()
    this.logic.init()
  }
}
