
import Vue from 'vue'
import Component from 'vue-class-component'
import FilialeHeader from '../custom/FilialeHeader.vue'
import BezahlenLogic from '@/logic/bestellung/bezahlen_logic'

@Component({ components: { FilialeHeader } })
export default class Bezahlen extends Vue {
  logic?: BezahlenLogic = null

  mounted() {
    this.logic = new BezahlenLogic()
    this.logic.init()
  }

  weiter() {
    this.logic.weiter()
  }
}
