
import Component, { mixins } from 'vue-class-component'
import { AbholzeitType, LoadState } from '@/entities/common/enums'
import Calculator from '../mixins/Calculator.vue'
import ArtikelRow from '../custom/ArtikelRow.vue'
import FilialeHeader from '../custom/FilialeHeader.vue'
import NichtGueltig from '../custom/NichtGueltig.vue'
import BestaetigenLieferungLogic from '@/logic/bestellung_neu/bestaetigen_lieferung_logic'
import Formatter from '@/logic/formatter'
@Component({
  components: {
    ArtikelRow,
    FilialeHeader,
    NichtGueltig,
  },
})
export default class BestaetigenLieferung extends mixins(Calculator) {
  logic: BestaetigenLieferungLogic = null

  get bestaetigenLoading() {
    return this.logic.bestaetigenLoadState == LoadState.LOADING
  }

  mounted() {
    this.logic = new BestaetigenLieferungLogic()
    this.logic.init()
  }

  bestellungBestaetigen() {
    this.logic.bestellungBestaetigen()
  }

  formatiereAbholzeitpunkt() {
    if (this.logic.lieferungData.abholzeitType == AbholzeitType.BALDEST) {
      return 'schnellstmöglich'
    } else if (
      this.logic.lieferungData.abholzeitType == AbholzeitType.UHRZEIT
    ) {
      return Formatter.formatiereDatumUhrzeit(
        this.logic.lieferungData.gewaehlteUhrzeit
      )
    }
  }

  formatiereKundeName() {
    return (
      this.logic.lieferungData.kundeNachname +
      ', ' +
      this.logic.lieferungData.kundeVorname
    )
  }

  formatiereKundePlzOrt() {
    return (
      this.logic.lieferungData.kundePlz +
      ' ' +
      this.logic.lieferungData.kundeOrt
    )
  }
}
