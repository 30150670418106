import EigeneDaten from '@/entities/common/eigene_daten'
import RemoteResult from '@/entities/common/remote_result'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import router from '@/router'
import RouteNames from '@/util/route_names'

export default class MeinAccountLogic {
  eigeneDaten: EigeneDaten = null

  init() {
    Poster.postUser<RemoteResult<EigeneDaten>>(
      RestPaths.kundeEigeneDatenBenutzer,
      {}
    ).then((response) => {
      if (response.data.success) {
        this.eigeneDaten = response.data.payload
      }
    })
  }

  passwortAendern() {
    router.push({ name: RouteNames.BESTELLUNG.PASSWORT_AENDERN })
  }
}
