
import Vue from 'vue'
import Component from 'vue-class-component'
import MemberPageLogic from '@/logic/member_page_logic'
import store from '@/store'
import RestPaths from '@/util/rest_paths'
import StoreOps from '@/util/store_ops'
import StaticLogic from '@/logic/static_logic'
import { Watch } from 'vue-property-decorator'
import FilialeSimple from '@/entities/member/filiale_simple'
import router from '@/router'
import RouteNames from '@/util/route_names'

@Component
export default class MemberPage extends Vue {
  logic?: MemberPageLogic = null
  webSocket?: WebSocket = null
  filiale?: FilialeSimple = null

  showLoginPassword: boolean = false

  @Watch('$store.state.user.filiale', { deep: true, immediate: true })
  watchFiliale(newValue: FilialeSimple) {
    this.filiale = newValue
  }

  filialeWaehlen() {
    router.push({ name: RouteNames.MEMBER.FILIALE_SELECT })
  }

  mounted() {
    this.logic = new MemberPageLogic()
    this.logic.init()

    store.watch(
      (state) => state.user.loggedIn,
      (newValue) => {
        if (newValue != null) {
          this.webSocket = new WebSocket(RestPaths.urlBaseWs + 'member')
          this.webSocket.onopen = this.webserviceOpened
          this.webSocket.onmessage = this.webserviceOnMessage

          StaticLogic.instance.webSocket = this.webSocket
        }
      },
      { immediate: true, deep: true }
    )
  }

  logout() {
    this.logic.logout()
  }

  login() {
    this.logic.login()
  }

  showMemberLogin() {
    this.logic.showMemberLogin()
  }

  navHome() {
    this.logic.navHome()
  }

  navService() {
    this.logic.navService()
  }

  navKueche() {
    this.logic.navKueche()
  }

  navLieferungen() {
    this.logic.navLieferungen()
  }

  navBestellliste() {
    this.logic.navBestellliste()
  }

  navBestellungen() {
    this.logic.navBestellungen()
  }

  navReservierungen() {
    this.logic.navReservierungen()
  }

  navAuswertungen() {
    this.logic.navAuswertungen()
  }

  navMitarbeiter() {
    this.logic.navMitarbeiter()
  }

  navProdukte() {
    this.logic.navProdukte()
  }

  navVerfuegbarkeit() {
    this.logic.navVerfuegbarkeit()
  }

  navInhaltsstoffe() {
    this.logic.navInhaltsstoffe()
  }

  navFilialen() {
    this.logic.navFilialen()
  }

  navPreislisten() {
    this.logic.navPreislisten()
  }

  navOeffnungszeiten() {
    this.logic.navOeffnungszeiten()
  }

  navTische() {
    this.logic.navTische()
  }

  webserviceOpened() {
    console.log('Member socket opened')
    this.webSocket?.send(store.state.user.key)
  }

  webserviceOnMessage(message: MessageEvent) {
    var parsedObject = JSON.parse(message.data)
    store.commit(StoreOps.POST_INCOMING_MESSAGE, parsedObject)
  }
}
