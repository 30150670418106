
import Vue from 'vue'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import TischDetailsMemcommonLogic from '@/logic/memcommon/tisch_details_memcommon_logic'
import ToolbarButton from '../custom/ToolbarButton.vue'
import { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'

@Component({ components: { ToolbarButton } })
export default class TischDetailsMemCommon extends mixins(Calculator) {
  @Prop() logic: TischDetailsMemcommonLogic

  loeschenBestDialogVisible: boolean = false

  formatiereBoolean(value: boolean): string {
    return value ? 'ja' : 'nein'
  }

  aktivieren() {
    this.logic.aktivieren()
  }

  deaktivieren() {
    this.logic.deaktivieren()
  }

  tischBearbeiten() {
    this.logic.tischBearbeiten()
  }

  tischLoeschen() {
    this.loeschenBestDialogVisible = true
  }

  loeschenBestaetigen() {
    this.logic.loeschen()
    this.loeschenBestDialogVisible = false
  }
}
