
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ProduktNeuLogic from '@/logic/member/produkt_neu_logic'
import ProduktNeuMemCommon from '../memcommon/ProduktNeuMemCommon.vue'
import store from '@/store'

@Component({ components: { ProduktNeuMemCommon } })
export default class ProduktNeu extends Vue {
  logic?: ProduktNeuLogic = null

  mounted() {
    this.logic = new ProduktNeuLogic()
    this.logic.betriebId = store.state.user.betriebId
    this.logic.init()
  }
}
