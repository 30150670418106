
import Component from 'vue-class-component'
import { mixins } from 'vue-class-component'
import Calculator from '@/components/mixins/Calculator.vue'
import ArtikelRow from '../custom/ArtikelRow.vue'
import FilialeHeader from '../custom/FilialeHeader.vue'
import ReservierungBestellungLogic from '@/logic/bestellung/reservierung_bestellung_logic'
import ReservierungBestellung from './ReservierungBestellung.vue'

@Component({ components: { ArtikelRow, FilialeHeader } })
export default class ReservierungBestellungBestaetigen extends mixins(
  Calculator
) {
  logic?: ReservierungBestellungLogic = null

  mounted() {
    this.logic = (this.$parent as ReservierungBestellung).logic
  }

  backToOverview() {
    this.logic.backToOverview()
  }

  bestellungBestaetigen() {
    this.logic.bestellungAufgeben()
  }
}
