import Inhaltsstoff from "@/entities/common/inhaltsstoff"

export default class PduktInsertRequest {
  id?: string
  index?: number
  betriebId?: string
  basiskategorieId?: string
  bezeichnung?: string
  beschreibung?: string
  artikelNr?: string
  bildId?: string
  vegetarisch?: boolean = false
  vegan?: boolean = false
  hot1?: boolean = false
  hot2?: boolean = false
  hot3?: boolean = false
  extraZusatz?: boolean = false
  standardpreis: number = 0.0
  kategorieId?: string
  menu?: boolean = false
  inhaltsstoffe?: string[] = []
  extras?: Extra[] = []
  subprodukte?: Subprodukt[] = []
  auswahlen?: Auswahl[] = []
}

class Extra {
  id?: string
  index?: number
  produktId?: string
}

class Auswahl {
  id?: string
  index?: number
  bezeichnung?: string
  beschreibung?: string
  errorText?: string
  required?: boolean = false
  optionen?: Option[] = []
  defaultOptionId?: string = null
}

class Subprodukt {
  id?: string
  index?: number
  bezeichnung?: string
  beschreibung?: string
  errorText?: string
  required?: boolean = false
  optionen?: Option[] = []
}

class Option {
  id?: string
  index?: number
  produktId?: string
}

export {Extra, Auswahl, Option, Subprodukt}
