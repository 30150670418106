
import moment from 'moment'
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import Calculator from '../mixins/Calculator.vue'
import BestellungAbholung from './BestellungAbholung.vue'
import BestellungReservierung from './BestellungReservierung.vue'
import BestellungSofort from './BestellungSofort.vue'
import BestellungLieferung from './BestellungLieferung.vue'
import BestellungRow from './BestellungRow.vue'
import BestellungenLogic from '@/logic/member/bestellungen_logic'

@Component({
  components: {
    BestellungAbholung,
    BestellungReservierung,
    BestellungSofort,
    BestellungLieferung,
    BestellungRow,
  },
})
export default class Bestellungen extends mixins(Calculator) {
  logic: BestellungenLogic = null

  removeBestellung() {
    this.logic.removeBestellung()
  }

  filterAnwenden() {
    this.logic.updateBestellungen(null)
  }

  detailsAnzeigen(bestellung, index) {
    this.logic.index = index
    this.logic.details = bestellung
    var rightPane = document.getElementById('rightPane')
    rightPane.scrollIntoView({ behavior: 'smooth' })
  }

  resize() {
    var leftPane = document.getElementById('leftPane')
    var rightPane = document.getElementById('rightPane')
    var rectLeft = leftPane.getBoundingClientRect()
    var rectRight = rightPane.getBoundingClientRect()
    const newHeightLeft = window.innerHeight - rectLeft.top
    const newHeightRight = window.innerHeight - rectRight.top
    leftPane.style.height = newHeightLeft - 12 + 'px'
    rightPane.style.height = newHeightRight - 12 + 'px'
  }

  mounted() {
    this.logic = new BestellungenLogic()
    this.logic.init(
      this.$route.query.filiale as string,
      this.$route.query.datum as string,
      () => {
        this.resize()
      }
    )
    this.resize()

    window.onresize = () => {
      this.resize()
    }

    setInterval(this.durationSet, 1000)
  }

  beforeDestroy() {}

  durationSet() {
    //this.logic.tick()
  }
}
