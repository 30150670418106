
import PasswortAendernLogic from '@/logic/passwort_aendern_logic'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class PasswortVergessen extends Vue {
  $refs!: {
    form: HTMLFormElement
  }

  logic?: PasswortAendernLogic = null

  mounted() {
    this.logic = new PasswortAendernLogic()
    this.logic.init(this.$route.params.id)
  }

  passwortAendern() {
    var valid = this.$refs.form.validate()
    if (!valid) return

    this.logic.passwortAendern()
  }
}
