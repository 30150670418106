
import Component from 'vue-class-component'
import ProduktExtrasLogic from '@/logic/member/produkt_extras_logic'
import ProduktExtrasMemCommon from '@/components/memcommon/ProduktExtrasMemCommon.vue'
import Vue from 'vue'
import store from '@/store'

@Component({ components: { ProduktExtrasMemCommon } })
export default class ProduktExtras extends Vue {
  logic?: ProduktExtrasLogic = null

  mounted() {
    this.logic = new ProduktExtrasLogic()
    this.logic.betriebId = store.state.user.betriebId
    this.logic.init()
  }
}
