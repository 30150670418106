export default class FilialeInsertRequest {
  id?: string
  aktiv: boolean = true
  bezeichnung?: string
  strasse?: string
  plz?: string
  ort?: string
  telefon?: string
  email?: string
  betriebId?: string
  latitude?: number
  longitude?: number
  timeZoneId?: number
  tempBildId? : string
  tempBannerId? : string
  supportsAbholung: boolean = false
  supportsReservierung: boolean = false
  supportsSofortbestellung: boolean = false
  supportsLieferung: boolean = false
  supportsPaypal: boolean = false
  abholungSettings?: AbholungSettings
  reservierungSettings?: ReservierungSettings
  sofortbestellungSettings?: SofortbestellungSettings
  lieferungSettings?: LieferungSettings
  paypalSettings?: PaypalSettings
}

class AbholungSettings {
  supportsVorbestellung: boolean = false
  supportsMehrereTage: boolean = false
  zeitVorSchliessung?: number
  vorbestellungTage?: number
}

class ReservierungSettings {
  supportsVorbestellung: boolean = false
  zeitVorSchliessung?: number
  reservierungTage?: number
}

class SofortbestellungSettings {
  zeitVorSchliessung?: number
}

class LieferungSettings {
  supportsTermin: boolean = false
  terminTage?: number
}

class PaypalSettings {
  paypalAdresse?: string
}

export { AbholungSettings, ReservierungSettings, SofortbestellungSettings, LieferungSettings, PaypalSettings }
