
import BstlArtikel from '@/entities/common/bstl_artikel'
import ShoppingLogic from '@/logic/bestellung_neu/shopping_logic'
import Component from 'vue-class-component'
import { Vue, Watch } from 'vue-property-decorator'
import NichtGueltig from '../custom/NichtGueltig.vue'
import Shopping from './Shopping.vue'

@Component({ components: { NichtGueltig } })
export default class ShoppingArtikel extends Vue {
  logic?: ShoppingLogic = null

  added(value: BstlArtikel) {
    this.logic.addArtikel(value)
  }

  @Watch('$parent.logic', { immediate: true, deep: false })
  watchParentLogic() {
    this.logic = (this.$parent as Shopping).logic
    this.logic.createdListener = () => {
      if (this.logic.artikel == null) {
        this.logic.reloadArtikel(this.$route.params.id)
      }
    }
  }

  mounted() {
    this.logic = (this.$parent as Shopping).logic
    this.logic.createdListener = () => {
      if (this.logic.artikel == null) {
        this.logic.reloadArtikel(this.$route.params.id)
      }
    }
  }
}
