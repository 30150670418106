
import Vue from 'vue'
import Component from 'vue-class-component'
import TageskarteDetailsLogic from '@/logic/member/tageskarte_details_logic'
import TageskarteDetailsMemCommon from '../memcommon/TageskarteDetailsMemCommon.vue'

@Component({components: {TageskarteDetailsMemCommon}})
export default class TageskarteDetails extends Vue {
  logic: TageskarteDetailsLogic = null

  mounted() {
    this.logic = new TageskarteDetailsLogic()
    this.logic.init(this.$route.params.preislisteId)
  }
}
