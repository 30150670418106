
import Vue from "vue";
import Component from "vue-class-component";
import BezahlungWeiterleitenLogic from '@/logic/bestellung/bezahlung_weiterleiten_logic'

@Component
export default class BezahlungWeiterleiten extends Vue {
  logic = new BezahlungWeiterleitenLogic

  mounted() {
    this.logic.init(this.$route.params.bestellungId)
  }
}
