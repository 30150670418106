import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Basiskategorie from '@/entities/member/basiskategorie'
import betrieb from '@/entities/member/betrieb'
import BasiskategorieList from '@/entities/member/lists/basiskategorie_list'
import BasiskategorieInsertRequest from '@/entities/member/request/basiskategorie_insert_request'
import BasiskategorieUpdateRequest from '@/entities/member/request/basiskategorie_update_request'
import Tischsperrung from '@/entities/member/tischsperrung'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import { v4 as uuidv4 } from 'uuid'
import TischeLogic from '../member/tische_logic'
import BasiskategorienMemcommonLogic from '../memcommon/basiskategorien_memcommon_logic'
import BetriebDetailsLogic from './betrieb_details_logic'
import BetriebObserver from './betrieb_observer'
import TischDetailsLogic from './tisch_details_logic'

export default class BasiskategorienLogic extends BasiskategorienMemcommonLogic {
  // liste?: Basiskategorie[] = []
  // neuDialogVisible: boolean = false
  // editDialogVisible: boolean = false
  // detailDialogVisible: boolean = false
  // kategorie = new Kategorie()
  // editKategorie?: Kategorie = null
  // insertCompleted: boolean = false
  // insertFormDisabled: boolean = false
  // insertWorking: boolean = false
  // insertError: boolean = false
  // editCompleted: boolean = false
  // editFormDisabled: boolean = false
  // editWorking: boolean = false
  // editError: boolean = false
  // listWorking: boolean = false
  // listError: boolean = false

  // parent?: BetriebDetailsLogic = null

  // notifyBetriebChanged(betrieb: betrieb) {
  //   if (betrieb) {
  //     var request = new IdRequest()
  //     request.id = betrieb.id

  //     Poster.postMember<RemoteResult<BasiskategorieList>>(
  //       RestPaths.urlBase + RestPaths.artikelBasiskategorieList,
  //       request
  //     ).then((response) => {
  //       this.liste = response.data.payload.list
  //     })
  //   }
  // }

  // bearbeiten(kategorie: Basiskategorie, event) {
  //   this.editKategorie = new Kategorie()
  //   this.editKategorie.id = kategorie.id
  //   this.editKategorie.bezeichnung = kategorie.bezeichnung
  //   this.kategorie = kategorie
  //   this.editDialogVisible = true
  //   event.stopPropagation()
  // }

  // speichern() {
  //   this.editWorking = true
  //   this.editFormDisabled = true
  //   this.kategorie.bezeichnung = this.editKategorie.bezeichnung

  //   var request = new BasiskategorieUpdateRequest()
  //   request.id = this.kategorie.id
  //   request.bezeichnung = this.kategorie.bezeichnung

  //   Poster.postMember<RemoteResult<Basiskategorie>>(
  //     RestPaths.urlBase + RestPaths.artikelBasiskategorieSpeichern,
  //     request
  //   )
  //     .then((response) => {
  //       this.editWorking = false
  //       if (response.data.success) {
  //         this.editDialogVisible = false
  //         this.editError = false
  //         this.editFormDisabled = false
  //       } else {
  //         this.editError = true
  //         this.editFormDisabled = false
  //       }
  //     })
  //     .catch(() => {
  //       this.editWorking = false
  //       this.editError = true
  //       this.editFormDisabled = false
  //     })
  // }

  // detailsAnzeigen() {}

  // neueKategorieEinfuegen() {
  //   var request = new BasiskategorieInsertRequest()
  //   request.betriebId = this.parent.betrieb.id
  //   request.id = uuidv4()
  //   request.bezeichnung = this.kategorie.bezeichnung

  //   Poster.postMember(
  //     RestPaths.urlBase + RestPaths.artikelBasiskategorieEinfuegen,
  //     request
  //   ).then(() => {
  //     this.neuDialogVisible = false
  //   })
  // }

  // neueKategorie() {
  //   this.neuDialogVisible = true
  //   this.kategorie = new Kategorie()
  // }

  // init(parent: BetriebDetailsLogic) {
  //   this.parent = parent
  //   this.parent.attachObserver(this)
  //   this.notifyBetriebChanged(parent.betrieb)
  // }
}

class Kategorie {
  id?: string
  bezeichnung?: string
}
