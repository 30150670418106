
import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import FilialeSimple from '@/entities/member/filiale_simple'
import FilialeSimpleList from '@/entities/member/lists/filiale_simple_list'
import ProduktShortcut from '@/entities/member/produkt_shortcut'
import ProduktShortcutUpdateRequest from '@/entities/member/request/produkt_shortcut_update_request'
import vuexStore from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class Shortcuts extends Vue {
  filialen?: FilialeSimple[] = []
  _filiale?: string
  shortcuts?: ProduktShortcut = null

  get filiale() {
    return this._filiale
  }

  set filiale(newValue: string) {
    this._filiale = newValue
    if(newValue) {
      var request = new IdRequest()
      request.id = newValue

      Poster.postMember<RemoteResult<ProduktShortcut>>(RestPaths.artikelPduktShortcuts, request)
        .then(response => {
          if(response.data.success) {
            this.shortcuts = response.data.payload
          }
        })
    }
  }

  mounted() {
    var request = new IdRequest()
    request.id = vuexStore.state.user.betriebId

    Poster.postMember<RemoteResult<FilialeSimpleList>>(
      RestPaths.filialeListeSimple,
      request
    ).then((response) => {
      this.filialen = response.data.payload.list
    })
  }

  speichern() {
    var request = new ProduktShortcutUpdateRequest()
    console.log(this._filiale)
    request.filialeId = this._filiale
    request.produktA = this.shortcuts.produktA
    request.produktB = this.shortcuts.produktB
    request.produktC = this.shortcuts.produktC
    request.produktD = this.shortcuts.produktD

    Poster.postMember<RemoteResult<any>>(RestPaths.artikelUpdatePduktShortcuts, request)
      .then(response => {
        if(response.data.success) {
          console.log("gespeichert")
        }
      })
  }
}
