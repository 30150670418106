import Bestellung from '@/entities/common/bestellung'
import DateTimeCapsule from '@/entities/common/data_time_capsule'
import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import ReservierungEinbuchungRequest from '@/entities/common/request/reservierung_einbuchung_request'
import router from '@/router'
import store from '@/store'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import RouteNames from '@/util/route_names'
import StoreOps from '@/util/store_ops'

export default class DetailsReservierungLogic {
  bestellung?: Bestellung = null
  einbuchenWorking: boolean = false
  einbuchenError: boolean = false
  einbuchenCompleted: boolean = false

  stornierenWorking: boolean = false
  stornierenError: boolean = false
  stornierenCompleted: boolean = false

  init() {
    this.bestellung = store.state.bestellung.bestellung
  }

  serviceAnfordern() {
    router.push({ name: RouteNames.BESTELLUNG.SERVICE_ANFORDERN })
  }

  nachbestellen() {
    store.commit(StoreOps.BEGINNE_NACHBESTELLUNG, this.bestellung)
    router.push({ name: RouteNames.BESTELLUNG.NACHBESTELLEN.PRODUKTE })
  }

  vorbestellen() {
    store.commit(StoreOps.BEGINNE_VORBESTELLUNG, this.bestellung)
    router.push({ name: RouteNames.BESTELLUNG.VORBESTELLUNG.PRODUKTE })
  }

  bestellen() {
    store.commit(StoreOps.BEGINNE_BESTELLUNG, this.bestellung)
    router.push({
      name: RouteNames.BESTELLUNG.RESERVIERUNG_BESTELLUNG.PRODUKTE,
    })
  }

  einbuchen() {
    this.einbuchenWorking = true
    this.einbuchenError = false
    this.einbuchenCompleted = false

    var request = new ReservierungEinbuchungRequest()
    request.id = this.bestellung.id
    request.bestellart = 'SERVICE'

    Poster.postUser<RemoteResult<DateTimeCapsule>>(
      RestPaths.bestellungKundeReservierungEinbuchen,
      request
    )
      .then((response) => {
        this.einbuchenWorking = false
        if (response.data.success) {
          this.bestellung.reservierung.eingebucht = true
          this.einbuchenCompleted = true
        } else {
          this.einbuchenError = true
        }
      })
      .catch(() => {
        this.einbuchenWorking = false
        this.einbuchenError = true
      })
  }

  bezahlen() {
    router.push({ name: RouteNames.BESTELLUNG.BEZAHLEN })
  }

  stornieren(handler: () => void) {
    this.stornierenWorking = true
    this.stornierenError = false
    this.stornierenCompleted = false

    var request = new IdRequest()
    request.id = this.bestellung.id

    Poster.postUser<RemoteResult<DateTimeCapsule>>(
      RestPaths.bestellungKundeStornieren,
      request
    )
      .then((response) => {
        this.stornierenWorking = false
        if (response.data.success) {
          this.stornierenCompleted = true
          this.bestellung.storniert = true
          this.bestellung.abgeschlossen = true
          handler()
        } else {
          this.stornierenError = true
        }
      })
      .catch(() => {
        this.stornierenWorking = false
        this.stornierenError = true
      })
  }
}
