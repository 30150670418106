import RemoteResult from '@/entities/common/remote_result'
import IdRequest from '@/entities/common/request/id_request'
import Filiale from '@/entities/member/filiale'
import OeffnungszeitZeitraumList from '@/entities/member/lists/oeffnungszeit_zeitraum_list'
import OeffnungszeitZeitraum from '@/entities/member/oeffnungszeit_zeitraum'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import MemberBaseLogic from '../member_base_logic'

export default abstract class OeffnungszeitenFilialeMemcommonLogic extends MemberBaseLogic {
  filiale?: Filiale = null
  oeffnungListe: OeffnungszeitZeitraum[] = []
  loadError: boolean = false

  abstract zeigeDetails(oeffnungszeit: OeffnungszeitZeitraum)

  abstract ausnahmenHinzufuegen()

  abstract neueOeffnungszeit()

  init(filialeId: string) {
    var request = new IdRequest()
    request.id = filialeId

    Poster.postMember<RemoteResult<Filiale>>(
      RestPaths.filialeDetails,
      request
    ).then((response) => {
      if (response.data.success) {
        this.filiale = response.data.payload
      }
    })

    Poster.postMember<RemoteResult<OeffnungszeitZeitraumList>>(
      RestPaths.oeffnungszeitZeitraumListe,
      request
    ).then((response) => {
      if (response.data.success) {
        this.oeffnungListe = response.data.payload.list
      }
    })
  }
}
