import Inhaltsstoff from '@/entities/common/inhaltsstoff'
import InhaltsstoffInsertRequest from '@/entities/member/request/inhaltsstoff_insert_request'
import InhaltsstoffUpdateRequest from '@/entities/member/request/inhaltsstoff_update_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import { v4 as uuidv4 } from 'uuid'
import store from '@/store'
import IdRequest from '@/entities/common/request/id_request'
import RemoteResult from '@/entities/common/remote_result'
import InhaltsstoffList from '@/entities/member/lists/inhaltsstoff_list'
import MemberBaseLogic from '../member_base_logic'

export default class InhaltsstoffeMemcommonLogic extends MemberBaseLogic {
  inhaltsstoffe?: Inhaltsstoff[] = []
  inhaltsstoff: Inhaltsstoff = new Inhaltsstoff()
  neuerInhaltsstoff: Inhaltsstoff = new Inhaltsstoff()
  editDialogOpen = false
  newDialogOpen = false
  editInhaltsstoff: Inhaltsstoff = null

  bezeichnungRules = [(v) => !!v || 'Bitte Bezeichnung angeben']

  einfuegenFormDisabled: boolean = false
  einfuegenError: boolean = false

  betriebId: string = null

  loadError: boolean = false

  editWorking: boolean = false
  editFormDisabled: boolean = false
  editError: boolean = false

  init() {
    var request = new IdRequest()
    request.id = this.betriebId

    Poster.postMember<RemoteResult<InhaltsstoffList>>(
      RestPaths.inhaltsstoffeListe,
      request
    )
      .then((response) => {
        if (response.data.success) {
          this.inhaltsstoffe = response.data.payload.list
        } else {
          this.loadError = true
        }
      })
      .catch(() => {
        this.loadError = true
      })
  }

  speichern() {
    this.editWorking = true
    this.editFormDisabled = true
    this.editError = true

    this.inhaltsstoff.bezeichnung = this.editInhaltsstoff.bezeichnung
    this.inhaltsstoff.beschreibung = this.editInhaltsstoff.beschreibung

    var request = new InhaltsstoffUpdateRequest()
    request.id = this.inhaltsstoff.id
    request.bezeichnung = this.inhaltsstoff.bezeichnung
    request.beschreibung = this.inhaltsstoff.beschreibung

    Poster.postMember<RemoteResult<Inhaltsstoff>>(
      RestPaths.inhaltsstoffeUpdate,
      request
    )
      .then((response) => {
        if (response.data.success) {
          this.editWorking = false
          this.editFormDisabled = false
          this.editDialogOpen = false
          this.editInhaltsstoff = null
          this.inhaltsstoff = null
        } else {
          this.editWorking = false
          this.editError = true
        }
      })
      .catch(() => {
        this.editWorking = false
        this.editError = true
      })
  }

  einfuegen() {
    this.einfuegenFormDisabled = true
    this.einfuegenError = false

    var request = new InhaltsstoffInsertRequest()
    request.id = uuidv4()
    request.betriebId = store.state.user.betriebId
    request.bezeichnung = this.neuerInhaltsstoff.bezeichnung
    request.beschreibung = this.neuerInhaltsstoff.beschreibung

    Poster.postMember<RemoteResult<Inhaltsstoff>>(
      RestPaths.inhaltsstoffeInsert,
      request
    ).then((response) => {
      if (response.data.success) {
        this.newDialogOpen = false
        this.inhaltsstoffe.push(response.data.payload)
        this.neuerInhaltsstoff = new Inhaltsstoff()
      } else {
        this.einfuegenError = true
      }
    }).catch(()=> {
      this.einfuegenError = true
    })
  }

  bearbeiten(inhaltsstoff: Inhaltsstoff) {
    this.inhaltsstoff = inhaltsstoff
    this.editInhaltsstoff = new Inhaltsstoff()
    this.editInhaltsstoff.bezeichnung = inhaltsstoff.bezeichnung
    this.editInhaltsstoff.beschreibung = inhaltsstoff.beschreibung
    this.editDialogOpen = true
  }

  neuenErstellen() {
    this.inhaltsstoff = new Inhaltsstoff()
    this.newDialogOpen = true
  }
}
