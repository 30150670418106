
import TischeLogic from '@/logic/system/tische_logic'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import BetriebDetails from './BetriebDetails.vue'
import TischeMemCommon from '../memcommon/TischeMemCommon.vue'

@Component({ components: { TischeMemCommon } })
export default class Tische extends Vue {
  logic?: TischeLogic = null

  mounted() {
    this.logic = new TischeLogic()
    this.logic.parent = (this.$parent as BetriebDetails).logic
    this.logic.init()
  }
}
