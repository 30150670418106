
import ProduktDetailsLogic from '@/logic/system/produkt_details_logic'
import Vue from 'vue'
import Component from 'vue-class-component'
import ProduktDetailsMemCommon from '../memcommon/ProduktDetailsMemCommon.vue'

@Component({ components: { ProduktDetailsMemCommon } })
export default class ProduktDetails extends Vue {
  logic?: ProduktDetailsLogic = null

  mounted() {
    this.logic = new ProduktDetailsLogic()
    this.logic.init(this.$route.params.id)
  }
}
