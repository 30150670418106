
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Calculator from '../mixins/Calculator.vue'
import OeffnungszeitFilialeMemcommonLogic from '@/logic/memcommon/oeffnungszeit_filiale_memcommon_logic'
import OeffnungstagVorlage from '@/entities/member/oeffnungstag_vorlage'
import { ZeitraumData } from '@/entities/member/request/oeffnungszeit_zeitraum_update_request'

@Component
export default class OeffnungszeitFilialeMemCommon extends mixins(Calculator) {
  @Prop() logic: OeffnungszeitFilialeMemcommonLogic
  formValid: boolean = true

  $refs: {
    form: HTMLFormElement
  }

  gueltigVonRules: any[] = [(v) => !!v || 'Bitte Datum angeben']

  gebeOeffnungszeitMin(vorlage: OeffnungstagVorlage, index: number): string {
    return this.logic.gebeOeffnungszeitMin(vorlage, index)
  }

  change(event: boolean, vorlage: OeffnungstagVorlage) {
    if (event == true) {
      vorlage.oeffnungszeiten.push(new ZeitraumData())
    }
  }

  checkExtraButtonDisabled(
    vorlage: OeffnungstagVorlage,
    index: number
  ): boolean {
    return this.logic.checkExtraButtonDisabled(vorlage, index)
  }

  zeitEntfernen(vorlage: OeffnungstagVorlage, index: number) {
    vorlage.oeffnungszeiten.splice(index, 1)
  }

  zurueckZurUebersicht() {
    // this.logic.zurueckZurUebersicht()
  }

  extraZeitHinzufuegen(vorlage) {
    vorlage.addOeffnungszeit()
  }

  zeitVonSpeichern(vorlage: ZeitraumData) {
    vorlage.zeitVonVisible = false
  }

  zeitBisSpeichern(vorlage: ZeitraumData) {
    vorlage.zeitBisVisible = false
  }

  checkVon(vorlage: OeffnungstagVorlage, oeffnungszeit: ZeitraumData): any[] {
    if (vorlage.geoeffnet) {
      if (oeffnungszeit.zeitVon == null) return ['Bitte Zeit angeben']
    }
    return [true]
  }

  checkBis(vorlage: OeffnungstagVorlage, oeffnungszeit: ZeitraumData): any[] {
    if (vorlage.geoeffnet) {
      if (oeffnungszeit.zeitBis == null) return ['Bitte Zeit angeben']
    }
    return [true]
  }

  oeffnungszeitenSpeichern() {
    this.invalidate()

    var valid = this.validate()
    this.formValid = this.$refs.form.validate()
    if (valid && this.formValid) {
      if (!this.formValid) return
      this.logic.speichern()
    }
  }

  validate(): boolean {
    var valid: boolean = true
    this.logic.wochentage.forEach((v) => {
      if (v.geoeffnet) {
        v.oeffnungszeiten.forEach((o) => {
          if (o.zeitVon == null) {
            o.zeitVonError = 'Bitte Zeit wählen'
            valid = false
          }
          if (o.zeitBis == null) {
            o.zeitBisError = 'Bitte Zeit wählen'
          }
        })
      }
    })
    return valid
  }

  invalidate() {
    this.logic.wochentage.forEach((v) => {
      v.oeffnungszeiten.forEach((o) => {
        o.zeitVonError = null
        o.zeitBisError = null
      })
    })
  }
}
