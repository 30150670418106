import router from '@/router'
import RouteNames from '@/util/route_names'
import FilialeDetailsMemcommonLogic from '../memcommon/filiale_details_memcommon_logic'

export default class FilialeDetailsLogic extends FilialeDetailsMemcommonLogic {
  oeffnungszeitenFilialeBearbeiten() {
    throw new Error('Method not implemented.')
  }
  
  oeffnungszeitenBearbeiten() {
    router.push({
      name: RouteNames.SYSTEM.OEFFNUNGSZEITEN_FILIALE,
      params: { filialeId: this.filiale.id },
    })
  }
  preislistenBearbeiten() {
    router.push({
      name: RouteNames.SYSTEM.PREISLISTEN_FILIALE,
      params: { filialeId: this.filiale.id },
    })
  }
  tischeBearbeiten() {
    router.push({
      name: RouteNames.SYSTEM.TISCHE_FILIALE,
      params: { filialeId: this.filiale.id },
    })
  }
  liefergebietZuordnen(): void {
    throw new Error('Method not implemented.')
  }

  get isAdmin(): boolean {
    return true
  }
  navBearbeiten(): void {
    router.push({
      name: RouteNames.SYSTEM.FILIALE_EDIT,
      params: { id: this.filiale.id },
    })
  }
}
