
import Vue from 'vue'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import Inhaltsstoff from '@/entities/common/inhaltsstoff'
import ToolbarButton from '../custom/ToolbarButton.vue'
import ProduktEditMemcommonLogic from '@/logic/memcommon/produkt_edit_memcommon_logic'

@Component({ components: { ToolbarButton } })
export default class ProduktEditMemCommon extends Vue {
  @Prop() logic: ProduktEditMemcommonLogic

  $refs: {
    editForm: HTMLFormElement
    inhaltsstoffForm: HTMLFormElement
  }

  neueAuswahlErstellen() {
    this.logic.neueAuswahlErstellen()
  }

  removeInhaltsstoff(inh: Inhaltsstoff) {
    this.logic.removeInhaltsstoff(inh)
  }

  pushInhaltsstoff(inh: Inhaltsstoff) {
    this.logic.pushInhaltsstoff(inh)
  }

  neueAuswahlEinfuegen() {
    this.logic.neueAuswahlEinfuegen()
  }

  neueAuswahlAbbrechen() {
    this.logic.neueAuswahlAbbrechen()
  }

  inhaltsstoffeVerwalten() {
    this.logic.inhaltsstoffeVerwalten()
  }

  neuesSubproduktErstellen() {
    this.logic.neuesSubproduktErstellen()
  }

  neuesSubproduktEinfuegen() {
    this.logic.neuesSubproduktEinfuegen()
  }

  neuesSubproduktAbbrechen() {
    this.logic.neuesSubproduktAbbrechen()
  }

  subproduktBearbeiten(index: number) {
    this.logic.subproduktBearbeiten(index)
  }

  subproduktEntfernen(index: number) {
    this.logic.subproduktEntfernen(index)
  }

  moveSubproduktUp(index: number) {
    this.logic.moveSubproduktUp(index)
  }

  moveSubproduktDown(index: number) {
    this.logic.moveSubproduktDown(index)
  }

  neuesExtraErstellen() {
    this.logic.neuesExtraErstellen()
  }

  neuesExtraEinfuegen() {
    this.logic.neuesExtraEinfuegen()
  }

  neuesExtraAbbrechen() {
    this.logic.neuesExtraAbbrechen()
  }

  extrasSpeichern() {
    this.logic.extrasSpeichern()
  }

  extrasAbbrechen() {
    this.logic.extrasAbbrechen()
  }

  extraEntfernen(index: number) {
    this.logic.extraEntfernen(index)
  }

  moveExtraUp(index: number) {
    this.logic.moveExtraUp(index)
  }

  moveExtraDown(index: number) {
    this.logic.moveExtraDown(index)
  }

  neuerInhaltsstoffErstellen() {
    this.logic.neuerInhaltsstoffErstellen()
  }

  neuerInhaltsstoffAbbrechen() {
    this.logic.neuerInhaltsstoffAbbrechen()
  }

  neuerInhaltsstoffEinfuegen() {
    this.logic.neuerInhaltsstoffEinfuegen()
  }

  inhaltsstoffeSpeichern() {
    this.logic.inhaltsstoffeSpeichern()
  }

  inhaltsstoffeAbbrechen() {
    this.logic.inhaltsstoffeAbbrechen()
  }

  moveInhaltsstoffUp(index: number) {}

  moveInhaltsstoffDown(index: number) {}

  inhaltsstoffEntfernen(index: number) {
    this.logic.inhaltsstoffEntfernen(index)
  }

  extraHinzufuegen(extra) {
    this.logic.extraHinzufuegen(extra)
  }

  extraBearbeiten() {
    this.logic.extraBearbeiten()
  }

  pushExtra(extra) {
    this.logic.pushExtra(extra)
  }

  removeExtra(extra) {
    this.logic.removeExtra(extra)
  }

  speichern() {
    var valid = this.$refs.editForm.validate()
    if (valid) {
      this.logic.speichern()
    }
  }

  auswahlBearbeiten(index: number) {
    this.logic.auswahlBearbeiten(index)
  }

  auswahlEntfernen(index: number) {
    this.logic.auswahlEntfernen(index)
  }

  moveAuswahlUp(index: number) {
    this.logic.moveAuswahlUp(index)
  }

  moveAuswahlDown(index: number) {
    this.logic.moveAuswahlDown(index)
  }

  previewImage() {
    this.logic.previewImage()
  }

  navBack() {
    this.logic.navBack()
  }
}
