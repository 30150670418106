import Inhaltsstoff from '@/entities/common/inhaltsstoff'
import Pdukt from '@/entities/member/pdukt'
import PduktEditRequest from '@/entities/member/request/pdukt_edit_request'
import PduktInsertRequest from '@/entities/member/request/pdukt_insert_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import { v4 as uuidv4 } from 'uuid'
import store from '@/store'
import PduktRequest from '@/entities/member/request/pdukt_request'
import IdRequest from '@/entities/common/request/id_request'
import RemoteResult from '@/entities/common/remote_result'
import InhaltsstoffList from '@/entities/member/lists/inhaltsstoff_list'
import PduktList from '@/entities/member/lists/pdukt_list'
import MemberBaseLogic from '../member_base_logic'

export default class ProduktExtrasMemcommonLogic extends MemberBaseLogic {
  formDisabled: boolean = false
  wirdEingefuegt: boolean = false
  einfuegenCompleted: boolean = false
  einfuegenError: boolean = false

  produkte?: Pdukt[] = []
  inhaltsstoffe?: Inhaltsstoff[] = []
  selectedInhaltsstoffe?: Inhaltsstoff[] = []
  produktNeu?: ProduktNeu = null
  aktuellesProdukt?: Pdukt = null
  editProdukt?: Pdukt = null

  availableInhaltsstoffe?: Inhaltsstoff[] = []
  newInhaltsstoffe?: Inhaltsstoff[] = []

  neuDialogVisible: boolean = false
  editDialogVisible: boolean = false
  detailDialogVisible: boolean = false
  inhaltsstoffeDialogVisible: boolean = false
  inhaltsstoffeEditDialogVisible: boolean = false

  editWorking: boolean = false
  editError: boolean = false

  betriebId: string = null

  extraSpeichern() {
    this.editWorking = true
    this.editError = false

    var request = new PduktEditRequest()
    request.id = this.editProdukt.id
    request.basiskategorieId = this.editProdukt.kategorieId
    request.bezeichnung = this.editProdukt.bezeichnung
    request.beschreibung = this.editProdukt.beschreibung
    request.artikelNr = this.editProdukt.artikelNr
    request.vegetarisch = this.editProdukt.vegetarisch
    request.vegan = this.editProdukt.vegan
    request.hot1 = this.editProdukt.hot1
    request.hot2 = this.editProdukt.hot2
    request.hot3 = this.editProdukt.hot3
    request.standardpreis = this.editProdukt.standardpreis

    this.editProdukt.inhaltsstoffe.forEach((element) => {
      request.inhaltsstoffe.push(element.id)
    })

    Poster.postMember<RemoteResult<any>>(RestPaths.artikelPduktUpdate, request)
      .then((response) => {
        this.editWorking = false
        if (response.data.success) {
          this.editDialogVisible = false
          this.editProdukt = null
        } else {
          this.editError = true
        }
      })
      .catch(() => {
        this.editError = true
      })
  }

  inhaltsstoffeHinzufuegen() {
    this.newInhaltsstoffe.forEach((element) => {
      this.editProdukt.inhaltsstoffe.push(element)
    })
    this.newInhaltsstoffe = []
    this.inhaltsstoffeEditDialogVisible = false
  }

  inhaltsstoffeEditVerwalten() {
    this.availableInhaltsstoffe = this.inhaltsstoffe.filter((element) => {
      return (
        this.editProdukt.inhaltsstoffe.findIndex((x) => {
          return x.id === element.id
        }) < 0
      )
    })
    this.inhaltsstoffeEditDialogVisible = true
  }

  inhaltsstoffeVerwalten() {
    this.inhaltsstoffeDialogVisible = true
  }

  neuesExtra() {
    this.produktNeu = new ProduktNeu()
    this.neuDialogVisible = true
  }

  produktEinfuegen() {
    this.formDisabled = true
    this.wirdEingefuegt = true

    var request = new PduktInsertRequest()
    request.id = uuidv4()
    request.betriebId = store.state.user.betriebId
    request.extraZusatz = true
    request.bezeichnung = this.produktNeu.bezeichnung
    request.beschreibung = this.produktNeu.beschreibung
    request.artikelNr = this.produktNeu.artikelNr
    request.vegan = this.produktNeu.vegan
    request.vegetarisch = this.produktNeu.vegetarisch
    request.hot1 = this.produktNeu.hot1
    request.hot2 = this.produktNeu.hot2
    request.hot3 = this.produktNeu.hot3
    request.standardpreis = this.produktNeu.standardpreis

    this.selectedInhaltsstoffe.forEach((inhaltsstoff) => {
      request.inhaltsstoffe.push(inhaltsstoff.id)
    })

    Poster.postMember<RemoteResult<Pdukt>>(
      RestPaths.artikelPduktEinfuegen,
      request
    )
      .then((response) => {
        this.wirdEingefuegt = false
        if (response.data.success) {
          this.neuDialogVisible = false
          this.formDisabled = false
          this.produktNeu = null
          this.produkte.push(response.data.payload)
        } else {
          this.einfuegenError = true
          this.formDisabled = false
        }
      })
      .catch(() => {
        this.wirdEingefuegt = false
        this.einfuegenError = true
        this.formDisabled = false
      })
  }

  produktBearbeiten(produkt, event) {
    this.editProdukt = produkt
    this.editDialogVisible = true
    event.stopPropagation()
  }

  detailsAnzeigen(produkt) {
    this.aktuellesProdukt = produkt
    this.detailDialogVisible = true
  }

  init() {
    var request = new PduktRequest()
    request.betriebId = this.betriebId
    request.extraZusatz = true

    Poster.postMember<RemoteResult<PduktList>>(
      RestPaths.artikelPduktListe,
      request
    ).then((response) => {
      if (response.data.success) {
        this.produkte = response.data.payload.list
      }
    })

    var irequest = new IdRequest()
    irequest.id = store.state.user.betriebId

    Poster.postMember<RemoteResult<InhaltsstoffList>>(
      RestPaths.inhaltsstoffeListe,
      irequest
    ).then((response) => {
      this.inhaltsstoffe = response.data.payload.list
    })
  }
}

class ProduktNeu {
  bezeichnung: string
  beschreibung: string
  standardpreis: number = 0.0
  artikelNr: string
  inhaltsstoffe = []
  vegetarisch: boolean = false
  vegan: boolean = false
  hot1: boolean = false
  hot2: boolean = false
  hot3: boolean = false
}
