
import Vue from 'vue'
import Component from 'vue-class-component'
import TageskarteEditMemCommon from '../memcommon/TageskarteEditMemCommon.vue'
import TageskarteEditLogic from '@/logic/member/tageskarte_edit_logic'
import vuexStore from '@/store'

@Component({ components: { TageskarteEditMemCommon } })
export default class TageskarteEdit extends Vue {
  logic: TageskarteEditLogic = null

  mounted() {
    this.logic = new TageskarteEditLogic()
    this.logic.init(
      this.$route.params.preislisteId,
      vuexStore.state.user.betriebId
    )
  }
}
