
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'
import TischEditMemcommonLogic from '@/logic/memcommon/tisch_edit_memcommon_logic'
import router from '@/router'

@Component
export default class TischEditMemCommon extends Vue {
  @Prop() logic: TischEditMemcommonLogic

  speichern() {
    this.logic.speichern()
  }

  navBack() {
    router.back()
  }
}
