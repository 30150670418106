
import Kuechenaufgabe from '@/entities/member/kuechenaufgabe'
import { Zeitraum } from '@/logic/formatter'
import Component, { mixins } from 'vue-class-component'
import { Emit, Prop, PropSync } from 'vue-property-decorator'
import Calculator from '../mixins/Calculator.vue'

@Component
export default class KuecheRow extends mixins(Calculator) {
  @Prop() aufgabe: Kuechenaufgabe
  @PropSync('color') colorSync: string

  duration: Zeitraum = null

  get inverse() {
    return this.duration.inverse
  }

  mounted() {
    this.berechne()
    setInterval(this.berechne, 1000)
  }

  berechne() {
    if (this.aufgabe.abholung) {
      this.duration = this.berechneZeitraum(this.aufgabe.abholung.gewAbholzeit)
    } else if (this.aufgabe.lieferung) {
      this.duration = this.berechneZeitraum(
        this.aufgabe.lieferung.gewLieferzeit
      )
    }
  }

  @Emit('aufgabeClicked')
  aufgabeClicked() {}
}
