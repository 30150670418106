
import Component, { mixins } from 'vue-class-component'
import Calculator from '../mixins/Calculator.vue'
import ArtikelRow from '../custom/ArtikelRow.vue'
import moment from 'moment'
import { AnforderungStatus, Bezahlart } from '@/entities/common/enums'
import FilialeHeader from '../custom/FilialeHeader.vue'
import DetailsReservierungLogic from '@/logic/bestellung/details_reservierung_logic'
import NachbestellungSheet from '../custom/NachbestellungSheet.vue'

@Component({ components: { ArtikelRow, FilialeHeader, NachbestellungSheet } })
export default class DetailsReservierung extends mixins(Calculator) {
  logic?: DetailsReservierungLogic = null
  stornierenDialogVisible: boolean = false

  formatiereBoolean(value: boolean) {
    return value ? 'ja' : 'nein'
  }

  formatiereBezahlart(value: string) {
    switch (value) {
      case Bezahlart.BARZAHLUNG:
        return 'Zahlung an der Kasse'
      case Bezahlart.PAYPAL:
        return 'PayPal'
    }
  }

  mounted() {
    this.logic = new DetailsReservierungLogic()
    this.logic.init()
  }

  computeDauer(value) {
    return moment(value).format('HH:mm')
  }

  computeStatusIcon(service) {
    if (service.status == AnforderungStatus.OFFEN) {
      return 'mdi-timer-sand-empty'
    }
  }

  formatiereServiceStatus(value) {
    switch (value) {
      case AnforderungStatus.OFFEN:
        return 'offen'
      case AnforderungStatus.ANGENOMMEN:
        return 'angenommen'
      case AnforderungStatus.ABGELEHNT:
        return 'abgelehnt'
      case AnforderungStatus.ERLEDIGT:
        return 'erledigt'
    }
  }

  serviceAnfordern() {
    this.logic.serviceAnfordern()
  }

  nachbestellen() {
    this.logic.nachbestellen()
  }

  vorbestellen() {
    this.logic.vorbestellen()
  }

  bestellen() {
    this.logic.bestellen()
  }

  einbuchen() {
    this.logic.einbuchen()
  }

  bezahlen() {
    this.logic.bezahlen()
  }

  stornieren() {
    this.logic.stornieren(() => {
      this.stornierenDialogVisible = false
    })
  }
}
