import Bestellung from "@/entities/common/bestellung"
import { LoadState } from "@/entities/common/enums"
import BestellungList from "@/entities/common/lists/bestellung_list"
import RemoteResult from "@/entities/common/remote_result"
import IdRequest from "@/entities/common/request/id_request"
import FilialeSimple from "@/entities/member/filiale_simple"
import FilialeSimpleList from "@/entities/member/lists/filiale_simple_list"
import BestellungenRequest from "@/entities/member/request/bestellungen_request"
import router from "@/router"
import store from '@/store'
import Poster from "@/util/poster"
import RestPaths from "@/util/rest_paths"
import RouteNames from "@/util/route_names"
import BetriebDetailsLogic from "./betrieb_details_logic"

export default class BestellverwaltungLogic {
  loadState: string = LoadState.IDLE

  parent?: BetriebDetailsLogic = null

  filter: Filter = new Filter()

  bestellungen: Bestellung[] = []
  selected: Bestellung[] = []

  init(parent: BetriebDetailsLogic) {
    this.parent = parent
  }

  bestellungAnzeigen(bestellung: Bestellung) {
    router.push({
      name: RouteNames.MEMBER.BESTELLUNG,
      params: { id: bestellung.id },
    })
  }

  aktualisieren() {
    this.loadState = LoadState.LOADING

    var request = new BestellungenRequest()
    request.betriebId = this.parent.betrieb.id
    request.filialeBeliebig = this.filter.filialeBeliebig
    request.filialeId = this.filter.filiale

    request.abgeschlossene = this.filter.abgeschlossene
    request.stornierte = this.filter.stornierte
    request.bezahlte = this.filter.bezahlte
    request.datumVon = this.filter.datumVon
    request.datumBis = this.filter.datumBis

    Poster.postMember<RemoteResult<BestellungList>>(
      RestPaths.bestellungMitarbeiterListeComplex,
      request
    ).then((response) => {
      this.bestellungen = response.data.payload.list
      this.loadState = LoadState.COMPLETED
    })
  }
}

class Filter {
  filialeBeliebig: boolean = true
  filiale: string = null
  datumVon: string = null
  datumBis: string = null
  abgeschlossene: number = 0
  stornierte: number = 0
  bezahlte: number = 0
  bestellarten: number[] = []
}
