import ArtikelData from './artikel_data'

export default class BestellungInsertRequest {
  id?: string
  bezeichnung?: string
  identifier?: string
  fcmKey?: string
  filialeId?: string
  terminart?: string
  artikelliste: ArtikelData[] = []
  bezahlart?: string
  sofort?: Sofort
  reservierung?: Reservierung
  abholung?: Abholung
  lieferung?: Lieferung
  paypalOrder?: any

  constructor() {}

  createAbholung() {
    this.abholung = new Abholung()
  }

  createReservierung() {
    this.reservierung = new Reservierung()
  }

  createSofortbestellung() {
    this.sofort = new Sofort()
  }

  createLieferung() {
    this.lieferung = new Lieferung()
  }
}

class Sofort {
  id?: string
  tischId?: string
  personen: number = 0
  bestellart?: string
}

class Reservierung {
  id?: string
  tischId?: string
  art?: string
  zeitVon?: string
  zeitBis?: string
  personen?: number = 0
  kundeNachname?: string
  kundeVorname?: string
  kundeEmail?: string
  kundeTelefon?: string
  kundeKommentare?: string
  bestellart?: string
}

class Abholung {
  id?: string
  gewAbholZp?: string
  type?: string
  kundeNachname?: string
  kundeVorname?: string
  kundeTelefon?: string
  kundeEmail?: string
  direktabholung?: boolean = false
  tischId?: string
  verzehrart?: string
}

class Lieferung {
  id?: string
  lieferzeitpunkt?: string
  zeitType?: string
  kundeNachname?: string
  kundeVorname?: string
  kundeStrasse?: string
  kundePlz?: string
  kundeOrt?: string
  kundeAdresszusatz?: string
  kundeTelefon?: string
  kundeEmail?: string
  kommentare?: string
  liefergebietId?: string
}
