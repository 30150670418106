import RemoteResult from '@/entities/common/remote_result'
import Filiale from '@/entities/member/filiale'
import OeffnungszeitOverview, {
  Oeffnung as Oeffn,
  OeffnungData,
} from '@/entities/member/oeffnungszeit_overview'
import OeffnungszInsertRequest from '@/entities/member/request/oeffnungsz_insert_request'
import OeffnungszeitOverviewRequest from '@/entities/member/request/oeffnungszeit_overview_request'
import Poster from '@/util/poster'
import RestPaths from '@/util/rest_paths'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import MemberBaseLogic from '../member_base_logic'
import IdRequest from '@/entities/common/request/id_request'
import OeffnungszUpdateRequest from '@/entities/member/request/oeffnungsz_update_request'
import ZeitraumData from '@/entities/common/zeitraum_data'

export default class OeffnungszeitAusnahmenFilialeMemcommonLogic extends MemberBaseLogic {
  filiale?: Filiale = null
  _datum?: string = null
  geschlossen: boolean = false
  oeffnungen?: Oeffnung[] = []
  dialogOpen: boolean = false
  editDialogOpen: boolean = false
  oeffnungstag?: Oeffnungstag = null
  ausnahme?: Oeffnungstag = null

  ausnahmen: Oeffn[] = []
  events: any[] = []

  aktuellesDatum?: Oeffn = null
  pickerDate?: string = null

  get datum() {
    return this._datum
  }

  set datum(value: string) {
    this._datum = value

    this.ausnahmen.forEach((element) => {
      if (element.datum === value) {
        this.aktuellesDatum = element

        this.oeffnungstag = new Oeffnungstag()
        this.oeffnungstag.id = element.ausnahme.id
        this.oeffnungstag.datum = element.datum
        if (element.ausnahme) {
          this.oeffnungstag.geschlossen = !element.ausnahme.geoeffnet
          element.ausnahme.zeitraeume.forEach((zeitraum) => {
            var oeffnung = new Oeffnung()
            oeffnung.von = moment(zeitraum.zeitVon, 'hh:mm:ss').format('hh:mm')
            oeffnung.bis = moment(zeitraum.zeitBis, 'hh:mm:ss').format('hh:mm')
            this.oeffnungstag.ausnahmen.push(oeffnung)
          })
        }
        return
      }
    })
  }

  gebeOeffnungszeitMin(index: number) {
    if (index == null) return null
    if (index > 0) return this.oeffnungen[index - 1].bis
  }

  gebeOeffnungszeitMinEdit(index: number) {
    if (index == null) return null
    if (index > 0) return this.oeffnungstag.ausnahmen[index - 1].bis
  }

  zeitLoeschen(index: number) {
    if (index == null) return
    if (index > 0) return this.oeffnungen.splice(index, 1)
  }

  zeitLoeschenEdit(index: number) {
    if (index == null) return
    if (index > 0) return this.oeffnungstag.ausnahmen.splice(index, 1)
  }

  getEventColor(date: string) {
    var result = null
    this.ausnahmen.forEach((element) => {
      if (element.datum === date) {
        if (element.ausnahme) {
          if (element.ausnahme.geoeffnet) {
            result = 'blue'
            return
          } else {
            result = 'black'
            return
          }
        } else {
          if (element.normal.geoeffnet) {
            result = 'green lighten-1'
          } else {
            result = 'grey lighten-1'
          }
          return
        }
      }
    })
    return result
  }

  functionEvents(date) {
    this.ausnahmen.forEach((element) => {
      if (element.datum === date) {
        return true
      }
    })
  }

  reloadDates(val) {
    var request = new OeffnungszeitOverviewRequest()
    request.monatJahr = moment(val, 'yyyy-MM').format('MM.yyyy')
    request.filialeId = this.filiale.id

    Poster.postMember<RemoteResult<OeffnungszeitOverview>>(
      RestPaths.oeffnungszeitOverview,
      request
    ).then((response) => {
      this.ausnahmen = response.data.payload.oeffnungen
      console.log(this.ausnahmen)
      this.events = this.ausnahmen.map((x) => {
        return x.datum
      })
    })
  }

  ausnahmeErstellen() {
    this.ausnahme = new Oeffnungstag()
    this.ausnahme.datum = this.aktuellesDatum.datum
    this.dialogOpen = true
  }

  ausnahmeEinfuegen() {
    var request = new OeffnungszInsertRequest()
    request.id = uuidv4()
    request.filialeId = this.filiale.id
    request.datum = this.ausnahme.datum
    request.geoeffnet = !this.ausnahme.geschlossen

    this.ausnahme.ausnahmen.forEach((oeffnung) => {
      var zeitraumData = new ZeitraumData()
      zeitraumData.zeitVon = oeffnung.von
      zeitraumData.zeitBis = oeffnung.bis
      request.oeffnungszeiten.push(zeitraumData)
    })

    Poster.postMember<RemoteResult<OeffnungData>>(
      RestPaths.oeffnungszeitOeffnungszeitEinfuegen,
      request
    ).then((response) => {
      this.dialogOpen = false
      this.geschlossen = false
      this.oeffnungen = []

      this.aktuellesDatum.ausnahme = response.data.payload
    })
  }

  ausnahmeLoeschen() {
    var request = new IdRequest()
    request.id = this.aktuellesDatum.ausnahme.id

    Poster.postMember<RemoteResult<Boolean>>(
      RestPaths.oeffnungszeitOeffnungszeitLoeschen,
      request
    ).then((response) => {
      if (response.data.success) {
        this.aktuellesDatum.ausnahme = null
      }
    })
  }

  ausnahmeBearbeiten() {
    this.editDialogOpen = true
    this.ausnahme = new Oeffnungstag()
    this.ausnahme.datum = this.aktuellesDatum.datum
  }

  ausnahmeSpeichern() {
    var request = new OeffnungszUpdateRequest()
    request.id = this.oeffnungstag.id
    request.geoeffnet = !this.oeffnungstag.geschlossen
    this.oeffnungstag.ausnahmen.forEach((a) => {
      var zd = new ZeitraumData()
      zd.zeitVon = a.von
      zd.zeitBis = a.bis
      request.oeffnungszeiten.push(zd)
    })

    Poster.postMember<RemoteResult<any>>(
      RestPaths.oeffnungszeitOeffnungszeitSpeichern,
      request
    ).then((response) => {
      if (response.data.success) {
        this.editDialogOpen = false
        this.aktuellesDatum.ausnahme.geoeffnet = !this.oeffnungstag.geschlossen
        this.aktuellesDatum.ausnahme.zeitraeume = []
        this.oeffnungstag.ausnahmen.forEach((element) => {
          var zrData = new ZeitraumData()
          zrData.zeitVon = element.von
          zrData.zeitBis = element.bis
          this.aktuellesDatum.ausnahme.zeitraeume.push(zrData)
        })
      } else {
        console.log(response.data.errorCode)
      }
    })
  }

  oeffnungHinzufuegen() {
    this.ausnahme.ausnahmen.push(new Oeffnung())
    //this.oeffnungen.push(new Oeffnung())
  }

  editOeffnungHinzufuegen() {
    this.oeffnungstag.ausnahmen.push(new Oeffnung())
  }

  init(filialeId: string) {
    var request = new IdRequest()
    request.id = filialeId

    Poster.postMember<RemoteResult<Filiale>>(
      RestPaths.filialeDetails,
      request
    ).then((response) => {
      if (response.data.success) {
        this.filiale = response.data.payload
      }
    })

    this.oeffnungen.push(new Oeffnung())
  }
}

class Oeffnung {
  von?: string = null
  bis?: string = null
  vonOpen: boolean = false
  bisOpen: boolean = false
}

class Oeffnungstag {
  id: string
  datum: string
  geschlossen: boolean = false
  ausnahmen: Oeffnung[] = []
}

export { Oeffnung }
