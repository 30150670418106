import Tisch from '@/entities/common/tisch'
import router from '@/router'
import RouteNames from '@/util/route_names'
import BetriebDetailsLogic from './betrieb_details_logic'
import TischeMemcommonLogic from '../memcommon/tische_memcommon_logic'
import FilialeSimple from '@/entities/member/filiale_simple'

export default class TischeLogic extends TischeMemcommonLogic {
  parent?: BetriebDetailsLogic = null
  get filialen(): FilialeSimple[] {
    return this.parent.filialen
  }

  tischAnzeigen(tisch: Tisch) {
    router.push({
      name: RouteNames.SYSTEM.TISCH_DETAILS,
      params: { id: tisch.id },
    })
  }
}