import RemoteResult from "@/entities/common/remote_result"
import IdRequest from "@/entities/common/request/id_request"
import PasswortUpdateRequest from "@/entities/common/request/passwort_udpate_request"
import Poster from "@/util/poster"
import RestPaths from "@/util/rest_paths"

export default class PasswortAendernLogic {
  id?: string
  linkValid?: boolean = null
  showPassword: boolean = false

  passwort?: string = ''
  passwortWiederholen?: string = ''

  passwortGeaendert?: boolean = false

  passwortRules: any[] = [this.vorhanden, this.min6]
  passwortWiederholenRules: any[] = [this.vorhanden, this.min6, this.validatePw]

  vorhanden(x: any) {
    if (!!!x) return 'Bitte Passwort angeben'
  }

  min6(x: any) {
    if (x.length < 6) return 'Bitte min. 6 Zeichen'
  }

  validatePw() {
    //if (this.passwort !== this.passwortWiederholen)
    //  return 'Passwörter müssen übereinstimmen'
    return true
  }

  init(id: string) {
    this.id = id
    var request = new IdRequest()
    request.id = this.id

    Poster.postUser<RemoteResult<boolean>>(
      RestPaths.kundePasswortAendernValid,
      request
    ).then((response) => {
      if (response.data.payload == true) {
        this.linkValid = true
      } else {
        this.linkValid = false
      }
    })
  }

  passwortAendern() {
    var request = new PasswortUpdateRequest()
    request.neuesPasswort = this.passwort
    request.randomLink = this.id

    Poster.postUser<RemoteResult<boolean>>(
      RestPaths.kundePasswortAendern,
      request
    ).then((response) => {
      var success = response.data.payload
      if (success) {
        this.passwortGeaendert = true
      } else {
        console.log("nicht geändert")
      }
    })
  }
}